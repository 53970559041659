import styled from "@xstyled/styled-components";

import { modularScale } from "@otta/design-tokens";

export const ExperienceSummaryContainer = styled.div`
  display: grid;
  grid-template-columns: ${modularScale()} 1fr;
  column-gap: sm;
  align-items: start;
`;
