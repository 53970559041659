import { Link as RouterLink } from "react-router-dom";

import { Button, Middle, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links";
import { modularScale } from "@otta/design-tokens";

export function NoCompany(): React.ReactElement {
  return (
    <Middle maxWidth={600} style={{ padding: modularScale() }}>
      <Spacing size={-1}>
        <Text as="h2" bold align="center" size={3}>
          You don't have access to a company
        </Text>
        <Text align="center">
          Log out and try another account, or get in touch with us at{" "}
          <Link to="mailto:companies@otta.com">companies@otta.com</Link>
        </Text>
        <Spacing>
          <Button level="primary" as={RouterLink} to="/logout">
            Logout
          </Button>
        </Spacing>
      </Spacing>
    </Middle>
  );
}
