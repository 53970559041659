import styled from "@xstyled/styled-components";

import { Text } from "../Typography";

import { pxToRem, palette } from "@otta/design-tokens";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Ellipsis = styled.div`
  display: flex;
  height: ${pxToRem(40)};
  flex 0 0 ${pxToRem(40)};
  justify-content: center;
  align-items: center;
  margin-bottom: sm;
  margin-right: sm;
  user-select: none;
`;

const PageNumber = styled.button<{ selected?: boolean }>`
  display: flex;
  height: ${pxToRem(40)};
  flex 0 0 ${pxToRem(40)};
  background-color: ${({ selected }) =>
    selected ? palette.extended.yellow.shade100 : palette.brand.white};
  border: ${({ selected }) =>
    selected ? `1px solid ${palette.brand.yellow}` : "none"};
  border-radius: ${pxToRem(4)};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: sm;
  &:not(:last-child) {
    margin-right: sm;
  }
  &:hover {
    text-decoration: underline;
  }
  &:active {
    color: unset;
  }
`;

interface PaginationProps {
  current: number;
  total: number;
  onChange: (pageNumber: number) => void;
  // maxVisible will round up to the nearest odd number, so that the selected page will be in the middle
  maxVisible?: number;
}

type Page = number | "ELLIPSIS";

const getPages = (current: number, total: number, maxVisible: number) => {
  if (total <= maxVisible) {
    return Array.from({ length: total }, (_, i) => i + 1);
  }

  const leftDistance = current - 1;
  const rightDistance = total - current;
  const maxVisiblePerSide = Math.floor(maxVisible / 2);
  const collapseLeft = leftDistance > maxVisiblePerSide;
  const collapseRight = rightDistance > maxVisiblePerSide;
  const buffer = maxVisiblePerSide - 2;

  const leftSide: Page[] = [];
  for (let i = 1; i <= current; i++) {
    if (i == 2 && collapseLeft) {
      i +=
        leftDistance -
        buffer -
        Math.max(0, maxVisiblePerSide - rightDistance) -
        1;
      leftSide.push("ELLIPSIS");
    }
    leftSide.push(i);
  }

  const rightSide: Page[] = [];
  for (let i = total; i > current; i--) {
    if (i == total - 1 && collapseRight) {
      i -=
        rightDistance -
        buffer -
        Math.max(0, maxVisiblePerSide - leftDistance) -
        1;
      rightSide.push("ELLIPSIS");
    }
    rightSide.push(i);
  }

  return [...leftSide, ...rightSide.reverse()];
};

/**
 *
 * ```ts
 *
 * import { Pagination } from '@otta/design'
 *
 * ```
 */

export function Pagination({
  current,
  total,
  onChange,
  maxVisible = 9,
}: PaginationProps): React.ReactElement {
  const pages = getPages(current, total, maxVisible);

  return (
    <Container>
      {pages.map((page, i) =>
        page === "ELLIPSIS" ? (
          <Ellipsis key={i} data-testid="page">
            <Text size={1}>...</Text>
          </Ellipsis>
        ) : (
          <PageNumber
            data-testid="page"
            key={i}
            onClick={() => onChange(page)}
            selected={current === page}
          >
            <Text bold>{page}</Text>
          </PageNumber>
        )
      )}
    </Container>
  );
}
