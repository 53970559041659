import { MutationUpdaterFn, useMutation, useQuery } from "@apollo/client";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useCallback } from "react";
import { toast } from "react-toastify";

import { SourcingPageHeader } from "./components/SourcingPageHeader";
import { SourcingPage } from "./enums";
import { PaginatedCandidatesList } from "./components/CandidatesList/PaginatedCandidatesList";
import {
  CandidatesContainer,
  PageContainer,
  SpinnerWrapper,
} from "./components/shared";

import { handleMutationError } from "@hire/errors";
import {
  CreateCandidateReactionDocument,
  DiscardedCandidatesDocument,
  RestoreCandidatesToPoolDocument,
} from "@hire/schema";
import { Spinner } from "@otta/shared-components";

const removeCandidateFromCache =
  (candidateId: string, variables: { jobId: string }): MutationUpdaterFn =>
  cache => {
    cache.updateQuery(
      {
        query: DiscardedCandidatesDocument,
        variables,
      },
      data => {
        if (!data?.discardedCandidates) {
          return null;
        }

        return {
          discardedCandidates: data.discardedCandidates.filter(
            ({ externalId: cacheId }) => cacheId !== candidateId
          ),
        };
      }
    );
  };

export function SeenCandidates(): React.ReactElement {
  const navigate = useNavigate();
  const { jobId, companySlug } = useParams();

  const { data, loading } = useQuery(DiscardedCandidatesDocument, {
    variables: {
      jobId: jobId as string,
    },
    fetchPolicy: "network-only",
  });

  const [createReaction] = useMutation(CreateCandidateReactionDocument);

  const [restoreCandidates] = useMutation(RestoreCandidatesToPoolDocument, {
    variables: { jobId: jobId as string },
    onCompleted: () => {
      navigate(`/${companySlug}/jobs/${jobId}/sourcing/search`);
      toast.success("Candidates added back into pool");
    },
  });

  const shortlistCandidate = useCallback(
    (candidateId: string) => {
      createReaction({
        variables: {
          candidateId,
          jobId: jobId as string,
          direction: true,
        },
        update: removeCandidateFromCache(candidateId, {
          jobId: jobId as string,
        }),
        onError: handleMutationError,
        onCompleted: () => {
          toast.success("Candidate shortlisted");
        },
      });
    },
    [createReaction, jobId]
  );

  const messageCandidate = useCallback(
    (candidateId: string) => {
      navigate({
        pathname: `${candidateId}/message`,
        search: location.search,
      });
    },
    [navigate]
  );

  const handleAfterMessage = useCallback(
    (candidateId: string) => {
      shortlistCandidate(candidateId);
      navigate({
        pathname: `.`,
        search: location.search,
      });
    },
    [navigate, shortlistCandidate]
  );

  const candidates = data?.discardedCandidates ?? [];

  return (
    <PageContainer>
      <Outlet context={{ onAfterMessage: handleAfterMessage }} />
      <CandidatesContainer>
        <SourcingPageHeader
          currentPage={SourcingPage.Seen}
          numCandidates={candidates.length}
          loading={loading}
          restoreCandidates={restoreCandidates}
        />
        {loading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <PaginatedCandidatesList
            candidates={candidates}
            type={SourcingPage.Seen}
            messageCandidate={messageCandidate}
            shortlistCandidate={shortlistCandidate}
          />
        )}
      </CandidatesContainer>
    </PageContainer>
  );
}
