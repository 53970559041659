import styled, { css, up } from "@xstyled/styled-components";

import { Card, Heading, Spacing, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";

const CardContainer = styled.div`
  display: flex;
  gap: md;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  ${up(
    "tablet",
    css`
      flex-wrap: nowrap;
    `
  )};
`;

const StyledCard = styled(Card)<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${pxToRem(325)};
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  ${up(
    "desktop",
    css`
      padding-right: 0;
    `
  )};
`;

const CardImage = styled.img`
  width: ${pxToRem(120)};
  height: ${pxToRem(120)};

  ${up(
    "tablet",
    css`
      display: none;
    `
  )};

  ${up(
    "desktop",
    css`
      display: block;
    `
  )};
`;

const CandidateNotifiedDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: sm;
`;

const Tag = styled(Text).attrs({ size: -1, as: "span" })`
  padding: xs;
  border-radius: ${pxToRem(8)};
  background-color: ${palette.brand.green};
`;

const formatNumber = (number?: number) =>
  number ? number.toLocaleString() : "N/A";

export function Overview({
  candidatePool,
  candidatesViewed,
  candidateNotified,
  candidateNotifiedLastWeek,
  firstPosted,
  lastRefreshed,
}: {
  candidatePool?: number;
  candidatesViewed?: number;
  candidateNotified?: number;
  candidateNotifiedLastWeek?: number;
  sourcingPool?: number;
  firstPosted: string | null;
  lastRefreshed: string | undefined;
}) {
  return (
    <Spacing>
      <Spacing>
        <Text bold size={2}>
          Overview
        </Text>
        {firstPosted ? (
          <Text>
            This job was posted on {firstPosted}. This information is updated
            daily. {!!lastRefreshed && <>Last updated on {lastRefreshed}.</>}
          </Text>
        ) : null}
      </Spacing>
      <CardContainer>
        {typeof candidatePool === "number" && candidatePool > 0 && (
          <DataCard
            value={formatNumber(candidatePool)}
            description="Matched candidates active in the last 12 weeks"
            image="https://static.otta.com/images/hire-app/detail_icon_larger.png"
            backgroundColor={palette.beige.shade200}
          />
        )}
        {typeof candidatesViewed === "number" && (
          <DataCard
            value={formatNumber(candidatesViewed)}
            description="Candidates viewed this job"
            image="https://static.otta.com/images/hire-app/MagnifyingGlass.png"
            backgroundColor={palette.beige.shade200}
          />
        )}
        {typeof candidateNotified === "number" && (
          <DataCard
            value={formatNumber(candidateNotified)}
            description={
              <CandidateNotifiedDescription>
                <Tag>
                  <strong>+{formatNumber(candidateNotifiedLastWeek)}</strong>{" "}
                  from last week
                </Tag>
                <Text>
                  Candidates notified about your role{" "}
                  <TooltipWithIcon
                    content="Candidates receive push notifications or emails about new roles daily or weekly."
                    icon={<Icon icon="circle-info" />}
                  />
                </Text>
              </CandidateNotifiedDescription>
            }
            image="https://static.otta.com/images/hire-app/megaphone.webp"
            backgroundColor={palette.beige.shade200}
          />
        )}
      </CardContainer>
    </Spacing>
  );
}

const DataCard = ({
  value,
  description,
  image,
  backgroundColor,
}: {
  value: string;
  description: React.ReactNode;
  image: string;
  backgroundColor: string;
}) => {
  return (
    <StyledCard backgroundColor={backgroundColor}>
      <div>
        <Spacing>
          <Heading size={4}>{value}</Heading>
          {typeof description === "string" ? (
            <Text>{description}</Text>
          ) : (
            description
          )}
        </Spacing>
      </div>

      <CardImage src={image} alt={""} />
    </StyledCard>
  );
};
