import { useFormik } from "formik";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";

import { ExplainerText } from "./ExplainerText";
import { DetailsFormFields } from "./types";

import {
  ImportedCandidateFragment,
  JobPipelineStagesFragment,
} from "@hire/schema";
import { MoreInfoFieldWrapper } from "@hire/components/form/MoreInfoFieldWrapper";
import { Button, InputField, SelectField, Spacing } from "@otta/design";

const FullWidthButton = styled(Button)`
  width: 100%;
`;

const validationSchema = Yup.object({
  firstName: Yup.string().required().max(100).label("First name"),
  lastName: Yup.string().required().max(100).label("Last name"),
  phoneNumber: Yup.string().max(33).label("Phone number"),
  source: Yup.string().max(100).label("Source"),
  sourcedUrl: Yup.string().max(1_000).label("LinkedIn URL"),
  portfolioUrl: Yup.string().max(1_000).label("Other URL"),
  currentEmployer: Yup.string().max(100).label("Current company"),
});

interface DetailsFormProps {
  emailAddress: string;
  onSubmit: (input: DetailsFormFields) => void;
  importedCandidate: ImportedCandidateFragment | null;
  stages: JobPipelineStagesFragment[];
  currentStage?: JobPipelineStagesFragment;
}

export const DetailsForm = ({
  emailAddress,
  onSubmit,
  importedCandidate,
  stages,
  currentStage,
}: DetailsFormProps): React.ReactElement => {
  const stageOptions = stages?.map(stage => {
    return { label: stage.name, value: stage.id };
  });

  const defaultStageId = (
    stageOptions.find(o => o.label == "Outreach sent") ?? stageOptions[0]
  ).value;

  const initialValues: DetailsFormFields = {
    firstName: importedCandidate?.firstName ?? "",
    lastName: importedCandidate?.lastName ?? "",
    phoneNumber: importedCandidate?.phoneNumber ?? undefined,
    sourcedUrl: importedCandidate?.sourcedUrl ?? undefined,
    portfolioUrl: importedCandidate?.portfolioUrl ?? undefined,
    currentEmployer: importedCandidate?.currentEmployer ?? undefined,
    source: importedCandidate?.source ?? undefined,
    attachmentUrl: importedCandidate?.attachmentUrl ?? undefined,
    pipelineStageId: currentStage?.id ?? defaultStageId,
  };

  const form = useFormik<DetailsFormFields>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <ExplainerText
        emailAddress={emailAddress}
        importedCandidate={importedCandidate}
        currentStageName={currentStage?.name}
      />
      <form onSubmit={form.handleSubmit}>
        <Spacing size={2}>
          <InputField
            value={form.values.firstName}
            name="firstName"
            label="First name"
            onChange={form.handleChange("firstName")}
            onBlur={form.handleBlur("firstName")}
            error={form.touched.firstName ? form.errors.firstName : undefined}
          />
          <InputField
            value={form.values.lastName}
            name="lastName"
            label="Last name"
            onChange={form.handleChange("lastName")}
            onBlur={form.handleBlur("lastName")}
            error={form.touched.lastName ? form.errors.lastName : undefined}
          />
          <InputField
            value={form.values.phoneNumber}
            name="phoneNumber"
            label="Phone number"
            onChange={form.handleChange("phoneNumber")}
            onBlur={form.handleBlur("phoneNumber")}
            error={
              form.touched.phoneNumber ? form.errors.phoneNumber : undefined
            }
          />
          <InputField
            value={form.values.source}
            name="source"
            label="Source"
            onChange={form.handleChange("source")}
            onBlur={form.handleBlur("source")}
            error={form.touched.source ? form.errors.source : undefined}
            placeholder="e.g. LinkedIn, referred by Melissa"
          />
          <InputField
            value={form.values.sourcedUrl}
            name="sourcedUrl"
            label="LinkedIn URL"
            onChange={form.handleChange("sourcedUrl")}
            onBlur={form.handleBlur("sourcedUrl")}
            error={form.touched.sourcedUrl ? form.errors.sourcedUrl : undefined}
          />
          <InputField
            value={form.values.portfolioUrl}
            name="portfolioUrl"
            label="Other URL"
            onChange={form.handleChange("portfolioUrl")}
            onBlur={form.handleBlur("portfolioUrl")}
            error={
              form.touched.portfolioUrl ? form.errors.portfolioUrl : undefined
            }
            placeholder="e.g. GitHub URL"
          />
          <InputField
            value={form.values.currentEmployer}
            name="currentEmployer"
            label="Current company"
            onChange={form.handleChange("currentEmployer")}
            onBlur={form.handleBlur("currentEmployer")}
            error={
              form.touched.currentEmployer
                ? form.errors.currentEmployer
                : undefined
            }
          />
          <MoreInfoFieldWrapper
            label="Add to stage"
            fieldName="pipelineStageId"
          >
            <SelectField
              value={stageOptions.find(
                o => o.value === form.values.pipelineStageId
              )}
              onChange={e =>
                e && form.setFieldValue("pipelineStageId", e.value)
              }
              onBlur={form.handleBlur("pipelineStageId")}
              options={stageOptions}
              inputId="pipelineStageId"
              name="pipelineStageId"
            />
          </MoreInfoFieldWrapper>

          <FullWidthButton type="submit" level="primary">
            {importedCandidate ? "Update candidate" : "Add candidate"}
          </FullWidthButton>
        </Spacing>
      </form>
    </>
  );
};
