import styled from "@xstyled/styled-components";
import { useId } from "react";

import { palette, pxToRem } from "@otta/design-tokens";
import { Input, Label, Text } from "@otta/design";

const LabelContainer = styled.div`
  justify-content: space-evenly;
  display: flex;
  width: 100%;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
`;
/**
 * This tremendous hack is styling some spans when the component is visible
 * to highlight the entire field, not just the min/max, when focused
 * The alternative is useState misery
 */
const HackyBorderSpan = styled.span`
  border: 1px solid ${palette.grayscale.shade400};
  border-radius: ${pxToRem(5)};
  pointer-events: none;
  position: absolute;
  display: block;
  inset: 0 0 0 0;
`;

/**
 * This tremendous hack is styling the span when the component is visible
 * to highlight the entire field, not just the min/max, when focused
 * The alternative is useState misery
 */
const StyledInput = styled(Input)`
  background-color: transparent;
  -moz-appearance: textfield;
  pointer-events: auto;
  text-align: center;
  border: none;
  &:focus {
    border: none;
  }
  &[aria-invalid="true"] ~ span:last-child {
    border: 2px solid ${palette.brand.red};
    margin: -2px;
  }
  &:focus ~ span:last-child {
    border: 2px solid black;
    margin: -2px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface RangeProps {
  className?: string;
  min?: React.InputHTMLAttributes<HTMLInputElement>;
  max?: React.InputHTMLAttributes<HTMLInputElement>;
}

/**
 * Pure HTML range element that doesn't know about Formik
 * It simply renders two text fields and lets you do whatever with them
 */
export const Range = ({ min, max, className }: RangeProps) => {
  const minId = useId();
  const maxId = useId();

  return (
    <div className={className}>
      <LabelContainer>
        <Label htmlFor={minId}>
          <Text bold size={-1}>
            Minimum
          </Text>
        </Label>
        <Separator>&nbsp;</Separator>
        <Label htmlFor={maxId}>
          <Text bold size={-1}>
            Maximum
          </Text>
        </Label>
      </LabelContainer>
      <InputContainer>
        <StyledInput id={minId} type="number" {...min} />
        <Separator>
          <Text color={palette.grayscale.shade400}>&ndash;</Text>
        </Separator>
        <StyledInput id={maxId} type="number" {...max} />
        <HackyBorderSpan />
      </InputContainer>
    </div>
  );
};
