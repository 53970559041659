import { useQuery } from "@apollo/client";

import { CompanyJobIdDocument } from "@hire/schema";

export const useJobInternalId = (
  jobExternalId: string
): { loading: boolean; jobInternalId: string } => {
  const { data, loading } = useQuery(CompanyJobIdDocument, {
    variables: {
      externalId: jobExternalId,
    },
  });

  return { loading, jobInternalId: data?.companyJob?.id ?? "" };
};
