import { useMutation, useQuery } from "@apollo/client";
import styled from "@xstyled/styled-components";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useState } from "react";

import { MessageRequestVariableButtons } from "./MessageRequestVariableButtons";
import { MessageRequestJobSelector } from "./MessageRequestJobSelector";
import {
  MessageRequestTemplateSelector,
  MessageTemplateValue,
} from "./MessageRequestTemplateSelector";
import {
  MessageRequestCreateTemplateForm,
  messageTemplateMutationOptions,
} from "./MessageRequestCreateTemplateForm";

import { handleMutationError } from "@hire/errors";
import {
  AllTemplatesDocument,
  CreateCandidateMessageRequestDocument,
  MessageTemplateTypeEnum,
  UpdateMessageTemplateDocument,
} from "@hire/schema";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Icon } from "@otta/icons";
import {
  Label as DefaultLabel,
  VerticalSpacing,
  Button as DefaultButton,
  TextareaField,
} from "@otta/design";

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled(DefaultLabel)`
  margin-right: xs;
`;

const Button = styled(DefaultButton)`
  width: 100%;
  display: block;
`;

const MESSAGE_TOOLTIP =
  "The variables {candidateName}, {jobTitle}, {companyName} and {recruiterName} can be used within a message template";

const MESSAGE_PLACEHOLDER = `Hi {candidateName},

I'm currently looking for a {jobTitle} to join the team, and you look like a great fit for the role because of your experience at Company X.

Are you available at any point over the next few days to discuss the role in more detail? In the meantime, you can read more about us and the role by viewing the job description.

Best,
{recruiterName}`;

const MESSAGED_RECENTLY_ERROR =
  "This candidate was just messaged recently by someone on your team - wait a month before messaging this candidate again";

const validationSchema = Yup.object({
  message: Yup.string()
    .required("Enter a message")
    .min(100, "Enter at least 100 characters")
    .max(2500, "Enter not more than 2500 characters"),
});

interface MessageRequestFormFields {
  jobId: string;
  message: string;
  messageTemplate?: MessageTemplateValue;
}

interface MessageRequestFormProps {
  onAfterMessage: (candidateId: string) => void;
}

export function MessageRequestForm({
  onAfterMessage,
}: MessageRequestFormProps): React.ReactElement {
  const { jobId, candidateId } = useParams();
  const [isCreatingMessageTemplate, setIsCreatingMessageTemplate] =
    useState(false);

  const { data: allTemplatesData } = useQuery(AllTemplatesDocument, {
    variables: { messageTemplateType: MessageTemplateTypeEnum.Sourcing },
  });

  const allTemplates = allTemplatesData?.messageTemplates ?? [];

  const [createMessageRequestMutation] = useMutation(
    CreateCandidateMessageRequestDocument
  );

  const handleSubmit = (values: MessageRequestFormFields) => {
    createMessageRequestMutation({
      variables: {
        message: values.message,
        jobId: values.jobId,
        candidateId: candidateId as string,
        messageTemplateId: values.messageTemplate?.id,
      },
      onError: error => {
        if (error.message.includes("Candidate was messaged recently")) {
          toast.error(MESSAGED_RECENTLY_ERROR);
        } else {
          handleMutationError(error);
        }
      },
      onCompleted: () => {
        onAfterMessage(candidateId as string);
        toast.success(`Message sent`);
        pushAnalyticsEvent({
          eventName: "Company Recruiter Sent Message Request",
        });
      },
    });
  };

  const form = useFormik<MessageRequestFormFields>({
    initialValues: {
      jobId: jobId as string,
      message: "",
      messageTemplate: undefined,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const [updateMessageTemplateMutation] = useMutation(
    UpdateMessageTemplateDocument,
    messageTemplateMutationOptions
  );

  const handleUpdateTemplate = () => {
    if (!form.values.messageTemplate) {
      return;
    }
    updateMessageTemplateMutation({
      variables: {
        id: form.values.messageTemplate.id,
        name: form.values.messageTemplate.name,
        message: form.values.message,
      },
      onError: handleMutationError,
      onCompleted: () => {
        toast.success("Template updated");
      },
    });
  };

  return (
    <>
      <form onSubmit={form.handleSubmit}>
        <VerticalSpacing>
          <MessageRequestTemplateSelector
            allTemplates={allTemplates}
            value={form.values.messageTemplate}
            onChange={data => form.setValues({ ...form.values, ...data })}
          />

          <MessageRequestJobSelector
            value={form.values.jobId}
            onChange={jobId => form.setFieldValue("jobId", jobId)}
          />

          <VerticalSpacing size={-4}>
            <LabelContainer>
              <Label>Message</Label>
              <TooltipWithIcon
                icon={<Icon icon="circle-info" size={1} />}
                content={MESSAGE_TOOLTIP}
              />
            </LabelContainer>

            <MessageRequestVariableButtons
              insertMessageVariable={variable =>
                form.setFieldValue("message", form.values.message + variable)
              }
            />

            <TextareaField
              name="message"
              value={form.values.message}
              onChange={form.handleChange}
              placeholder={MESSAGE_PLACEHOLDER}
              rows={14}
              error={form.touched.message ? form.errors.message : undefined}
            />
          </VerticalSpacing>

          {!isCreatingMessageTemplate && (
            <VerticalSpacing size={-4}>
              <Button level="primary" type="submit">
                Send
              </Button>

              {form.values.messageTemplate ? (
                <Button
                  level="secondary"
                  type="button"
                  onClick={handleUpdateTemplate}
                  disabled={!form.dirty}
                >
                  Update template
                </Button>
              ) : (
                <Button
                  level="secondary"
                  type="button"
                  onClick={() => setIsCreatingMessageTemplate(true)}
                >
                  Save as new template
                </Button>
              )}
            </VerticalSpacing>
          )}
        </VerticalSpacing>
      </form>
      {isCreatingMessageTemplate && (
        <MessageRequestCreateTemplateForm
          message={form.values.message}
          allTemplates={allTemplates}
          onClose={() => setIsCreatingMessageTemplate(false)}
          onCreated={val => form.setFieldValue("messageTemplate", val)}
        />
      )}
    </>
  );
}
