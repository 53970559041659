import { List, Spacing, Text } from "@otta/design";

export function NoEndorsements({
  companyName,
}: {
  companyName: string;
}): React.ReactElement {
  return (
    <Spacing>
      <Text bold align="center">
        You don&apos;t have any employee endorsements yet
      </Text>
      <List type="positive">
        <li>
          Endorsements help job seekers understand what it&apos;s like to work
          at {companyName}
        </li>
        <li>Responses are anonymous and only include the team you work in</li>
      </List>
    </Spacing>
  );
}
