import { AnimatePresence, motion } from "framer-motion";
import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";

const ToastWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

const Toast = styled.div`
  background: ${palette.brand.black};
  box-shadow: ${pxToRem(0)} ${pxToRem(4)} ${pxToRem(16)} rgba(0, 0, 0, 0.16);
  border-radius: ${pxToRem(8)};
  padding: md;
  pointer-events: auto;
  margin-left: sm;
  margin-right: sm;
`;

const ToastContainer = styled.div`
  height: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 5;
`;

interface ToastProps {
  isVisible: boolean;
  children: React.ReactElement;
}

export function ToastMessage({
  isVisible,
  children,
}: ToastProps): React.ReactElement {
  return (
    <ToastContainer>
      <AnimatePresence>
        {isVisible && (
          <ToastWrapper
            initial={{ opacity: 0, y: 0 }}
            animate={
              isVisible ? { opacity: 1, y: "-120%" } : { opacity: 0, y: "0%" }
            }
            transition={{
              type: "tween",
              duration: 0.25,
              ease: "easeInOut",
            }}
            exit={{ opacity: 0, y: "0%" }}
          >
            <Toast>{children}</Toast>
          </ToastWrapper>
        )}
      </AnimatePresence>
    </ToastContainer>
  );
}
