import { useQuery } from "@apollo/client";

import { GetJobPipelineDocument } from "@hire/schema";

export const useCurrentStage = (jobId: string, pipelineItemId: string) => {
  const { data, loading } = useQuery(GetJobPipelineDocument, {
    variables: { jobId, workExperienceLimit: 1 },
  });

  const currentStage = data?.getJobPipeline?.candidatePipelineStages?.find(
    stage =>
      stage.candidatePipelineItems?.map(i => i.id).includes(pipelineItemId)
  );

  return { loading, currentStage };
};
