import { useQuery } from "@apollo/client";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";

import { CandidateShortlistDocument, CurrentUserDocument } from "@hire/schema";
import { pushAnalyticsEvent } from "@otta/analytics";
import { modularScale } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Button } from "@otta/design";
import { Stage } from "@hire/components/icons/Stage";
import { hireAppUser } from "@hire/util/user";

const ButtonWrapper = styled.div`
  display: flex;
  gap: lg;
  overflow-x: auto;
  width: calc(100vw - ${modularScale()});

  ::-webkit-scrollbar {
    display: none;
  }

  ${up(
    "desktop",
    css`
      overflow: auto;
      width: auto;
    `
  )}
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  width: fit-content;

  :last-child {
    margin-right: lg;
  }
`;

const AddButton = styled(StyledButton)`
  svg {
    margin-right: sm;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 5;
`;

export const PipelineButtons = ({
  externalJobId,
}: {
  externalJobId: string;
}): React.ReactElement => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { data: shortlistData } = useQuery(CandidateShortlistDocument, {
    variables: { jobId: externalJobId },
  });

  const shortlistedCount = (shortlistData?.candidateShortlist ?? []).length;
  const { data: currentUserData } = useQuery(CurrentUserDocument);
  const me = currentUserData ? hireAppUser(currentUserData.me) : undefined;

  const addCandidate = () => {
    pushAnalyticsEvent({
      eventName: "Company Recruiter initiated add candidate experience",
      companyId: me?.currentCompany?.externalId,
      jobId: externalJobId,
    });
    navigate({
      pathname: "add-new-candidate",
      search: searchParams.toString(),
    });
  };

  return (
    <ButtonWrapper>
      <StyledButton
        as={RouterLink}
        level="secondary"
        to="../sourcing/shortlist"
        onClick={() => {
          pushAnalyticsEvent({
            eventName:
              "Company Recruiter clicked 'View sourcing shortlist' button",
            jobId: externalJobId,
            companyId: me?.currentCompany?.id,
          });
        }}
      >
        View sourcing shortlist {shortlistedCount}
      </StyledButton>
      <AddButton
        level="secondary"
        onClick={() =>
          navigate({
            pathname: "add-new-stage",
            search: searchParams.toString(),
          })
        }
      >
        <Stage />
        Add a stage
      </AddButton>

      <AddButton level="secondary" onClick={addCandidate}>
        <StyledIcon icon="user" />
        Add a candidate
      </AddButton>
    </ButtonWrapper>
  );
};
