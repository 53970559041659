import { createContext, useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { CandidatePipelineItemFragment } from "@hire/schema";

interface CandidateDrawerContextProps {
  candidatePipelineItem: CandidatePipelineItemFragment | null;
  setCurrentCandidatePipelineItem: (
    currentCandidate: CandidatePipelineItemFragment
  ) => void;
  setAllCurrentCandidatePipelineItems: (
    candidates: Map<string, CandidatePipelineItemFragment>
  ) => void;
}

export const CandidateDrawerContext =
  createContext<CandidateDrawerContextProps>({
    candidatePipelineItem: null,
    setCurrentCandidatePipelineItem: noop => noop,
    setAllCurrentCandidatePipelineItems: noop => noop,
  });

export function CandidateDrawerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { candidateId } = useParams();

  const [candidatePipelineItem, setCandidatePipelineItem] =
    useState<CandidatePipelineItemFragment | null>(null);

  const setCurrentCandidatePipelineItem = useCallback(
    (currentCandidate: CandidatePipelineItemFragment | null) => {
      setCandidatePipelineItem(currentCandidate);
    },
    []
  );

  const setAllCurrentCandidatePipelineItems = useCallback(
    (candidates: Map<string, CandidatePipelineItemFragment>) => {
      if (candidateId) {
        candidates?.forEach(item => {
          if (item.importedCandidate?.externalId === candidateId) {
            setCandidatePipelineItem(item);
          }
        });
      }
    },
    [candidateId]
  );

  return (
    <CandidateDrawerContext.Provider
      value={{
        setCurrentCandidatePipelineItem,
        setAllCurrentCandidatePipelineItems,
        candidatePipelineItem,
      }}
    >
      {children}
    </CandidateDrawerContext.Provider>
  );
}
