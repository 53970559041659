import { SectionProps } from "../../common/types";
import { Subheading } from "../../common/Subheading";
import { Divider } from "../../common/Divider";
import { Section, SectionHeader } from "../../common/Section";

import { JobInvolves } from "./JobInvolves";
import { JobRequirements } from "./JobRequirements";
import { JobApplicationSteps } from "./JobApplicationSteps";

import { Spacing } from "@otta/design";

export function Role(props: SectionProps) {
  return (
    <Section>
      <SectionHeader>Role</SectionHeader>
      <Spacing size={0}>
        <Subheading>Who you are</Subheading>
        <JobRequirements {...props} />
        <Divider />
        <Subheading>What the job involves</Subheading>
        <JobInvolves {...props} />
        <JobApplicationSteps {...props} />
      </Spacing>
    </Section>
  );
}
