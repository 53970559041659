import { useMemo } from "react";

import { useCurrentUser } from "./useCurrentUser";

import { CompanyWorkflowStatus } from "@hire/schema";

type Active = {
  active?: boolean;
  loading: boolean;
};

export function useCompanyIsActive(): Active {
  const [data, { loading }] = useCurrentUser();
  return useMemo(
    () => ({
      loading,
      active:
        (data?.currentCompany &&
          [
            CompanyWorkflowStatus.Changed,
            CompanyWorkflowStatus.Completed,
          ].includes(data?.currentCompany?.workflowStatus)) ??
        undefined,
    }),
    [data, loading]
  );
}
