import { useApolloClient, useLazyQuery } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { useFormik } from "formik";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";
import { useCookie } from "react-use";

import { useRedirect } from "./useRedirect";

import { CurrentUserDocument } from "@hire/schema";
import {
  Button as DefaultButton,
  ErrorText,
  InputField,
  VerticalSpacing,
} from "@otta/design";
import { hireAppUser } from "@hire/util/user";

const Button = styled(DefaultButton)`
  width: 100%;
`;

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Fill in this field"),
  password: Yup.string().required("Fill in this field"),
});

interface LoginFormFields {
  email: string;
  password: string;
}

interface LoginFormProps {
  loginUrl: string;
}

export function LoginForm({ loginUrl }: LoginFormProps): React.ReactElement {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<
    "INVALID_CREDENTIALS" | "SSO_ENFORCED" | "403_OTHER" | "NO_USER" | null
  >(null);
  const client = useApolloClient();
  const [csrf] = useCookie(import.meta.env.VITE_CSRF_COOKIE);
  const [queryUser] = useLazyQuery(CurrentUserDocument);
  const redirect = useRedirect();

  const handleSubmit = useCallback(
    async ({ email, password }: LoginFormFields) => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(loginUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...(csrf ? { "X-CSRF-Token": csrf } : {}),
          },
          body: JSON.stringify({ email, password }),
          credentials: "include",
        });

        if (response.status === 403) {
          const json = await response.json();

          if (json.error === "Invalid credentials") {
            return setError("INVALID_CREDENTIALS");
          }

          if (json.error === "SSO enforced") {
            return setError("SSO_ENFORCED");
          }

          return setError("403_OTHER");
        }

        if (response.status !== 200) {
          throw new Error("Invalid response received from signin api");
        }

        const gqlResponse = await queryUser({ fetchPolicy: "network-only" });

        if (!gqlResponse.data?.me) {
          throw new Error("No user returned after signing in");
        }

        const urlSafeName = hireAppUser(gqlResponse?.data.me)?.currentCompany
          ?.urlSafeName;
        if (urlSafeName) {
          await client.resetStore();
          navigate(redirect(urlSafeName.toLowerCase()));
        }
      } catch (error) {
        Sentry.captureException(error);
        setError("NO_USER");
      } finally {
        setLoading(false);
      }
    },
    [loginUrl, queryUser, client, navigate, redirect]
  );

  const form = useFormik<LoginFormFields>({
    initialValues: { email: "", password: "" },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <VerticalSpacing>
        <InputField
          type="text"
          label="Work email"
          name="email"
          value={form.values.email}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.email ? form.errors.email : undefined}
        />
        <InputField
          type="password"
          label="Password"
          name="password"
          value={form.values.password}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.password ? form.errors.password : undefined}
        />
        {error && (
          <div>
            <ErrorText>
              {error === "INVALID_CREDENTIALS"
                ? "Incorrect email or password."
                : error === "SSO_ENFORCED"
                ? "SSO is enforced for your company, sign in using SSO instead."
                : "Something went wrong, try again."}
            </ErrorText>
          </div>
        )}
        <Button level="primary" type="submit" disabled={loading}>
          {loading ? "Loading..." : "Sign in with password"}
        </Button>
      </VerticalSpacing>
    </form>
  );
}
