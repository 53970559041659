import { useQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { To, useNavigate } from "react-router-dom";

import { CurrentUserDocument } from "@hire/schema";

type Args = {
  state: "logged-in" | "logged-out";
  redirect?: To;
};

export function useRequireLoginState({ state, redirect }: Args): {
  loading?: boolean;
} {
  const navigate = useNavigate();
  const { loading, data } = useQuery(CurrentUserDocument);

  useEffect(() => {
    if (
      !loading &&
      ((state === "logged-out" && data?.me !== null) ||
        (state === "logged-in" && data?.me === null))
    ) {
      navigate(redirect ?? "/");
    }
  }, [loading, data, state, redirect, navigate]);
  return useMemo(() => ({ loading }), [loading]);
}
