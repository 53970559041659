import { useLayoutEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const CHART_SIZE = 111;
const SEGMENT_WIDTH = 12;
const SEGMENT_GAP = 2;
const SEGMENT_TRANSITION = 0.2;
// (CHART_SIZE - SEGMENT_WIDTH) * Math.PI = 311.01767270538954
// but getTotalLength() on the circles returns 310.5152587890625, ✨ Math ✨
const CIRCUMFERENCE = 310.5152587890625;

type Segment = {
  stroke: string;
  strokeDasharray: number[];
  strokeDashoffset: number;
};

const Segment = ({
  stroke,
  strokeDasharray,
  strokeDashoffset,
  index,
}: Segment & { index: number }) => {
  // Start at 0 CIRCUMFERENCE so we can animate the stroke-dasharray when the segment is in view
  const [formattedStrokeDasharray, setFormattedStrokeDasharray] = useState(
    `0 ${CIRCUMFERENCE}`
  );
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

  useLayoutEffect(() => {
    if (!inView) return;
    setFormattedStrokeDasharray(
      `${Math.max(0, strokeDasharray[0] - SEGMENT_GAP)} ${
        strokeDasharray[1] + SEGMENT_GAP
      }`
    );
  }, [inView, strokeDasharray]);

  return (
    <circle
      ref={ref}
      cx={CHART_SIZE / 2}
      cy={CHART_SIZE / 2}
      // Strokes are centered on the element rather than using box-sizing
      // it'll be cutoff if we don't adjust it
      r={CHART_SIZE / 2 - SEGMENT_WIDTH / 2}
      fill="none"
      stroke={stroke}
      strokeWidth={SEGMENT_WIDTH}
      strokeDasharray={formattedStrokeDasharray}
      strokeDashoffset={strokeDashoffset}
      style={{
        transition: `linear ${SEGMENT_TRANSITION}s ${
          index * SEGMENT_TRANSITION
        }s`,
      }}
    />
  );
};

export const DonutChart = ({
  data,
}: {
  data: { color: string; value: number; name: string }[];
}) => {
  const total = data.reduce((acc, { value }) => acc + value, 0);

  let strokeDashoffset = 0;
  const formattedData: Segment[] = [];
  for (const item of data) {
    if (item.value === 0) continue;

    const segmentLength = CIRCUMFERENCE / (total / item.value);
    const strokeDasharray = [segmentLength, CIRCUMFERENCE - segmentLength];
    formattedData.push({
      strokeDasharray,
      strokeDashoffset,
      stroke: item.color,
    });
    strokeDashoffset += strokeDasharray[1];
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={CHART_SIZE}
      height={CHART_SIZE}
      data-testid="donut-chart"
    >
      {/* Circles start on the right rather than the top */}
      <g transform={`rotate(-90 ${CHART_SIZE / 2} ${CHART_SIZE / 2})`}>
        {formattedData.map((item, i) => (
          <Segment
            key={item.stroke}
            index={i}
            stroke={item.stroke}
            strokeDasharray={item.strokeDasharray}
            strokeDashoffset={item.strokeDashoffset}
          />
        ))}
      </g>
    </svg>
  );
};
