import styled from "@xstyled/styled-components";

import { Icon } from "@otta/icons";
import { Spacing, Text } from "@otta/design";

const FailureWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(transparent, white 50%);
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface FailureOverlayProps {
  children: string;
}

export const FailureOverlay = ({
  children,
}: FailureOverlayProps): React.ReactElement | null => (
  <FailureWrapper>
    <Spacing>
      <Icon icon="triangle-exclamation" />
      <Text align="center" bold>
        {children}
      </Text>
    </Spacing>
  </FailureWrapper>
);
