import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const IconButton = styled.div<{
  color: string;
  hoverCover: string;
}>`
  display: flex;
  color: ${({ color }) => color};
  cursor: pointer;

  &:hover {
    color: ${({ hoverCover }) => hoverCover};
  }
`;

export const DeleteButton = ({
  size = 2,
  color = palette.brand.red,
  hoverColor = palette.extended.red.shade800,
  onClick: handleClick,
  label = "Delete",
}: {
  size?: number;
  hoverColor?: string;
  color?: string;
  onClick: () => void;
  label?: string;
}) => {
  return (
    <IconButton
      role="button"
      color={color}
      hoverCover={hoverColor}
      onClick={handleClick}
      data-testid="delete-button"
      aria-label={label}
    >
      <Icon icon="delete" size={size} />
    </IconButton>
  );
};
