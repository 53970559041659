import styled from "@xstyled/styled-components";

import { useJobEditFormikContext } from "../../useHandlers";

import { pxToRem } from "@otta/design-tokens";
import { LocationField } from "@hire/components/form/LocationField";
import { FieldWrapper } from "@otta/design";

const NarrowSelect = styled(LocationField)`
  max-width: ${pxToRem(400)};
`;

export const JobEditLocationField = ({ className }: { className?: string }) => {
  const form = useJobEditFormikContext();

  return (
    <FieldWrapper
      required
      label="Where is this job based?"
      fieldError={
        form.touched.locationPreferences
          ? form.errors.locationPreferences
          : undefined
      }
      className={className}
    >
      {({ field }) => (
        <NarrowSelect
          inputId={field.id}
          placeholder="Type or select locations&hellip;"
          value={form.values.locationPreferences}
          onChange={v =>
            form.setFieldValue(
              "locationPreferences",
              v.map(v => v.value)
            )
          }
          onBlur={() =>
            form.setFieldTouched(
              "locationPreferences",
              true,
              !!form.validateOnBlur
            )
          }
          aria-describedby={field["aria-describedby"]}
          aria-invalid={field["aria-invalid"]}
          hideSelectedOptions={false}
        />
      )}
    </FieldWrapper>
  );
};
