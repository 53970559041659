import { useMutation } from "@apollo/client";

import {
  CompanySamlDataDocument,
  CreateCompanySamlDataDocument,
} from "@hire/schema";
import { Button, Middle } from "@otta/design";

export function StartSSO(): React.ReactElement {
  const [mutate] = useMutation(CreateCompanySamlDataDocument, {
    update: (cache, { data }) => {
      const cacheData = cache.readQuery({
        query: CompanySamlDataDocument,
      });

      if (
        !cacheData?.currentUser?.currentCompany ||
        !data?.createCompanySamlData
      ) {
        return;
      }

      cache.writeQuery({
        query: CompanySamlDataDocument,
        data: {
          ...cacheData,
          currentUser: {
            ...cacheData.currentUser,
            currentCompany: {
              ...cacheData.currentUser.currentCompany,
              samlData: data.createCompanySamlData,
            },
          },
        },
      });
    },
  });

  return (
    <Middle>
      <Button level="primary" onClick={() => mutate()}>
        Start setting up SSO
      </Button>
    </Middle>
  );
}
