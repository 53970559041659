import styled from "@xstyled/styled-components";
import { format } from "date-fns";

import { Divider } from "../../common/Divider";
import { Subheading } from "../../common/Subheading";
import { SectionProps } from "../../common/types";

import { Spacing, Text } from "@otta/design";
import { currencyPrefix } from "@hire/util/currencies";
import { palette } from "@otta/design-tokens";
import { Currency } from "@hire/schema";

function formatLotsOfMoney(amount: number | string, currency: string): string {
  const prefix = currencyPrefix(currency);
  const float = typeof amount === "string" ? parseFloat(amount) : amount;
  return float >= 1_000_000
    ? prefix + (float / 1_000_000).toFixed(1) + "m"
    : prefix + (float / 1_000).toFixed(1) + "k";
}

function formatFundingDate(month: number, year: number): string {
  return format(new Date(year, month - 1, 1), "MMM yyyy");
}

const FundingContainer = styled.div`
  display: grid;
  row-gap: 1.5rem;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: max-content auto max-content;
  background-color: ${palette.beige.shade200};
  border-radius: 0.75rem;
  padding: 0.75rem;
`;

const FundingAmount = styled(Text)`
  text-align: center;
  font-weight: bold;
`;

const Stage = styled(Text)`
  background-color: ${palette.extended.blue.shade200};
  padding: 0.13rem 0.5rem;
  border-radius: 0.5rem;
  margin-left: auto;
`;

type FundingRoundProps = {
  company: Pick<SectionProps["company"], "fundingRounds" | "totalFunding">;
};

function FundingRound(round: {
  month: number;
  year: number;
  currency: string;
  amount: number;
  round: string | null;
}): React.ReactElement {
  return (
    <>
      <Text color={palette.grayscale.shade600}>
        {formatFundingDate(round.month, round.year)}
      </Text>
      <FundingAmount>
        {formatLotsOfMoney(round.amount, round.currency)}
      </FundingAmount>
      {!!round && (
        <Stage bold size={-1}>
          {round.round}
        </Stage>
      )}
    </>
  );
}

export function FundingRounds({
  company: { fundingRounds, totalFunding },
}: FundingRoundProps): React.ReactElement | null {
  const lastTwo = fundingRounds.slice(0, 2);
  if (!fundingRounds.length || !totalFunding) {
    return null;
  }

  return (
    <Spacing size={0}>
      <Divider />
      <Subheading>
        Funding (
        {fundingRounds.length > 2 ? (
          <>last 2 of {fundingRounds.length} rounds</>
        ) : (
          <>{fundingRounds.length} rounds</>
        )}
        )
      </Subheading>
      <FundingContainer>
        {lastTwo.map(round => (
          <FundingRound
            month={round.month}
            year={round.year}
            currency={round.funding.currency}
            amount={round.funding.amount}
            round={round.round}
          />
        ))}
      </FundingContainer>
      <Text size={0} bold>
        Total funding:{" "}
        {formatLotsOfMoney(totalFunding.amount, totalFunding.currency)}
      </Text>
    </Spacing>
  );
}

export function ExampleFundingRounds(): React.ReactElement {
  return (
    <Spacing size={0}>
      <Divider />
      <Subheading>[Example] funding rounds</Subheading>
      <FundingContainer>
        <FundingRound
          month={10}
          year={2022}
          currency={Currency.Gbp}
          round={"GROWTH EQUITY"}
          amount={36800000}
        />
        <FundingRound
          month={12}
          year={2019}
          currency={Currency.Gbp}
          round={"SERIES C"}
          amount={7200000}
        />
      </FundingContainer>
      <Text size={0} bold>
        Total funding: {formatLotsOfMoney(48700000, Currency.Gbp)}
      </Text>
    </Spacing>
  );
}
