import { StageType } from "@hire/schema";
import { Card } from "@otta/design";
import { Link } from "@hire/components/links";

interface EmptyCardProps {
  type: StageType;
}

export const EmptyCard = ({ type }: EmptyCardProps): React.ReactElement => {
  const sourced = (
    <>
      Candidates you message with{" "}
      <Link to="../sourcing/search/">Our sourcing tool</Link> will appear in
      this column.
    </>
  );

  const applied =
    "Applications will automatically appear here for you to review.";

  const genericNoCandidates = "No candidates at this stage right now.";

  return (
    <Card>
      {type === "SOURCED"
        ? sourced
        : type === "APPLIED"
        ? applied
        : genericNoCandidates}
    </Card>
  );
};
