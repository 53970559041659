import { Command } from "cmdk";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

import { Kanban } from "../icons";

import { Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { JobFeature } from "@hire/schema";
import { modularScale } from "@otta/design-tokens";

export function JobPage({
  slug,
  id,
  title,
  access,
  onSelect,
}: {
  slug: string;
  id: string;
  title: string;
  access: { feature: JobFeature; granted: boolean }[];
  onSelect(): void;
}) {
  const navigate = useNavigate();

  const baseUrl = [slug, "jobs", id].join("/");

  const features = useMemo(
    () => new Set(access.filter(a => a.granted).map(a => a.feature)),
    [access]
  );

  return (
    <Command.Group heading={title}>
      {features.has(JobFeature.Pipeline) && (
        <Command.Item
          onSelect={() => {
            navigate(`${baseUrl}/pipeline`);
            onSelect();
          }}
        >
          <Kanban width={modularScale()} />
          <Text>Pipeline</Text>
        </Command.Item>
      )}
      {features.has(JobFeature.Inbox) && (
        <Command.Item
          onSelect={() => {
            navigate(`${baseUrl}/inbox`);
            onSelect();
          }}
        >
          <Icon icon="response-rate" size={1} />
          <Text>Messages</Text>
        </Command.Item>
      )}
      {features.has(JobFeature.Sourcing) && (
        <Command.Item
          onSelect={() => {
            navigate(`${baseUrl}/sourcing/search`);
            onSelect();
          }}
        >
          <Icon icon="sourcing" size={1} />
          <Text>Sourcing</Text>
        </Command.Item>
      )}
      {features.has(JobFeature.Edit) && (
        <Command.Item
          onSelect={() => {
            navigate(`${baseUrl}/edit`);
            onSelect();
          }}
        >
          <Icon icon="settings" size={1} />
          <Text>Edit</Text>
        </Command.Item>
      )}
    </Command.Group>
  );
}
