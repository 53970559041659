import { CSSProperties, FlattenSimpleInterpolation } from "styled-components";
import styled, { css } from "@xstyled/styled-components";

import { palette, pxToRem, modularScale } from "@otta/design-tokens";

type ButtonLevel = "primary" | "secondary" | "tertiary" | "destructive";
type SizeLevel = "regular" | "small";

export interface ButtonProps {
  level: ButtonLevel;
  size?: SizeLevel;
}

export interface GenericButtonProps {
  style?: CSSProperties;
  className?: string;
}

const sizing: Record<SizeLevel, FlattenSimpleInterpolation> = {
  regular: css`
    border-radius: ${pxToRem(100)};
    padding: md xxl;
    font-size: ${modularScale()};
    letter-spacing: 0.03em;
  `,
  small: css`
    border-radius: ${modularScale()};
    font-size: ${modularScale(-1)};
    padding: xs 6;
  `,
};

const colors: Record<ButtonLevel, FlattenSimpleInterpolation> = {
  primary: css`
    background: ${palette.brand.yellow};
    border-color: ${palette.brand.yellow};
    font-weight: 700;

    &:hover {
      background: ${palette.extended.yellow.shade200};
    }

    &:disabled {
      background: ${palette.brand.grey};
      border-color: ${palette.grayscale.shade200};
      color: ${palette.grayscale.shade400};
      cursor: not-allowed;
    }
  `,

  secondary: css`
    background: ${palette.grayscale.shade50};
    border-color: ${palette.grayscale.shade200};
    font-weight: 700;

    &:hover {
      background: ${palette.brand.grey};
    }

    &:disabled {
      background: ${palette.brand.grey};
      color: ${palette.grayscale.shade400};
      cursor: not-allowed;
    }
  `,

  tertiary: css`
    background: ${palette.brand.white};
    font-weight: 400;
    border-color: ${palette.grayscale.shade200};

    &:hover {
      border-color: ${palette.brand.black};
    }

    &:disabled {
      background: ${palette.brand.grey};
      color: ${palette.grayscale.shade400};
      cursor: not-allowed;
    }
  `,
  destructive: css`
    background: ${palette.grayscale.shade50};
    border-color: ${palette.brand.red};
    color: ${palette.brand.red};
    font-weight: 700;

    &:hover {
      background: ${palette.brand.grey};
    }

    &:disabled {
      background: ${palette.brand.grey};
      border-color: ${palette.grayscale.shade200};
      color: ${palette.grayscale.shade400};
      cursor: not-allowed;
    }
  `,
};
/**
 * ```ts
 *
 * import { Button } from '@otta/design'
 *
 * ```
 *
 * These are the buttons we use throughout all of our applications, and there are four levels to choose from:
 *
 * - <b>Primary (Yellow & Bold)</b>
 * - <b>Secondary (Grey & Bold)</b>
 * - <b>Tertiary (White)</b>
 * - <b>Destructive (Red & Grey)</b>
 *
 * You can see each type by using the controls below:
 */

export const Button = styled.button<ButtonProps>`
  color: ${palette.brand.black};
  cursor: pointer;
  font-family: inherit;
  -webkit-tap-highlight-color: transparent;
  border: 1px solid;
  outline: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;

  ${({ size = "regular" }) => sizing[size]}
  ${({ level }) => colors[level]}


 transition: default;

  &:active {
    transform: scale(0.88);
  }
`;

export const Clickable = styled.button<GenericButtonProps>`
  all: unset;
  cursor: pointer;
`;
