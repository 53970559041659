import { useMutation, useQuery } from "@apollo/client";

import { CreateTemplateSection } from "./CreateTemplateSection";
import { TemplateCard } from "./TemplateCard";
import {
  handleDeleteMutationUpdate,
  handleUpdateMutationUpdate,
} from "./mutationUpdateFunctions";

import { handleMutationError } from "@hire/errors";
import { HasAccess } from "@hire/HasAccess";
import {
  AllTemplatesDocument,
  DeleteMessageTemplateDocument,
  Feature,
  MessageTemplateTypeEnum,
  UpdateMessageTemplateDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { Spacing, Text } from "@otta/design";

const helpText: Record<MessageTemplateTypeEnum, string> = {
  [MessageTemplateTypeEnum.Sourcing]:
    "Create templates to help you message candidates faster.",
  [MessageTemplateTypeEnum.Rejection]:
    "Add templates to help you reply to every candidate who applies to your jobs.",
};

export const AllTemplates = ({
  messageTemplateType,
}: {
  messageTemplateType: MessageTemplateTypeEnum;
}) => {
  const { data, loading } = useQuery(AllTemplatesDocument, {
    variables: { messageTemplateType },
  });

  const messageTemplates = data?.messageTemplates ?? [];

  const [deleteMessageTemplate] = useMutation(DeleteMessageTemplateDocument, {
    update: handleDeleteMutationUpdate(messageTemplateType),
    onError: handleMutationError,
  });

  const [updateMessageTemplate] = useMutation(UpdateMessageTemplateDocument, {
    update: handleUpdateMutationUpdate(messageTemplateType),
    onError: handleMutationError,
  });

  const handleDelete = async (templateId: string) => {
    await deleteMessageTemplate({ variables: { id: templateId } });
  };

  const handleSave = async (newValues: {
    id: string;
    name: string;
    message: string;
  }) => {
    await updateMessageTemplate({ variables: newValues });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Spacing>
      <Text>{helpText[messageTemplateType]}</Text>
      <Spacing size={3}>
        <HasAccess feature={Feature.CreateMessageTemplate}>
          <CreateTemplateSection messageTemplateType={messageTemplateType} />
        </HasAccess>
        <div data-testid={`${messageTemplateType.toLowerCase()}-templates`}>
          <Spacing>
            {messageTemplates.map(template => (
              <TemplateCard
                key={template.id}
                onDelete={handleDelete}
                onSave={handleSave}
                template={template}
              />
            ))}
          </Spacing>
        </div>
      </Spacing>
    </Spacing>
  );
};
