import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";
import { Card, Text } from "@otta/design";

export const TemplateContainer = styled(Card)<{ color?: string }>`
  position: relative;
  text-align: left;
  background-color: ${({ color }) => color ?? palette.brand.white};
`;

export const TemplateBodyText = styled(Text)`
  overflow-wrap: break-word;
  white-space: pre-line;
`;

export const TemplateExpandToggle = styled.a`
  display: flex;
  justify-content: end;
  color: ${palette.brand.black};
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
`;
