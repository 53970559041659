import styled from "@xstyled/styled-components";

import { Link } from "@hire/components/links";
import { Button, Heading, Spacing, Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { useProgress } from "@hire/components/layout/QuizLayout";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

const PageContainer = styled.div`
  max-width: ${pxToRem(980)}}
`;

const BackToOttaButton = styled(Button)`
  text-decoration: none;
  margin-top: 40;
  width: ${pxToRem(200)};
`;

export function Unsupported() {
  useProgress(0);
  const companyName = useWelcomeToTheJungle();

  return (
    <PageContainer>
      <Heading size={5} style={{ marginBottom: pxToRem(24) }}>
        Sorry, we don't support the roles you're hiring for yet.
      </Heading>
      <Spacing>
        <Text>This may change in the future though, so keep an eye out.</Text>
      </Spacing>

      <BackToOttaButton as={Link} level="primary" to="https://otta.com/">
        Back to {companyName}
      </BackToOttaButton>
    </PageContainer>
  );
}
