import { useParams } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { BroadcastOverview } from "./BroadcastOverview";
import { NotCertified } from "./NotCertified";

import { JobFeature } from "@hire/schema";
import { pxToRem } from "@otta/design-tokens";
import { Loading } from "@otta/shared-components";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { useJobAuthorization } from "@hire/providers/authorization";
import { Experiment } from "@hire/constants/experiments";
import { useExperiment } from "@otta/experiments/client";

const Container = styled(ResponsiveCenteredContainer)`
  max-width: ${pxToRem(1200)};
`;

export const Broadcast = () => {
  const params = useParams<"jobId">();

  const jobId = params.jobId as string;

  const { granted, loading } = useJobAuthorization(JobFeature.Broadcast);
  const { variant } = useExperiment(Experiment.OttaCertified);

  if (loading) {
    return <Loading />;
  }
  return (
    <Container>
      {granted ? (
        <BroadcastOverview jobId={jobId} />
      ) : variant === "variant" ? (
        <NotCertified />
      ) : null}
    </Container>
  );
};
