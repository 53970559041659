import { useQuery } from "@apollo/client";
import { useState } from "react";

import { CreateNote } from "./CreateNote";
import { ViewNote } from "./ViewNote";
import { EditNote } from "./EditNote";

import { CompanyConversationDocument } from "@hire/schema";
import { useRequiredParams } from "@hire/util/routing";
import { ReloadableError } from "@hire/components/ReloadableError";
import { modularScale } from "@otta/design-tokens";

const CandidateNotePage = () => {
  const { jobId, candidateId } = useRequiredParams(["jobId", "candidateId"]);

  const [editing, setEditing] = useState(false);

  const { data, refetch } = useQuery(CompanyConversationDocument, {
    variables: {
      jobId,
      candidateId,
    },
  });

  const item = data?.getJobPipelineItem;

  if (!item) {
    return <ReloadableError action={refetch} />;
  }

  const note = item.candidatePipelineItemNote;

  if (editing) {
    return (
      <EditNote
        itemId={item.id}
        jobId={jobId}
        noteId={note?.id}
        content={note?.content}
        onComplete={() => {
          setEditing(false);
        }}
      />
    );
  }

  if (!note) {
    return (
      <CreateNote
        onCreate={() => {
          setEditing(true);
        }}
      />
    );
  }

  return (
    <ViewNote
      onEdit={() => {
        setEditing(true);
      }}
      content={note.content}
      lastEditor={note.lastUpdatedBy}
      timestamp={note.updatedAt}
    />
  );
};

export const CandidateNotes = () => (
  <div style={{ padding: modularScale(), flex: 1, minHeight: "80vh" }}>
    <CandidateNotePage />
  </div>
);
