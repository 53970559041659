import { useCallback, useState } from "react";
import { InView } from "react-intersection-observer";

import { Loading } from "@otta/shared-components";

interface FetchMoreProps {
  fetchMore: () => Promise<void>;
}

export function FetchMore({ fetchMore }: FetchMoreProps): React.ReactElement {
  const [loading, setLoading] = useState(false);

  const handleInView = useCallback(
    async (inView: boolean) => {
      if (!inView) {
        return;
      }

      setLoading(true);

      await fetchMore();

      setLoading(false);
    },
    [fetchMore]
  );

  return (
    <InView onChange={handleInView}>
      {({ ref }) => (
        <div ref={ref} style={{ minHeight: "10px", width: "100%" }}>
          {loading ? <Loading /> : null}
        </div>
      )}
    </InView>
  );
}
