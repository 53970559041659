import { UserProfileFormFields } from "./types";

import { CurrentUserProfileQuery, UserInput } from "@hire/schema";

export const transformIn = (
  data: NonNullable<CurrentUserProfileQuery["currentUser"]>
): UserProfileFormFields => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    jobTitle: data.currentTitle ?? undefined,
    profilePicturePath: data.profileImagePath ?? undefined,
  };
};

export const transformOut = (data: UserProfileFormFields): UserInput => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    currentTitle: data.jobTitle,
  };
};
