import { useMutation } from "@apollo/client";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { BulkMessageData } from "../types";
import { ModalRef, WarningModal } from "../../WarningModal";

import { BulkActionModal } from "./BulkActionModal";

import { handleMutationError } from "@hire/errors";
import {
  BulkMessageCandidatesAndUpdateStageDocument,
  GetJobPipelineDocument,
  StageType,
} from "@hire/schema";
import { handleCompleteBulkMove } from "@hire/pages/JobPage/Pipeline/helpers";

interface BulkMessageModalProps {
  selectedItems: { itemId: string; candidateId: string }[];
  externalJobId: string;
  handleSubmit: (stageId: string | null) => void;
  stageOptions: {
    value: string;
    label: string;
  }[];
  jobId?: string;
}

export type BulkMessageModalRef = {
  show(): void;
  hide(): void;
};

export const BulkMessageModal = forwardRef<
  BulkMessageModalRef,
  BulkMessageModalProps
>(function BulkMessageModal(
  { selectedItems, externalJobId, handleSubmit, stageOptions, jobId },
  ref
) {
  const [show, setShow] = useState(false);
  const modalRef = useRef<ModalRef>(null);

  useImperativeHandle(ref, () => ({
    show() {
      setShow(true);
    },
    hide() {
      setShow(false);
      modalRef.current?.hide();
    },
  }));

  const [messageState, setMessageState] = useState<BulkMessageData>({
    message: "",
  });

  const [sendBulkMessagesAndUpdateStage, { loading }] = useMutation(
    BulkMessageCandidatesAndUpdateStageDocument,
    {
      onError: handleMutationError,
      refetchQueries: [
        {
          query: GetJobPipelineDocument,
          variables: { jobId, workExperienceLimit: 1 },
          fetchPolicy: "network-only",
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const performBulkUpdate = async ({
    message,
    stageId,
  }: BulkMessageData): Promise<void> => {
    const newStageId = stageId ? stageId : null;

    await sendBulkMessagesAndUpdateStage({
      variables: {
        itemIds: selectedItems.map(({ itemId }) => itemId),
        candidateIds: selectedItems.map(item => item.candidateId),
        content: message,
        jobId: externalJobId,
        newStageId: newStageId,
      },
      onCompleted: () => {
        const stageName = stageOptions.find(
          option => option.value === newStageId
        )?.label;

        if (stageName) {
          const candidateIds = selectedItems.map(
            ({ candidateId }) => candidateId
          );

          handleCompleteBulkMove({
            candidateIds,
            stageName,
          });
        }
      },
    });
    setShow(false);
    handleSubmit(newStageId);
  };

  const handleConfirmHire = () => {
    if (messageState.message) {
      const { value: stageId } = stageOptions.filter(
        ({ label }) => label.toUpperCase() === StageType.Hired
      )[0];

      performBulkUpdate({
        message: messageState.message,
        stageId,
      });
    }

    modalRef.current?.hide();
  };

  const handleFormSubmit = (data: BulkMessageData) => {
    setMessageState(data);
    const stageName = stageOptions.find(o => o.value === data.stageId)?.label;
    if (stageName && stageName.toUpperCase() === StageType.Hired) {
      setShow(false);
      modalRef.current?.show(selectedItems.length);
      return;
    }

    performBulkUpdate(data);
  };

  return (
    <>
      <BulkActionModal
        open={show}
        onOpenChange={setShow}
        messageState={messageState}
        stageOptions={stageOptions}
        onSubmit={handleFormSubmit}
        numCandidates={selectedItems.length}
        loading={loading}
      />
      <WarningModal
        ref={modalRef}
        onConfirm={handleConfirmHire}
        onCancel={() => {
          setShow(true);
          modalRef.current?.hide();
        }}
        howToUndoText="If you've moved them here by accident, you can go back and select a different stage to move them to."
      />
    </>
  );
});
