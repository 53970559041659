import styled, { css, up } from "@xstyled/styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";

import { Modal, Text, Heading, Button } from "@otta/design";
import { modularScale, pxToRem } from "@otta/design-tokens";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${pxToRem(86)};
  padding: 50;
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: lg;
  display: flex;
  flex-direction: column;
  gap: lg;
  ${up(
    "tablet",
    css`
      padding: 40 4xl;
    `
  )}
`;

const ResponsiveHeading = styled(Heading)`
  font-size: ${modularScale(3)};
  ${up(
    "tablet",
    css`
      font-size: ${modularScale(4)};
    `
  )}
`;

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: lg;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: sm;
`;

const BenefitsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: md;
  justify-items: center;
  align-items: center;

  ${up(
    "tablet",
    css`
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `
  )}
`;

const BenefitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Image = styled.img`
  width: ${pxToRem(90)};
  height: ${pxToRem(90)};
  margin-bottom: md;

  ${up(
    "tablet",
    css`
      width: ${pxToRem(130)};
      height: ${pxToRem(130)};
    `
  )}
`;

const StickyFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: lg;
`;

const BenefitText = styled(Text)`
  font-size: ${modularScale(-1)};

  ${up(
    "tablet",
    css`
      font-size: ${modularScale(0)};
    `
  )}
`;

function Benefit({
  title,
  text,
  image,
}: {
  title: string;
  text: React.ReactNode;
  image: string;
}): React.ReactElement {
  return (
    <BenefitWrapper>
      <Image src={image} />
      <BenefitText align="center" bold>
        {title}
      </BenefitText>
      <BenefitText align="center">{text}</BenefitText>
    </BenefitWrapper>
  );
}

function Benefits(): React.ReactElement {
  return (
    <BenefitsWrapper>
      <Benefit
        image="https://static.otta.com/hero-images/colourway-1/invest_in_yourself.png"
        title="Boosted Jobs"
        text="Receive more, high quality applications"
      />
      <Benefit
        image="https://static.otta.com/images/hire-app/messaging_icon.png"
        title="Sourcing"
        text="Quickly find and message relevant candidates"
      />
      <Benefit
        image="https://static.otta.com/images/hire-app/pie_chart_icon.png"
        title="Insights"
        text="Improve your talent brand and job performance"
      />
      <Benefit
        image="https://static.otta.com/images/hire-app/detail_icon_larger.png"
        title="Customer Success"
        text="Achieve your business goals with dedicated support"
      />
    </BenefitsWrapper>
  );
}

function Question({
  title,
  text,
}: {
  title: string;
  text: React.ReactNode;
}): React.ReactElement {
  return (
    <QuestionWrapper>
      <BenefitText bold>{title}</BenefitText>
      <BenefitText>{text}</BenefitText>
    </QuestionWrapper>
  );
}

function Questions(): React.ReactElement {
  const companyName = useWelcomeToTheJungle();

  return (
    <QuestionsWrapper>
      <Question
        title="Why have my jobs been removed?"
        text={
          <>
            You&apos;re currently using our free but limited tier, Basic. Learn
            more about how our different product tiers work{" "}
            <a
              href="https://employers.otta.com/blog/otta-basic-q4-2022"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </>
        }
      />
      <Question
        title={`How can I ensure our jobs always stay on ${companyName}?`}
        text={`The only way to guarantee your jobs always stay on ${companyName} and ensure uninterrupted access is by upgrading to Pro.`}
      />
      <Question
        title="Make unlimited hires across all functions using Pro"
        text="You'll also unlock our exclusive features and key benefits,
          including:"
      />
    </QuestionsWrapper>
  );
}

function Header(): React.ReactElement {
  const companyName = useWelcomeToTheJungle();

  return (
    <HeaderContainer>
      <ResponsiveHeading align="center">
        Your jobs have been removed from {companyName}
      </ResponsiveHeading>
    </HeaderContainer>
  );
}

interface RemovedJobsExplanationProps {
  enabled: boolean;
  campaign: string;
}

export function RemovedJobsExplanation({
  enabled,
  campaign,
}: RemovedJobsExplanationProps): React.ReactElement {
  const [open, setOpen] = useState(true);

  return (
    <Modal
      open={enabled && open}
      onOpenChange={setOpen}
      headerContent={<Header />}
      style={{ maxWidth: pxToRem(900) }}
    >
      <ModalContentContainer>
        <ScrollableContent>
          <Questions />
          <Benefits />
        </ScrollableContent>
        <StickyFooter>
          <Button
            as={Link}
            level="primary"
            to={`/book-a-call?utm_campaign=${campaign}&utm_source=hire`}
            target="_blank"
          >
            Learn more about Pro
          </Button>
        </StickyFooter>
      </ModalContentContainer>
    </Modal>
  );
}
