import styled, { css, up } from "@xstyled/styled-components";

import { palette, modularScale } from "@otta/design-tokens";

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${up(
    "tablet",
    css`
      flex-direction: row;
    `
  )}
`;

const SelectValue = styled.div`
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  display: block;
  border-radius: 5px;
  font-size: ${modularScale()};
  padding: sm;
  border: 1px solid ${palette.grayscale.shade400};

  background-color: ${palette.grayscale.shade50};
  color: ${palette.grayscale.shade600};
`;

interface Props {
  children: React.ReactNode;
}

export const SelectBox = ({ children }: Props): React.ReactElement => {
  return (
    <SelectContainer>
      <SelectValue data-testid="select-text-field">{children}</SelectValue>
    </SelectContainer>
  );
};
