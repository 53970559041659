import { Command } from "cmdk";
import { useNavigate } from "react-router-dom";

import { Icon } from "@otta/icons";
import { Text } from "@otta/design";
import { palette } from "@otta/design-tokens";

export function Conversations({
  slug,
  conversations,
  onSelect,
}: {
  slug: string;
  conversations: {
    conversationId: string | null;
    job: { externalId: string; title: string };
    recipient: { externalId: string; firstName: string; lastName: string };
  }[];
  onSelect: () => void;
}) {
  const navigate = useNavigate();

  return (
    <Command.Group heading="Candidates">
      {conversations.map(conversation => (
        <Command.Item
          key={`${conversation.job.externalId}:${conversation.recipient.externalId}`}
          value={`${conversation.job.externalId}:${conversation.recipient.externalId} ${conversation.recipient.firstName} ${conversation.recipient.lastName}`}
          onSelect={() => {
            navigate(
              [
                slug,
                "jobs",
                conversation.job.externalId,
                "inbox",
                "candidates",
                conversation.recipient.externalId,
                conversation.conversationId ? "conversation" : "profile",
              ].join("/")
            );
            onSelect();
          }}
        >
          <Icon icon="response-rate" />
          <Text data-cs-mask>
            {conversation.recipient.firstName} {conversation.recipient.lastName}
          </Text>
          <Text color={palette.grayscale.shade600}>
            {conversation.job.title}
          </Text>
        </Command.Item>
      ))}
    </Command.Group>
  );
}
