import * as React from "react";
import type { SVGProps } from "react";
const SvgCircleUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M12 6.375a3.438 3.438 0 1 0 0 6.875 3.438 3.438 0 0 0 0-6.875Zm0 5a1.562 1.562 0 1 1 0-3.126 1.562 1.562 0 0 1 0 3.126ZM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2Zm0 18.125a8.068 8.068 0 0 1-4.863-1.632 3.891 3.891 0 0 1 3.465-2.118h2.8c1.475 0 2.786.82 3.464 2.118A8.086 8.086 0 0 1 12 20.125Zm6.258-2.95c-1.055-1.648-2.852-2.675-4.86-2.675h-2.796c-2.007 0-3.803 1.025-4.86 2.675A8.082 8.082 0 0 1 3.875 12c0-4.48 3.645-8.125 8.125-8.125S20.125 7.52 20.125 12c0 1.965-.703 3.77-1.867 5.176Z"
    />
  </svg>
);
export default SvgCircleUser;
