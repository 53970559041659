import { PlaceholderWrapper } from "./PlaceholderWrapper";

import { Text } from "@otta/design";
import { Icon } from "@otta/icons";

export const MobilePlaceholder = () => {
  return (
    <PlaceholderWrapper>
      <Icon size={2} icon="display" />
      <Text bold size={1}>
        View your analytics on desktop
      </Text>
      <Text>For detailed analytics, view this page on desktop.</Text>
    </PlaceholderWrapper>
  );
};
