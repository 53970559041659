import { useQuery } from "@apollo/client";
import { Outlet, useParams } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { CompanyProfileCompletionDocument } from "@hire/schema";
import { Icon } from "@otta/icons";
import { AttentionGetter } from "@hire/components/AttentionGetter";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { SubNavBar } from "@hire/components/layout/SubNavBar";
import { Section } from "@hire/components/layout/SubNavBar/types";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

function useProfileLinks(): Section[] {
  const { companySlug } = useParams<"companySlug">();
  const { data, loading } = useQuery(CompanyProfileCompletionDocument);

  const hasEndorsements =
    data?.currentUser?.currentCompany?.numberOfEndorsements !== undefined &&
    data?.currentUser?.currentCompany?.numberOfEndorsements > 0;
  const hasDiversityInitiatives =
    data?.currentUser?.currentCompany?.diversityInitiatives !== undefined &&
    data.currentUser.currentCompany.diversityInitiatives.length > 0;

  return [
    {
      links: [
        { content: "Company details", to: "details" },
        { content: "People", to: "people" },
        {
          content: "Diversity and inclusion",
          to: "diversity",
          badge:
            !loading && !hasDiversityInitiatives ? (
              <AttentionGetter />
            ) : undefined,
        },
        { content: "Investors and funding", to: "funding" },
        { content: "Employee benefits", to: "benefits" },
        { content: "External links", to: "links" },
        {
          content: "Endorsements",
          to: "endorsements",
          badge: !loading && !hasEndorsements ? <AttentionGetter /> : undefined,
        },
        { content: "Visa sponsorship", to: "visa-sponsorship" },
      ],
    },
    {
      links: [
        {
          content: `View profile`,
          badge: <Icon icon="external" />,
          to: `${
            import.meta.env.VITE_SEARCH_APP_HOST
          }/companies/${companySlug}`,
          target: "_blank",
        },
      ],
    },
  ];
}

export const CompanyProfile = () => {
  const sections = useProfileLinks();

  return (
    <GridContainer>
      <SubNavBar sections={sections} />
      <ResponsiveCenteredContainer>
        <Outlet />
      </ResponsiveCenteredContainer>
    </GridContainer>
  );
};
