import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { StrictMode } from "react";

import { Providers } from "./providers";
import App from "./App";
import { IdentityProxy } from "./IdentityProxy";
import { MessageStorage } from "./providers/MessageStorage";

import { GlobalStyles } from "@otta/design";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://1055ccf9930f8dcfa36b4beb37b6ce45@o140309.ingest.sentry.io/4506631359365120",
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
      "Non-Error exception captured with value: Object Not Found Matching Id:",
      "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
    ],
    denyUrls: [
      // Chrome extensions
      /^chrome:\/\//i,
    ],
  });
}

createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <GlobalStyles />
    <IdentityProxy>
      <Providers>
        <BrowserRouter>
          <MessageStorage>
            <App />
          </MessageStorage>
        </BrowserRouter>
      </Providers>
    </IdentityProxy>
  </StrictMode>
);
