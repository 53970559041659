import { CardText } from "../../common/CardText";
import { Subheading } from "../../common/Subheading";
import { SectionProps } from "../../common/types";

import { Spacing } from "@otta/design";

export function CompanyMission({
  company: { mission },
}: SectionProps): React.ReactElement | null {
  return mission ? (
    <Spacing size={0}>
      <Subheading>Company mission</Subheading>
      <CardText>{mission}</CardText>
    </Spacing>
  ) : (
    <CardText>[Company mission]</CardText>
  );
}
