import styled from "@xstyled/styled-components";

import { Recruiter } from "./Recruiter";

import { CompanyRecruiterFragment } from "@hire/schema";
import { pxToRem } from "@otta/design-tokens";
import { Text } from "@otta/design";

const UserList = styled.div`
  overflow-y: auto;
  height: ${pxToRem(350)};
`;

const GridItem = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  margin: md;
`;

const Header = styled(Text)`
  justify-self: center;
  text-align: center;
`;

interface RecruiterListProps {
  currentUserId?: string;
  companyMembers: CompanyRecruiterFragment[];
  visibleToIds: string[];
  onChange(id: string): void;
}

export function RecruiterList({
  currentUserId,
  companyMembers,
  visibleToIds,
  onChange: handleChange,
}: RecruiterListProps): React.ReactElement {
  return (
    <>
      <GridItem>
        <Text bold>Member</Text>
        <Header bold>Salary visible</Header>
      </GridItem>
      <UserList data-testid="recruiter-list">
        {companyMembers.map(recruiter => (
          <Recruiter
            recruiter={recruiter}
            isCurrentUser={recruiter.id === currentUserId}
            key={recruiter.id}
            onChange={() => {
              handleChange(recruiter.id);
            }}
            checked={visibleToIds.includes(recruiter.id)}
          />
        ))}
      </UserList>
    </>
  );
}
