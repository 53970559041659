import { FundingFormFields } from "./types";

import { CompanyFundingFragment, CompanyInput } from "@hire/schema";

const getChangedCompanyFields = (
  newValues: CompanyInput,
  initialValues: CompanyFundingFragment
) => {
  const changedFields = Object.entries(newValues).filter(([key, newValue]) => {
    if (key === "fundingRounds") {
      if (
        newValues.fundingRounds?.length !== initialValues.fundingRounds.length
      ) {
        return true;
      }
      const anyChangedFundingRounds = newValues.fundingRounds?.map(
        (fundingRound, index) => {
          const anyChangedFields = [
            fundingRound.month !== initialValues.fundingRounds[index].month,
            fundingRound.year !== initialValues.fundingRounds[index].year,
            fundingRound.round !== initialValues.fundingRounds[index].round,
            fundingRound.funding?.amount !==
              initialValues.fundingRounds[index].funding.amount,
            fundingRound.funding?.currency !==
              initialValues.fundingRounds[index].funding.currency,
          ].some(fieldChanged => fieldChanged === true);

          return anyChangedFields;
        }
      );
      return anyChangedFundingRounds?.some(hasChanged => hasChanged === true);
    }

    if (key === "investors") {
      if (newValues.investors?.length !== initialValues.investors.length) {
        return true;
      }
      const anyChangedInvestors = newValues.investors?.map(
        (investor, index) =>
          investor.value !== initialValues.investors[index].value
      );
      return anyChangedInvestors?.some(hasChanged => hasChanged === true);
    }

    const [, originalValue] = Object.entries(initialValues).filter(
      ([initialKey]) => {
        return initialKey === key;
      }
    )[0];

    return originalValue !== newValue;
  });

  return Object.fromEntries(changedFields);
};

export const transformIn = (
  data: CompanyFundingFragment
): FundingFormFields => {
  return {
    fundingRounds: data.fundingRounds.map(fundingRound => ({
      month: `${fundingRound.month}`,
      year: `${fundingRound.year}`,
      round: fundingRound.round ?? undefined,
      funding: {
        amount: `${fundingRound.funding.amount}`,
        currency: fundingRound.funding.currency,
      },
    })),
    investors: data.investors.map(({ id, value }) => ({
      label: value,
      value: id,
    })),
  };
};

export const transformOut = (
  formValues: FundingFormFields,
  initialValues: CompanyFundingFragment
): CompanyInput => {
  const values = {
    fundingRounds: formValues.fundingRounds.map(fundingRound => ({
      month: parseInt(fundingRound.month),
      year: parseInt(fundingRound.year),
      round: fundingRound.round ?? null,
      funding: {
        amount: parseInt(fundingRound.funding.amount),
        currency: fundingRound.funding.currency,
      },
    })),
    investors: formValues.investors.map(({ label }) => ({
      value: label,
    })),
  };

  return getChangedCompanyFields(values, initialValues);
};
