import { Button, Middle, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links/Link";

export function CantIntegrate({
  scraperUrl,
}: {
  scraperUrl: string;
}): React.ReactElement {
  return (
    <Middle>
      <Spacing size={4}>
        <Spacing size={1}>
          <Text align="left" data-testid="scraping-roles-link">
            We are currently adding your jobs from the following page:
          </Text>
          <Text align="left">
            <Link to={scraperUrl} newTab>
              <Button level="primary">View page</Button>
            </Link>
          </Text>
          <Text align="left">
            If you want to add new jobs, upload them to your ATS and they will
            be published within 1 working day.
          </Text>
          <Text align="left">
            If your ATS has changed,{" "}
            <Link newTab to="https://form.typeform.com/to/Lxg4Qa0T">
              complete this form to let us know
            </Link>
            .
          </Text>
        </Spacing>
      </Spacing>
    </Middle>
  );
}
