import { forwardRef, useEffect, useRef } from "react";
import { animate } from "framer-motion";
import styled from "@xstyled/styled-components";

const CountContainer = styled.div`
  margin-left: -4;
  position: relative;
  text-align: right;
  display: inline;
`;

const ActualCount = styled.span`
  position: absolute;
  right: 0;
`;

const SizingCount = styled.span`
  visibility: hidden;
`;

const Count = forwardRef<HTMLSpanElement, { countTo: number }>(
  ({ countTo }, ref) => (
    <CountContainer>
      <SizingCount>{"".padStart(`${countTo}`.length, "0")}</SizingCount>
      <ActualCount ref={ref} />
    </CountContainer>
  )
);

export default function AnimatedCounter({ countTo }: { countTo: number }) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = ref.current;
    if (!node) return;

    const from = countTo - 30 > 0 ? countTo - 30 : 0;

    const controls = animate(from, countTo, {
      duration: 1,
      ease: "easeInOut",
      onUpdate(value) {
        node.textContent = Math.round(value).toLocaleString("en-GB");
      },
    });

    return () => controls.stop();
  }, [countTo]);

  return <Count ref={ref} countTo={countTo} />;
}
