import { JobAuthorizationProvider } from "@hire/providers/authorization";
import { useRequiredParams } from "@hire/util/routing";

export function JobProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const { jobId } = useRequiredParams(["jobId"]);

  return (
    <JobAuthorizationProvider jobId={jobId}>
      {children}
    </JobAuthorizationProvider>
  );
}
