import {
  addYears,
  differenceInMonths,
  differenceInYears,
  parseISO,
} from "date-fns";

export const yearsAndMonths = (
  startDate: string | null,
  endDate: string | null
): string => {
  const start = startDate ? parseISO(startDate) : new Date();
  const end = endDate ? parseISO(endDate) : new Date();

  const years = differenceInYears(end, start);
  const startMonths = addYears(start, years);
  const months = differenceInMonths(end, startMonths);

  if (years > 0 && months > 0) {
    return `${years}y ${months}m`;
  }
  if (years > 0) {
    return `${years}y`;
  }
  if (months > 0) {
    return `${months}m`;
  }
  if (months === 0 && start <= end) {
    return `1m`;
  }
  return "";
};
