import { useEffect } from "react";
import styled from "@xstyled/styled-components";
import { useLocation } from "react-router-dom";

import { pushAnalyticsEvent } from "@otta/analytics";

const FormContainer = styled.div`
  margin: 0 auto;
  padding: 4xl lg;
  max-width: 600px;
  min-height: 100vh;
  height: 100%;
`;

function isHubspotEvent(e: MessageEvent<unknown>): e is MessageEvent<{
  type: string;
  eventName: string;
  data: { name: string; value: string }[];
}> {
  return (
    typeof e.data === "object" &&
    e.data !== null &&
    "type" in e.data &&
    "eventName" in e.data &&
    "data" in e.data
  );
}

/*
 * https://help.chilipiper.com/hc/en-us/articles/360053800373-About-Concierge-Snippet-and-JS-API
 */
export function BookACall({
  chiliPiperRouterName: cpRouterName,
  portalId,
  formId,
  utmSource,
}: {
  chiliPiperRouterName: string;
  portalId: string;
  formId: string;
  utmSource?: string;
}): React.ReactElement {
  const { pathname } = useLocation();
  useEffect(() => {
    const onLoad = () => {
      if (typeof window.hbspt !== "undefined") {
        window.hbspt.forms.create({
          region: "na1",
          portalId,
          formId,
          target: ".form-container",
        });
      }
    };
    if (typeof window.ChiliPiper === "undefined") {
      const cpScript = document.createElement(`script`);
      cpScript.src = `//js.na.chilipiper.com/marketing.js`;
      cpScript.id = "chili_piper_scheduler";
      document.head.appendChild(cpScript);
    }
    if (typeof window.hbspt === "undefined") {
      const script = document.createElement(`script`);
      script.defer = true;
      script.onload = onLoad;
      script.src = `//js.hsforms.net/forms/v2.js`;
      document.head.appendChild(script);
    } else {
      onLoad();
    }

    const cpTenantDomain = "otta-com";

    // Listen for form submitted message
    const listener: (event: MessageEvent<unknown>) => void = event => {
      if (
        isHubspotEvent(event) &&
        event.data.type === "hsFormCallback" &&
        event.data.eventName === "onFormSubmit"
      ) {
        const lead = event.data.data.reduce<Record<string, string>>(
          (obj, item) => {
            obj[item.name] = item.value;
            return obj;
          },
          {}
        );

        pushAnalyticsEvent({
          eventName: "Submitted Form - Inbound - HubSpot",
          page: pathname,
          utmSource: utmSource,
        });

        if (typeof window.ChiliPiper !== "undefined") {
          const options = {
            map: true,
            lead,
            onSuccess() {
              pushAnalyticsEvent({
                eventName: "Submitted Form",
                page: pathname,
                utmSource,
              });
            },
          };

          window.ChiliPiper.submit(cpTenantDomain, cpRouterName, options);
        }
      }
    };
    window.addEventListener("message", listener);
    return () => {
      window.removeEventListener("message", listener);
    };
  }, [utmSource, portalId, formId, cpRouterName, pathname]);

  return (
    <FormContainer className="form-container" data-testid="form-container" />
  );
}
