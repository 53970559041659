import { MutationUpdaterFn, Reference, gql } from "@apollo/client";

import { UpdateCompanyJobApplicationMutation } from "@hire/schema";

export const updateApplicationResponse: MutationUpdaterFn<
  UpdateCompanyJobApplicationMutation
> = (proxy, result) => {
  const cache = proxy.readQuery<{ me: { id: string } | null }>({
    query: gql`
      {
        me {
          id
        }
      }
    `,
  });

  if (!cache?.me) {
    return;
  }

  const newConversation = result.data?.updateCompanyJobApplicationConversation;
  const jobApplicationId = newConversation?.jobApplicationId;
  const conversationId = newConversation?.conversationId;

  const idToRemove = `null-${jobApplicationId}`;
  const idToAdd = `CompanyConversation:{"conversationId":"${conversationId}","jobApplicationId":"${jobApplicationId}"}`;

  proxy.modify({
    id: proxy.identify({ ...cache.me }),
    fields: {
      companyConversations(existingConversationRefs, { readField }) {
        const newConversationRef = proxy.writeFragment({
          id: idToAdd,
          data: newConversation,
          fragment: gql`
            fragment NewCompanyConversation on CompanyConversation {
              conversationId
              jobApplicationId
            }
          `,
        });

        const updatedExistingConversationRefs = existingConversationRefs.filter(
          (ref: Reference) =>
            `${readField("conversationId", ref) as string}-${
              readField("jobApplicationId", ref) as string
            }` !== idToRemove
        );

        return [newConversationRef, ...updatedExistingConversationRefs];
      },
    },
  });
};
