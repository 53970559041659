import styled from "@xstyled/styled-components";

import { palette, pxToRem, borderRadius } from "@otta/design-tokens";
import { Text } from "@otta/design";
import { Padlock } from "@hire/components/icons";

const Container = styled.span`
  display: inline-flex;
  align-items: center;
  background-color: ${palette.extended.yellow.shade100};
  padding-right: sm;
  border-radius: ${pxToRem(borderRadius)};
  margin-left: 19;
`;

const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.brand.yellow};

  height: ${pxToRem(18)};
  width: ${pxToRem(18)};
  border-radius: 50%;

  margin-right: xs;

  svg {
    width: ${pxToRem(8)};
  }
`;

export const DefaultTemplateBadge = (): React.ReactElement => {
  return (
    <Container>
      <IconWrapper>
        <Padlock />
      </IconWrapper>
      <Text bold size={-2}>
        Template
      </Text>
    </Container>
  );
};
