import styled from "@xstyled/styled-components";
import { Formik, FormikProps, useField } from "formik";
import * as Yup from "yup";
import { useCallback, useMemo } from "react";

import { FunctionField } from "./fields/FunctionField";
import { LanguageField } from "./fields/LanguageField";
import { FullOrPartTime, FullTimeField } from "./fields/FullTimeField";

import {
  Alert,
  Button,
  FieldProps,
  FieldWrapper,
  Fieldset,
} from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import {
  ExperienceSlider,
  Year,
} from "@hire/components/sliders/ExperienceRangeSlider";
import { Location, QuizFieldValuesQuery } from "@hire/schema";
import { LocationField } from "@hire/components/form/LocationField";
import { REMOTE_LOCATIONS } from "@hire/util/locations";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

export type FormValues = Partial<{
  subFunction: string;
  locations: Location[];
  fullTime: FullOrPartTime;
  experience: [Year, Year];
  languages: string[];
}>;

const initialValues: FormValues = {
  fullTime: FullOrPartTime.FullTime,
  subFunction: undefined,
  experience: [0, 2],
  locations: [],
  languages: [],
};

const NarrowLocation = styled(LocationField)`
  max-width: ${pxToRem(400)};
`;
const NarrowFunction = styled(FunctionField)`
  max-width: ${pxToRem(400)};
`;
const NarrowLanguage = styled(LanguageField)`
  max-width: ${pxToRem(400)};
`;

const NarrowAlert = styled(Alert)`
  max-width: ${pxToRem(400)};
`;

const ExperienceWrapper = styled.div`
  margin-bottom: 50;
  padding: 0 xxl 0 xxl;
`;

function useValidationSchema() {
  return useMemo(
    () =>
      Yup.object({
        subFunction: Yup.string().required("Select a function"),
        languages: Yup.array(Yup.string()).optional(),
        locations: Yup.array(Yup.string()).min(1, "Select a location"),
        experience: Yup.array().of(Yup.number()).required().min(2).max(2),
        fullTime: Yup.string().oneOf(
          [FullOrPartTime.FullTime],
          "We don’t currently support part-time roles"
        ),
      }),
    []
  );
}

function FullTime(props: FieldProps) {
  const [fullTime] = useField({
    value: FullOrPartTime.FullTime,
    name: "fullTime",
    type: "radio",
  });

  const [partTime] = useField({
    value: FullOrPartTime.PartTime,
    name: "fullTime",
    type: "radio",
  });

  return (
    <FullTimeField
      fullTime={{ ...fullTime, ...props }}
      partTime={{ ...partTime, ...props }}
    />
  );
}

function error(
  form: FormikProps<FormValues>,
  field: keyof FormValues
): string | undefined {
  return form.touched[field] ? form.errors[field] : undefined;
}

function setSingle<A>(
  form: FormikProps<FormValues>,
  field: keyof FormValues
): (v: { value: A } | null) => void {
  return v =>
    form.setFieldValue(field, v?.value ?? undefined, !!form.errors[field]);
}

function setMulti<A>(
  form: FormikProps<FormValues>,
  field: keyof FormValues
): (v: readonly { value: A }[]) => void {
  return vs =>
    form.setFieldValue(
      field,
      vs.map(v => v.value),
      !!form.errors[field]
    );
}

function LocationsAlert({ locations }: { locations: FormValues["locations"] }) {
  const hasOnlyRemoteLocations = useMemo(
    () =>
      (locations?.length ?? 0) > 0 &&
      locations?.every(l => REMOTE_LOCATIONS.includes(l)),
    [locations]
  );

  return hasOnlyRemoteLocations ? (
    <NarrowAlert level="information">
      If you require candidates in the office more than 1-2 days a month, select
      a city instead of remote.
    </NarrowAlert>
  ) : null;
}

export function Form({
  onSubmit,
  options,
}: {
  onSubmit: (values: FormValues) => void;
  options: QuizFieldValuesQuery;
}) {
  const schema = useValidationSchema();
  const companyName = useWelcomeToTheJungle();

  const handleSubmit = useCallback(
    (values: FormValues) => {
      onSubmit(values);
    },
    [onSubmit]
  );

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {form => (
        <form onSubmit={form.handleSubmit}>
          <Fieldset>
            <FieldWrapper
              label="Employment type"
              fieldError={form.errors.fullTime}
              required
            >
              {({ field: { id, ...field } }) => <FullTime {...field} />}
            </FieldWrapper>
            <FieldWrapper
              required
              label="Function"
              fieldError={error(form, "subFunction")}
            >
              {({ field: { id, ...field } }) => (
                <NarrowFunction
                  onChange={setSingle(form, "subFunction")}
                  options={options.jobFunctions}
                  value={form.values.subFunction}
                  inputId={id}
                  {...field}
                />
              )}
            </FieldWrapper>
            <FieldWrapper
              required
              label="Experience level"
              fieldError={error(form, "experience")}
            >
              {({ field }) => (
                <ExperienceWrapper>
                  <ExperienceSlider
                    value={form.values.experience ?? [1, 2]}
                    onChange={v => form.setFieldValue("experience", v, false)}
                    {...field}
                  />
                </ExperienceWrapper>
              )}
            </FieldWrapper>
            <FieldWrapper
              label="Where is this job based?"
              fieldError={error(form, "locations")}
              required
            >
              {({ field: { id, ...field } }) => (
                <>
                  <NarrowLocation
                    onChange={setMulti(form, "locations")}
                    value={form.values.locations ?? []}
                    inputId={id}
                    {...field}
                  />
                  <LocationsAlert locations={form.values.locations} />
                </>
              )}
            </FieldWrapper>
            <FieldWrapper
              label="Language"
              advice={`Jobs on ${companyName} require English. Add other required languages here.`}
              fieldError={error(form, "languages")}
            >
              {({ field: { id, ...field } }) => (
                <NarrowLanguage
                  onChange={setMulti(form, "languages")}
                  options={options.languageRequirements}
                  value={form.values.languages ?? []}
                  inputId={id}
                  {...field}
                />
              )}
            </FieldWrapper>
            <Button type="submit" level="primary" disabled={form.isSubmitting}>
              {form.isSubmitting ? <>Calculating&hellip;</> : "Continue"}
            </Button>
          </Fieldset>
        </form>
      )}
    </Formik>
  );
}
