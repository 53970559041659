import { useEffect, useId, useState } from "react";
import styled from "@xstyled/styled-components";

import { Button, ButtonProps, InputField, Label, Spacing } from "@otta/design";

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: md;
`;

const InputFieldWrapper = styled.div`
  width: 100%;
`;

interface CopyFieldProps {
  value: string;
  label?: string;
  dataId?: string;
  tip?: React.ReactElement;
  level?: ButtonProps["level"];
}

const LABEL_TIMEOUT_MS = 2500;

export const CopyTextField = ({
  value,
  label,
  dataId,
  level = "primary",
}: CopyFieldProps) => {
  const id = useId();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setCopied(false), LABEL_TIMEOUT_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  const clickHandler = async () => {
    await navigator.clipboard.writeText(value);
    setCopied(true);
  };

  return (
    <Spacing size={-6}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <InputWrapper>
        <InputFieldWrapper>
          <InputField
            name={id}
            value={value}
            type="text"
            style={{ width: "100%" }}
            disabled
            data-testid="copy-text-field"
          />
        </InputFieldWrapper>
        <Button data-analytics-id={dataId} level={level} onClick={clickHandler}>
          {copied ? "Copied" : "Copy"}
        </Button>
      </InputWrapper>
    </Spacing>
  );
};
