import { useJobEditField } from "../../useHandlers";

import { Textarea } from "@hire/components/input/Textarea";
import { FieldWrapper } from "@otta/design";

export function Restrictions() {
  const [formik, meta] = useJobEditField("locationRestrictions");

  return (
    <FieldWrapper
      label="Timezone or location restrictions"
      advice="For example, ‘+/- 2 hours of GMT’ or ‘Only hiring in these states’."
      fieldError={meta.error}
    >
      {({ field }) => <Textarea {...formik} {...field} minRows={1} />}
    </FieldWrapper>
  );
}
