import styled from "@xstyled/styled-components";

import { CandidateCardActions } from "../interfaces";

import { CandidateCardFragment } from "@hire/schema";
import { Button } from "@otta/design";

const Container = styled.div`
  display: flex;
  gap: sm;
`;

interface CandidateActionButtonsProps extends CandidateCardActions {
  candidate: CandidateCardFragment;
}

export function CandidateActionButtons({
  candidate,
  messageCandidate,
  removeCandidate,
  shortlistCandidate,
}: CandidateActionButtonsProps): React.ReactElement | null {
  return (
    <Container>
      {shortlistCandidate && (
        <Button
          level="secondary"
          onClick={e => {
            e.stopPropagation();
            shortlistCandidate(candidate.externalId);
          }}
        >
          Shortlist
        </Button>
      )}
      {removeCandidate && (
        <Button
          level="secondary"
          onClick={e => {
            e.stopPropagation();
            removeCandidate(candidate.externalId);
          }}
        >
          Remove
        </Button>
      )}
      {messageCandidate && (
        <Button
          level="primary"
          onClick={e => {
            e.stopPropagation();
            messageCandidate(candidate.externalId);
          }}
        >
          Message
        </Button>
      )}
    </Container>
  );
}
