import styled from "@xstyled/styled-components";

import { formatDatesToFrom, formatDuration } from "./utils";
import { DatesWrapper } from "./shared";

import { CandidateFragment } from "@hire/schema";
import { Text, List, VerticalSpacing } from "@otta/design";
import { HighlightableText } from "@hire/components/HighlightableText";

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface CandidateEducationExperienceProps {
  educationExperience: CandidateFragment["educationExperiences"][0];
}

export function CandidateEducationExperience({
  educationExperience: {
    institutionName,
    fieldOfStudy,
    startDate,
    endDate,
    descriptions,
  },
}: CandidateEducationExperienceProps): React.ReactElement {
  const datesToFrom = formatDatesToFrom({
    startDate,
    endDate,
    dateFormat: "yyyy",
  });

  const duration = formatDuration(startDate, endDate);

  return (
    <VerticalSpacing size={-3}>
      <SpaceBetween>
        <Text bold>
          <HighlightableText>{institutionName}</HighlightableText>
        </Text>
        {datesToFrom && <DatesWrapper size={-1}>{datesToFrom}</DatesWrapper>}
      </SpaceBetween>

      {fieldOfStudy && (
        <Text bold>
          <HighlightableText>{fieldOfStudy}</HighlightableText>
        </Text>
      )}

      {duration && <Text size={-1}>{duration}</Text>}

      {descriptions.length > 0 && (
        <List type="bullet" size={-1}>
          {descriptions.map(({ id, value }) => (
            <li key={id}>
              <HighlightableText>{value}</HighlightableText>
            </li>
          ))}
        </List>
      )}
    </VerticalSpacing>
  );
}
