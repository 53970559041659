import styled from "@xstyled/styled-components";

import { SourcingPreferencesFieldProps } from "./interfaces";

import { VerticalSpacing, Label, Text } from "@otta/design";
import {
  Year,
  ExperienceSlider,
} from "@hire/components/sliders/ExperienceRangeSlider";

const ExperienceSliderWrapper = styled.div`
  padding: lg xl;
`;

export function ExperienceRangeField({
  preferences,
  onChange,
}: SourcingPreferencesFieldProps): React.ReactElement {
  return (
    <VerticalSpacing size={-4}>
      <Label htmlFor="experienceRange">
        <Text bold>Experience level</Text>
      </Label>
      <ExperienceSliderWrapper>
        <ExperienceSlider
          value={[
            (preferences.experienceRange?.minYearsExperienceRequired ??
              0) as Year,
            (preferences.experienceRange?.maxYearsExperienceRequired ??
              8) as Year,
          ]}
          onChange={([min, max]) => {
            onChange({
              experienceRange: {
                minYearsExperienceRequired: min,
                maxYearsExperienceRequired: max,
              },
            });
          }}
          shortForm
        />
      </ExperienceSliderWrapper>
    </VerticalSpacing>
  );
}
