import styled from "@xstyled/styled-components";
import { Link, useParams } from "react-router-dom";

import { JobSlotsModal } from "../JobSlotsModal";

import { Button, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

const ButtonContainer = styled.div`
  margin-top: xl;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1em;
`;

const StyledButton = styled(Button)`
  width: 50%;
  text-decoration: none;
`;
export const RecentlyCancelled = () => {
  const companyName = useWelcomeToTheJungle();
  const unfreezeEmailLink = `mailto:companies@otta.com?subject=Publish jobs on ${companyName}`;
  const { companySlug } = useParams<"companySlug">();

  return (
    <JobSlotsModal
      name="recently-cancelled-subscription"
      redirectOnClose={`/${companySlug}/jobs`}
    >
      <Text size={2} bold style={{ marginBottom: pxToRem(24) }}>
        Publish jobs
      </Text>
      <Text>
        It looks like you recently cancelled your subscription. This means you
        will need to email us at{" "}
        <Link to={unfreezeEmailLink} style={{ color: palette.brand.black }}>
          companies@otta.com
        </Link>{" "}
        to publish your jobs. We will do this for you within 1 working day.
      </Text>
      <ButtonContainer>
        <StyledButton
          title="Send email"
          level="primary"
          data-testid="send-email-link"
          to={unfreezeEmailLink}
          as={Link}
        >
          Send email
        </StyledButton>
        <StyledButton level="destructive" as={Link} to="..">
          Cancel
        </StyledButton>
      </ButtonContainer>
    </JobSlotsModal>
  );
};
