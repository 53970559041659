export function Stage(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5 0H1.5C0.671562 0 0 0.671562 0 1.5V12.5C0 13.3284 0.671562 14 1.5 14H14.5C15.3284 14 16 13.3284 16 12.5V1.5C16 0.671562 15.3284 0 14.5 0ZM7.25 12.5H1.6875C1.63777 12.5 1.59008 12.4802 1.55492 12.4451C1.51975 12.4099 1.5 12.3622 1.5 12.3125V2.5H7.25V12.5ZM14.3125 12.5H8.75V2.5H14.5V12.3125C14.5 12.3622 14.4802 12.4099 14.4451 12.4451C14.4099 12.4802 14.3622 12.5 14.3125 12.5Z"
        fill="black"
      />
    </svg>
  );
}
