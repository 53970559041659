import styled from "@xstyled/styled-components";
import { useMemo, useState } from "react";

import { Statistic } from "./components/CardStatistics";
import { SourcingPerformance } from "./types";
import { BarGraph } from "./components/BarGraph";
import { MessageOutcome } from "./components/MessageOutcome";
import { FilterToggle } from "./components/FilterToggle";
import { FilterOptions } from "./components/graph-helpers";
import { StackedBarGraph } from "./components/StackedBarGraph";
import { MissingDataPlaceholder } from "./components/MissingDataPlaceholder";
import { SourcingNudge } from "./components/SourcingNudge";

import { Spacing, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { JobSourcingPerformanceSubsetStatistics } from "@hire/schema";
import { ANALYTICS_DESKTOP_BREAKPOINT } from "@hire/components/layout/constants";

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  display: grid;
  column-gap: 40;
  row-gap: lg;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const BarGraphWrapper = styled.div`
  grid-column: span 3;

  @media (min-width: ${ANALYTICS_DESKTOP_BREAKPOINT}px) {
    grid-column: span 2;
  }
`;

const DonutGraphWrapper = styled.div`
  display: grid;
  grid-column: span 3;

  @media (min-width: ${ANALYTICS_DESKTOP_BREAKPOINT}px) {
    grid-column: span 1;
  }
`;

export function SourcingStatistics({
  data,
  relevantCandidates,
}: {
  data?: SourcingPerformance | null;
  relevantCandidates: number | null;
}) {
  const [currentFilter, setCurrentFilter] = useState<FilterOptions>("all");

  const graphDetails = useMemo(() => {
    if (!data) return [];

    return [
      {
        title: "Recruiter viewed",
        value: data.totals.viewed.total,
      },
      {
        title: "Recruiter shortlisted",
        value: data.totals.shortlisted.total,
      },
      {
        title: "Recruiter messaged",
        value: data.totals.messaged.total,
      },
    ];
  }, [data]);

  const filteredGraphDetails = useMemo(() => {
    const filteredData =
      currentFilter === "all"
        ? null
        : (data?.[currentFilter] as JobSourcingPerformanceSubsetStatistics);

    return [
      {
        title: "Recruiter viewed",
        values: filteredData?.viewed ?? [],
      },
      {
        title: "Recruiter shortlisted",
        values: filteredData?.shortlisted ?? [],
      },
      {
        title: "Recruiter messaged",
        values: filteredData?.messaged ?? [],
      },
    ];
  }, [data, currentFilter]);

  const messageOutcome = useMemo(() => {
    if (!data) return [];

    return [
      {
        value: data.messageOutcomes.accepted.total,
        percent: data.messageOutcomes.accepted.percent,
        color: palette.brand.green,
        name: "Accepted",
      },
      {
        value: data.messageOutcomes.declined.total,
        percent: data.messageOutcomes.declined.percent,
        color: palette.extended.red.shade500,
        name: "Declined",
      },
      {
        value: data.messageOutcomes.unanswered.total,
        percent: data.messageOutcomes.unanswered.percent,
        color: palette.grayscale.shade400,
        name: "Unanswered",
      },
    ];
  }, [data]);

  const [hoveredItem, setHoveredItem] = useState<string>();

  const hasData = data && data.totals.viewed.total > 0;
  const hasRelevantCandidates = !!relevantCandidates && relevantCandidates > 0;

  return (
    <Spacing size={2}>
      <TitleSection>
        <Text bold size={2}>
          Sourcing performance
        </Text>
        {hasData && (
          <FilterToggle
            currentFilter={currentFilter}
            section="sourcing-performance"
            changeFilter={filter => setCurrentFilter(filter)}
          />
        )}
      </TitleSection>
      {hasData && (
        <Container>
          <Statistic
            title="Recruiter viewed"
            value={data.totals.viewed.total}
            filterData={filteredGraphDetails[0].values}
            hoveredItem={hoveredItem}
            setHoveredItem={setHoveredItem}
          />
          <Statistic
            title="Recruiter shortlisted"
            value={data.totals.shortlisted.total}
            filterData={filteredGraphDetails[1].values}
            conversion={data.totals.shortlisted.conversion ?? undefined}
            hoveredItem={hoveredItem}
            setHoveredItem={setHoveredItem}
          />
          <Statistic
            title="Recruiter messaged"
            value={data.totals.messaged.total}
            filterData={filteredGraphDetails[2].values}
            conversion={data.totals.messaged.conversion ?? undefined}
            hoveredItem={hoveredItem}
            setHoveredItem={setHoveredItem}
            hasChevron={false}
          />

          <BarGraphWrapper>
            {currentFilter === "all" && (
              <BarGraph bars={graphDetails} graphTitle="Number of candidates" />
            )}

            {currentFilter !== "all" && (
              <StackedBarGraph
                bars={filteredGraphDetails}
                graphTitle="Number of candidates"
                hoveredItem={hoveredItem}
                setHoveredItem={setHoveredItem}
              />
            )}
          </BarGraphWrapper>
          <DonutGraphWrapper>
            <MessageOutcome data={messageOutcome} />
          </DonutGraphWrapper>
        </Container>
      )}
      {!hasData && hasRelevantCandidates && (
        <SourcingNudge relevantCandidates={relevantCandidates} />
      )}
      {!hasData && !hasRelevantCandidates && <MissingDataPlaceholder />}
    </Spacing>
  );
}
