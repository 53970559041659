import { useFormik } from "formik";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";

import { ArticleFormValues } from "./types";

import { Button, InputField, Spacing } from "@otta/design";
import { ButtonWrapper } from "@hire/components/buttons/ButtonWrapper";

const SubFormWrapper = styled.div`
  padding: lg;
`;

export const ArticleForm = ({
  initialValues,
  onClose,
  onSave,
}: {
  initialValues?: ArticleFormValues;
  onClose: () => void;
  onSave: (article: ArticleFormValues) => void;
}) => {
  const validationSchema = Yup.object({
    url: Yup.string()
      .trim()
      .url("Enter a valid URL")
      .required("Fill in this field"),
    title: Yup.string().trim().min(3).max(127).required("Fill in this field"),
  });

  const form = useFormik<ArticleFormValues>({
    initialValues: initialValues ?? {
      url: "",
      title: "",
    },
    onSubmit: onSave,
    validationSchema,
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <SubFormWrapper>
        <Spacing>
          <InputField
            value={form.values.url}
            label="URL"
            name="url"
            onChange={form.handleChange("url")}
            onBlur={form.handleBlur("url")}
            error={form.touched.url ? form.errors.url : undefined}
          />
          <InputField
            value={form.values.title}
            label="Title"
            name="title"
            onChange={form.handleChange("title")}
            onBlur={form.handleBlur("title")}
            error={form.touched.title ? form.errors.title : undefined}
          />
          <ButtonWrapper align="start">
            <Button level="secondary" type="button" onClick={onClose}>
              Cancel
            </Button>
            <Button level="primary" type="submit" disabled={form.isSubmitting}>
              Confirm
            </Button>
          </ButtonWrapper>
        </Spacing>
      </SubFormWrapper>
    </form>
  );
};
