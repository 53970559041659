import { ApolloError } from "@apollo/client";

export const handleMutationError = (error: ApolloError): void => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    const errorMessage = error.graphQLErrors
      .map(({ message }) => message)
      .map(message => removeSnakeCasing(message))
      .map(message => titleize(message))
      .join(". ");
    window.alert(errorMessage);
  } else {
    window.alert("Something went wrong. Try again.");
  }
};

// When presenting a fieldname to the end-user, it helps to present it in its
// human-readable form, which means removing the underscores.
const removeSnakeCasing = (input: string): string => input.replace("_", " ");

const titleize = (input: string): string => {
  if (input && input.length > 0) {
    return input[0].toUpperCase() + input.slice(1);
  } else {
    return input;
  }
};
