import { ProfileColumn, ProfileContainer } from "../shared";

import { CandidateEducationExperiences } from "./CandidateEducationExperiences";
import { CandidateSnippets } from "./CandidateSnippets";
import { CandidateWorkExperiences } from "./CandidateWorkExperiences";
import { CandidateInterests } from "./CandidateInterests";
import { CandidateContextQuestionResponses } from "./CandidateContextQuestionResponses";
import { CandidateLanguages } from "./CandidateLanguages";

import { CandidateCardFragment, CandidateCardJobQuery } from "@hire/schema";
import { VerticalSpacing } from "@otta/design";

interface CandidateExpandedProps {
  candidate: CandidateCardFragment;
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
}

export function CandidateExpanded({
  candidate,
  job,
}: CandidateExpandedProps): React.ReactElement {
  return (
    <ProfileContainer>
      <ProfileColumn>
        <VerticalSpacing>
          <CandidateWorkExperiences candidate={candidate} job={job} />
        </VerticalSpacing>
      </ProfileColumn>
      <ProfileColumn>
        <VerticalSpacing>
          <CandidateEducationExperiences candidate={candidate} />
          <CandidateSnippets candidate={candidate} />
          <CandidateInterests candidate={candidate} />
          <CandidateContextQuestionResponses candidate={candidate} />
          <CandidateLanguages candidate={candidate} />
        </VerticalSpacing>
      </ProfileColumn>
    </ProfileContainer>
  );
}
