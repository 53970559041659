import { Link } from "react-router-dom";

import { Middle, Paragraph, Spacing, Text, Button } from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";

interface IRequiresEmailVerificationProps {
  email: string;
}

export function RequiresEmailVerification({
  email,
}: IRequiresEmailVerificationProps): React.ReactElement {
  return (
    <Middle
      maxWidth={650}
      style={{ padding: `${modularScale(5)} ${modularScale()}` }}
    >
      <Spacing>
        <Text as="h2" size={3} bold>
          Verify your email
        </Text>
        <Paragraph>
          We&apos;ve sent an email to <b>{email}</b>.
        </Paragraph>
        <Paragraph>
          No email in your inbox or spam folder?{" "}
          <Link to="/verify-email" style={{ color: palette.brand.black }}>
            Click here to resend
          </Link>
          .
        </Paragraph>

        <Button
          data-testid="logout-button"
          level="primary"
          as={Link}
          to="/logout"
        >
          Log out
        </Button>
      </Spacing>
    </Middle>
  );
}
