import { useQuery } from "@apollo/client";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useEffect } from "react";

import { useRedirect } from "./useRedirect";

import { CurrentUserDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { pushAnalyticsEvent } from "@otta/analytics";
import { hireAppUser } from "@hire/util/user";

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: md;
`;

export function Auth(): React.ReactElement {
  const location = useLocation();
  const { data, loading } = useQuery(CurrentUserDocument);
  const urlSafeName = hireAppUser(data?.me)?.currentCompany?.urlSafeName;
  const redirect = useRedirect();

  useEffect(() => {
    if (!urlSafeName && !loading) {
      pushAnalyticsEvent({
        eventName: "User Viewed Page",
        pathname: location.pathname,
      });
    }
  }, [location.pathname, urlSafeName, loading]);

  if (loading) {
    return <Loading />;
  }

  if (urlSafeName) {
    return <Navigate to={redirect(urlSafeName)} replace />;
  }

  return (
    <PageContainer>
      <Outlet />
    </PageContainer>
  );
}
