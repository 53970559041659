import {
  parseISO,
  format,
  isSameDay,
  addYears,
  differenceInMonths,
  differenceInYears,
} from "date-fns";

import { pluraliser } from "@hire/util/strings";

export function formatDatesToFrom({
  startDate,
  endDate,
  dateFormat,
}: {
  startDate: string | null;
  endDate: string | null;
  dateFormat: string;
}): string | null {
  if (!startDate) {
    return null;
  }

  const start = parseISO(startDate);
  const end = endDate ? parseISO(endDate) : null;
  const formattedStartDate = format(start, dateFormat);

  if (end && isSameDay(start, end)) {
    return formattedStartDate;
  }
  if (end) {
    return `${formattedStartDate} - ${format(end, dateFormat)}`;
  }
  return `${formattedStartDate} - Present`;
}

export function formatDuration(
  startDate: string | null,
  endDate: string | null
): string | null {
  const start = startDate ? parseISO(startDate) : new Date();
  const end = endDate ? parseISO(endDate) : new Date();

  const years = differenceInYears(end, start);
  const startMonths = addYears(start, years);
  const months = differenceInMonths(end, startMonths);
  const yearString = pluraliser(years, "year");
  const monthString = pluraliser(months, "month");

  if (years > 0 && months > 0) {
    return `${years} ${yearString} & ${months} ${monthString}`;
  } else if (years > 0) {
    return `${years} ${yearString}`;
  } else if (months > 0) {
    return `${months} ${monthString}`;
  }
  return null;
}
