import { PropsWithChildren } from "react";
import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

const FooterWrapper = styled.div`
  background: ${palette.brand.white};
  border-top: 1px solid ${palette.grayscale.shade200};
  bottom: 0;
  left: 0;
  padding: lg;
  position: fixed;
  width: 100%;
`;

export function MobileFooter({ children }: PropsWithChildren) {
  return <FooterWrapper>{children}</FooterWrapper>;
}
