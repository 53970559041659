import styled from "@xstyled/styled-components";

import { Tooltip, TooltipProps } from "@otta/design";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6;
`;

const StyledTooltip = styled(Tooltip)`
  display: flex;
`;

interface TooltipWithIconProps extends Omit<TooltipProps, "children"> {
  icon: JSX.Element;
  children?: React.ReactNode;
}

export function TooltipWithIcon({
  icon,
  children,
  ...rest
}: TooltipWithIconProps): React.ReactElement {
  const tooltip = <StyledTooltip {...rest}>{icon}</StyledTooltip>;

  if (children) {
    return (
      <Wrapper>
        {children}
        {tooltip}
      </Wrapper>
    );
  }

  return tooltip;
}
