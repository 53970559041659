export function pluraliser(total: number, singularForm: string): string {
  if (
    singularForm.endsWith("ies") ||
    singularForm.endsWith("es") ||
    (!singularForm.endsWith("us") && singularForm.endsWith("s")) ||
    total === 1
  ) {
    return singularForm;
  }

  return singularForm.endsWith("y") && !singularForm.endsWith("ay")
    ? singularForm.substring(0, singularForm.length - 1) + "ies"
    : singularForm.endsWith("us")
    ? singularForm + "es"
    : singularForm + "s";
}
