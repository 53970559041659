import { useMutation } from "@apollo/client";
import { format } from "date-fns";
import { useCallback } from "react";
import styled from "@xstyled/styled-components";

import { RecruiterInfo } from "../../../components/RecruiterInfo";

import { handleMutationError } from "@hire/errors";
import {
  CompanyRoleType,
  TeamMembersDocument,
  UpdateCompanyRoleDocument,
  UpdateUserCurrentCompanyDocument,
} from "@hire/schema";
import { palette, modularScale, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Dropdown, DropdownItem, Text } from "@otta/design";

const RecruiterInfoItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const UserStatusWrapper = styled.div<{ width: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? pxToRem(width) : pxToRem(75))};
`;

const ClickableText = styled(Text)`
  display: flex;
  flex-direction: row;
  &:hover {
    cursor: pointer;
  }
`;

const RemoveUserText = styled(Text)`
  display: flex;
  flex-direction: row;
  text-decoration: underline;
  color: ${palette.brand.red};
  &:hover {
    cursor: pointer;
  }
`;

const StyledDownArrow = styled(Icon).attrs({ icon: "chevron-down" })`
  width: ${pxToRem(10)};
  height: ${pxToRem(23)};
  margin-left: xxs;
`;

const StyledText = styled(Text)`
  display: flex;
`;

interface AdminRecruiterCardProps {
  currentUserId: string;
  currentCompanyName: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  profileImagePath: string | null;
  lastActive: string | null;
}

export function AdminRecruiterCard({
  currentUserId,
  currentCompanyName,
  id,
  firstName,
  lastName,
  email,
  role,
  profileImagePath,
  lastActive,
}: AdminRecruiterCardProps): React.ReactElement {
  const lastActiveDate = lastActive
    ? format(new Date(lastActive), "d MMMM yyyy")
    : "Never logged in";
  const isAdmin = role === CompanyRoleType.Admin;
  const isCurrentUser = currentUserId === id;

  const [updateCompanyRole] = useMutation(UpdateCompanyRoleDocument, {
    onError: handleMutationError,
  });

  const [removeCompanyRecruiter] = useMutation(
    UpdateUserCurrentCompanyDocument,
    {
      onError: handleMutationError,
      refetchQueries: [TeamMembersDocument],
    }
  );

  const handleAdminClick = useCallback(() => {
    if (!isAdmin) {
      updateCompanyRole({
        variables: {
          newRole: CompanyRoleType.Admin,
          userId: id,
        },
      });
    }
  }, [isAdmin, updateCompanyRole, id]);

  const handleMemberClick = useCallback(() => {
    if (isAdmin) {
      updateCompanyRole({
        variables: {
          newRole: CompanyRoleType.Member,
          userId: id,
        },
      });
    }
  }, [isAdmin, updateCompanyRole, id]);

  const handleRemoveClick = useCallback(async () => {
    const confirmWindow = window.confirm(
      `Are you sure you want to remove ${firstName}? This will remove their access from ${currentCompanyName}'s account.`
    );

    if (confirmWindow) {
      await removeCompanyRecruiter({
        variables: {
          userId: id,
          companyId: null,
        },
      });
    }
  }, [firstName, currentCompanyName, removeCompanyRecruiter, id]);

  return (
    <>
      <RecruiterInfo
        firstName={firstName}
        lastName={lastName}
        email={email}
        profileImagePath={profileImagePath}
        isCurrentUser={isCurrentUser}
      />

      <RecruiterInfoItemWrapper>
        {!isCurrentUser ? (
          <Dropdown
            toggler={
              <UserStatusWrapper width={65}>
                <ClickableText size={-1}>
                  {isAdmin ? "Admin" : "Member"}{" "}
                </ClickableText>
                <StyledDownArrow />
              </UserStatusWrapper>
            }
          >
            <DropdownItem data-testid="admin-option" onClick={handleAdminClick}>
              <UserStatusWrapper width={75}>
                <Text size={-1}>Admin </Text>
                {isAdmin && (
                  <StyledText>
                    <Icon icon="checkmark" size={-0.5} />
                  </StyledText>
                )}
              </UserStatusWrapper>
            </DropdownItem>
            <DropdownItem
              data-testid="member-option"
              onClick={handleMemberClick}
            >
              <UserStatusWrapper width={75}>
                <Text size={-1}>Member </Text>
                {!isAdmin && (
                  <StyledText>
                    <Icon icon="checkmark" size={-0.5} />
                  </StyledText>
                )}
              </UserStatusWrapper>
            </DropdownItem>
          </Dropdown>
        ) : (
          <Text size={-1} style={{ paddingLeft: modularScale(-4) }}>
            {isAdmin ? "Admin" : "Member"}
          </Text>
        )}
      </RecruiterInfoItemWrapper>
      <RecruiterInfoItemWrapper>
        <Text size={-1} align="left">
          {lastActiveDate}
        </Text>
      </RecruiterInfoItemWrapper>
      <RecruiterInfoItemWrapper>
        {!isCurrentUser && (
          <RemoveUserText size={-1} onClick={handleRemoveClick}>
            Remove
          </RemoveUserText>
        )}
      </RecruiterInfoItemWrapper>
    </>
  );
}
