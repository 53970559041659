import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";

import { MessageTemplateForm } from "../MessageTemplateForm";
import { handleCreateMutationUpdate } from "../mutationUpdateFunctions";

import { handleMutationError } from "@hire/errors";
import {
  CreateMessageTemplateDocument,
  MessageTemplateTypeEnum,
} from "@hire/schema";
import { Button } from "@otta/design";

export const CreateTemplateSection = ({
  messageTemplateType,
}: {
  messageTemplateType: MessageTemplateTypeEnum;
}) => {
  const [creatingTemplate, setCreatingTemplate] = useState(false);

  const [createTemplate] = useMutation(CreateMessageTemplateDocument, {
    update: handleCreateMutationUpdate(messageTemplateType),
    onError: handleMutationError,
  });

  const handleCreate = useCallback(
    (newTemplateData: { name: string; message: string }) => {
      createTemplate({
        variables: { ...newTemplateData, type: messageTemplateType },
      });
      setCreatingTemplate(false);
    },
    [createTemplate, messageTemplateType]
  );

  if (creatingTemplate) {
    return (
      <MessageTemplateForm
        onClose={() => setCreatingTemplate(false)}
        onSave={handleCreate}
      />
    );
  }

  return (
    <Button level="secondary" onClick={() => setCreatingTemplate(true)}>
      Add a template
    </Button>
  );
};
