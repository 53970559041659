import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import styled, { css, up } from "@xstyled/styled-components";

import { FakeProfileModal } from "../FakeProfileModal";

import { NavigationStrip } from "./components/NavigationStrip";
import { TabRow } from "./components/TabRow";
import { getStageOptions } from "./utils";

import { handleMutationError } from "@hire/errors";
import {
  CompanyConversationQuery,
  CreateCandidateCvPdfDocument,
  GetJobPipelineDocument,
  UpdateCandidatePipelineItemStageDocument,
} from "@hire/schema";
import { modularScale, palette, pxToRem } from "@otta/design-tokens";
import { Button, VerticalSpacing, Text } from "@otta/design";
import { StageSelect } from "@hire/pages/JobPage/Pipeline/StageSelect";
import { useCurrentStage } from "@hire/pages/JobPage/Pipeline/hooks/useCurrentStage";
import { useStages } from "@hire/pages/JobPage/Pipeline/hooks/useStages";

const DrawerHeaderContainer = styled.div`
  padding: lg lg 0 ${modularScale()};

  ${up(
    "tablet",
    css`
      padding: lg 19 0;
    `
  )}
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  gap: lg;
`;

const ReportButton = styled(Button)`
  margin: 0 10;
  background-color: ${palette.brand.white};
  width: ${pxToRem(160)};
`;

const MobileWrapper = styled(VerticalSpacing)<{
  hideOnMobile?: boolean;
  addTopPadding?: boolean;
}>`
  ${({ addTopPadding }) =>
    addTopPadding &&
    css`
      padding-top: lg;

      ${up(
        "tablet",
        css`
          padding-top: 0;
        `
      )}
    `}

  ${({ hideOnMobile }) =>
    hideOnMobile &&
    css`
      display: none;

      ${up(
        "tablet",
        css`
          display: block;
        `
      )}
    `}
`;

interface CandidateHeaderProps {
  pipelineItem: NonNullable<CompanyConversationQuery["getJobPipelineItem"]>;
  onNext?: () => void;
  onPrevious?: () => void;
  applied: boolean;
  appliedAt?: string;
  sourcedAt?: string;
  conversation: boolean;
  jobId: string;
  showConversationTab: boolean;
}

export const CandidateHeader = ({
  pipelineItem,
  applied,
  jobId,
  appliedAt,
  sourcedAt,
  conversation,
  onNext,
  onPrevious,
  showConversationTab,
}: CandidateHeaderProps) => {
  const [showModal, setShowModal] = useState(false);

  const candidate = itemToCandidate(pipelineItem);

  const candidateEmail =
    candidate?.__typename === "ImportedCandidate"
      ? candidate.emailAddress
      : candidate?.email;

  const { stages } = useStages(jobId);
  const { currentStage } = useCurrentStage(jobId, pipelineItem.id);

  const stageOptions = getStageOptions(stages, applied);

  const [updateCandidateStage] = useMutation(
    UpdateCandidatePipelineItemStageDocument,
    {
      refetchQueries: [
        {
          query: GetJobPipelineDocument,
          variables: { jobId, workExperienceLimit: 1 },
          fetchPolicy: "network-only",
        },
      ],
      onError: handleMutationError,
    }
  );

  const [createCV, { loading: creatingCV }] = useMutation(
    CreateCandidateCvPdfDocument,
    {
      onError: handleMutationError,
    }
  );

  const handleStageChange = useCallback(
    (newStageId?: string) => {
      if (!pipelineItem) {
        return;
      }

      if (newStageId && currentStage && currentStage.id !== newStageId) {
        updateCandidateStage({
          variables: {
            jobId: jobId,
            itemId: pipelineItem.id,
            stageId: newStageId,
            workExperienceLimit: 1,
          },
        });
      }
    },
    [currentStage, jobId, pipelineItem, updateCandidateStage]
  );

  if (!candidate || !currentStage) {
    return null;
  }

  return (
    <>
      <NavigationStrip
        candidate={candidate}
        onPrevious={onPrevious}
        onNext={onNext}
      />
      <DrawerHeaderContainer>
        <Row>
          <Actions>
            <StageSelect
              stageOptions={stageOptions}
              currentStageId={currentStage.id}
              onChange={e => handleStageChange(e?.value)}
            />

            {candidate.__typename === "PipelineCandidate" && (
              <Button
                level="secondary"
                type="button"
                onClick={async () => {
                  const result = await createCV({
                    variables: {
                      candidateId: candidate.id,
                    },
                  });
                  const url = result?.data?.createCandidateCvPdf?.url;
                  if (url) {
                    window.open(url);
                  }
                }}
                disabled={creatingCV}
              >
                {creatingCV ? "Exporting..." : "Download CV"}
              </Button>
            )}
          </Actions>
          {candidateEmail && (
            <ReportButton
              type="button"
              size="small"
              level="destructive"
              name="Report fake profile"
              onClick={() => setShowModal(() => true)}
            >
              <Text as="span" bold>
                Report fake profile
              </Text>
            </ReportButton>
          )}
        </Row>
        <MobileWrapper addTopPadding>
          <TabRow
            pipelineItem={pipelineItem}
            appliedAt={appliedAt}
            sourcedAt={sourcedAt}
            stageName={currentStage.name}
            conversation={conversation}
            showConversationTab={showConversationTab}
          />
        </MobileWrapper>
        {candidateEmail && (
          <FakeProfileModal
            open={showModal}
            handleClose={() => setShowModal(false)}
            jobId={jobId}
            suspectEmail={candidateEmail}
          />
        )}
      </DrawerHeaderContainer>
    </>
  );
};

const itemToCandidate = (
  item: NonNullable<CompanyConversationQuery["getJobPipelineItem"]> | null
) => {
  return item?.candidate || item?.importedCandidate || null;
};
