import { NavLink } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { palette, modularScale } from "@otta/design-tokens";

interface TabLinkProps {
  size?: number;
}

export const TabLink = styled(NavLink)<TabLinkProps>`
  font-size: ${({ size = 0 }) => modularScale(size)};
  color: ${palette.brand.black};
  text-decoration: none;
  font-weight: 700;
  border-bottom: 5px solid transparent;
  padding-bottom: 6;
  cursor: pointer;
  &:hover {
    border-color: ${palette.grayscale.shade200};
  }
  &.active {
    border-color: ${palette.brand.yellow};
  }
`;
