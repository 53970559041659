import { Reference, FieldFunctionOptions } from "@apollo/client";

const companyConversations = {
  keyArgs: [
    "jobId",
    "allMessages",
    "sourced",
    "favourites",
    "applied",
    "onlyAwaitingResponse",
    "onlyArchivedMessages",
  ],
  merge(
    existing: Reference[] = [],
    incoming: Reference[] = [],
    { readField }: FieldFunctionOptions
  ): Reference[] {
    const newIdMap = new Map(
      incoming.map(conv => [
        `${readField("conversationId", conv) as string}-${
          readField("jobApplicationId", conv) as string
        }`,
        conv,
      ])
    );

    const merged: Reference[] = [];

    existing.forEach(conv => {
      const conversationId = readField("conversationId", conv) as string;
      const jobApplicationId = readField("jobApplicationId", conv) as string;
      const id = `${conversationId}-${jobApplicationId}`;

      if (newIdMap.has(id)) {
        newIdMap.delete(id);
      }

      merged.push(conv);
    });

    return [...merged, ...Array.from(newIdMap.values())];
  },
};

export const typePolicies = {
  Query: {
    fields: {
      personalisedCandidates: {
        merge: false,
      },
      candidatesWithInterviews: {
        merge: false,
      },
      discardedCandidates: {
        merge: false,
      },
      candidateShortlist: {
        merge: false,
      },
      messageTemplates: {
        merge: false,
      },
    },
  },
  Company: {
    fields: {
      atsApiKeys: {
        merge: false,
      },
      founders: { merge: false },
      visaSponsorshipCountries: { merge: false },
      listJobs: { merge: false },
    },
  },
  Job: {
    fields: {
      hasAccess: {
        merge: false,
      },
    },
  },
  CompanyConversation: {
    keyFields: ["conversationId", "jobApplicationId"],
  },
  JobPipeline: {
    keyFields: ["jobId"],
  },
  CurrentAdmin: {
    fields: {
      companyConversations: companyConversations,
    },
  },
  CurrentCompanyRecruiter: {
    fields: {
      companyConversations: companyConversations,
    },
  },
};
