import { useMemo, useState } from "react";
import styled from "@xstyled/styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";

import { VariableButtonSection } from "./components/VariableButtonSection";
import { TemplateFormData, TemplateVariables } from "./types";
import { transformIn } from "./transformers";
import { MessageField } from "./components/MessageField";

import { MessageTemplateDataFragment } from "@hire/schema";
import { Spacing, Card, InputField, Label, Button } from "@otta/design";
import { ButtonWrapper } from "@hire/components/buttons/ButtonWrapper";

const StyledCard = styled(Card)`
  text-align: left;
`;

interface MessageTemplateFormProps {
  templateData?: MessageTemplateDataFragment;
  onClose: () => void;
  onSave: (newTemplateData: { name: string; message: string }) => void;
  isUpdate?: boolean;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Fill in this field"),
  message: Yup.string()
    .min(100, "Enter at least 100 characters")
    .max(2500, "Enter a max of 2500 characters")
    .required("Fill in this field"),
});

export const MessageTemplateForm = ({
  templateData,
  onClose: handleClose,
  onSave: handleSave,
  isUpdate = false,
}: MessageTemplateFormProps): React.ReactElement => {
  const initialValues = useMemo(
    () => transformIn(templateData),
    [templateData]
  );
  const [cursorPosition, setCursorPosition] = useState(
    templateData?.message ? templateData.message.length : 0
  );
  const submitButtonCopy = isUpdate ? "Save changes" : "Create template";

  const form = useFormik<TemplateFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSave,
  });

  const addTemplateVariable = (variable: TemplateVariables) => {
    const newMessage = `${form.values.message.slice(
      0,
      cursorPosition
    )}${variable}${form.values.message.slice(cursorPosition)}`;
    setCursorPosition(cursorPosition + variable.length);

    form.setFieldValue("message", newMessage);
  };

  const handleChangeMessage = (newMessage: string) => {
    form.setFieldValue("message", newMessage);
  };

  return (
    <form onSubmit={form.handleSubmit} data-testid="template-form">
      <StyledCard>
        <Spacing>
          <InputField
            value={form.values.name}
            name="name"
            label="Template name"
            onChange={form.handleChange("name")}
            error={form.touched.name ? form.errors.name : undefined}
          />
          <Label htmlFor="message">Message content</Label>
          <VariableButtonSection onClick={addTemplateVariable} />
          <MessageField
            value={form.values.message}
            updateCursorPosition={(selectionStart: number) => {
              setCursorPosition(selectionStart);
            }}
            onChange={handleChangeMessage}
            error={form.touched.message ? form.errors.message : undefined}
          />
          <ButtonWrapper align="start">
            <Button level="secondary" type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button level="primary" type="submit" disabled={form.isSubmitting}>
              {submitButtonCopy}
            </Button>
          </ButtonWrapper>
        </Spacing>
      </StyledCard>
    </form>
  );
};
