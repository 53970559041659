import { Formik, useField, useFormikContext } from "formik";
import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";
import { GroupBase } from "react-select";

import { validationSchema } from "./validationSchema";
import { useJobOptions } from "./useJobOptions";

import {
  Spacing,
  Text,
  Card,
  FieldWrapper,
  InputField,
  Button,
  TextareaField,
  SelectField,
} from "@otta/design";
import { Loading } from "@otta/shared-components";
import { pxToRem } from "@otta/design-tokens";
import { ReportFakeProfileDocument } from "@hire/schema";
import { IOption } from "@hire/components/form/JobEditForm/types";
import { FieldErrors } from "@hire/components/form/FieldErrors";

const LeftCard = styled(Card)`
  max-width: ${pxToRem(448)};
  text-align: left;
`;

type Fields = {
  suspectEmail: string;
  reason?: string;
  jobId?: string;
};

function EmailAddress() {
  const [field, meta] = useField("suspectEmail");
  return (
    <FieldWrapper
      label="Candidate email"
      fieldError={meta.touched ? meta.error : undefined}
      required
    >
      {({ field: errors }) => <InputField {...field} {...errors} />}
    </FieldWrapper>
  );
}

function JobId({ jobs }: { jobs: GroupBase<IOption>[] }): React.ReactElement {
  const { touched, errors, setFieldValue } = useFormikContext<Fields>();

  return (
    <FieldWrapper
      label="Job they applied to"
      fieldError={touched["jobId"] ? errors["jobId"] : undefined}
    >
      {({ field: { id, ...field } }) => (
        <SelectField
          {...field}
          inputId={id}
          options={jobs}
          onChange={value => setFieldValue("jobId", value?.value)}
        />
      )}
    </FieldWrapper>
  );
}

function Reason(): React.ReactElement {
  const [props, meta] = useField("reason");
  return (
    <FieldWrapper
      label="Why do you think this profile is fake?"
      fieldError={meta.touched ? meta.error : undefined}
    >
      {({ field }) => <TextareaField {...field} {...props} />}
    </FieldWrapper>
  );
}

export function FakeProfile() {
  const { data: jobOptions, loading: jobsLoading } = useJobOptions();
  const [submit, { data, loading: submitLoading, reset, error }] = useMutation(
    ReportFakeProfileDocument
  );

  if (jobsLoading) {
    return <Loading />;
  }

  if (data) {
    return (
      <Spacing size={1}>
        <Text as="h2" bold size={2} align="left">
          Report fake profile
        </Text>
        <LeftCard>
          <Spacing size={1}>
            <Text>
              We've received your report and will investigate this profile.
              Thank you for helping us to identify fake profiles.
            </Text>
            <Button level="secondary" onClick={() => reset()}>
              Report another profile
            </Button>
          </Spacing>
        </LeftCard>
      </Spacing>
    );
  }

  return (
    <Spacing size={1}>
      <Text as="h2" bold size={2} align="left">
        Report fake profile
      </Text>
      <Formik
        onSubmit={data => submit({ variables: data })}
        validationSchema={validationSchema}
        initialValues={{
          suspectEmail: "",
          jobId: undefined,
          reason: undefined,
        }}
      >
        {form => (
          <LeftCard>
            <Spacing size={1}>
              <FieldErrors errors={error?.message} />
              <EmailAddress />
              <JobId jobs={jobOptions} />
              <Reason />
              <Button
                type="submit"
                level="primary"
                disabled={submitLoading}
                onClick={() => form.handleSubmit()}
              >
                Report profile
              </Button>
            </Spacing>
          </LeftCard>
        )}
      </Formik>
    </Spacing>
  );
}
