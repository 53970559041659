import * as React from "react";
import type { SVGProps } from "react";
const SvgIndustry = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M19.5 5.8H17V4.533C17 3.134 15.88 2 14.5 2h-5C8.121 2 7 3.134 7 4.533V5.8H4.5C3.12 5.8 2 6.936 2 8.333v10.134C2 19.866 3.12 21 4.5 21h15c1.38 0 2.5-1.134 2.5-2.533V8.333C22 6.936 20.879 5.8 19.5 5.8Zm-10-1.9h5a.63.63 0 0 1 .625.633V5.8h-6.25V4.533A.63.63 0 0 1 9.5 3.9Zm-5 3.8h15c.344 0 .625.285.625.633v3.8H3.875v-3.8c0-.348.281-.633.625-.633Zm15 11.4h-15a.631.631 0 0 1-.625-.633v-4.434H9.5v.634c0 .7.56 1.266 1.25 1.266h2.5c.69 0 1.25-.567 1.25-1.266v-.634h5.625v4.434a.631.631 0 0 1-.625.633Z"
    />
  </svg>
);
export default SvgIndustry;
