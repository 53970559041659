import styled from "@xstyled/styled-components";
import { MouseEventHandler } from "react";

import { EditButton } from "./styled";

import { Icon } from "@otta/icons";
import { Spacing, Text, Button } from "@otta/design";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  padding-right: lg;
`;

const StyledButton = styled(Button)`
  padding: 0.5rem 1.5rem;
  flex: 1;
`;

const DismissButtonWrap = styled.div`
  margin-top: -0.3rem;
`;

const DismissButton = ({
  onClick,
}: {
  onClick?: MouseEventHandler;
}): React.ReactElement => {
  return (
    <DismissButtonWrap>
      <EditButton data-testid="dismiss-x-button" onClick={onClick}>
        <Icon icon="close" />
      </EditButton>
    </DismissButtonWrap>
  );
};

interface SimplePollCueProps {
  onClick: (eventProperties: Record<string, string>) => void;
  onDismiss: () => void;
  title: string;
  description?: string;
}

export const SimplePollCue = ({
  onClick,
  onDismiss,
  title,
  description,
}: SimplePollCueProps): React.ReactElement => (
  <Spacing>
    <Header>
      <Text bold>{title}</Text>
      <DismissButton onClick={onDismiss} />
    </Header>
    {description && <Text>{description}</Text>}
    <Buttons>
      <StyledButton
        data-testid="poll-yes-button"
        level="secondary"
        size="small"
        onClick={() => onClick({ response: "yes" })}
      >
        Yes
      </StyledButton>
      <StyledButton
        data-testid="poll-no-button"
        level="secondary"
        size="small"
        onClick={() => onClick({ response: "no" })}
      >
        No
      </StyledButton>
    </Buttons>
  </Spacing>
);
