import styled from "@xstyled/styled-components";

import { salaryHiddenTextAdmin, salaryHiddenTextMember } from "./CopyHelpers";

import { Icon } from "@otta/icons";
import { Text } from "@otta/design";
import { ConditionalTipbox } from "@hire/components/ConditionalTipbox";

const ChangeButton = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
`;

const StyledText = styled(Text)`
  display: flex;
  align-items: center;
  gap: xs;
`;

export const SalaryVisibilityButton = ({
  hideSalary,
  thin,
  canHideAllSalary,
  canViewSalary,
  hiddenFromCount,
  handleShowModal,
}: {
  hideSalary: boolean;
  thin: boolean;
  canHideAllSalary: boolean;
  canViewSalary: boolean;
  hiddenFromCount: number;
  handleShowModal: () => void;
}): React.ReactElement => {
  return (
    <ConditionalTipbox condition={hideSalary} thin={thin}>
      <StyledText size={0}>
        <Icon
          icon={hideSalary ? "visible-off" : "visible-on"}
          size={0}
          data-testid={hideSalary ? "visible-off" : "visible-on"}
        />
        {canHideAllSalary
          ? salaryHiddenTextAdmin(hiddenFromCount, hideSalary)
          : salaryHiddenTextMember(!!canViewSalary, hideSalary)}
        {canViewSalary && (
          <ChangeButton
            role="button"
            size={0}
            as="span"
            onClick={handleShowModal}
            bold
          >
            Change visibility
          </ChangeButton>
        )}
      </StyledText>
    </ConditionalTipbox>
  );
};
