import { useQuery } from "@apollo/client";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { ConversationByIdDocument } from "./schema";
import { useCurrentUser } from "./hooks/useCurrentUser";
import { useRequiredParams } from "./util/routing";

import { Loading } from "@otta/shared-components";

export function SafeRedirect({
  path,
}: {
  path?: string | ((params: Record<string, string>) => string);
}) {
  const [user, { loading }] = useCurrentUser();
  const location = useLocation();
  const params = useParams();

  if (loading) {
    return <Loading />;
  }

  const urlSafeName = user?.currentCompany?.urlSafeName;

  const subPath =
    typeof path === "string"
      ? path
      : typeof path === "function"
      ? path(params as Record<string, string>)
      : undefined;

  const to = {
    pathname: urlSafeName
      ? `/${[encodeURIComponent(urlSafeName).toLowerCase(), subPath]
          .filter(Boolean)
          .join("/")}`
      : "/",
    search: location.search,
  };

  return <Navigate to={to} replace />;
}

export function MessagingRedirect() {
  const { conversationId } = useRequiredParams(["conversationId"]);

  const [user, { loading: userLoading }] = useCurrentUser();

  const { data, loading } = useQuery(ConversationByIdDocument, {
    variables: {
      id: conversationId,
      newApplication: false,
    },
  });

  if (loading || userLoading) {
    return <Loading />;
  }

  if (!user?.currentCompany) {
    return <Navigate to="/" replace />;
  }

  const slug = encodeURIComponent(
    user.currentCompany.urlSafeName.toLowerCase()
  );

  const job = data?.companyConversation.job;
  const candidate = data?.companyConversation.recipient;

  if (!job || !candidate) {
    return <Navigate to={`/${slug}`} replace />;
  }

  const path = `/${slug}/jobs/${job.externalId}/inbox/candidates/${candidate.externalId}/conversation`;

  return <Navigate to={path} replace />;
}
