import { useCallback, useMemo, useReducer } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { sortCandidatesByRecency } from "./helpers";
import { useJobInternalId } from "./hooks/useJobInternalId";

import {
  BatchUpdateCandidatePipelineItemStageDocument,
  GetJobPipelineDocument,
  GetJobPipelineQuery,
  UpdateCandidatePipelineItemStageDocument,
} from "@hire/schema";
import { handleMutationError } from "@hire/errors";
import { useRequiredParams } from "@hire/util/routing";

type ReducerState = {
  stageItems: {
    [key: string]: Set<string>;
  };
  itemStage: {
    [key: string]: string;
  };
};

type ReducerAction =
  | {
      type: "MOVE_ITEM";
      to: string;
      id: string;
    }
  | { type: "MOVE_ITEMS"; ids: string[]; to: string };

export function useStages(
  pipelineStages?: NonNullable<
    GetJobPipelineQuery["getJobPipeline"]
  >["candidatePipelineStages"]
) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { jobId: jobExternalId } = useRequiredParams(["jobId"]);
  const { jobInternalId } = useJobInternalId(jobExternalId);

  const candidates = useMemo(
    () =>
      new Map(
        pipelineStages?.flatMap(
          stage => stage.candidatePipelineItems?.map(i => [i.id, i]) ?? []
        ) ?? []
      ),
    [pipelineStages]
  );

  const [{ stageItems }, dispatch] = useReducer(
    (state: ReducerState, action: ReducerAction): ReducerState => {
      switch (action.type) {
        case "MOVE_ITEM": {
          const from = state.itemStage[action.id];
          const oldStage = new Set(state.stageItems[from]);
          const newStage = new Set(state.stageItems[action.to]);

          oldStage.delete(action.id);
          newStage.add(action.id);

          return {
            stageItems: {
              ...state.stageItems,
              [from]: oldStage,
              [action.to]: newStage,
            },
            itemStage: {
              ...state.itemStage,
              [action.id]: action.to,
            },
          };
        }

        case "MOVE_ITEMS": {
          const newStageRefs = action.ids.reduce<{ [key: string]: string }>(
            (acc, id) => {
              acc[id] = action.to;
              return acc;
            },
            {}
          );
          const newStages = action.ids.reduce<{ [key: string]: Set<string> }>(
            (acc, id) => {
              if (!acc[action.to]) {
                acc[action.to] = new Set(state.stageItems[action.to]);
              }
              acc[action.to].add(id);

              const from = state.itemStage[id];
              if (!acc[from]) {
                acc[from] = new Set(state.stageItems[from]);
              }
              acc[state.itemStage[id]].delete(id);

              return acc;
            },
            {}
          );

          return {
            stageItems: {
              ...state.stageItems,
              ...newStages,
            },
            itemStage: { ...state.itemStage, ...newStageRefs },
          };
        }
        default:
          return state;
      }
    },
    pipelineStages?.reduce<ReducerState>(
      (acc, stage) => {
        if (!acc.stageItems[stage.id]) {
          acc.stageItems[stage.id] = new Set();
        }

        stage.candidatePipelineItems?.forEach(item => {
          acc.stageItems[stage.id].add(item.id);
          acc.itemStage[item.id] = stage.id;
        });

        return acc;
      },
      { stageItems: {}, itemStage: {} }
    ) ?? { stageItems: {}, itemStage: {} }
  );

  const stages = useMemo(
    () =>
      Array.from(pipelineStages ?? [])
        .sort((a, b) => parseInt(a.position, 10) - parseInt(b.position, 10))
        .map(stage => {
          const pipelineItems = Array.from(stageItems[stage.id] ?? []).map(
            i => candidates.get(i)!
          );

          return {
            ...stage,
            candidatePipelineItems: sortCandidatesByRecency(pipelineItems),
          };
        }),
    [candidates, pipelineStages, stageItems]
  );

  const controls = useCallback(
    (id: string) => {
      //  Have to first find stage, then find candidate in stage
      // Can't flatmap, as UI only allows nav to next/prev in same stage
      const pipelineItems = stages.find(stage =>
        stage.candidatePipelineItems?.some(
          pipelineItem =>
            pipelineItem.candidate?.externalId === id ||
            pipelineItem.importedCandidate?.externalId === id
        )
      )?.candidatePipelineItems;

      if (!pipelineItems) {
        return { next: undefined, previous: undefined };
      }

      const pipelineItemIndex = pipelineItems.findIndex(
        pipelineItem =>
          pipelineItem.candidate?.externalId === id ||
          pipelineItem.importedCandidate?.externalId === id
      );

      const previousPipelineItem =
        pipelineItems[pipelineItemIndex - 1]?.candidate?.externalId;

      const nextPipelineItem =
        pipelineItems[pipelineItemIndex + 1]?.candidate?.externalId;

      return {
        next: nextPipelineItem
          ? () => {
              navigate({
                pathname: `candidate/${nextPipelineItem}/profile`,
                search: searchParams.toString(),
              });
            }
          : undefined,
        previous: previousPipelineItem
          ? () => {
              navigate({
                pathname: `candidate/${previousPipelineItem}/profile`,
                search: searchParams.toString(),
              });
            }
          : undefined,
      };
    },
    [navigate, searchParams, stages]
  );

  const [updateCandidateStage] = useMutation(
    UpdateCandidatePipelineItemStageDocument,
    {
      refetchQueries: [
        {
          query: GetJobPipelineDocument,
          variables: { jobId: jobExternalId, workExperienceLimit: 1 },
          fetchPolicy: "network-only",
        },
      ],
      onError: handleMutationError,
    }
  );

  const move = useCallback(
    async (id: string, stageId: string) => {
      dispatch({ type: "MOVE_ITEM", to: stageId, id });
      updateCandidateStage({
        variables: {
          jobId: jobInternalId,
          itemId: id,
          stageId,
          workExperienceLimit: 1,
        },
      });
    },
    [jobInternalId, updateCandidateStage]
  );

  const [batchUpdateStageMutation] = useMutation(
    BatchUpdateCandidatePipelineItemStageDocument,
    { onError: handleMutationError }
  );

  const bulkMove = useCallback(
    (ids: string[], stageId: string) => {
      dispatch({ type: "MOVE_ITEMS", ids, to: stageId });
      batchUpdateStageMutation({
        variables: {
          jobId: jobExternalId,
          newStageId: stageId,
          itemIds: ids,
          workExperienceLimit: 1,
        },
      });
    },
    [batchUpdateStageMutation, jobExternalId]
  );

  return { stages, candidates, controls, move, bulkMove };
}
