import styled, { css, down } from "@xstyled/styled-components";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Heading, Tipbox, Text, List, Spacing } from "@otta/design";
import { Link } from "@hire/components/links/Link";

const StyledTipbox = styled(Tipbox)`
  ${down(
    "tablet",
    css`
      padding-right: 28;
    `
  )};
  background-color: white;
  border-radius: ${modularScale(1)};
  padding-top: 19;
  padding-bottom: 19;
  text-align: left;
`;

const CertifiedIcon = styled(Icon)`
  margin-top: -7;
`;

const CertifiedPill = styled.div`
  display: flex;
  align-items: center;
  padding: 1 10 1 5;
  border-radius: ${pxToRem(15)};
  overflow: hidden;
  background: ${palette.extended.yellow.shade100};
`;

const HeadingWrapper = styled.div`
  display: flex;
  gap: 10;
  align-items: center;
`;

const ListWrapper = styled.div`
  & > ul {
    margin-top: sm;
    margin-left: xl;
  }
`;
export const NotCertified = () => {
  return (
    <StyledTipbox level="information">
      <Spacing>
        <HeadingWrapper>
          <Heading>Gain access to broadcast with Otta certified</Heading>
          <CertifiedPill>
            <CertifiedIcon icon="certified" size={2} />
            <Heading size={-1}>Otta certified</Heading>
          </CertifiedPill>
        </HeadingWrapper>
        <Text>
          Broadcast allows you to send a custom message to candidates who have
          saved your roles, encouraging them to apply.
        </Text>
        <ListWrapper>
          <Text> Get Otta Certfied by:</Text>
          <List type="bullet">
            <li>Adding public salaries to all of your roles</li>
            <li>Responding to all applications within 2 weeks</li>
            <li>Getting 3 employee endorsements</li>
            <li>
              Connecting Greenhouse, Lever, or Workable ATS if you have it
            </li>
          </List>
        </ListWrapper>
        <Link newTab to="https://otta.com/employers/certified">
          Find out more about Otta Certified
        </Link>
      </Spacing>
    </StyledTipbox>
  );
};
