import {
  FlexContainer,
  ListItemWrapper,
  UpdateDeleteButtonSection,
} from "./styled-components";

import { palette } from "@otta/design-tokens";
import { DeleteButton } from "@hire/components/buttons/DeleteButton";
import { EditButton } from "@hire/components/buttons/EditButton";

interface ItemCardProps {
  testId?: string;

  children: React.ReactElement;
  itemIndex: number;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
}

export const ItemCard = ({
  itemIndex,
  testId,
  onEdit: handleEdit,
  onDelete: handleDelete,
  children,
}: ItemCardProps) => {
  return (
    <FlexContainer data-testid={testId}>
      <ListItemWrapper>{children}</ListItemWrapper>
      <UpdateDeleteButtonSection>
        <EditButton
          onClick={() => handleEdit(itemIndex)}
          color={palette.grayscale.shade600}
          hoverColor={palette.brand.black}
          size={2}
        />

        <DeleteButton
          onClick={() => handleDelete(itemIndex)}
          color={palette.brand.red}
          hoverColor={palette.extended.red.shade800}
        />
      </UpdateDeleteButtonSection>
    </FlexContainer>
  );
};
