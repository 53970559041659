import { useState } from "react";
import { Options } from "react-select";
import CreatableSelect from "react-select/creatable";

import { palette } from "@otta/design-tokens";

interface Option {
  label: string;
  value: string;
}

interface MultiTextInputProps {
  onChange: (value: string[]) => void;
  value: string[] | null;
  minLength: number;
  placeholder: string;
}

const mapToOption = (label: string) => ({
  label,
  value: label,
});

// This should be moved to the design library

export function MultiTextInput({
  value,
  onChange,
  minLength,
  placeholder,
}: MultiTextInputProps): React.ReactElement {
  const [currInput, setCurrInput] = useState("");
  const values = value || [];
  const options: Option[] = values.map(mapToOption);

  const handleInputChange = (input: string) => {
    setCurrInput(input);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!currInput || currInput.length < minLength) {
      return;
    }
    if (event.key === "Enter" || event.key === "Tab" || event.key === ",") {
      event.preventDefault();
      onChange([...values, currInput]);
      setCurrInput("");
    }
  };

  const handleChange = (input: Options<Option>) => {
    const newValues = input?.map(({ value: val }) => val);
    onChange(newValues);
  };

  return (
    <CreatableSelect
      isMulti
      isClearable
      components={{ DropdownIndicator: null }}
      inputValue={currInput}
      menuIsOpen={false}
      value={options}
      placeholder={placeholder}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      styles={{
        multiValue: base => ({
          ...base,
          borderRadius: "4px",
          border: `1px solid ${palette.brand.grey}`,
        }),
        multiValueRemove: base => ({
          ...base,
          borderRadius: "4px",
          cursor: "pointer",
        }),
        multiValueLabel: base => ({
          ...base,
          background: "white",
          padding: "4px 8px",
          paddingLeft: "8px",
          borderRadius: "4px 0 0 4px",
        }),
      }}
      theme={originalTheme => ({
        ...originalTheme,
        borderRadius: 4,
        colors: {
          ...originalTheme.colors,
          primary: palette.brand.black,
          primary25: palette.brand.grey,
          danger: palette.brand.white,
          dangerLight: palette.grayscale.shade600,
        },
      })}
    />
  );
}
