import { SourcingPreferencesFieldProps } from "./interfaces";

import { VerticalSpacing, Label, Text, Checkbox } from "@otta/design";

export function CompletedProfileField({
  preferences,
  onChange,
}: SourcingPreferencesFieldProps): React.ReactElement {
  return (
    <VerticalSpacing size={-4}>
      <Label htmlFor="completedProfile">
        <Text bold>Completed profile</Text>
      </Label>
      <Checkbox
        label="Only show candidates with completed profiles"
        checked={!!preferences.completedProfile}
        onChange={checked => {
          onChange({ completedProfile: checked });
        }}
      />
    </VerticalSpacing>
  );
}
