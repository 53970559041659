import styled from "@xstyled/styled-components";
import { MouseEventHandler } from "react";

import { EditButton } from "./styled";

import { Icon } from "@otta/icons";
import { Spacing, Text, Button } from "@otta/design";
import { Link } from "@hire/components/links/Link";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const StyledButton = styled(Button)`
  padding: 0.5rem 1.5rem;
`;

const DismissButtonWrap = styled.div`
  margin-top: -0.3rem;
`;

const StyledText = styled(Text)`
  display: flex;
  i {
    margin-left: 0.5rem;
  }
`;

const DismissButton = ({
  onClick,
}: {
  onClick?: MouseEventHandler;
}): React.ReactElement => {
  return (
    <DismissButtonWrap>
      <EditButton data-testid="dismiss-x-button" onClick={onClick}>
        <Icon icon="close" />
      </EditButton>
    </DismissButtonWrap>
  );
};

interface ExternalLinkCueProps {
  onClick: () => void;
  onDismiss: () => void;
  title: string;
  description: string;
  url: string;
  primaryButtonCopy?: string;
}

export const ExternalLinkCue = ({
  onClick,
  onDismiss,
  title,
  description,
  url,
  primaryButtonCopy,
}: ExternalLinkCueProps): React.ReactElement => (
  <Spacing>
    <Header>
      <Text bold>{title}</Text>
      <DismissButton onClick={onDismiss} />
    </Header>
    <Text>{description}</Text>
    <Buttons>
      <Link to={url} newTab underline={false} onClick={onClick}>
        <StyledButton data-testid="launch-button" level="primary" size="small">
          <StyledText size={-1} bold>
            {primaryButtonCopy ? primaryButtonCopy : "Launch survey"}
            <Icon icon="external" />
          </StyledText>
        </StyledButton>
      </Link>
      <StyledButton
        data-testid="dismiss-button"
        level="secondary"
        size="small"
        onClick={onDismiss}
      >
        Not now
      </StyledButton>
    </Buttons>
  </Spacing>
);
