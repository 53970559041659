import { useContext, useState } from "react";

import { Step } from "../Steps";

import { Button, ErrorText, Middle, Spacing, Text } from "@otta/design";
import { CopyPasteMatrix } from "@hire/components/input/CopyPasteMatrix";
import { CopyableField } from "@hire/components/input/CopyableField";
import { Link } from "@hire/components/links/Link";
import { SensitiveInfoContext } from "@hire/providers/SensitiveInfo";

interface Props {
  first?: boolean;
  webhook: {
    key: string;
    requestedTopic: string | null;
    secretKey: string | null;
    lastRequestedAt: string | null;
  };
  loading: boolean;
  refetch(): Promise<unknown>;
}

export function AshbyWebhook({
  webhook,
  loading,
  refetch,
}: Props): React.ReactElement {
  const { sensitiveFieldsHidden } = useContext(SensitiveInfoContext);
  const [progressAttempts, setProgressAttempts] = useState(0);

  const attemptProgress = async () => {
    await refetch();
    setProgressAttempts(progressAttempts + 1);
  };

  return (
    <Spacing size={2}>
      <Step idx={1}>
        <Link to="https://app.ashbyhq.com/admin/api/webhooks">
          Go to the Webhooks page in Ashby.
        </Link>
      </Step>
      <Step idx={2}>
        <Text>
          Select the{" "}
          <Text as="span" bold>
            New
          </Text>{" "}
          button in the top right corner.
        </Text>
      </Step>
      <Step idx={3}>
        <Text>
          Select{" "}
          <Text as="span" bold>
            {webhook.requestedTopic}
          </Text>{" "}
          from the{" "}
          <Text as="span" bold>
            Webhook Type.
          </Text>{" "}
          dropdown.
        </Text>
      </Step>
      <Step idx={4}>
        <Text>
          Copy and paste the{" "}
          <Text as="span" bold>
            Request URL
          </Text>{" "}
          below into the{" "}
          <Text as="span" bold>
            Request URL
          </Text>{" "}
          field in Ashby.
        </Text>
        <CopyPasteMatrix
          helpText=""
          rows={[
            {
              label: "Request URL",
              element: (
                <CopyableField
                  value={`https://api.otta.com/hooks/${webhook.key}`}
                />
              ),
            },
          ]}
        />
      </Step>
      <Step idx={5}>
        <Text>
          Copy and paste the{" "}
          <Text as="span" bold>
            Secret Token
          </Text>{" "}
          below into the{" "}
          <Text as="span" bold>
            Secret Token
          </Text>{" "}
          field in Ashby.
        </Text>
        <CopyPasteMatrix
          helpText=""
          rows={[
            {
              label: "Secret Token",
              element: (
                <CopyableField
                  data-sensitive-field={sensitiveFieldsHidden}
                  value={`${webhook.secretKey}`}
                />
              ),
            },
          ]}
        />
      </Step>
      <Step idx={6}>
        Select the <b>Add</b> button.
      </Step>
      {progressAttempts > 0 && (
        <ErrorText data-testid="webhook-fail-message">
          We were unable to add this webhook. You need to repeat this step.
        </ErrorText>
      )}
      <Middle>
        <Button
          onClick={attemptProgress}
          type="button"
          level="primary"
          data-testid="webhook-advance-button"
          disabled={loading}
        >
          {loading
            ? "Checking webhook..."
            : progressAttempts > 0
            ? "Try again"
            : "Continue"}
        </Button>
      </Middle>
    </Spacing>
  );
}
