import styled from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";
import { VerticalSpacing, Text, Card as DefaultCard } from "@otta/design";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { pluraliser } from "@hire/util/strings";
import { Link } from "@hire/components/links";

const Container = styled(ResponsiveCenteredContainer)`
  max-width: ${pxToRem(1200)};
  text-align: left;
`;

const Card = styled(DefaultCard)`
  position: relative;
  padding: 0;
  overflow: hidden;
  padding: lg;
`;

export function JobBroadcastSent({
  numberCandidates,
  totalPreviousBroadcastRecipients,
}: {
  numberCandidates: number | undefined;
  totalPreviousBroadcastRecipients: number | undefined;
}): React.ReactElement {
  return (
    <Container>
      <Card>
        <VerticalSpacing size={1}>
          <Text bold size={1}>
            Your broadcast has been sent to {numberCandidates}{" "}
            {pluraliser(numberCandidates ?? 0, "candidate")}
          </Text>
          <Text>
            Best of luck with your hiring! You&apos;ll be able to send another
            broadcast for this role once new candidates save it.
          </Text>
          <Text>
            So far, you&apos;ve broadcasted to{" "}
            <strong>{totalPreviousBroadcastRecipients}</strong>{" "}
            {pluraliser(numberCandidates ?? 0, "candidate")} who were interested
            but hadn&apos;t applied yet.
          </Text>
          <Text>
            If you have any feedback on broadcasting to candidates, let us know
            by emailing{" "}
            <Link to="mailto:companies@otta.com">companies@otta.com</Link>
          </Text>
        </VerticalSpacing>
      </Card>
    </Container>
  );
}
