import { ApolloProvider } from "@apollo/client";
import { UnleashClient } from "unleash-proxy-client";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { ThemeProvider } from "@xstyled/styled-components";

import { apolloClient } from "./apollo";
import { AuthorizationProvider } from "./authorization";
import { Provider as MaintenanceProvider } from "./maintenance";
import { SensitiveInfoProvider } from "./SensitiveInfo";

import { theme } from "@otta/design";
import { ExperimentProvider } from "@otta/experiments/client";

const config = {
  url: import.meta.env.VITE_UNLEASH_URL,
  clientKey: import.meta.env.VITE_UNLEASH_PROXY_CLIENT_KEY,
  appName: "hire",
  environment: import.meta.env.VITE_ENV,
};

const client = new UnleashClient(config);

export function Providers({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <MaintenanceProvider>
          <ExperimentProvider client={client}>
            <AuthorizationProvider>
              <SensitiveInfoProvider>
                <TooltipProvider delayDuration={0}>{children}</TooltipProvider>
              </SensitiveInfoProvider>
            </AuthorizationProvider>
          </ExperimentProvider>
        </MaintenanceProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}
