import { useMemo, useState } from "react";
import styled from "@xstyled/styled-components";

import { Divider } from "../../common/Divider";
import { Subheading } from "../../common/Subheading";
import { SectionProps } from "../../common/types";
import { CardText } from "../../common/CardText";

import { Clickable, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links";

const More = styled(Clickable)`
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

export function Endorsements({
  company: { employeeSurveys, urlSafeName },
}: SectionProps): React.ReactElement | null {
  const response = employeeSurveys[0]?.responses[0];

  const [showFull, setShowFull] = useState(false);

  const [preview, full] = useMemo(() => {
    if ((response?.content?.length ?? 0) > 150) {
      const lastWord = response?.content?.substring(0, 150).lastIndexOf(" ");
      return [response?.content?.substring(0, lastWord), response?.content];
    } else {
      return [response?.content, null];
    }
  }, [response]);

  if (!preview || !response) {
    return null;
  }

  return (
    <Spacing size={0}>
      <Divider />
      <Subheading>Employee endorsements</Subheading>
      <Text size={0} bold as={"h3"}>
        {response.category.name}
      </Text>
      <CardText>
        "{showFull ? full ?? preview : preview}
        {full && !showFull ? (
          <>
            &hellip;" <More onClick={() => setShowFull(true)}>more</More>
          </>
        ) : (
          '"'
        )}
      </CardText>
      <Link
        to={`${
          import.meta.env.VITE_SEARCH_APP_HOST
        }/companies/${urlSafeName}#endorsements-section`}
        newTab
      >
        Browse all endorsements
      </Link>
    </Spacing>
  );
}
