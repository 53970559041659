import { useParams } from "react-router-dom";

import { MobileSubNavItem } from "./Mobile";

import { Feature } from "@hire/schema";
import { useAuthorizations } from "@hire/providers/authorization";

export function SettingsSubNav({ onClick }: { onClick?: () => void }) {
  const { companySlug } = useParams<"companySlug">();

  const { features } = useAuthorizations([
    Feature.TeamMembers,
    Feature.ViewMessageTemplates,
    Feature.SsoSettings,
    Feature.JobSlots,
  ]);

  return (
    <>
      <MobileSubNavItem
        to={`/${companySlug}/settings/billing`}
        onClick={onClick}
      >
        Billing
      </MobileSubNavItem>
      {(features.get(Feature.TeamMembers)?.granted ||
        features.get(Feature.TeamMembers)?.message) && (
        <MobileSubNavItem
          to={`/${companySlug}/settings/team`}
          onClick={onClick}
        >
          Team members
        </MobileSubNavItem>
      )}
      {(features.get(Feature.ViewMessageTemplates)?.granted ||
        features.get(Feature.ViewMessageTemplates)?.message) && (
        <MobileSubNavItem
          to={`/${companySlug}/settings/templates`}
          onClick={onClick}
        >
          Message templates
        </MobileSubNavItem>
      )}
      <MobileSubNavItem to={`/${companySlug}/settings/ats`} onClick={onClick}>
        ATS integration
      </MobileSubNavItem>
      {(features.get(Feature.SsoSettings)?.granted ||
        features.get(Feature.SsoSettings)?.message) && (
        <MobileSubNavItem to={`/${companySlug}/settings/sso`} onClick={onClick}>
          SSO settings
        </MobileSubNavItem>
      )}
    </>
  );
}
