import * as React from "react";
import type { SVGProps } from "react";
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M8.109 12c0-2.105 1.706-3.75 3.891-3.75 2.149 0 3.892 1.645 3.892 3.75 0 2.07-1.743 3.75-3.892 3.75-2.185 0-3.891-1.68-3.891-3.75ZM12 10.125c-1.074 0-1.946.84-1.946 1.875 0 1.035.872 1.875 1.946 1.875 1.075 0 1.946-.84 1.946-1.875 0-1.035-.871-1.875-1.946-1.875ZM13.504 2c.9 0 1.683.593 1.893 1.435l.317 1.269c.344.162.673.346.985.55L18 4.883c.863-.245 1.788.11 2.238.86l1.504 2.51c.446.75.304 1.7-.345 2.297l-.985.867a8.095 8.095 0 0 1 0 1.129l.985.902c.649.598.79 1.547.345 2.297l-1.504 2.508c-.45.75-1.375 1.105-2.238.863l-1.301-.37c-.313.202-.64.386-.985.55l-.317 1.27c-.21.84-.993 1.433-1.893 1.433h-3.008c-.9 0-1.682-.594-1.893-1.434l-.316-1.27a8.524 8.524 0 0 1-.985-.55l-1.338.371a1.952 1.952 0 0 1-2.2-.863l-1.503-2.508c-.45-.75-.307-1.7.344-2.297l.982-.902a8.15 8.15 0 0 1 0-1.129l-.982-.867a1.826 1.826 0 0 1-.344-2.297l1.504-2.51a1.947 1.947 0 0 1 2.2-.86l1.337.37c.312-.204.64-.388.985-.55l.316-1.269C8.814 2.593 9.596 2 10.496 2h3.008Zm-3.55 4.047-.455.183a6.458 6.458 0 0 0-1.427.797l-.393.29-2.229-.633L3.946 9.19l1.682 1.543-.062.47a6.17 6.17 0 0 0 0 1.593l.062.469-1.682 1.543 1.504 2.507 2.229-.632.393.289c.434.32.912.59 1.427.797l.454.183.543 2.172h3.008l.543-2.172.454-.183a6.459 6.459 0 0 0 1.428-.797l.393-.29 2.23.633 1.503-2.507-1.682-1.543.06-.47c.037-.26.053-.526.053-.796s-.016-.535-.052-.797l-.061-.469 1.682-1.543-1.504-2.507-2.23.632-.393-.289a6.46 6.46 0 0 0-1.427-.797l-.454-.183-.543-2.172h-3.008l-.543 2.172Z"
    />
  </svg>
);
export default SvgSettings;
