import { SectionProps } from "../../common/types";
import { Blobs, Blob } from "../../common/Blob";

export function JobTechnologies({
  fields: { technologiesUsed },
}: SectionProps): React.ReactElement | null {
  return technologiesUsed.length > 0 ? (
    <Blobs>
      {technologiesUsed.map(({ label }, i) => (
        <Blob bold={i === 0} key={i}>
          {label}
        </Blob>
      ))}
    </Blobs>
  ) : null;
}
