import styled from "@xstyled/styled-components";

interface CircularImgWrapperProps {
  width?: string;
}

const CircularImgWrapper = styled.div<CircularImgWrapperProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ width }) => (width ? width : "100%")};
  border-radius: 100%;
`;

const CircularImgInner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;

interface CircularImgProps {
  src: string;
  alt: string;
  width?: string;
}

export function CircularImg({
  src,
  width,
  alt,
}: CircularImgProps): React.ReactElement {
  return (
    <CircularImgWrapper width={width}>
      <CircularImgInner src={src} alt={alt} />
    </CircularImgWrapper>
  );
}
