import { useMutation } from "@apollo/client";
import styled from "@xstyled/styled-components";
import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { UpdateCompanySamlDataDocument } from "@hire/schema";
import { CopyPasteMatrix } from "@hire/components/input/CopyPasteMatrix";
import { modularScale } from "@otta/design-tokens";
import {
  Button,
  Spacing,
  TextareaField as DefaultTextareaField,
  VerticalSpacing,
  Tipbox,
  ErrorText,
} from "@otta/design";
import { CopyableField } from "@hire/components/input/CopyableField";
import { SelectBox } from "@hire/components/input/SelectBox";

const TextareaField = styled(DefaultTextareaField)`
  font-size: ${modularScale(-2)};
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono,
    DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: md;
`;

interface FormFields {
  idpMetadata: string;
}

const validationSchema = Yup.object().shape({
  idpMetadata: Yup.string().required("Fill in this field"),
});

interface SSOMetadataFormProps {
  consumeUrl: string;
  entityId: string;
  idpMetadata: string | null;
  enforced: boolean;
  onClose: () => void;
}

export function SSOMetadataForm({
  consumeUrl,
  entityId,
  idpMetadata,
  enforced,
  onClose,
}: SSOMetadataFormProps) {
  const [error, setError] = useState(false);
  const [mutate, { loading }] = useMutation(UpdateCompanySamlDataDocument, {
    onCompleted: () => onClose(),
    onError: () => setError(true),
  });

  const form = useFormik<FormFields>({
    initialValues: { idpMetadata: idpMetadata ?? "" },
    validationSchema,
    onSubmit: values => {
      setError(false);
      mutate({ variables: { input: values } });
    },
  });

  const copyValues = [
    {
      label: "App name",
      element: <CopyableField value="Otta" />,
    },
    {
      label: "ACS URL",
      element: <CopyableField value={consumeUrl} />,
    },
    {
      label: "Entity ID",
      element: <CopyableField value={entityId} />,
    },
    {
      label: "Name ID format",
      element: <SelectBox>email</SelectBox>,
    },
    {
      label: "First name attribute",
      element: <CopyableField value="first_name" />,
    },
    {
      label: "Last name attribute",
      element: <CopyableField value="last_name" />,
    },
  ];

  return (
    <Spacing>
      <CopyPasteMatrix
        helpText="Use these values when setting up Otta with your identity provider"
        rows={copyValues}
      />
      <VerticalSpacing>
        <TextareaField
          name="idpMetadata"
          value={form.values.idpMetadata}
          onChange={form.handleChange}
          label="Paste your company's identity provider metadata XML here"
          error={form.touched.idpMetadata ? form.errors.idpMetadata : undefined}
          rows={15}
        />
        {error && (
          <div>
            <ErrorText>
              Seems like something is wrong with your metadata or we currently
              don&apos;t support your IdP
            </ErrorText>
          </div>
        )}
        {enforced && form.dirty && (
          <Tipbox level="warning">
            Updating your metadata will disable SSO enforcement
          </Tipbox>
        )}
      </VerticalSpacing>
      <ButtonContainer>
        {idpMetadata && (
          <Button level="secondary" type="button" onClick={onClose}>
            Cancel
          </Button>
        )}
        <Button
          level={form.dirty ? "primary" : "secondary"}
          type="submit"
          onClick={form.submitForm}
          disabled={!form.dirty || loading}
        >
          {loading ? "Loading..." : form.dirty ? "Save changes" : "No changes"}
        </Button>
      </ButtonContainer>
    </Spacing>
  );
}
