import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import styled from "@xstyled/styled-components";

import { handleMutationError } from "@hire/errors";
import { UploadUserProfileImageDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { Button, Spacing, Text } from "@otta/design";

const FileInput = styled.input`
  display: none;
`;

interface UploadFieldProps {
  label: string;
  value?: string;
  accept: string[];
  display: (v: string) => React.ReactNode;
}

export function UploadField({
  label,
  value,
  accept,
  display,
}: UploadFieldProps): React.ReactElement {
  const [uploadMutation, { loading }] = useMutation(
    UploadUserProfileImageDocument,
    {
      onError: handleMutationError,
    }
  );

  const handleUpload = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    e => {
      if (e.target.files?.length === 1) {
        const file = e.target.files[0];
        uploadMutation({ variables: { file } });
      }
    },
    [uploadMutation]
  );

  return (
    <Spacing>
      <Text bold align="center">
        {label}
      </Text>
      {loading ? <Loading /> : value && display(value)}
      <Button level="secondary" as="label" style={{ display: "flex" }}>
        Upload file
        <FileInput
          type="file"
          accept={accept.join(", ")}
          onChange={handleUpload}
          data-testid="field-input-component"
        />
      </Button>
    </Spacing>
  );
}
