import { Currency } from "@hire/schema";
import { SalaryRange } from "@hire/util/salaries";

export const transformIncoming = (
  salaryRange: SalaryRange | undefined,
  currency: Currency
) => {
  if (!salaryRange) {
    return {
      minAmount: null,
      maxAmount: null,
      currency,
    };
  }
  return { ...salaryRange, currency };
};
