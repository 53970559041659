import styled from "@xstyled/styled-components";
import { useSearchParams } from "react-router-dom";

import { palette, modularScale } from "@otta/design-tokens";
import { Button as DefaultButton, Text, VerticalSpacing } from "@otta/design";
import { getLocationOrigin, getLocationHref } from "@hire/util/window";

const Button = styled(DefaultButton)`
  width: 100%;
`;

const ErrorText = styled(Text)`
  color: ${palette.brand.red};
  font-size: ${modularScale(-1)};
`;

interface SamlLoginButtonProps {
  loginUrl: string;
  companyUrlSafeName: string;
  type: "login" | "signup";
}

export function SamlLoginButton({
  loginUrl,
  companyUrlSafeName,
  type,
}: SamlLoginButtonProps): React.ReactElement {
  const [searchParams] = useSearchParams();
  const ssoError = !!searchParams.get("error");

  const params = new URLSearchParams();
  params.append(
    "success_url",
    `${getLocationOrigin()}/${companyUrlSafeName.toLowerCase()}`
  );
  params.append("error_url", getLocationHref());

  return (
    <VerticalSpacing>
      <Button as="a" level="primary" href={`${loginUrl}?${params.toString()}`}>
        {type === "login" ? "Sign in with SSO" : "Sign up with SSO"}
      </Button>

      {ssoError && (
        <div>
          <ErrorText align="center">
            Your company isn&apos;t set up for SSO
          </ErrorText>
        </div>
      )}
    </VerticalSpacing>
  );
}
