export function Checked(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.833496 9.5C0.833496 4.52944 4.86293 0.5 9.8335 0.5C14.8041 0.5 18.8335 4.52944 18.8335 9.5C18.8335 14.4706 14.8041 18.5 9.8335 18.5C4.86293 18.5 0.833496 14.4706 0.833496 9.5Z"
        fill="#9FC873"
      />
      <g clipPath="url(#clip0_433_25432)">
        <path
          d="M7.90923 13.8001L4.00922 9.90013C3.77492 9.66583 3.77492 9.28593 4.00922 9.0516L4.85773 8.20307C5.09204 7.96874 5.47196 7.96874 5.70626 8.20307L8.33349 10.8303L13.9607 5.20307C14.195 4.96877 14.5749 4.96877 14.8092 5.20307L15.6578 6.0516C15.8921 6.28591 15.8921 6.66581 15.6578 6.90013L8.75776 13.8002C8.52343 14.0345 8.14353 14.0345 7.90923 13.8001Z"
          fill="#5A8034"
        />
      </g>
      <defs>
        <clipPath id="clip0_433_25432">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(3.8335 3.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
