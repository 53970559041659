import { CandidateCardFragment } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

interface CandidateInterviewingBadgeProps {
  candidate: CandidateCardFragment;
}

export function CandidateInterviewingBadge({
  candidate,
}: CandidateInterviewingBadgeProps): React.ReactElement | null {
  // This is broken - This just checks if the candidate has had an interview ever
  const candidateAcceptedJobInterviewRecently =
    candidate.mostRecentJobInterview;

  return candidateAcceptedJobInterviewRecently ? (
    <RoundedTag
      icon="interviewing"
      content="Currently interviewing"
      color={palette.brand.green}
      hoverText="The candidate has accepted an interview within the past 2 weeks"
    />
  ) : null;
}
