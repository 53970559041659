import { useFormik } from "formik";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";

import { Button, InputField, SelectField, VerticalSpacing } from "@otta/design";
import { MoreInfoFieldWrapper } from "@hire/components/form/MoreInfoFieldWrapper";

type AddNewStageFormFields = {
  name: string;
  positionAfterStageId: string;
};

const FullWidthButton = styled(Button)`
  width: 100%;
`;

export const Form = ({
  onSubmit,
  stageOptions,
}: {
  onSubmit: (values: AddNewStageFormFields) => void;
  stageOptions: { label: string; value: string }[];
}) => {
  const initialValues = {
    name: "",
    positionAfterStageId: stageOptions[0].value,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required()
      .max(30)
      .test(
        "must-be-unique",
        "A stage with this name already exists",
        value =>
          !stageOptions
            .map(s => s.label.toLocaleLowerCase())
            .includes(value.toLocaleLowerCase())
      )
      .label("Stage name"),
  });

  const form = useFormik<AddNewStageFormFields>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <VerticalSpacing size={3}>
        <InputField
          name="name"
          label="Stage name"
          error={form.touched.name ? form.errors.name : undefined}
          onChange={form.handleChange("name")}
          onBlur={form.handleBlur("name")}
        />

        <MoreInfoFieldWrapper label="Insert after">
          <SelectField
            name="positionAfterStageId"
            options={stageOptions}
            value={stageOptions.find(
              o => o.value === form.values.positionAfterStageId
            )}
            onChange={e =>
              e && form.setFieldValue("positionAfterStageId", e.value)
            }
            onBlur={form.handleBlur("positionAfterStageId")}
          />
        </MoreInfoFieldWrapper>

        <FullWidthButton type="submit" level="primary">
          Create stage
        </FullWidthButton>
      </VerticalSpacing>
    </form>
  );
};
