import { MONTH_OPTIONS, ROUND_OPTIONS } from "./options";

import { Currency } from "@hire/schema";
import {
  FundingRound,
  FundingRoundModalForm,
} from "@hire/pages/CompanyProfile/InvestorsAndFunding/types";

export const transformInMoneyField = (
  fundingAmount: FundingRoundModalForm["fundingAmount"],
  fundingCurrency: FundingRoundModalForm["fundingCurrency"]
) => {
  return {
    amount: fundingAmount ? parseInt(fundingAmount) : null,
    currency: fundingCurrency ?? Currency.Gbp,
  };
};

export const transformIn = (data: FundingRound | undefined) => {
  const values = data
    ? {
        month: MONTH_OPTIONS.find(({ value }) => value === data.month),
        year: data.year,
        round: data.round
          ? ROUND_OPTIONS.find(({ label }) => label === data.round)
          : undefined,
        fundingAmount: data.funding.amount,
        fundingCurrency: data.funding.currency,
      }
    : {
        month: undefined,
        year: "",
        round: undefined,
        fundingAmount: undefined,
        fundingCurrency: undefined,
      };

  return values;
};

export const transformOut = (values: FundingRoundModalForm) => {
  if (
    values.month &&
    values.fundingAmount &&
    values.year &&
    values.fundingCurrency
  ) {
    return {
      month: values.month.value,
      year: values.year,
      round: values.round?.value,
      funding: {
        amount: values.fundingAmount,
        currency: values.fundingCurrency,
      },
    };
  }
  return null;
};
