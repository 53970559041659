import { Divider } from "../../common/Divider";
import { Subheading } from "../../common/Subheading";
import { SectionProps } from "../../common/types";

import { Text, Spacing } from "@otta/design";

export function CompanyHQ({
  company: { parsedHqAddress },
}: SectionProps): React.ReactElement | null {
  return parsedHqAddress ? (
    <Spacing size={0}>
      <Divider />
      <Subheading>Company HQ</Subheading>
      <Text>{parsedHqAddress}</Text>
    </Spacing>
  ) : null;
}
