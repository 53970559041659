import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { IOption } from "../types";
import { formatAndSortOptions } from "../util";
import { useJobEditFormikContext } from "../useHandlers";

import { SelectField, FieldWrapper, Fieldset } from "@otta/design";
import { JobFragment, SubfunctionsForFunctionDocument } from "@hire/schema";
import { pxToRem } from "@otta/design-tokens";

const SelectFieldContainer = styled.div`
  max-width: ${pxToRem(400)};
`;

export function Functions({
  jobFunctionOptions,
  companyJob,
}: {
  jobFunctionOptions: IOption[];
  companyJob?: JobFragment | null;
}) {
  const form = useJobEditFormikContext();
  const [subFunctionOptions, setSubFunctionOptions] = useState<IOption[]>();

  const [querySubFunctions, { data: subFunctionData }] = useLazyQuery(
    SubfunctionsForFunctionDocument,
    {
      fetchPolicy: "network-only",
    }
  );

  const handleChangeJobFunction = ({ value }: IOption) => {
    setSubFunctionOptions(undefined);
    form.setValues({
      ...form.values,
      jobFunction: value,
      jobSubFunction: "",
      technologiesUsed: [],
    });
    querySubFunctions({ variables: { id: value } });
  };

  useEffect(() => {
    if (companyJob?.jobFunction) {
      querySubFunctions({ variables: { id: companyJob.jobFunction.id } });
    }
  }, [companyJob, querySubFunctions]);

  useEffect(() => {
    if (subFunctionData) {
      const formattedSubFunctionOptions = subFunctionData
        ? formatAndSortOptions(subFunctionData.functionSubFunctions)
        : undefined;

      setSubFunctionOptions(formattedSubFunctionOptions);
    }
  }, [subFunctionData]);

  return (
    <Fieldset
      legend="Function"
      advice={
        <>
          We'll show this job to candidates who want to work in the function and
          sub-function you select. We only publish jobs in the listed functions.
        </>
      }
    >
      <FieldWrapper
        label="Function"
        fieldError={
          form.touched.jobFunction ? form.errors.jobFunction : undefined
        }
        required
      >
        {({ field }) => (
          <SelectFieldContainer data-testid="select-jobfunction">
            <SelectField
              inputId={field.id}
              name="jobFunction"
              value={jobFunctionOptions.find(
                option => form.values.jobFunction === option.value
              )}
              options={jobFunctionOptions}
              onChange={e => e && handleChangeJobFunction(e)}
              aria-describedby={field["aria-describedby"]}
              aria-invalid={field["aria-invalid"]}
            />
          </SelectFieldContainer>
        )}
      </FieldWrapper>

      <FieldWrapper
        label="Sub-function"
        required
        disabled={!subFunctionOptions}
        fieldError={
          form.touched.jobSubFunction ? form.errors.jobSubFunction : undefined
        }
      >
        {({ field }) => (
          <SelectFieldContainer data-testid="select-jobSubfunction">
            <SelectField
              inputId={field.id}
              placeholder={
                !subFunctionOptions ? "Select a function first..." : "Select..."
              }
              id="jobSubFunction"
              name="jobSubFunction"
              isDisabled={!subFunctionOptions}
              value={
                subFunctionOptions
                  ? subFunctionOptions.find(
                      option => form.values.jobSubFunction === option.value
                    )
                  : null
              }
              options={subFunctionOptions}
              onChange={e => e && form.setFieldValue("jobSubFunction", e.value)}
              aria-describedby={field["aria-describedby"]}
              aria-invalid={field["aria-invalid"]}
            />
          </SelectFieldContainer>
        )}
      </FieldWrapper>
    </Fieldset>
  );
}
