import {
  ControlledSalaryVisibilityField,
  SalaryVisibilityComponent,
} from "@hire/components/SalaryVisibility";

export const SalaryVisibilityField = ({
  externalId,
  hideSalary,
  handleFieldChange,
  salaryVisibleToIds,
}: {
  externalId?: string;
  hideSalary: boolean;
  handleFieldChange: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  salaryVisibleToIds: string[];
}) => {
  if (!externalId) {
    return (
      <ControlledSalaryVisibilityField
        hideSalary={hideSalary}
        handleFieldChanges={handleFieldChange}
        salaryVisibleToIds={salaryVisibleToIds}
      />
    );
  }

  return (
    <SalaryVisibilityComponent
      jobExternalId={externalId}
      handleFieldChange={handleFieldChange}
    />
  );
};
