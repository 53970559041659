import { useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";

import { BulkMessageData } from "../types";

import { AllTemplatesDocument, MessageTemplateTypeEnum } from "@hire/schema";
import { Button, SelectField, Text, VerticalSpacing } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { MessageField } from "@hire/pages/CompanySettings/Templates/MessageTemplateForm/components/MessageField";
import { VariableButtonSection } from "@hire/pages/CompanySettings/Templates/MessageTemplateForm/components/VariableButtonSection";
import {
  MessageRequestTemplateSelector,
  MessageTemplateValue,
} from "@hire/pages/JobPage/Sourcing/components/MessageCandidate/MessageRequestTemplateSelector";

interface BulkMessageFormFields {
  message: string;
  stage: { value: string; label: string };
  messageTemplate?: MessageTemplateValue;
}

interface BulkMessageFormProps {
  onSubmit: (data: { message: string; stageId: string }) => void;
  stageOptions: { value: string; label: string }[];
  messageState: BulkMessageData;
  numCandidates: number;
  handleClose: () => void;
}

const ButtonFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const validationSchema = Yup.object({
  message: Yup.string().required().min(100).max(2_500).label("Message"),
});

export const Form = ({
  onSubmit,
  stageOptions,
  messageState,
  numCandidates,
  handleClose,
}: BulkMessageFormProps) => {
  const [textCaretPosition, setTextCaretPosition] = useState(0);

  const { data: allTemplatesData, loading: templatesLoading } = useQuery(
    AllTemplatesDocument,
    {
      variables: { messageTemplateType: MessageTemplateTypeEnum.Rejection },
    }
  );

  const allTemplates = allTemplatesData?.messageTemplates ?? [];

  const initialValues = useMemo(() => {
    const stage =
      stageOptions.find(o => o.value === messageState.stageId) ??
      stageOptions[0];
    return {
      message: messageState.message ?? "",
      stage,
      messageTemplate: undefined,
    };
  }, [messageState.message, messageState.stageId, stageOptions]);

  const form = useFormik<BulkMessageFormFields>({
    initialValues,
    onSubmit: formValues =>
      onSubmit({
        message: formValues.message,
        stageId: formValues.stage.value,
      }),
    validationSchema,
  });

  const insertMessageVariable = (variable: string) => {
    const newMessage = `${form.values.message.slice(
      0,
      textCaretPosition
    )}${variable}${form.values.message.slice(textCaretPosition)}`;
    setTextCaretPosition(textCaretPosition + variable.length);

    form.setFieldValue("message", newMessage);
  };

  return (
    <form onSubmit={form.handleSubmit}>
      <VerticalSpacing size={-2}>
        <Text bold>
          Use an application template <Text as="span">optional</Text>
        </Text>
        {templatesLoading ? (
          <Loading />
        ) : (
          <MessageRequestTemplateSelector
            allTemplates={allTemplates}
            value={form.values.messageTemplate}
            onChange={data => form.setValues({ ...form.values, ...data })}
          />
        )}

        <Text bold>Message</Text>
        <VariableButtonSection onClick={insertMessageVariable} />
        <MessageField
          value={form.values.message}
          onChange={form.handleChange("message")}
          onBlur={form.handleBlur("message")}
          error={form.touched.message ? form.errors.message : undefined}
          updateCursorPosition={setTextCaretPosition}
        />

        <Text bold>Move candidates to a different stage?</Text>
        <SelectField
          options={stageOptions}
          menuPlacement="top"
          value={form.values.stage}
          isClearable={false}
          onChange={v => v && form.setFieldValue("stage", v)}
          onBlur={form.handleBlur("stage")}
        />

        <ButtonFooter>
          <StyledButton
            disabled={form.isSubmitting || !form.isValid}
            level="primary"
            type="submit"
          >
            {`Send to ${numCandidates} ${
              numCandidates > 1 ? "candidates" : "candidate"
            }`}
          </StyledButton>
          <StyledButton level="secondary" onClick={handleClose}>
            Cancel
          </StyledButton>
        </ButtonFooter>
      </VerticalSpacing>
    </form>
  );
};
