import { SourcingPreferencesFieldProps } from "./interfaces";

import { EthnicityEnum, Gender } from "@hire/schema";
import { Icon } from "@otta/icons";
import { Text, VerticalSpacing, Spacing, Checkbox } from "@otta/design";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";

const GENDERS = [
  { label: "Female", value: Gender.Female },
  { label: "Male", value: Gender.Male },
  { label: "Non-binary+", value: Gender.Other },
];

const ETHNICITIES = [
  { label: "Asian", value: EthnicityEnum.Asian },
  {
    label: "Black or African American",
    value: EthnicityEnum.Black,
  },
  {
    label: "Multiracial or Multiethnic",
    value: EthnicityEnum.Mixed,
  },
  {
    label: "Hispanic or Latino",
    value: EthnicityEnum.Hispanic,
  },
  { label: "White", value: EthnicityEnum.White },
  {
    label: "Other ethnic groups",
    value: EthnicityEnum.Other,
  },
];

export function DiversityFields(
  props: SourcingPreferencesFieldProps
): React.ReactElement {
  return (
    <VerticalSpacing size={-4}>
      <TooltipWithIcon
        content="Tick your diversity priorities for this role and we'll put matching candidates at the top of the list."
        icon={<Icon icon="circle-info" size={1} />}
      >
        <Text bold>Diversity priorities</Text>
      </TooltipWithIcon>
      <Text size={-1}>Gender</Text>
      <DiversityFilters<Gender>
        {...props}
        fieldName="genders"
        options={GENDERS}
      />
      <Text size={-1}>Ethnicity</Text>
      <DiversityFilters<EthnicityEnum>
        {...props}
        fieldName="ethnicities"
        options={ETHNICITIES}
      />
    </VerticalSpacing>
  );
}

interface DiversityFiltersProps<T> extends SourcingPreferencesFieldProps {
  fieldName: "genders" | "ethnicities";
  options: { label: string; value: T }[];
}

function DiversityFilters<T extends string>({
  onChange,
  preferences,
  fieldName,
  options,
}: DiversityFiltersProps<T>) {
  const values = (preferences[fieldName] || []) as T[];

  return (
    <Spacing size={-2}>
      {options.map(({ label, value }) => (
        <Checkbox
          key={value}
          checked={values.includes(value)}
          label={label}
          onChange={checked => {
            onChange({
              [fieldName]: checked
                ? values.concat([value])
                : values.filter(val => val !== value),
            });
          }}
        />
      ))}
    </Spacing>
  );
}
