import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FieldContainer } from "./FieldContainer";
import {
  CurrencySelection,
  CurrencyMoneyRangeWrapper as Wrapper,
  RangeField as RangeWrapper,
  AccessibilityProps,
} from "./style";

export interface ICurrencyMoneyRangeProps<T extends string> {
  values: { minAmount: number | null; maxAmount: number | null; currency: T };
  onChange?: (moneyRange: {
    minAmount: number | null;
    maxAmount: number | null;
    currency: T;
  }) => void;
  onBlur?: () => void;
  placeholders?: { minAmount: string; maxAmount: string };
  disabled?: boolean;
  options: { value: T; label: string }[];
  min?: AccessibilityProps;
  max?: AccessibilityProps;
}

/**
 * This component is used for currency range inputs in our Applications. An optional label can be added to this input by providing a `label` prop.
 *
 * ```ts
 *
 * import { CurrencyMoneyRange } from '@otta/design'
 *
 * ```
 */

export function CurrencyMoneyRange<T extends string>({
  values,
  options,
  onChange,
  onBlur,
  placeholders,
  disabled,
  min,
  max,
}: ICurrencyMoneyRangeProps<T>): React.ReactElement {
  const [expand, setExpand] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setExpand(false);
    }
  };

  const handleValueChange = useCallback(
    (v: { minAmount: number | null; maxAmount: number | null }) => {
      onChange?.({ ...values, ...v });
    },
    [onChange, values]
  );

  const handleCurrencyChange = useCallback(
    (c: T) => {
      onChange?.({ ...values, currency: c });
      onBlur?.();
    },
    [onChange, values, onBlur]
  );

  useEffect(() => {
    if (expand) {
      document.addEventListener("click", handleClickOutside, true);

      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }

    return undefined;
  }, [expand]);

  const selectedCurrency = useMemo(
    () => options.find(option => option.value === values.currency),
    [options, values.currency]
  );

  return (
    <FieldContainer>
      <Wrapper ref={ref}>
        <CurrencySelection
          currency={values.currency}
          options={options.map(option => option.value)}
          onClick={handleCurrencyChange}
          expand={expand}
          setExpand={setExpand}
          disabled={disabled}
        />
        <RangeWrapper
          values={values}
          prefix={selectedCurrency?.label}
          onChange={handleValueChange}
          onBlur={onBlur}
          placeholders={placeholders}
          disabled={disabled}
          min={min}
          max={max}
        />
      </Wrapper>
    </FieldContainer>
  );
}
