import { ReactElement } from "react";

import { ResponsiveCenteredContainer } from "./ResponsiveCenteredContainer";

import { Card } from "@otta/design";

export const CardContainer = ({ children }: { children: ReactElement }) => (
  <ResponsiveCenteredContainer>
    <Card>{children}</Card>
  </ResponsiveCenteredContainer>
);
