import { format, parseJSON } from "date-fns";
import styled, { css, up } from "@xstyled/styled-components";

import { CircleIconWrapper, Container } from "./styled";

import { Spacing, Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { palette, pxToRem } from "@otta/design-tokens";

const ViewContainer = styled(Container)`
  min-height: ${pxToRem(300)};
  max-height: 44vh;
  overflow-y: auto;
  overflow-wrap: break-word;
  white-space: pre-wrap;

  padding: 19;
  border: 1px solid ${palette.grayscale.shade200};

  ${up(
    "tablet",
    css`
      max-height: 60vh;
    `
  )}
`;

export const ViewNote = ({
  content,
  onEdit: handleToggleEditMode,
  timestamp,
  lastEditor,
}: {
  onEdit: () => void;
  content: string;
  lastEditor: { id: string; firstName: string; lastName: string } | null;
  timestamp: string;
}): React.ReactElement => {
  const formattedTimeStamp = format(
    parseJSON(timestamp),
    "HH:mm', 'd MMMM yyyy"
  );

  const editHistoryStamp = lastEditor
    ? `Last edited by ${lastEditor.firstName} ${lastEditor.lastName} at ${formattedTimeStamp}`
    : `Last edited at ${formattedTimeStamp}`;

  return (
    <Spacing size={-1}>
      <ViewContainer
        role="button"
        onClick={handleToggleEditMode}
        data-testid="view-note-container"
      >
        <CircleIconWrapper>
          <Icon icon="edit" />
        </CircleIconWrapper>
        {content}
      </ViewContainer>
      <Text data-cs-mask size={-1}>
        {editHistoryStamp}
      </Text>
    </Spacing>
  );
};
