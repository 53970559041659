import { useMemo } from "react";
import { Props } from "react-select";

import { QuizFieldValuesQuery } from "@hire/schema";
import { SelectField } from "@otta/design";

function useOptions(
  values: QuizFieldValuesQuery["languageRequirements"]
): { label: string; value: string }[] {
  return useMemo(
    () => values.map(({ id, value }) => ({ label: value, value: id })),
    [values]
  );
}

type FieldProps = Omit<
  Props<{ value: string; label: string }, true>,
  "options" | "value"
> & {
  options: QuizFieldValuesQuery["languageRequirements"];
  value: string[];
};

export function LanguageField({ options, value, ...props }: FieldProps) {
  const opts = useOptions(options);

  const values = useMemo(
    () => opts.filter(o => value.includes(o.value)),
    [opts, value]
  );

  return (
    <SelectField
      options={opts}
      value={values}
      {...props}
      isMulti
      closeMenuOnSelect={false}
    ></SelectField>
  );
}
