import { SectionProps } from "../../common/types";
import { Divider } from "../../common/Divider";
import { Section, SectionHeader } from "../../common/Section";

import { CompanyBenefits, ExampleCompanyBenefits } from "./CompanyBenefits";
import { CompanyValues, ExampleCompanyValues } from "./CompanyValues";
import { Endorsements } from "./Endorsements";
import { ExampleFundingRounds, FundingRounds } from "./FundingRounds";
import { CompanyHQ } from "./CompanyHQ";
import { CompanyArticles } from "./CompanyArticles";
import { CompanyPeople } from "./CompanyPeople";
import { DiversityInitiatives } from "./DiversityInitiatives";

import { List, Spacing, Tipbox, Text } from "@otta/design";
import { useCompanyIsActive } from "@hire/hooks/useCompanyIsActive";
import { Loading } from "@otta/shared-components";

const InsightsExample = () => (
  <img
    src="https://storage.googleapis.com/otta-static/images/hire-app/create-job-insights.png"
    width="100%"
    alt="Company insights example"
  />
);

export function Company(props: SectionProps) {
  const { active, loading } = useCompanyIsActive();

  if (loading) {
    return <Loading />;
  } else if (active) {
    return <RealProfile {...props} />;
  } else {
    return <ExampleProfile />;
  }
}

function RealProfile(props: SectionProps): React.ReactElement {
  return (
    <Section>
      <SectionHeader>Company</SectionHeader>
      <Spacing size={0}>
        <InsightsExample />
        <Endorsements {...props} />
        <FundingRounds {...props} />
        <CompanyBenefits {...props} />
        <CompanyValues {...props} />
        <CompanyHQ {...props} />
        <CompanyArticles {...props} />
        <CompanyPeople {...props} />
        <DiversityInitiatives {...props} />
      </Spacing>
    </Section>
  );
}

function ExampleProfile(): React.ReactElement {
  return (
    <Section>
      <SectionHeader>Company</SectionHeader>
      <Spacing size={0}>
        <Tipbox level="information">
          Your company profile is shown with every job. We’re currently writing
          this, so here’s an example instead.
        </Tipbox>
        <InsightsExample />
        <ExampleFundingRounds />
        <ExampleCompanyBenefits />
        <ExampleCompanyValues />
        <Divider />
        <Tipbox level="information">
          <Spacing size={0}>
            <Text bold>Alongside the above, we’ll also include:</Text>
            <List type="bullet">
              <li>Company headquarters</li>
              <li>Employee endorsements</li>
              <li>Visa sponsorship, if relevant</li>
              <li>Recent articles about your company</li>
              <li>Founder profiles</li>
              <li>People progressing at your company</li>
              <li>Diversity and inclusion information</li>
              <li>
                A take on your company written by us - this builds trust with
                candidates
              </li>
            </List>
          </Spacing>
        </Tipbox>
      </Spacing>
    </Section>
  );
}
