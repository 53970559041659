import { FormFields } from "./types";

import { CompanyExternalFragment, CompanyInput } from "@hire/schema";

export const transformIn = (data: CompanyExternalFragment): FormFields => {
  return {
    trustpilotUrl: data.trustpilotUrl ?? undefined,
    glassdoorUrl: data.glassdoorUrl ?? undefined,
  };
};

export const transformOut = (formValues: FormFields): CompanyInput => {
  return {
    trustpilotUrl: formValues.trustpilotUrl ? formValues.trustpilotUrl : null,
    glassdoorUrl: formValues.glassdoorUrl ? formValues.glassdoorUrl : null,
  };
};
