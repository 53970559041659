import styled from "@xstyled/styled-components";

import { CompanyRecruiterFragment } from "@hire/schema";
import { palette, pxToRem } from "@otta/design-tokens";
import { Toggle } from "@otta/design";
import { RecruiterInfo } from "@hire/components/RecruiterInfo";

const Divider = styled.div`
  display: flex;
  align-items: center;
  &::before,
  &::after {
    content: "";
    display: block;
    background-color: ${palette.brand.grey};
    flex: 1;
    height: ${pxToRem(1)};
  }
  grid-column: 1 / -1;
`;

const GridItem = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  margin: md;
`;

const SwitchWrapper = styled.div`
  justify-self: center;
  cursor: pointer;
`;

export function Recruiter({
  recruiter,
  isCurrentUser,
  onChange,
  checked,
}: {
  recruiter: CompanyRecruiterFragment;
  isCurrentUser: boolean;
  onChange: () => void;
  checked: boolean;
}) {
  return (
    <>
      <GridItem>
        <RecruiterInfo
          firstName={recruiter.firstName}
          lastName={recruiter.lastName}
          email={recruiter.email}
          profileImagePath={recruiter.profileImagePath}
          isCurrentUser={isCurrentUser}
        />
        <SwitchWrapper>
          <Toggle checked={checked} onChange={onChange} />
        </SwitchWrapper>
      </GridItem>
      <Divider />
    </>
  );
}
