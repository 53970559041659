import { Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { KanbanBoard } from "./KanbanBoard";
import { useStages } from "./useStages";
import { CandidateDrawerProvider } from "./Candidate/ImportedCandidateProfile/CandidateDrawerProvider";

import { GetJobPipelineDocument, GetJobPipelineQuery } from "@hire/schema";
import { useRequiredParams } from "@hire/util/routing";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { ReloadableError } from "@hire/components/ReloadableError";
import { Loading } from "@otta/shared-components";

const KanbanContainer = ({
  stages,
}: {
  stages?: NonNullable<
    GetJobPipelineQuery["getJobPipeline"]
  >["candidatePipelineStages"];
}) => {
  const {
    stages: sortedStages,
    candidates,
    controls,
    move,
    bulkMove,
  } = useStages(stages);
  return (
    <CandidateDrawerProvider>
      <KanbanBoard
        pipelineStages={sortedStages}
        candidates={candidates}
        move={move}
        bulkMove={bulkMove}
      />
      <Outlet context={{ controls }} />
    </CandidateDrawerProvider>
  );
};

export const Pipeline = () => {
  const { jobId } = useRequiredParams(["jobId"]);

  const { data, loading, error, refetch } = useQuery(GetJobPipelineDocument, {
    variables: { jobId, workExperienceLimit: 1 },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ResponsiveCenteredContainer>
        <ReloadableError action={refetch} />
      </ResponsiveCenteredContainer>
    );
  }

  return (
    <CandidateDrawerProvider>
      <KanbanContainer stages={data?.getJobPipeline?.candidatePipelineStages} />
    </CandidateDrawerProvider>
  );
};
