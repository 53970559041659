import styled from "@xstyled/styled-components";
import { forwardRef, useImperativeHandle, useState } from "react";

import { modularScale, pxToRem } from "@otta/design-tokens";
import { Button, Modal, Text, VerticalSpacing } from "@otta/design";

interface WarningModalProps {
  howToUndoText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ContentContainer = styled(VerticalSpacing)`
  padding: xl;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const ModalButton = styled(Button)`
  width: 100%;
  font-size: ${modularScale(-1)};
`;
const ButtonsWrapper = styled(VerticalSpacing)`
  width: 100%;
`;

export type ModalRef = {
  show(item: string | number): void;
  hide(): void;
};

export const WarningModal = forwardRef<ModalRef, WarningModalProps>(
  function WarningModal({ onConfirm, onCancel, howToUndoText }, ref) {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState<string>();

    useImperativeHandle(ref, () => ({
      show(item) {
        if (typeof item === "number") {
          setTitle(`Have you hired ${item} candidates?`);
        } else {
          setTitle(`Have you hired ${item}?`);
        }
        setOpen(true);
      },
      hide() {
        setOpen(false);
      },
    }));

    return (
      <Modal
        style={{ maxWidth: pxToRem(460) }}
        open={open}
        onOpenChange={setOpen}
      >
        <ContentContainer size={1}>
          <Text bold>{title}</Text>
          <Text>
            If you&apos;ve hired them, we&apos;ll send them a congratulations
            email after 24 hours.
          </Text>
          <Text>
            {howToUndoText ??
              "If you've moved them here by accident, you can undo this and move them back to their last stage."}
          </Text>
          <ButtonsWrapper size={-2}>
            <ModalButton
              data-testid="hired-button"
              level="primary"
              onClick={onConfirm}
            >
              I&apos;ve hired them
            </ModalButton>
            <ModalButton
              data-testid="not-hired-button"
              level="secondary"
              onClick={onCancel}
            >
              I haven&apos;t hired them
            </ModalButton>
          </ButtonsWrapper>
        </ContentContainer>
      </Modal>
    );
  }
);
