import { Currency } from "@hire/schema";

const CURRENCY_LABELS = {
  [Currency.Usd]: "$",
  [Currency.Eur]: "€",
  [Currency.Gbp]: "£",
  [Currency.Cad]: "$",
};

export const CURRENCY_OPTIONS = [
  Currency.Usd,
  Currency.Eur,
  Currency.Gbp,
  Currency.Cad,
].map(c => ({ value: c, label: CURRENCY_LABELS[c] }));

// eslint-disable-next-line import/no-unused-modules
export function currencyPrefix(currency: string | Currency): "£" | "$" | "€" {
  if (currency === Currency.Gbp) {
    return "£";
  } else if (currency == Currency.Eur) {
    return "€";
  }
  return "$";
}

const currencyFormatter = (currency: Currency) =>
  new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
    style: "currency",
    currency: currency,
  });

const currencyFormatters = {
  [Currency.Gbp]: currencyFormatter(Currency.Gbp),
  [Currency.Usd]: currencyFormatter(Currency.Usd),
  [Currency.Cad]: currencyFormatter(Currency.Cad),
  [Currency.Eur]: currencyFormatter(Currency.Eur),
};

export const formatCurrency = (amount: string, currency: Currency) => {
  const parsedAmount = Number.parseFloat(amount);
  const formatter = currencyFormatters[currency];
  return formatter.format(parsedAmount);
};
