import { ReactElement, useCallback, useState } from "react";
import styled from "@xstyled/styled-components";

import { Button, Spacing } from "@otta/design";
import { ItemCard } from "@hire/components/form/ItemCard";
import { BaseModal } from "@hire/components/modals/BaseModal";
import { updateArrayWithNewItem } from "@hire/util/updateArrayWithNewItem";

export { EmptyList } from "./EmptyList";

const AddButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export interface FormModalProps<T> {
  initialValues?: T;
  onClose: () => void;
  onSave: (item: T) => void;
}

interface ItemCardListProps<T> {
  name: string;
  data: T[];
  renderForm: (props: FormModalProps<T>) => ReactElement;
  renderPreview: (item: T) => ReactElement;
  renderEmpty: () => React.ReactElement;
  onChange: (values: T[]) => void;
}

export const ItemCardList = <T,>({
  name,
  renderPreview,
  renderForm,
  renderEmpty,
  data,
  onChange,
}: ItemCardListProps<T>) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState<number>();

  const handleDelete = useCallback(
    (index: number) => {
      const newValue = [...data];
      newValue.splice(index, 1);
      onChange(newValue);
    },
    [data, onChange]
  );

  const handleEdit = useCallback((index: number) => {
    setCurrentItemIndex(index);
    setModalVisible(true);
  }, []);

  const handleNew = useCallback(() => {
    setCurrentItemIndex(undefined);
    setModalVisible(true);
  }, []);

  const handleSave = useCallback(
    (item: T) => {
      const newValue = updateArrayWithNewItem(data, item, currentItemIndex);

      onChange(newValue);
      setModalVisible(false);
    },
    [currentItemIndex, data, onChange]
  );

  const handleClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  return (
    <>
      <BaseModal
        open={modalVisible}
        onOpenChange={setModalVisible}
        heading={
          currentItemIndex !== undefined ? `Edit ${name}` : `Add ${name}`
        }
      >
        {renderForm({
          initialValues:
            currentItemIndex !== undefined ? data[currentItemIndex] : undefined,
          onClose: handleClose,
          onSave: handleSave,
        })}
      </BaseModal>

      <Spacing>
        {data.length ? (
          <Spacing size={-5}>
            {data.map((item, index) => (
              <ItemCard
                testId={`item-card-${name}`}
                key={index}
                itemIndex={index}
                onEdit={() => handleEdit(index)}
                onDelete={() => handleDelete(index)}
              >
                {renderPreview(item)}
              </ItemCard>
            ))}
          </Spacing>
        ) : (
          renderEmpty()
        )}
        <AddButtonContainer>
          <Button
            aria-label={`Add ${name}`}
            level="secondary"
            onClick={e => {
              e.preventDefault();
              return handleNew();
            }}
          >
            Add {name}
          </Button>
        </AddButtonContainer>
      </Spacing>
    </>
  );
};
