import styled, { css, down } from "@xstyled/styled-components";
import { format } from "date-fns";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Heading, Spacing, Tipbox, Text } from "@otta/design";
import { pluraliser } from "@hire/util/strings";

interface UserProps {
  firstName: string;
  lastName: string;
}
interface PreviousBroadcastProps {
  companyRecruiter: UserProps;
  numberBroadcastedTo: number;
  insertedAt: string;
}

interface NotEnoughCandidatesProps {
  previousBroadcast?: PreviousBroadcastProps;
  ottaCertified: boolean;
}

const CertifiedIcon = styled(Icon)`
  margin-top: -7;
`;

const HeadingWrapper = styled.div`
  display: flex;
  gap: 10;
  align-items: center;
`;

const StyledTipbox = styled(Tipbox)`
  ${down(
    "tablet",
    css`
      padding-right: 28;
    `
  )};
  background-color: white;
  border-radius: ${modularScale(1)};
  padding-top: 19;
  padding-bottom: 19;
  text-align: left;
`;

const CertifiedPill = styled.div`
  display: flex;
  align-items: center;
  padding: 1 10 1 5;
  border-radius: ${pxToRem(15)};
  overflow: hidden;
  background: ${palette.extended.yellow.shade100};
`;

export function NotEnoughCandidates({
  previousBroadcast,
  ottaCertified,
}: NotEnoughCandidatesProps): React.ReactElement {
  return (
    <StyledTipbox level="information">
      <Spacing>
        <HeadingWrapper>
          <Heading>No candidates available for broadcast</Heading>
          {ottaCertified && (
            <CertifiedPill>
              <CertifiedIcon icon="certified" size={2} />
              <Heading size={-1}>Otta certified</Heading>
            </CertifiedPill>
          )}
        </HeadingWrapper>
        {previousBroadcast ? (
          <Text data-cs-mask>
            {previousBroadcast.companyRecruiter.firstName}{" "}
            {previousBroadcast.companyRecruiter.lastName} sent a broadcast to{" "}
            {previousBroadcast.numberBroadcastedTo}{" "}
            {pluraliser(previousBroadcast.numberBroadcastedTo, "candidate")} on{" "}
            {format(new Date(previousBroadcast.insertedAt), "d MMMM Y")}. You
            can send another one when new candidates save the role.
          </Text>
        ) : (
          <Text>You can send a broadcast when candidates save the role.</Text>
        )}
      </Spacing>
    </StyledTipbox>
  );
}
