import styled, { css, up } from "@xstyled/styled-components";

import { PAGE_CONTENT_CONTAINER_PADDING } from "../layout/constants";

import { palette, pxToRem } from "@otta/design-tokens";

export const PageContentContainer = styled.div`
  flex-grow: 1;
  background-color: ${palette.brand.white};
  width: 100%;
  margin: 0 auto;
  padding: lg 6;

  ${up(
    "tablet",
    css`
      padding: ${pxToRem(PAGE_CONTENT_CONTAINER_PADDING)};
      padding-bottom: 40;
    `
  )}
`;
