import { Link } from ".";

export const ConditionalLink = ({
  link,
  condition,
  children,
  ...props
}: {
  link: string;
  condition: boolean;
  children: React.ReactElement;
}): React.ReactElement =>
  condition ? (
    <Link to={link} underline={false} {...props}>
      {children}
    </Link>
  ) : (
    children
  );
