import { useQuery } from "@apollo/client";

import {
  CandidatePipelineItemFragment,
  GetJobPipelineDocument,
} from "@hire/schema";

export const usePipelineItem = ({
  jobId,
  candidateId,
}: {
  jobId: string;
  candidateId: string;
}) => {
  // Why doesn't this use the JobPipelineItem query to get the item directly instead of getting the whole pipeline and then finding it?
  const { data, loading, refetch } = useQuery(GetJobPipelineDocument, {
    variables: { jobId, workExperienceLimit: 1 },
  });

  //  Have to first find stage, then find candidate in stage
  // Can't flatmap, as UI only allows nav to next/prev in same stage
  const pipelineItems = data?.getJobPipeline?.candidatePipelineStages?.find(
    stage =>
      stage.candidatePipelineItems?.some(pipelineItem =>
        matchCandidateId(candidateId, pipelineItem)
      )
  )?.candidatePipelineItems;

  if (!pipelineItems) {
    return {
      loading,
      refetch,
      pipelineItem: undefined,
      previousPipelineItem: undefined,
      nextPipelineItem: undefined,
    };
  }

  const pipelineItemIndex = pipelineItems.findIndex(pipelineItem =>
    matchCandidateId(candidateId, pipelineItem)
  );

  const pipelineItem =
    pipelineItemIndex >= 0 ? pipelineItems[pipelineItemIndex] : null;

  const previousPipelineItem =
    pipelineItemIndex > 0 ? pipelineItems[pipelineItemIndex - 1] : null;

  const nextPipelineItem =
    pipelineItems.length > pipelineItemIndex + 1
      ? pipelineItems[pipelineItemIndex + 1]
      : null;

  return {
    loading,
    refetch,
    pipelineItem,
    previousPipelineItem,
    nextPipelineItem,
  };
};

const matchCandidateId = (
  id: string,
  pipelineItem: CandidatePipelineItemFragment
): boolean =>
  pipelineItem?.candidate?.externalId === id ||
  pipelineItem?.importedCandidate?.externalId === id;
