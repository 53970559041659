import styled from "@xstyled/styled-components";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { Button, Spacing, Text } from "@otta/design";
import { JobTitleDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { useRequiredParams } from "@hire/util/routing";

const PageWrapper = styled(ResponsiveCenteredContainer)`
  text-align: left;
  max-width: 100%;
  padding: 40;
`;

const ButtonContainer = styled.div`
  margin-top: xl;
  display: flex;
  width: 100%;
  gap: 1em;
`;

const StyledButton = styled(Button)``;

export function JobSlotConfirmation(): React.ReactElement | null {
  const { companySlug, externalId } = useRequiredParams([
    "companySlug",
    "externalId",
  ]);

  const { data, loading } = useQuery(JobTitleDocument, {
    skip: !externalId,
    variables: { externalId },
  });

  const navigate = useNavigate();

  if (loading) {
    return <Loading />;
  }

  const jobTitle = data?.publicJob?.title;

  if (!jobTitle) {
    return null;
  }

  return (
    <PageWrapper>
      <Spacing size={5}>
        <Text size={3} bold>
          {`Your ${jobTitle} role is now published`}
        </Text>
        <Spacing size={2}>
          <Text>
            We have emailed you a receipt of your prorated payment for the
            remainder of this billing cycle. Your new monthly price will take
            effect from your next billing cycle.
          </Text>
          <Text>
            You can view and update your plan from the &apos;Manage
            billing&apos; link in Settings.
          </Text>
        </Spacing>
        <ButtonContainer>
          <StyledButton
            level="primary"
            onClick={() => navigate(`/${companySlug}/jobs/${externalId}`)}
          >
            View this job
          </StyledButton>
          <StyledButton
            level="secondary"
            onClick={() => navigate(`/${companySlug}/jobs`)}
          >
            Back to jobs
          </StyledButton>
        </ButtonContainer>
      </Spacing>
    </PageWrapper>
  );
}
