import { useMemo } from "react";

import { SourcingPreferencesFieldProps } from "./interfaces";

import { JobSubFunctionsQuery } from "@hire/schema";
import { Icon } from "@otta/icons";
import { VerticalSpacing, Text, Label, SelectField } from "@otta/design";
import { mapQueryResultToSelectOption } from "@hire/util/select";
import { alphabeticalSortByKey } from "@hire/util/collections";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";

interface JobSubFunctionIdsFieldProps extends SourcingPreferencesFieldProps {
  optionsData?: JobSubFunctionsQuery;
}

export function JobSubFunctionIdsField({
  optionsData,
  preferences,
  onChange,
}: JobSubFunctionIdsFieldProps): React.ReactElement {
  const { jobSubFunctionOptions } = useMemo(() => {
    const options = (optionsData?.jobSubFunctions ?? [])
      .map(mapQueryResultToSelectOption("value"))
      .sort(alphabeticalSortByKey("label"));

    return { jobSubFunctionOptions: options };
  }, [optionsData]);

  return (
    <VerticalSpacing size={-4}>
      <TooltipWithIcon
        content="Select additional role categories to increase the pool of relevant candidates"
        icon={<Icon icon="circle-info" size={1} />}
      >
        <Label htmlFor="jobSubFunctionIds">
          <Text bold>Relevant role categories</Text>
        </Label>
      </TooltipWithIcon>
      <SelectField
        isMulti
        options={jobSubFunctionOptions}
        value={jobSubFunctionOptions.filter(({ value }) =>
          (preferences.jobSubFunctionIds || []).find(
            (id: string) => id === value
          )
        )}
        onChange={vals => {
          onChange({ jobSubFunctionIds: vals.map(({ value }) => value) });
        }}
      />
    </VerticalSpacing>
  );
}
