import { useMemo } from "react";

import { WizardStep } from "../WizardStep";

import { JobBoardAPIKey } from "./JobBoardAPIKey";
import { HarvestAPIKey } from "./HarvestAPIKey";
import { GreenhouseTrackingLink } from "./TrackingLink";
import { GreenhouseWebhook } from "./Webhook";

import { CompanyAtsApiKeyTypeEnum } from "@hire/schema";
import { Spacing } from "@otta/design";

export function GreenhouseForm({
  companyId,
  atsApiKeys,
  trackingCode,
  webhooks,
  refetch,
  refetching,
}: {
  refetching: boolean;
  refetch(): Promise<unknown>;
  companyId: string;
  atsApiKeys: { type: CompanyAtsApiKeyTypeEnum }[];
  webhooks: {
    key: string;
    requestedTopic: string | null;
    secretKey: string | null;
    lastRequestedAt: string | null;
  }[];
  trackingCode: string | null;
}) {
  const jobBoardApiKey = useMemo(
    () =>
      atsApiKeys.find(
        key => key.type === CompanyAtsApiKeyTypeEnum.GreenhouseJobBoard
      ),
    [atsApiKeys]
  );

  const harvestApiKey = useMemo(
    () =>
      atsApiKeys.find(
        key => key.type === CompanyAtsApiKeyTypeEnum.GreenhouseHarvest
      ),
    [atsApiKeys]
  );

  const firstUnrequestedWebhook = useMemo(
    () => webhooks.findIndex(webhook => !webhook.lastRequestedAt),
    [webhooks]
  );

  return (
    <Spacing>
      <WizardStep
        title="Step 1 - Create Job Board API Key"
        complete={!!jobBoardApiKey}
        expanded={!jobBoardApiKey}
      >
        <JobBoardAPIKey companyId={companyId} />
      </WizardStep>
      <WizardStep
        title="Step 2 - Create Harvest API Key"
        complete={!!harvestApiKey}
        expanded={!!jobBoardApiKey && !harvestApiKey}
      >
        <HarvestAPIKey companyId={companyId} />
      </WizardStep>
      <WizardStep
        title="Step 3 - Create Job Board Tracking Link"
        complete={!!trackingCode}
        expanded={!!harvestApiKey && !!jobBoardApiKey && !trackingCode}
      >
        <GreenhouseTrackingLink
          trackingCode={trackingCode}
          companyId={companyId}
        />
      </WizardStep>
      {webhooks.map((webhook, index) => (
        <WizardStep
          key={webhook.key}
          title={`Step ${index + 4} - Create Webhook`}
          complete={!!webhook.lastRequestedAt}
          expanded={
            !!harvestApiKey &&
            !!jobBoardApiKey &&
            !!trackingCode &&
            firstUnrequestedWebhook === index
          }
        >
          <GreenhouseWebhook
            first={index === 0}
            webhook={webhook}
            loading={refetching}
            refetch={refetch}
          />
        </WizardStep>
      ))}
    </Spacing>
  );
}
