import { Link, Outlet } from "react-router-dom";
import styled, { css, down, up } from "@xstyled/styled-components";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMedia } from "react-use";

import { Card, Logo, theme } from "@otta/design";
import { palette, pxToEm, pxToRem } from "@otta/design-tokens";

const OttaLogo = styled(Logo)`
  height: 1.5rem;
`;

const Main = styled.main`
  min-width: max-content;
  min-height: 100vh;

  ${down(
    "tablet",
    css`
      background-color: ${palette.brand.white};
      min-width: unset;
    `
  )}
`;

const ResponsivePadding = css`
  padding: 0rem 1rem;
  ${up(
    "desktop",
    css`
      padding: 0rem 9.5rem;
    `
  )}
`;

const Title = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  ${ResponsivePadding}
  gap: 57.125rem;
  flex-shrink: 0;
  background-color: ${palette.brand.white};
  box-shadow: 0 ${pxToRem(4)} ${pxToRem(20)} 0 rgba(0, 0, 0, 0.1);

  ${down(
    "tablet",
    css`
      box-shadow: unset;
    `
  )}
`;

const OutletContainer = styled.div`
  margin-top: 1.5rem;
  ${ResponsivePadding}
  flex-direction: column;
  display: flex;
  gap: 1.5rem;
`;

const DesktopCard = styled(Card)`
  align-self: center;

  ${up(
    "tablet",
    css`
      width: ${pxToRem(720)};
    `
  )}
`;

const MobileContainer = styled.div``;

type HireQuantities = "less-than-6" | "6-or-more" | "not-sure";

type QuizAnswers = {
  email?: string;
  jobCount?: HireQuantities;
  companyName?: string;
  websiteUrl?: string;
};

type QuizState = {
  progress: number;
  answers: QuizAnswers;
};

const emptyState: QuizState = {
  progress: 0,
  answers: { jobCount: undefined },
};

type QuizProviderValue = QuizState & {
  setState(q: QuizState): void;
};

const QuizContext = createContext<QuizProviderValue>({
  setState: () => undefined,
  ...emptyState,
});

/**
 * Use this hook like useState to get & set answers,
 * you get access to all the answers but can set a subset
 */
export function useAnswers(): [QuizAnswers, (q: Partial<QuizAnswers>) => void] {
  const state = useContext(QuizContext);

  const callback = useCallback(
    (ans: Partial<QuizAnswers>) => {
      const { setState, answers, ...quizState } = state;
      setState({ ...quizState, answers: { ...answers, ...ans } });
    },
    [state]
  );

  return [state.answers, callback];
}

/**
 * Use this hook to flag how far along the quiz the user is
 * the intent is this just gets put in each quiz page with useProgress(1)
 */
export function useProgress(progress: number): void {
  const state = useContext(QuizContext);

  useEffect(() => {
    if (state.progress !== progress) {
      const { setState, ...quizState } = state;
      setState({ ...quizState, progress });
    }
  }, [progress, state]);
}

// we store the state in local storage
// otherwise if you go to the pro form you lose your answers
const localStorageKey = "hire-onboarding-quiz-state";

/**
 * Set up the provider that holds all the quiz info
 * and render the current quiz page in the outlet
 */
export function QuizProvider(): React.ReactElement | null {
  const initial: QuizState = useMemo(() => {
    const previous = localStorage.getItem(localStorageKey);
    return previous ? JSON.parse(previous) : { ...emptyState };
  }, []);

  const [state, setState] = useState(initial);
  const providerValue = useMemo(() => {
    return {
      ...state,
      setState: (state: QuizState) => {
        localStorage.setItem(localStorageKey, JSON.stringify(state));
        setState(state);
      },
    };
  }, [state]);

  return (
    <QuizContext.Provider value={providerValue}>
      <Outlet />
    </QuizContext.Provider>
  );
}

export function LogoHeader({ className }: { className?: string }) {
  return (
    <Title className={className}>
      <Link to="https://otta.com/">
        <OttaLogo />
      </Link>
    </Title>
  );
}

/**
 * Add common framing elements to each page
 */
export function QuizLayout({
  className,
}: {
  className?: string;
}): React.ReactElement {
  const isDesktop = useMedia(`(min-width: ${pxToEm(theme.screens.tablet)})`);
  const QuestionWrapperComponent = isDesktop ? DesktopCard : MobileContainer;

  return (
    <Main className={className}>
      <LogoHeader />
      <OutletContainer>
        <QuestionWrapperComponent>
          <Outlet />
        </QuestionWrapperComponent>
      </OutletContainer>
    </Main>
  );
}

/**
 * Add common framing elements to each page
 */
export function NavQuizLayout(): React.ReactElement {
  return (
    <main>
      <LogoHeader />
      <OutletContainer>
        <Outlet />
      </OutletContainer>
    </main>
  );
}
