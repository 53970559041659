import styled from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";

import { CompanyJobDocument } from "@hire/schema";
import { pxToRem } from "@otta/design-tokens";
import { Spacing, Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { JobEditForm } from "@hire/components/form/JobEditForm";
import { useRequiredParams } from "@hire/util/routing";
import { ReloadableError } from "@hire/components/ReloadableError";

const PageWrapper = styled(ResponsiveCenteredContainer)`
  text-align: left;
  max-width: ${pxToRem(1000)};
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EditJob = () => {
  const { jobId } = useRequiredParams(["jobId"]);

  const { data, loading, refetch } = useQuery(CompanyJobDocument, {
    variables: { jobId },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }

  if (!data?.companyJob) {
    return <ReloadableError action={refetch} />;
  }

  return (
    <PageWrapper>
      <Spacing>
        <TopSection>
          <Text size={1} bold>
            Edit job
          </Text>
        </TopSection>
        <JobEditForm companyJob={data.companyJob} />
      </Spacing>
    </PageWrapper>
  );
};
