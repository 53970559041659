import { useMutation } from "@apollo/client";

import { useRefetchConversations } from "../Context";

import { handleMutationError } from "@hire/errors";
import { UpdateCompanyConversationDocument } from "@hire/schema";
import { Button } from "@otta/design";

export function ArchiveButton({
  id,
  archived,
}: {
  id: string;
  archived: boolean;
}) {
  const refetchConversations = useRefetchConversations();
  const [updateConversationMutation, { loading }] = useMutation(
    UpdateCompanyConversationDocument,
    {
      onCompleted: () => {
        refetchConversations();
      },
      onError: handleMutationError,
    }
  );

  return (
    <Button
      level="secondary"
      onClick={() => {
        updateConversationMutation({
          variables: { id, qualified: archived },
        });
      }}
      disabled={loading}
    >
      {archived ? "Unarchive" : "Archive"}
    </Button>
  );
}
