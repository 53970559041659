import { useQuery } from "@apollo/client";

import * as Banner from "@hire/components/Banner";
import { useRequiredParams } from "@hire/util/routing";
import { Text } from "@otta/design";
import {
  CompanyJobSlotCountsDocument,
  CompanyJobSlotExperimentPhase,
} from "@hire/schema";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

function CTA({
  jobExternalId,
  companySlug,
}: {
  jobExternalId: string;
  companySlug: string;
}): React.ReactElement {
  return (
    <Banner.CTA to={`/${companySlug}/jobs/unfreeze-job/${jobExternalId}`}>
      Publish this job
    </Banner.CTA>
  );
}

export function JobBanner({
  isPaused,
  jobExternalId,
}: {
  isPaused: boolean;
  jobExternalId: string;
}) {
  const { companySlug } = useRequiredParams(["companySlug"]);
  const { data } = useQuery(CompanyJobSlotCountsDocument);
  const slots = data?.currentUser?.currentCompany?.jobSlotData;
  const companyName = useWelcomeToTheJungle();

  if (!slots || !slots.required) {
    return null;
  }

  switch (slots.experimentPhase) {
    case CompanyJobSlotExperimentPhase.Warning:
      return null;
    case CompanyJobSlotExperimentPhase.Active:
      if (isPaused && slots.paid === 0) {
        return (
          <Banner.Info name="paused">
            <Banner.Content>
              <Text bold>
                It&apos;s no longer free to post this job on {companyName}
              </Text>
              <Text size={0}>
                You&apos;re missing out on applications. Publish this job with
                our new pay-as-you-go pricing. Pay monthly, cancel anytime.
              </Text>
            </Banner.Content>
            <CTA companySlug={companySlug} jobExternalId={jobExternalId} />
          </Banner.Info>
        );
      }
      return null;

    default:
      return null;
  }
}
