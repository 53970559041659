import { SSOMetadata } from "./SSOMetadata";
import { SSOSettingsForm } from "./SSOSettingsForm";

import { CompanySamlData } from "@hire/schema";
import { VerticalSpacing } from "@otta/design";

interface ConfigureSSOProps {
  samlData: CompanySamlData;
}

export function ConfigureSSO({
  samlData,
}: ConfigureSSOProps): React.ReactElement {
  return (
    <VerticalSpacing>
      <SSOMetadata samlData={samlData} />
      <SSOSettingsForm samlData={samlData} />
    </VerticalSpacing>
  );
}
