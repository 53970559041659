import { useFormik } from "formik";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";

import { Button, InputField, Spacing, TextareaField } from "@otta/design";
import { PersonProgressing } from "@hire/pages/CompanyProfile/People/types";
import { FormModalProps } from "@hire/components/form/ItemCardList";
import { ButtonWrapper } from "@hire/components/buttons/ButtonWrapper";

const SubFormWrapper = styled.div`
  padding: lg;
`;

const linkedinUrlRegex =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[-%\p{L}\p{M}0-9]{3,100}\/?$/iu;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Fill in this field"),
  bio: Yup.string()
    .max(200, "Bio must be at most 200 characters")
    .required("Fill in this field"),
  linkedinUrl: Yup.string()
    .matches(linkedinUrlRegex, "Enter a valid LinkedIn URL, or leave it blank")
    .required("Fill in this field"),
});

export const ProgressionModal = ({
  onSave,
  onClose,
  initialValues,
}: FormModalProps<PersonProgressing>) => {
  const handleSubmit = (values: PersonProgressing) => {
    onSave(values);
    form.resetForm();
  };
  const form = useFormik<PersonProgressing>({
    initialValues: initialValues ?? {
      name: "",
      linkedinUrl: "",
      bio: "",
    },
    onSubmit: handleSubmit,
    validationSchema,
  });

  const handleClose = () => {
    form.resetForm();
    onClose();
  };

  return (
    <form onSubmit={form.handleSubmit}>
      <SubFormWrapper>
        <Spacing>
          <InputField
            value={form.values.name}
            label="Name"
            name="name"
            onChange={form.handleChange("name")}
            error={form.touched.name ? form.errors.name : undefined}
          />
          <InputField
            value={form.values.linkedinUrl}
            label="LinkedIn URL"
            name="linkedinUrl"
            onChange={form.handleChange("linkedinUrl")}
            error={
              form.touched.linkedinUrl ? form.errors.linkedinUrl : undefined
            }
          />
          <TextareaField
            value={form.values.bio}
            name="bio"
            label="Bio"
            onChange={form.handleChange("bio")}
            error={form.touched.bio ? form.errors.bio : undefined}
          />
          <ButtonWrapper align="start">
            <Button level="secondary" type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button level="primary" type="submit" disabled={form.isSubmitting}>
              Confirm
            </Button>
          </ButtonWrapper>
        </Spacing>
      </SubFormWrapper>
    </form>
  );
};
