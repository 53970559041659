import * as React from "react";
import type { SVGProps } from "react";
const SvgCompany = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M7.667 6.063c0-.346.298-.625.666-.625h2c.367 0 .667.28.667.625v1.875c0 .343-.3.625-.667.625h-2c-.368 0-.666-.282-.666-.626V6.063Zm8-.625c.366 0 .666.28.666.625v1.875c0 .343-.3.625-.666.625h-2c-.367 0-.667-.282-.667-.626V6.063c0-.345.3-.625.667-.625h2Zm-8 5.625c0-.344.298-.626.666-.626h2c.367 0 .667.282.667.626v1.874c0 .344-.3.626-.667.626h-2c-.368 0-.666-.282-.666-.626v-1.874Zm8-.626c.366 0 .666.282.666.626v1.874c0 .344-.3.626-.666.626h-2c-.367 0-.667-.282-.667-.626v-1.874c0-.344.3-.626.667-.626h2ZM4 4.5C4 3.12 5.194 2 6.667 2h10.666C18.804 2 20 3.12 20 4.5v15c0 1.379-1.196 2.5-2.667 2.5H6.667C5.194 22 4 20.879 4 19.5v-15Zm2 0v15c0 .344.298.625.667.625H10v-2.5c0-1.035.896-1.875 2-1.875s2 .84 2 1.875v2.5h3.333c.367 0 .667-.281.667-.625v-15c0-.345-.3-.625-.667-.625H6.667c-.369 0-.667.28-.667.625Z"
    />
  </svg>
);
export default SvgCompany;
