import { useFormik } from "formik";
import { useCallback, useMemo } from "react";

import { transformIn, transformOut } from "./transformers";
import { FormFields, VisaSponsorshipCountry } from "./types";
import { VisaSponsorshipField } from "./VisaSponsorshipForm/VisaSponsorshipField";

import { CompanyVisaSponsorshipFragment, CompanyInput } from "@hire/schema";
import { Button, Spacing } from "@otta/design";

export const VisaSponsorshipForm = ({
  data,
  handleSave,
}: {
  data: CompanyVisaSponsorshipFragment;
  handleSave: (input: CompanyInput) => void;
}) => {
  const initialValues = useMemo(() => transformIn(data), [data]);

  const onSubmit = useCallback(
    (values: FormFields) => {
      return handleSave(transformOut(values));
    },
    [handleSave]
  );

  const form = useFormik<FormFields>({
    initialValues,
    onSubmit,
  });

  const handleUpdateVisaSponsorshipCountries = (
    input: Array<VisaSponsorshipCountry>
  ) => {
    form.setFieldValue("visaSponsorshipCountries", input);
  };

  return (
    <form onSubmit={form.handleSubmit}>
      <Spacing size={2}>
        <VisaSponsorshipField
          onChange={handleUpdateVisaSponsorshipCountries}
          values={form.values.visaSponsorshipCountries}
        />
        <Button type="submit" level="primary" disabled={form.isSubmitting}>
          {form.isSubmitting ? "Saving..." : "Save changes"}
        </Button>
      </Spacing>
    </form>
  );
};
