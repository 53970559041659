import { useQuery } from "@apollo/client";
import styled from "@xstyled/styled-components";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { differenceInDays, parseISO } from "date-fns";
import { useContext } from "react";

import { SourcingSideBar } from "../shared";

import { MessageRequestForm } from "./MessageRequestForm";
import { MessageRequestMessagedRecently } from "./MessageRequestMessagedRecently";

import { CandidateMessageRequestDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { Link } from "@hire/components/links/Link";
import { modularScale } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Text, Clickable, VerticalSpacing } from "@otta/design";
import { SensitiveInfoContext } from "@hire/providers/SensitiveInfo";

const CloseButton = styled(Clickable)`
  display: flex;
  justify-content: flex-end;
  height: ${modularScale()};
  width: 100%;
`;

export function MessageCandidate(): React.ReactElement {
  const { sensitiveFieldsHidden } = useContext(SensitiveInfoContext);
  const { onAfterMessage } = useOutletContext<{
    onAfterMessage: (candidateId: string) => void;
  }>();

  const { jobId, candidateId, companySlug } = useParams();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("..");
  };

  const {
    data: candidateMessageRequestData,
    loading: candidateMessageRequestLoading,
  } = useQuery(CandidateMessageRequestDocument, {
    variables: { jobId: jobId as string, candidateId: candidateId as string },
  });

  if (candidateMessageRequestLoading) {
    return (
      <SourcingSideBar>
        <Loading />
      </SourcingSideBar>
    );
  }

  const candidate = candidateMessageRequestData?.candidate;

  if (!candidate?.firstName) {
    return <SourcingSideBar />;
  }

  const messageRequestsFromCompany =
    candidate?.messageRequestsSentFromCompany ?? [];

  const messagedRecently =
    messageRequestsFromCompany.length > 0 &&
    differenceInDays(
      new Date(),
      parseISO(
        messageRequestsFromCompany[messageRequestsFromCompany.length - 1]
          .insertedAt
      )
    ) < 30;

  return (
    <SourcingSideBar>
      <CloseButton onClick={handleClose}>
        <Icon icon="close" />
      </CloseButton>
      {messagedRecently ? (
        <MessageRequestMessagedRecently
          candidateName={candidate.firstName}
          messageRequestsFromCompany={messageRequestsFromCompany}
        />
      ) : (
        <VerticalSpacing>
          <Text as="h3" bold align="center" size={1}>
            Message{" "}
            <Text
              as="span"
              data-cs-mask
              data-sensitive-field={sensitiveFieldsHidden}
            >
              {candidate.firstName}
            </Text>
          </Text>
          <MessageRequestForm onAfterMessage={onAfterMessage} />
          <Text size={-1} align="center">
            <Link to={`/${companySlug}/settings/templates/sourcing`}>
              Edit and delete sourcing templates from your profile
            </Link>
          </Text>
        </VerticalSpacing>
      )}
    </SourcingSideBar>
  );
}
