import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

import { PeopleForm } from "./PeopleForm";

import { handleMutationError } from "@hire/errors";
import {
  CompanyInput,
  CompanyPeopleDocument,
  UpdateCompanyPeopleDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { Text } from "@otta/design";
import { CardContainer } from "@hire/components/containers/CardContainer";
import { ReloadableError } from "@hire/components/ReloadableError";

export const People = () => {
  const { data, loading, refetch } = useQuery(CompanyPeopleDocument);
  const [update] = useMutation(UpdateCompanyPeopleDocument, {
    onError: handleMutationError,
  });

  const handleSave = useCallback(
    async (input: CompanyInput) => {
      const dataToUpdate = !!Object.keys(input).length;

      if (!data?.currentUser?.currentCompany?.id || !dataToUpdate) {
        return;
      }

      await update({
        variables: {
          id: data?.currentUser?.currentCompany?.id,
          input,
        },
      });
    },
    [data, update]
  );

  if (loading) {
    return <Loading />;
  }

  if (!data?.currentUser?.currentCompany) {
    return <ReloadableError action={refetch} />;
  }

  return (
    <>
      <Text size={2} bold align="center">
        People
      </Text>
      <CardContainer>
        <PeopleForm
          data={data.currentUser.currentCompany}
          onSave={handleSave}
        />
      </CardContainer>
    </>
  );
};
