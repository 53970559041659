import styled, { css, up } from "@xstyled/styled-components";

import { borderRadius, palette, pxToRem } from "@otta/design-tokens";

/**
 *  ```ts
 *
 * import { Card } from '@otta/design'
 *
 * ```
 */

export const Card = styled.div`
  padding: lg;
  border-radius: ${pxToRem(borderRadius)};
  background: ${palette.brand.white};

  ${up(
    "tablet",
    css`
      padding: xl;
    `
  )}

  &:empty {
    display: none;
  }
`;
