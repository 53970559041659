import styled from "@xstyled/styled-components";
import { useFormikContext } from "formik";
import { useMemo, useState } from "react";

import { JobFormFields } from "../../types";

import { JobEditLocationField } from "./LocationField";
import { OfficeDays } from "./OfficeDays";
import { OfficeLocation } from "./OfficeLocation";
import { RemoteInfo } from "./RemoteInfo";
import { Restrictions } from "./Restrictions";

import { pxToRem } from "@otta/design-tokens";
import { Button, Fieldset, Spacing, Text } from "@otta/design";
import { Icon } from "@otta/icons";

const MoreButton = styled(Button)`
  padding: 0.5rem 1rem 0.5rem 1rem;
  max-width: ${pxToRem(400)};
  margin-top: 1rem;
`;

const ButtonIcon = styled(Icon)`
  margin-bottom: 0.1rem;
`;

const ButtonText = styled(Text)`
  display: flex;
  gap: 0.5rem;
`;

const Optional = styled.em`
  font-weight: normal;
`;

const StyledLocationField = styled(JobEditLocationField)`
  margin-top: 19;
`;

export function allRemote(locs?: string[]): boolean {
  return locs?.every(v => v.startsWith("REMOTE_")) ?? true;
}

function useIsRemote(): boolean {
  const {
    values: { locationPreferences },
  } = useFormikContext<JobFormFields>();
  return useMemo(() => allRemote(locationPreferences), [locationPreferences]);
}

/**
 * We show the extra form fields either if you ask for them
 * or if you previously set some info and are editing a job
 */
function useShowExtraFields(): { show: boolean; setShow: () => void } {
  const {
    values: { locationRestrictions, locationInformation },
  } = useFormikContext<JobFormFields>();
  const [show, setShow] = useState(false);
  return useMemo(
    () => ({
      show: show || !!locationRestrictions || !!locationInformation,
      setShow: () => setShow(true),
    }),
    [locationRestrictions, locationInformation, show, setShow]
  );
}

export function Location() {
  const remote = useIsRemote();
  const { show, setShow } = useShowExtraFields();

  return (
    <Fieldset legend="Location" spacing={null}>
      <Spacing size={1}>
        <StyledLocationField />
        {!remote && (
          <Spacing size={1}>
            <OfficeLocation />
            <OfficeDays />
          </Spacing>
        )}
        {show && (
          <Spacing size={1}>
            <RemoteInfo />
            <Restrictions />
          </Spacing>
        )}
      </Spacing>
      {!show && (
        <MoreButton size="small" level="secondary" onClick={() => setShow()}>
          <ButtonText bold size={-1}>
            <ButtonIcon icon="plus" />
            <span>
              Add location restrictions or other information{" "}
              <Optional>optional</Optional>
            </span>
          </ButtonText>
        </MoreButton>
      )}
    </Fieldset>
  );
}
