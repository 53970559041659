import { TestConfig } from "yup";

const emailRegex = new RegExp(
  /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/
);

const urlRegex = new RegExp(
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
);

export const mustNotContainUrl: TestConfig<string | undefined | null> = {
  name: "must-not-contain-url",
  message: ({ label }) => `${label} must not contain a URL`,
  test: value => {
    if (value === null || value === undefined) return true;

    const trimmedValue = value.trim();
    if (trimmedValue === "") return true;
    return !urlRegex.test(trimmedValue);
  },
};

export const mustNotContainEmail: TestConfig<string | undefined | null> = {
  name: "must-not-contain-email",
  message: ({ label }) => `${label} must not contain an email address`,
  test: value => {
    if (value === null || value === undefined) return true;

    const trimmedValue = value.trim();
    if (trimmedValue === "") return true;
    return !emailRegex.test(trimmedValue);
  },
};

export const workEmailRegex = /^(?!.*(gmail|hotmail)).*$/;
