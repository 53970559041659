import styled from "@xstyled/styled-components";
import { NumericFormat, NumberFormatValues } from "react-number-format";

import { palette, pxToRem } from "@otta/design-tokens";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${pxToRem(90)} 1fr;
  box-shadow: inset 0 0 0 1px ${palette.grayscale.shade400};
  border-radius: ${pxToRem(5)};
  background-color: ${palette.brand.white};
`;

export const CurrencyMoneyRangeWrapper = styled.div`
  display: flex;
  width: fit-content;
  border-radius: ${pxToRem(5)};
`;

export interface AccessibilityProps {
  "aria-describedby": string;
  "aria-invalid": boolean;
}

interface IMoneyInputProps {
  value: number | null;
  customInput: React.ComponentType<any>;
  placeholder?: string;
  prefix?: string;
  onBlur?: () => void;
  onChange?: (amount: number | null) => void;
  disabled?: boolean;
  attrs?: AccessibilityProps;
}

export const MoneyInput = ({
  value,
  prefix,
  placeholder,
  customInput,
  onBlur,
  onChange,
  disabled,
  attrs: accessibilityProps,
}: IMoneyInputProps) => {
  const handleChange =
    onChange &&
    (({ value }: NumberFormatValues) => {
      if (value === "") {
        return onChange(null);
      }

      const asNumber = Number(value);

      if (Number.isNaN(asNumber)) {
        return onChange(null);
      }

      return onChange(asNumber);
    });

  return (
    <NumericFormat
      data-testid="money-input"
      value={value}
      placeholder={[prefix, placeholder].filter(Boolean).join("")}
      prefix={prefix}
      decimalScale={2}
      thousandSeparator=","
      customInput={customInput}
      allowNegative={false}
      onBlur={onBlur}
      onValueChange={handleChange}
      disabled={disabled}
      {...accessibilityProps}
    />
  );
};
