type DiscriminateUnion<T, V> = T extends { __typename: V } ? T : never;

export type HireAppUser<T extends { __typename: string }> = DiscriminateUnion<
  T,
  "CurrentAdmin" | "CurrentCompanyRecruiter"
>;

export function hireAppUser<T extends { __typename: string }>(
  user?: T | null
): HireAppUser<T> | null {
  if (
    user &&
    (user.__typename === "CurrentAdmin" ||
      user.__typename === "CurrentCompanyRecruiter")
  ) {
    return user as DiscriminateUnion<
      T,
      "CurrentAdmin" | "CurrentCompanyRecruiter"
    >;
  }

  return null;
}
