import { Heading, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

export const ATSConnected = () => {
  const companyName = useWelcomeToTheJungle();

  return (
    <Spacing>
      <Heading size={1}>ATS connected</Heading>
      <Text>
        When new jobs are uploaded we will automatically add them to{" "}
        {companyName}.{" "}
        <Link underline to="settings/ats">
          Check your ATS integration settings
        </Link>
      </Text>
    </Spacing>
  );
};
