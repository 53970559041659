import { Maybe } from "graphql/jsutils/Maybe";

import { currencyPrefix } from "./currencies";

import { Currency } from "@hire/schema";

export type SalaryRange<T extends number | string = number> = {
  minAmount: T | null;
  maxAmount: T | null;
  currency: Currency;
};

const roundSalary = (salaryAmount: string) => {
  return parseFloat((Number(salaryAmount) / 1000).toFixed(1));
};

export const getSalaryInfo = (
  salaryRange: Maybe<SalaryRange<string>>,
  hideSalary: boolean
): string | null => {
  if (
    hideSalary ||
    !salaryRange ||
    !salaryRange.minAmount ||
    !salaryRange.maxAmount
  ) {
    return null;
  }

  const minSalary = roundSalary(salaryRange.minAmount);
  const maxSalary = roundSalary(salaryRange.maxAmount);

  if (minSalary === 0 || minSalary > maxSalary) {
    return null;
  }

  if (minSalary === maxSalary) {
    return `${currencyPrefix(salaryRange.currency)}${minSalary}k`;
  }

  return `${currencyPrefix(salaryRange.currency)}${minSalary}-${maxSalary}k`;
};
