import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/browser";
import { print } from "graphql";
import Cookies from "js-cookie";
import { createLink } from "apollo-absinthe-upload-link";

import { typePolicies } from "./typePolicies";

const authLink = setContext((_, { headers, emailToken }) => {
  return {
    headers: {
      ...headers,
      "X-CSRF-Token": Cookies.get(import.meta.env.VITE_CSRF_COOKIE),
      "X-XSRF-Token": Cookies.get(import.meta.env.VITE_XSRF_COOKIE),
      ...(emailToken ? { "X-Otta-Email-Token": emailToken } : {}),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  Sentry.withScope(scope => {
    scope.addBreadcrumb({
      type: "http",
      category: "graphql",
      level: "info",
      data: {
        name: operation.operationName,
        query: operation.query.loc?.source?.body ?? print(operation.query),
      },
    });

    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        Sentry.captureMessage(error.message, "error");
      });
    }

    if (networkError) {
      Sentry.captureException(networkError);
    }
  });
});

const httpLink = createLink({
  uri: `${import.meta.env.VITE_API_HOST}/graphql`,
  credentials: "include",
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({ typePolicies }),
});
