import styled, { css, up } from "@xstyled/styled-components";
import { useContext, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";

import { Step } from "../Steps";

import {
  ValidateAtsApiKeyDocument,
  CompanyAts,
  CompanyAtsApiKeyTypeEnum,
  StartKeyAtsIntegrationDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { Button, ErrorText, Input, Middle, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links/Link";
import { CopyPasteMatrix } from "@hire/components/input/CopyPasteMatrix";
import { CopyableField } from "@hire/components/input/CopyableField";
import { SensitiveInfoContext } from "@hire/providers/SensitiveInfo";

const ButtonPostion = styled(Middle)`
  width: 100%;

  ${up(
    "tablet",
    css`
      width: auto;
    `
  )}
`;

export function AshbyAPIKey(): React.ReactElement {
  const { sensitiveFieldsHidden } = useContext(SensitiveInfoContext);
  const [apiKey, setApiKey] = useState<string | null>();

  const [validateKey, { called, data, loading: validationLoading }] =
    useLazyQuery(ValidateAtsApiKeyDocument, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        if (data.validateAtsApiKey.status === "OK") {
          startAtsIntegration();
        }
      },
    });

  const isValidKey = data?.validateAtsApiKey?.status === "OK";

  const [startAtsIntegration, { loading }] = useMutation(
    StartKeyAtsIntegrationDocument,
    {
      variables: {
        ats: CompanyAts.Ashby,
        type: CompanyAtsApiKeyTypeEnum.Ashby,
        key: apiKey as string,
      },
    }
  );

  return (
    <Spacing size={2}>
      <Step idx={1}>
        <Link newTab to="https://app.ashbyhq.com/admin/api/keys">
          Go to the API Keys page in Ashby.
        </Link>
      </Step>
      <Step idx={2}>
        <Text>
          Select the{" "}
          <Text as="span" bold>
            New
          </Text>{" "}
          button in the top right corner.
        </Text>
      </Step>
      <Step idx={3}>
        <Text>
          Copy the{" "}
          <Text as="span" bold>
            Name
          </Text>{" "}
          below and paste it into the{" "}
          <Text bold as="span">
            Name
          </Text>{" "}
          field in Ashby.
        </Text>
        <CopyPasteMatrix
          helpText=""
          rows={[
            {
              label: "Name",
              element: <CopyableField value="Otta Integration" />,
            },
          ]}
        />
      </Step>
      <Step idx={4}>
        <Text>
          Select the{" "}
          <Text as="span" bold>
            Create API Key
          </Text>{" "}
          button.
        </Text>
      </Step>
      <Step idx={5}>
        <Text>
          Select the{" "}
          <Text as="span" bold>
            Copy and Close
          </Text>{" "}
          button.
        </Text>
      </Step>
      <Step idx={6}>
        <Spacing size={-1}>
          <Text>Paste the API Key into the field below:</Text>
          <Input
            data-sensitive-field={sensitiveFieldsHidden}
            value={apiKey || ""}
            onChange={e => setApiKey(e.target.value)}
            onBlur={() => setApiKey(apiKey?.trim() ?? null)}
            type="text"
            name="api-key"
            placeholder="Paste your API Key here"
            data-testid="input-api-key"
          />
        </Spacing>
      </Step>
      <ButtonPostion>
        <Button
          onClick={() =>
            validateKey({
              variables: {
                ats: CompanyAts.Ashby,
                atsApiKey: apiKey as string,
              },
            })
          }
          type="button"
          level="primary"
          data-testid="key-advance-button"
          disabled={!apiKey || loading || validationLoading}
        >
          Save and continue
        </Button>
      </ButtonPostion>
      {called &&
        !isValidKey &&
        (!validationLoading ? (
          <ErrorText>
            We were unable to save your API key. You need to repeat this step.
          </ErrorText>
        ) : (
          <Loading />
        ))}
    </Spacing>
  );
}
