import { QuizHeading } from "./QuizHeading";

import { Spacing, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";

export function QuizHeadingWithSteps({
  children,
  step,
  totalSteps = 3,
}: React.PropsWithChildren<unknown> & { step: number; totalSteps?: number }) {
  return (
    <Spacing size={-4}>
      <Text size={-1} color={palette.grayscale.shade600}>
        {`Step ${step} of ${totalSteps}`}
      </Text>
      <QuizHeading>{children}</QuizHeading>
    </Spacing>
  );
}
