import { Location } from "@hire/schema";

// This should ideally live in @otta/locations

export const UK_LOCATIONS = [Location.RemoteUk, Location.London];

export const EU_LOCATIONS = [
  Location.Amsterdam,
  Location.Barcelona,
  Location.Berlin,
  Location.Dublin,
  Location.Paris,
  Location.RemoteFrance,
  Location.RemoteGermany,
  Location.RemoteIreland,
  Location.RemoteNetherlands,
  Location.RemoteSpain,
  Location.RemoteEurope,
];

export const CA_LOCATIONS = [
  Location.RemoteCanada,
  Location.Toronto,
  Location.Vancouver,
];

export const US_LOCATIONS = [
  Location.AustinUs,
  Location.BostonUs,
  Location.ChicagoUs,
  Location.DenverUs,
  Location.LosAngelesUs,
  Location.MiamiUs,
  Location.NewYorkUs,
  Location.RemoteUs,
  Location.SanFranciscoBayAreaUs,
];

export const REMOTE_LOCATIONS = Object.values(Location).filter(v =>
  v.startsWith("REMOTE_")
);

export const locationToLocationOption = (
  location: Location
): { label: string; value: string } | undefined => {
  switch (location) {
    case Location.Amsterdam:
      return { label: "Amsterdam, Netherlands", value: Location.Amsterdam };
    case Location.AustinUs:
      return { label: "Austin, TX", value: Location.AustinUs };
    case Location.Barcelona:
      return { label: "Barcelona, Spain", value: Location.Barcelona };
    case Location.Berlin:
      return { label: "Berlin, Germany", value: Location.Berlin };
    case Location.BostonUs:
      return { label: "Boston, MA", value: Location.BostonUs };
    case Location.ChicagoUs:
      return { label: "Chicago, IL", value: Location.ChicagoUs };
    case Location.DenverUs:
      return { label: "Denver, CO", value: Location.DenverUs };
    case Location.Dublin:
      return { label: "Dublin, Ireland", value: Location.Dublin };
    case Location.London:
      return { label: "London, UK", value: Location.London };
    case Location.LosAngelesUs:
      return { label: "Los Angeles, CA", value: Location.LosAngelesUs };
    case Location.MiamiUs:
      return { label: "Miami, FL", value: Location.MiamiUs };
    case Location.NewYorkUs:
      return { label: "New York, NY", value: Location.NewYorkUs };
    case Location.Paris:
      return { label: "Paris, France", value: Location.Paris };
    case Location.SanFranciscoBayAreaUs:
      return {
        label: "San Francisco (Bay Area)",
        value: Location.SanFranciscoBayAreaUs,
      };
    case Location.RemoteUk:
      return { label: "Remote (within the UK)", value: Location.RemoteUk };
    case Location.RemoteUs:
      return { label: "Remote (within the US)", value: Location.RemoteUs };
    case Location.RemoteFrance:
      return { label: "Remote (within France)", value: Location.RemoteFrance };
    case Location.RemoteGermany:
      return {
        label: "Remote (within Germany)",
        value: Location.RemoteGermany,
      };
    case Location.RemoteIreland:
      return {
        label: "Remote (within Ireland)",
        value: Location.RemoteIreland,
      };
    case Location.RemoteNetherlands:
      return {
        label: "Remote (within the Netherlands)",
        value: Location.RemoteNetherlands,
      };
    case Location.RemoteSpain:
      return {
        label: "Remote (within Spain)",
        value: Location.RemoteSpain,
      };
    case Location.RemoteEurope:
      return { label: "Remote (within the EU)", value: Location.RemoteEurope };

    case Location.Toronto:
      return { label: "Toronto, Canada", value: Location.Toronto };

    case Location.Vancouver:
      return { label: "Vancouver, Canada", value: Location.Vancouver };

    case Location.RemoteCanada:
      return { label: "Remote (within Canada)", value: Location.RemoteCanada };

    default:
      return undefined;
  }
};

export const stringToLocation = (stringLoc: string) => {
  const match = Object.entries(Location).find(
    locationEnum => locationEnum[1] === stringLoc
  );
  if (match) {
    return match[1];
  }
  return undefined;
};
