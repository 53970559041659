import { useSearchParams } from "react-router-dom";

import { SendEmail } from "./SendEmail";
import { Verify } from "./Verify";

export function VerifyEmail() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const mode = searchParams.get("mode") ?? undefined;

  if (!token) {
    return <SendEmail />;
  }

  return <Verify token={token} mode={mode} />;
}
