import { Fragment } from "react";
import styled from "@xstyled/styled-components";

import { ExperienceSummaryContainer } from "./shared";

import { CandidateCardFragment, CandidateCardJobQuery } from "@hire/schema";
import { HighlightableText } from "@hire/components/HighlightableText";
import { palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Tooltip, Text } from "@otta/design";

const PrimaryTitle = styled(Text)<{ highlighted?: boolean }>`
  color: ${({ highlighted }) =>
    highlighted ? palette.brand.green : palette.brand.black};
`;

interface CandidateWorkedWithTechnologiesProps {
  candidate: CandidateCardFragment;
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
}

export function CandidateWorkedWithTechnologies({
  candidate,

  job,
}: CandidateWorkedWithTechnologiesProps): React.ReactElement | null {
  const workExperienceTechnologies = (candidate.workExperiences ?? [])
    .flatMap(({ technologiesUsed }) => technologiesUsed)
    .map(({ value }) => value);

  const distinctTechnologies = workExperienceTechnologies
    .filter((item, index) => workExperienceTechnologies.indexOf(item) === index)
    .slice(0, 5);

  const jobTechnologies = new Set(
    job.technologiesUsed.map(({ value }) => value)
  );

  if (distinctTechnologies.length === 0) {
    return null;
  }

  return (
    <ExperienceSummaryContainer>
      <Icon icon="technology" />
      <div>
        <Text as="span" bold size={-1}>
          Worked with:{" "}
        </Text>
        {distinctTechnologies.map((value, index) => {
          const requiredTechnology = jobTechnologies.has(value);

          const title = (
            <PrimaryTitle
              as="span"
              size={-1}
              highlighted={requiredTechnology}
              bold={requiredTechnology}
            >
              <HighlightableText>{value}</HighlightableText>
              {index === distinctTechnologies.length - 1 ? null : ", "}
            </PrimaryTitle>
          );

          return (
            <Fragment key={value}>
              {requiredTechnology ? (
                <Tooltip content="Candidate knows a technology that is used in this role">
                  {title}
                </Tooltip>
              ) : (
                title
              )}
            </Fragment>
          );
        })}
      </div>
    </ExperienceSummaryContainer>
  );
}
