import { Blobs, Blob } from "../../common/Blob";
import { SectionProps } from "../../common/types";

export function CompanySectorTags({
  company: { sectorTags },
}: SectionProps): React.ReactElement | null {
  return sectorTags.length > 0 ? (
    <Blobs>
      {sectorTags.map(({ value }, index) => (
        <Blob key={index} bold={index === 0}>
          {value}
        </Blob>
      ))}
    </Blobs>
  ) : (
    <Blobs>
      <Blob>[Industries]</Blob>
    </Blobs>
  );
}
