import { differenceInDays } from "date-fns";
import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Text } from "@otta/design";

interface TimeInStageWarningProps {
  movedToStageAt: string;
  stageName: string;
}

const StyledText = styled(Text)`
  display: flex;
  color: ${palette.grayscale.shade600};
  i {
    margin-right: xs;
  }
`;

export const TimeInStage = ({
  stageName,
  movedToStageAt,
}: TimeInStageWarningProps): React.ReactElement => {
  const numDaysOld = differenceInDays(new Date(), new Date(movedToStageAt));

  return (
    <StyledText size={-1}>
      <Icon icon="clock" /> {numDaysOld}d at {stageName}
    </StyledText>
  );
};
