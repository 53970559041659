import { useEffect, useRef, useState } from "react";
import styled from "@xstyled/styled-components";

import { Text, Tooltip } from "@otta/design";

const StyledText = styled(Text)<{ clickable: boolean }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    cursor: ${({ clickable }) => (clickable ? "pointer" : "initial")};
  }
`;

export const TruncatedStageName = ({
  stageName,
  onClick,
}: {
  stageName: string;
  onClick?: () => void;
}) => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const [overflowing, setOverflowing] = useState(false);

  const isClickable = onClick !== undefined;

  useEffect(() => {
    const element = ref.current;
    element && setOverflowing(element.scrollWidth > element.clientWidth);
  }, []);

  if (!overflowing) {
    return (
      <StyledText
        ref={ref}
        bold
        as="span"
        clickable={isClickable}
        onClick={onClick}
      >
        {stageName}
      </StyledText>
    );
  }

  return (
    <Tooltip content={stageName}>
      <StyledText bold as="span" clickable={isClickable} onClick={onClick}>
        {stageName}
      </StyledText>
    </Tooltip>
  );
};
