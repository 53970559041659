import { pluraliser } from "@hire/util/strings";

const SALARY_VISIBLE_MESSAGE = "Salary visible to everyone.";

export const salaryHiddenTextAdmin = (
  colleaguesHiddenFrom: number,
  hideSalary: boolean
) => {
  const colleague = pluraliser(colleaguesHiddenFrom, "colleague");
  if (hideSalary) {
    return `Salary not visible to candidates${
      colleaguesHiddenFrom ? ` and ${colleaguesHiddenFrom} ${colleague}.` : "."
    }`;
  }
  return SALARY_VISIBLE_MESSAGE;
};

export const salaryHiddenTextMember = (
  canViewSalary: boolean,
  hideSalary: boolean
) => {
  if (hideSalary) {
    if (canViewSalary) {
      return `Salary not visible to candidates.`;
    }
    return "Salary information is hidden for this role. Contact one of your team's admins if you need to see it.";
  }
  return SALARY_VISIBLE_MESSAGE;
};
