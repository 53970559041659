import { SectionProps } from "../../common/types";

import { Text } from "@otta/design";

export function CompanySize({
  company: { size },
}: SectionProps): React.ReactElement | null {
  return size?.value ? (
    <Text>{size?.value} employees</Text>
  ) : (
    <Text>[Number of employees]</Text>
  );
}
