import { CandidateCardFragment, CandidateCardJobQuery } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { RoundedTag } from "@hire/components/tags/RoundedTag";
import { REMOTE_LOCATIONS } from "@hire/util/locations";

interface CandidateWorksRemotelyBadgeProps {
  candidate: CandidateCardFragment;
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
}

export function CandidateWorksRemotelyBadge({
  candidate,
  job,
}: CandidateWorksRemotelyBadgeProps): React.ReactElement | null {
  const isJobRemote = job.locationPreferences.some(pref =>
    REMOTE_LOCATIONS.includes(pref.location)
  );

  const candidateWorksRemotely = candidate.locationPreferences.some(pref =>
    REMOTE_LOCATIONS.includes(pref.location)
  );

  const candidateTimezoneLocation = candidate.timezone?.location;

  return isJobRemote && candidateWorksRemotely ? (
    <RoundedTag
      content="Remote OK"
      icon="remote"
      color={palette.brand.green}
      hoverText={
        candidate.timezone
          ? `Time zone: ${candidateTimezoneLocation}`
          : undefined
      }
    />
  ) : null;
}
