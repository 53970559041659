import { useMemo } from "react";
import { useVariant } from "@unleash/proxy-client-react";
import * as Sentry from "@sentry/browser";
import { useLocation } from "react-router-dom";

import { AnyOttacue, parseAndValidateCue } from "./utils";
import { OttaCueComponent } from "./OttaCueComponent";

export const Ottacue = (): React.ReactElement | null => {
  const { enabled, name, payload } = useVariant("20220826_OTTACUES_HIREAPP");

  const location = useLocation();

  const cueData = useMemo<AnyOttacue | undefined>(() => {
    if (!payload) {
      return undefined;
    }
    try {
      return parseAndValidateCue(payload.value);
    } catch (error) {
      Sentry.captureException(error);
    }

    return undefined;
  }, [payload]);

  if (!enabled || !name || !cueData) {
    return null;
  }

  return (
    <OttaCueComponent
      key={name}
      name={name}
      payload={cueData}
      location={location}
    />
  );
};
