import styled from "@xstyled/styled-components";

import { useJobEditField } from "../useHandlers";

import { FieldWrapper, Fieldset, InputField } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";

const StyledInputField = styled(InputField)`
  max-width: ${pxToRem(400)};
  &[aria-invalid="true"] {
    border: 2px solid ${palette.brand.red};
  }
`;

export function Titles() {
  const [title, titleMeta] = useJobEditField("title");
  const [subtitle, subtitleMeta] = useJobEditField("subtitle");

  return (
    <Fieldset legend="Job title">
      <FieldWrapper
        label="Job title"
        fieldError={titleMeta.touched ? titleMeta.error : undefined}
        required
      >
        {({ field }) => <StyledInputField {...field} {...title} />}
      </FieldWrapper>
      <FieldWrapper
        label="Subtitle"
        advice="Include a specific team, market or contract details. For example, Growth, EMEA or 12 month fixed-term contract."
        fieldError={subtitleMeta.touched ? subtitleMeta.error : undefined}
      >
        {({ field }) => <StyledInputField {...subtitle} {...field} />}
      </FieldWrapper>
    </Fieldset>
  );
}
