// Formik's superb API requires liberal use of `any`
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  FormikContextType,
  useField,
  useFormikContext,
} from "formik";
import { useMemo } from "react";

import { JobFormFields } from "./types";

/**
 * Returns a decorated formik context that sneakily changes setFieldValue
 * to make it always validate on changes if the field in question has errors
 */
export function useJobEditFormikContext(): FormikContextType<JobFormFields> {
  const form = useFormikContext<JobFormFields>();

  return useMemo(
    () => ({
      ...form,
      setFieldValue(field, value, shouldValidate) {
        const hasErrors =
          form.errors[field as keyof JobFormFields] !== undefined;
        return form.setFieldValue(field, value, hasErrors || shouldValidate);
      },
    }),
    [form]
  );
}

/**
 * Single field variant of the above hook
 * Did you know Formik's slogan is "Build forms in React, without the tears"?
 * Seems strange because I am crying a lot writing this
 */
export function useJobEditField(
  name: string
): [FieldInputProps<any>, FieldMetaProps<any>, FieldHelperProps<any>] {
  const [field, fieldMeta, fieldHelpers] = useField(name);
  const { setFieldValue } = useJobEditFormikContext();

  return useMemo(
    () => [
      {
        ...field,
        onChange(e: any) {
          setFieldValue(name, e.target.value);
        },
      },
      fieldMeta,
      fieldHelpers,
    ],
    [name, field, fieldMeta, fieldHelpers, setFieldValue]
  );
}
