import styled from "@xstyled/styled-components";

import { palette, modularScale } from "@otta/design-tokens";

export const AttentionGetter = styled.div.attrs({
  "aria-label": "Needs attention",
})`
  display: inline-block;
  width: ${modularScale(-2)};
  height: ${modularScale(-2)};
  border-radius: 50%;
  background: ${palette.brand.red};
  align-self: center;
  margin-left: 6;
`;
