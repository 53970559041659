import styled from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { JobsListDocument, JobWorkflowStatus } from "@hire/schema";
import { Icon } from "@otta/icons";
import {
  SelectField,
  Label as DefaultLabel,
  VerticalSpacing,
} from "@otta/design";
import { Loading } from "@otta/shared-components";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";
import { hireAppUser } from "@hire/util/user";

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled(DefaultLabel)`
  margin-right: xs;
`;

interface MessageRequestJobSelectorProps {
  value: string;
  onChange: (jobId: string) => void;
}

export const MessageRequestJobSelector = ({
  value,
  onChange,
}: MessageRequestJobSelectorProps): React.ReactElement | null => {
  const workflowStatuses = [
    JobWorkflowStatus.Completed,
    JobWorkflowStatus.Changed,
    JobWorkflowStatus.QaInternal,
    JobWorkflowStatus.RecruiterDraft,
  ];

  const { data, loading } = useQuery(JobsListDocument, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      workflowStatuses,
      locations: [],
    },
  });

  const company = useMemo(
    () => hireAppUser(data?.me)?.currentCompany,
    [data?.me]
  );

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  const companyJobs = company?.listJobs ?? [];

  const jobOptions = companyJobs.map(({ externalId, title, subtitle }) => {
    return {
      label: subtitle ? `${title} (${subtitle})` : title,
      value: externalId,
    };
  });

  return (
    <VerticalSpacing size={-4}>
      <LabelContainer>
        <Label>Job</Label>
        <TooltipWithIcon
          icon={<Icon icon="circle-info" size={1} />}
          content={
            "You can choose to message this candidate about a different job"
          }
        />
      </LabelContainer>

      <SelectField
        options={jobOptions}
        value={jobOptions.filter(({ value: jobValue }) => jobValue === value)}
        onChange={option => option && onChange(option.value)}
      />
    </VerticalSpacing>
  );
};
