import { useState } from "react";
import styled from "@xstyled/styled-components";

import { Icon } from "@otta/icons";
import { Spacing, Text } from "@otta/design";

const Details = styled.details`
  & summary {
    width: fit-content;
    cursor: pointer;
    list-style: none;
  }
`;

export const FunctionGroup = ({
  total,
  name,
  children,
}: {
  total: number;
  name: string;
  children: React.ReactNode;
}) => {
  const [open, setOpen] = useState(true);

  return (
    <Details open style={{ overflow: "visible" }}>
      <Spacing>
        <summary onClick={() => setOpen(!open)}>
          <Text size={1} bold>
            <Icon icon={open ? "chevron-down" : "chevron-right"} size={0} />{" "}
            {name} <Text as="span">({total})</Text>
          </Text>
        </summary>
        {children}
      </Spacing>
    </Details>
  );
};
