import { Divider } from "../../common/Divider";
import { Subheading } from "../../common/Subheading";
import { SectionProps } from "../../common/types";

import { List, Spacing } from "@otta/design";

export function JobApplicationSteps({
  fields: { applicationProcessBullets },
}: SectionProps): React.ReactElement | null {
  return applicationProcessBullets.some(v => !!v.value) ? (
    <Spacing size={0}>
      <Divider />
      <Subheading>Application process</Subheading>
      <List type="bullet">
        {applicationProcessBullets.map(({ value }, i) => (
          <li key={i}>{value}</li>
        ))}
      </List>
    </Spacing>
  ) : null;
}
