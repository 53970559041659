import { useQuery } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { JobsPreview } from "./JobsPreview";

import { CurrentUserDocument } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { Text, Spacing } from "@otta/design";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";

const Container = styled.div`
  background: ${palette.brand.white};
`;

export const JobOverview = () => {
  const { data } = useQuery(CurrentUserDocument);

  return (
    <Container>
      <ResponsiveCenteredContainer align="left" maxWidth={1400}>
        <Spacing size={2}>
          {data?.me?.firstName && (
            <Text data-cs-mask size={2} bold>
              Welcome back, {data.me.firstName}
            </Text>
          )}
          <JobsPreview />
        </Spacing>
      </ResponsiveCenteredContainer>
    </Container>
  );
};
