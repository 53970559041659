import { useFormik } from "formik";
import { useCallback, useMemo } from "react";
import { NumericFormat } from "react-number-format";

import { FundingFormFields, FundingRound } from "../types";
import { transformIn, transformOut } from "../transformers";

import { FundingRoundSection } from "./FundingRoundSection";
import { InvestorsSection } from "./InvestorsSection";

import { CompanyFundingFragment, CompanyInput } from "@hire/schema";
import { Spacing, Button, Text } from "@otta/design";
import { MoreInfoFieldWrapper } from "@hire/components/form/MoreInfoFieldWrapper";
import { currencyPrefix } from "@hire/util/currencies";
import { Link } from "@hire/components/links";

export const InvestorsAndFundingForm = ({
  data,
  onSave: handleSave,
}: {
  data: CompanyFundingFragment;
  onSave: (input: CompanyInput) => void;
}) => {
  const { integratedWithDealroom, totalFunding } = data;
  const initialValues = useMemo(() => transformIn(data), [data]);

  const handleSubmit = useCallback(
    (values: FundingFormFields) => {
      return handleSave(transformOut(values, data));
    },
    [data, handleSave]
  );

  const form = useFormik<FundingFormFields>({
    initialValues,
    onSubmit: handleSubmit,
  });

  const handleUpdateFundingRounds = (updatedFundingRounds: FundingRound[]) => {
    form.setFieldValue("fundingRounds", updatedFundingRounds);
  };

  const handleUpdateInvestors = (value: FundingFormFields["investors"]) => {
    form.setFieldValue("investors", value);
  };

  return (
    <form onSubmit={form.handleSubmit} data-testid="investors-funding-form">
      <Spacing size={2}>
        <FundingRoundSection
          fundingRounds={form.values.fundingRounds}
          allowUpdate={!integratedWithDealroom}
          onChange={handleUpdateFundingRounds}
        />
        {totalFunding && (
          <MoreInfoFieldWrapper
            label="Total funding amount"
            fieldName="totalFunding"
          >
            <NumericFormat
              name="totalFunding"
              value={totalFunding.amount ?? 0}
              prefix={currencyPrefix(totalFunding.currency)}
              decimalScale={0}
              allowNegative={false}
              thousandSeparator
              displayType="text"
            />
          </MoreInfoFieldWrapper>
        )}
        <InvestorsSection
          value={form.values.investors}
          onChange={handleUpdateInvestors}
          disabled={integratedWithDealroom}
        />
        {integratedWithDealroom ? (
          <Text align="left">
            If this information is incorrect, go to{" "}
            <Link
              to="https://knowledge.dealroom.co/knowledge/claiming-company-profile"
              newTab
            >
              dealroom.co
            </Link>
            &nbsp;to claim your profile and update the funding details.
          </Text>
        ) : (
          <Button type="submit" level="primary" disabled={form.isSubmitting}>
            {form.isSubmitting ? "Saving..." : "Save changes"}
          </Button>
        )}
      </Spacing>
    </form>
  );
};
