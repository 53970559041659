import styled, { css, up } from "@xstyled/styled-components";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";

import { Step } from "../Steps";

import { UpdateCompanyTrackingLinkDocument } from "@hire/schema";
import { Button, Input, Middle, Spacing, Text } from "@otta/design";
import { CopyPasteMatrix } from "@hire/components/input/CopyPasteMatrix";
import { SelectBox } from "@hire/components/input/SelectBox";
import { CopyableField } from "@hire/components/input/CopyableField";
import { Link } from "@hire/components/links/Link";
import { Ellipsis } from "@hire/components/icons";

const ButtonPostion = styled(Middle)`
  width: 100%;

  ${up(
    "tablet",
    css`
      width: auto;
    `
  )}
`;

function formatTrackingLink(link: string): string {
  const splitLink = link.split("/");
  return splitLink[splitLink.length - 1];
}

interface GreenhouseTrackingLinkProps {
  companyId: string;

  trackingCode: string | null;
}

export function GreenhouseTrackingLink({
  trackingCode: initialTrackingCode,
  companyId: id,
}: GreenhouseTrackingLinkProps): React.ReactElement {
  const [trackingCode, setTrackingCode] = useState<string | null>(
    initialTrackingCode
  );

  const [mutation, { loading }] = useMutation(
    UpdateCompanyTrackingLinkDocument,
    {
      update: (cache, { data: atsData }) => {
        if (!atsData?.updateCompany) {
          return;
        }

        cache.writeFragment({
          id: cache.identify({ __typename: "Company", id }),
          fragment: gql`
            fragment CompanyTrackingCode on Company {
              trackingCode
            }
          `,
          data: {
            trackingCode: atsData.updateCompany.trackingCode,
          },
        });
      },
    }
  );

  return (
    <Spacing size={2}>
      <Step idx={1}>
        <Link to="https://app.greenhouse.io/configure/custom/options/sources">
          Open &apos;Manage Sources&apos; in Greenhouse (you may need to sign in
          first)
        </Link>
      </Step>
      <Step idx={2}>
        <Text>
          Click the{" "}
          <Text as="span" bold>
            &apos;Create New Source&apos;
          </Text>{" "}
          button
        </Text>
      </Step>
      <Step idx={3}>
        <CopyPasteMatrix
          helpText="Enter the following fields in the pop-up window."
          rows={[
            {
              element: <SelectBox>Third-party boards</SelectBox>,
              label: "Sourcing Strategy",
            },
            {
              element: <CopyableField value="Otta" />,
              label: "Name of Source",
            },
          ]}
        />
      </Step>
      <Step idx={4}>
        <Text>
          Click the{" "}
          <Text as="span" bold>
            &apos;Create Source&apos;
          </Text>{" "}
          button
        </Text>
      </Step>
      <Step idx={5}>
        <Link to="https://app.greenhouse.io/jobboard">
          Open &apos;Configure Job Boards&apos; in Greenhouse
        </Link>
      </Step>
      <Step idx={6}>
        <Text>
          Click the{" "}
          <Text as="span" bold>
            &apos;Ellipsis&apos;
          </Text>{" "}
          icon (
          <Ellipsis
            width="20"
            height="20"
            style={{ verticalAlign: "bottom" }}
          />
          ) on your Job Board
        </Text>
      </Step>
      <Step idx={7}>
        <Text>
          Click the{" "}
          <Text as="span" bold>
            &apos;Tracking Link&apos;
          </Text>{" "}
          button in the dropdown menu
        </Text>
      </Step>
      <Step idx={8}>
        <CopyPasteMatrix
          helpText="Enter the following fields in the pop-up window"
          rows={[
            {
              element: <SelectBox>None</SelectBox>,
              label: "Who gets credit",
            },
            {
              element: (
                <SelectBox>
                  Otta <strong>(third-party board)</strong>
                </SelectBox>
              ),

              label: "Select a source...",
            },
          ]}
        />
      </Step>
      <Step idx={9}>
        <Text>
          Click the{" "}
          <Text as="span" bold>
            &apos;Create Link&apos;
          </Text>{" "}
          button
        </Text>
      </Step>
      <Step idx={10}>
        <Spacing size={-1}>
          <Text>
            Copy the link that was created from the box into this field:
          </Text>
          <Input
            value={trackingCode || ""}
            onChange={e => setTrackingCode(e.target.value)}
            type="text"
            name="api-key"
            placeholder="Paste your tracking link here..."
            data-testid="input-tracking-link"
          />
        </Spacing>
      </Step>
      <ButtonPostion>
        <Button
          onClick={() =>
            mutation({
              variables: {
                id,
                input: {
                  trackingCode:
                    trackingCode && formatTrackingLink(trackingCode),
                },
              },
            })
          }
          type="button"
          level="primary"
          data-testid="key-advance-button"
          disabled={loading && !!trackingCode}
        >
          Save and Continue
        </Button>
      </ButtonPostion>
    </Spacing>
  );
}
