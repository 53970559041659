import { CandidateCardFragment, CandidateCardJobQuery } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { RoundedTag } from "@hire/components/tags/RoundedTag";
import {
  REMOTE_LOCATIONS,
  locationToLocationOption,
} from "@hire/util/locations";

interface CandidateWantsToWorkInBadgeProps {
  candidate: CandidateCardFragment;
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
}

export function CandidateWantsToWorkInBadge({
  candidate,
  job,
}: CandidateWantsToWorkInBadgeProps): React.ReactElement | null {
  const intersectingLocations = job.locationPreferences
    .map(pref => pref.location)
    .filter(loc => !REMOTE_LOCATIONS.includes(loc))
    .filter(loc =>
      candidate.locationPreferences.map(pref => pref.location).includes(loc)
    )
    .flatMap(locationString => {
      const location = locationToLocationOption(locationString);
      return location ? [location.label] : [];
    });

  if (!intersectingLocations.length) {
    return null;
  }

  return (
    <RoundedTag
      content={`Wants to work in ${intersectingLocations.join(", ")}`}
      icon="location"
      color={palette.brand.green}
    />
  );
}
