import styled from "@xstyled/styled-components";

import { useJobEditField } from "../../useHandlers";

import { FieldWrapper, InputField } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";

const NarrowField = styled(InputField)`
  max-width: ${pxToRem(400)};
`;

export function OfficeLocation() {
  const [formik, meta] = useJobEditField("officeLocation");

  return (
    <FieldWrapper
      label="Office location"
      advice="For example, Barbican, London or Boulder, Colorado."
      fieldError={meta.touched ? meta.error : undefined}
    >
      {({ field }) => <NarrowField {...formik} {...field} />}
    </FieldWrapper>
  );
}
