import { pushAnalyticsEvent } from "@otta/analytics";

export const analyticsClickListener = (event: MouseEvent): void => {
  const firstNode = event.target as HTMLElement;

  const search = (currNode: HTMLElement | null): void => {
    if (!currNode) {
      return;
    }

    const analyticsId = currNode.getAttribute("data-analytics-id");
    if (
      analyticsId &&
      (currNode instanceof HTMLAnchorElement ||
        currNode instanceof HTMLInputElement ||
        currNode instanceof HTMLButtonElement ||
        currNode instanceof HTMLSelectElement ||
        currNode instanceof HTMLTextAreaElement)
    ) {
      return pushAnalyticsEvent({
        eventName: "Company Recruiter Clicked",
        pathname: window.location.pathname,
        type: currNode.nodeName,
        name: analyticsId,
      });
    }
    return search(currNode.parentElement);
  };

  search(firstNode);
};
