import { NetworkStatus, useQuery } from "@apollo/client";

import { Integration } from "./Integration";
import { NoIntegration } from "./NoIntegration";

import { ATSNames } from "@hire/globalConstants";
import { AtsIntegrationDocument, CompanyAts, Feature } from "@hire/schema";
import { Card, Spacing, Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { ReloadableError } from "@hire/components/ReloadableError";
import { useAuthorization } from "@hire/providers/authorization";

export const ATSIntegration = () => {
  const { granted, message, loading } = useAuthorization(
    Feature.AtsIntegration
  );

  const { data, refetch, error, networkStatus } = useQuery(
    AtsIntegrationDocument,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  if (loading || networkStatus === NetworkStatus.loading) {
    return <Loading />;
  }

  const company = data?.currentUser?.currentCompany;

  if (error || !company) {
    return <ReloadableError action={refetch} />;
  }

  const hasStartedIntegration =
    granted || (!company.atsAuthorizeUrl && message === "not integrated");

  return (
    <div>
      <Spacing>
        <Text align="left" size={2} bold>
          ATS integration
        </Text>
        {company.ats && hasStartedIntegration ? (
          <Integration
            ats={company.ats}
            atsName={ATSNames[company.ats]}
            atsApiKeys={company.atsApiKeys}
            atsAuthorizeUrl={company.atsAuthorizeUrl}
            atsWebhooks={company.atsWebhooks}
            trackingCode={company.trackingCode}
            companyId={company.id}
            refetch={refetch}
            refetching={networkStatus === NetworkStatus.refetch}
            hasCompletedAtsIntegration={message == null}
          />
        ) : (
          <Card>
            <NoIntegration
              atsName={ATSNames[company.ats as CompanyAts]}
              atsAuthorizeUrl={company.atsAuthorizeUrl}
              scraperUrl={company.scraperUrl}
            />
          </Card>
        )}
      </Spacing>
    </div>
  );
};
