import { SourcingPreferencesFieldProps } from "./interfaces";

import { VerticalSpacing, Label, Text, Spacing } from "@otta/design";
import { Radio } from "@hire/components/input/Radio";

export function RecentlyActiveField({
  onChange,
  preferences,
}: SourcingPreferencesFieldProps): React.ReactElement {
  return (
    <VerticalSpacing size={-4}>
      <Label htmlFor="recentlyActive">
        <Text bold>Candidate activity</Text>
      </Label>
      <Spacing size={-2}>
        <Radio
          size={-1}
          label="Last 6 weeks"
          name="last-6-weeks-radio-input"
          checked={!preferences.recentlyActive}
          onChange={() => {
            onChange({ recentlyActive: false });
          }}
        />
        <Radio
          size={-1}
          label="Last 7 days"
          name="last-7-days-radio-input"
          checked={!!preferences.recentlyActive}
          onChange={() => {
            onChange({ recentlyActive: true });
          }}
        />
      </Spacing>
    </VerticalSpacing>
  );
}
