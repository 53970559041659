export function Messages(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      {...props}
      viewBox="0 0 41 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.5554 11.7791C29.5554 5.64019 23.0901 0.667969 15.1109 0.667969C7.13178 0.667969 0.666504 5.64019 0.666504 11.7791C0.666504 14.161 1.64567 16.3555 3.30539 18.168C2.37484 20.2652 0.840115 21.9319 0.819282 21.9527C0.666504 22.1124 0.624837 22.3485 0.715115 22.5569C0.805393 22.7652 0.999837 22.8902 1.22206 22.8902C3.76373 22.8902 5.86789 22.036 7.38178 21.1541C9.61789 22.2444 12.2637 22.8902 15.1109 22.8902C23.0901 22.8902 29.5554 17.918 29.5554 11.7791ZM38.0276 27.0569C39.6873 25.2513 40.6665 23.0499 40.6665 20.668C40.6665 16.0221 36.9512 12.043 31.6873 10.3832C31.7498 10.8416 31.7776 11.3069 31.7776 11.7791C31.7776 19.1332 24.2985 25.1124 15.1109 25.1124C14.3609 25.1124 13.6318 25.0569 12.9096 24.9805C15.0971 28.9735 20.2359 31.7791 26.2221 31.7791C29.0693 31.7791 31.7151 31.1402 33.9512 30.043C35.4651 30.9249 37.5693 31.7791 40.111 31.7791C40.3332 31.7791 40.5346 31.6471 40.6179 31.4457C40.7082 31.2444 40.6665 31.0082 40.5137 30.8416C40.4929 30.8207 38.9582 29.161 38.0276 27.0569Z"
        fill="black"
      />
    </svg>
  );
}
