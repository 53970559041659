import { useSearchParams } from "react-router-dom";

import { AuthContainer } from "../Login";

import { ForgotPasswordForm } from "./ForgotPaswordForm";
import { ResetPasswordForm } from "./ResetPasswordForm";

import { Card, Heading, VerticalSpacing } from "@otta/design";

export function ForgotPassword(): React.ReactElement {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  return (
    <AuthContainer>
      <VerticalSpacing size={1}>
        <Heading size={4} align="center">
          {token ? "Change your password" : "Forgot your password?"}
        </Heading>
        <Card>
          {token ? <ResetPasswordForm token={token} /> : <ForgotPasswordForm />}
        </Card>
      </VerticalSpacing>
    </AuthContainer>
  );
}
