import { format, parseJSON } from "date-fns";

import { RecruiterInfo } from "../../../components/RecruiterInfo";

import { CompanyRoleType } from "@hire/schema";
import { Text } from "@otta/design";

interface MemberRecruiterCardProps {
  currentUserId: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  profileImagePath: string | null;
  lastActive: string | null;
}

export function MemberRecruiterCard({
  currentUserId,
  id,
  firstName,
  lastName,
  email,
  role,
  profileImagePath,
  lastActive,
}: MemberRecruiterCardProps): React.ReactElement {
  const lastActiveDate = lastActive
    ? format(parseJSON(lastActive), "d MMMM yyyy")
    : null;
  const isAdmin = role === CompanyRoleType.Admin;
  const isCurrentUser = currentUserId == id;

  return (
    <>
      <RecruiterInfo
        firstName={firstName}
        lastName={lastName}
        email={email}
        profileImagePath={profileImagePath}
        isCurrentUser={isCurrentUser}
      />
      <Text size={-1}>{isAdmin ? "Admin" : "Member"}</Text>
      <Text size={-1} align="right">
        {lastActiveDate}
      </Text>
    </>
  );
}
