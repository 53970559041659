import { differenceInDays, parseISO } from "date-fns";

import { CandidateCardFragment } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

interface CandidateIsRecentlyActiveBadgeProps {
  candidate: CandidateCardFragment;
}

export function CandidateIsRecentlyActiveBadge({
  candidate,
}: CandidateIsRecentlyActiveBadgeProps): React.ReactElement | null {
  const candidateIsRecentlyActive =
    differenceInDays(new Date(), parseISO(candidate.lastActive)) < 7;

  return candidateIsRecentlyActive ? (
    <RoundedTag
      icon="clock"
      content="Recently active"
      color={palette.brand.green}
      hoverText="The candidate has been active in the last 7 days"
    />
  ) : null;
}
