import { useMutation } from "@apollo/client";
import styled from "@xstyled/styled-components";
import { useFormik } from "formik";

import { handleMutationError } from "@hire/errors";
import { CompanySamlData, UpdateCompanySamlDataDocument } from "@hire/schema";
import {
  Button,
  Toggle,
  Spacing,
  Card,
  VerticalSpacing,
  Tipbox,
} from "@otta/design";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: md;
`;

interface FormFields {
  enabled: boolean;
  enforced: boolean;
}

interface SSOSettingsFormProps {
  samlData: CompanySamlData;
}

export function SSOSettingsForm({
  samlData: { idpMetadata, enabled, verified, enforced },
}: SSOSettingsFormProps): React.ReactElement {
  const [mutate, { loading }] = useMutation(UpdateCompanySamlDataDocument, {
    onError: handleMutationError,
  });

  const form = useFormik<FormFields>({
    initialValues: {
      enabled,
      enforced,
    },
    onSubmit: (values, { resetForm }) => {
      mutate({
        variables: { input: values },
        onCompleted: ({ updateCompanySamlData: { enabled, enforced } }) =>
          resetForm({ values: { enabled, enforced } }),
      });
    },
  });

  return (
    <Card>
      <Spacing>
        <Spacing>
          <Toggle
            label="Enable SSO"
            checked={form.values.enabled}
            onChange={v => {
              form.setValues({ enabled: v, enforced: false });
            }}
            disabled={!idpMetadata}
          />
          {!idpMetadata && (
            <Tipbox level="warning">
              Metadata has to first be set in order to enable SSO.
            </Tipbox>
          )}
          <VerticalSpacing>
            <Toggle
              label="Enforce SSO for all users"
              checked={form.values.enforced}
              onChange={v => form.setFieldValue("enforced", v)}
              disabled={!form.values.enabled || !verified}
            />
            {!form.values.enabled && (
              <Tipbox level="warning">
                SSO needs to be enabled in order to enforce it.
              </Tipbox>
            )}
            {form.values.enabled && !verified && (
              <Tipbox level="warning">
                Your configuration needs to be verified before you can enforce
                SSO. Log out and log back in to Otta using SSO to verify your
                configuration.
              </Tipbox>
            )}
            {form.values.enforced && form.dirty && (
              <Tipbox level="warning">
                Enforcing SSO will log out all users who are not logged in using
                SSO.
              </Tipbox>
            )}
          </VerticalSpacing>
        </Spacing>
        <ButtonContainer>
          <Button
            level={form.dirty ? "primary" : "secondary"}
            type="submit"
            onClick={form.submitForm}
            disabled={!form.dirty || loading}
          >
            {loading
              ? "Loading..."
              : form.dirty
              ? "Save changes"
              : "No changes"}
          </Button>
        </ButtonContainer>
      </Spacing>
    </Card>
  );
}
