import { CandidateCardFragment } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

interface CandidateLookingNowBadgeProps {
  candidate: CandidateCardFragment;
}

export function CandidateLookingNowBadge({
  candidate,
}: CandidateLookingNowBadgeProps): React.ReactElement | null {
  const candidateLookingNow = candidate.searchStage === "looking_now";

  return candidateLookingNow ? (
    <RoundedTag
      icon="search"
      content="Looking ASAP"
      color={palette.brand.green}
      hoverText="The candidate is looking for new roles now"
    />
  ) : null;
}
