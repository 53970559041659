import { SectionProps } from "../../common/types";

import { Link } from "@hire/components/links/Link";
import { Text } from "@otta/design";

export function JobTitle({
  company,
  fields: { title, subtitle },
}: SectionProps): React.ReactElement {
  return (
    <>
      <Text as={"h1"} size={2} bold>
        {title || "[Job title]"},{" "}
        {company.websiteUrl ? (
          <Link to={company.websiteUrl} newTab>
            {company.name}
          </Link>
        ) : (
          company.name
        )}
      </Text>
      {!!subtitle && (
        <Text bold as={"h2"}>
          {subtitle}
        </Text>
      )}
    </>
  );
}
