import { useFormik } from "formik";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";

import { Loading } from "@otta/shared-components";
import { Button, InputField, Text, VerticalSpacing } from "@otta/design";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

const FullWidthButton = styled(Button)`
  width: 100%;
`;

const validationSchema = Yup.object({
  emailAddress: Yup.string()
    .required()
    .min(5)
    .max(100)
    .email()
    .label("Email address"),
});

export const EmailForm = ({
  onSubmit,
}: {
  onSubmit: ({ emailAddress }: { emailAddress: string }) => void;
}): React.ReactElement => {
  const form = useFormik<{ emailAddress: string }>({
    initialValues: {
      emailAddress: "",
    },
    onSubmit,
    validationSchema,
  });
  const companyName = useWelcomeToTheJungle();

  return (
    <>
      <Text>
        Keep track of your entire pipeline by adding candidates from outside
        {companyName}.
      </Text>
      <Text>Enter the candidate&apos;s email address to get started.</Text>
      <form onSubmit={form.handleSubmit}>
        {form.isSubmitting ? (
          <div>
            <Loading />
          </div>
        ) : (
          <VerticalSpacing size={3}>
            <InputField
              value={form.values.emailAddress}
              name="emailAddress"
              label="Email address"
              onChange={form.handleChange("emailAddress")}
              onBlur={form.handleBlur("emailAddress")}
              error={
                form.touched.emailAddress ? form.errors.emailAddress : undefined
              }
            />

            <FullWidthButton type="submit" level="primary">
              Next
            </FullWidthButton>
          </VerticalSpacing>
        )}
      </form>
    </>
  );
};
