import styled from "@xstyled/styled-components";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { fadeIn } from "../../theme";

import { modularScale, palette } from "@otta/design-tokens";

export const Container = styled(DropdownMenu.Content)`
  background: ${palette.brand.white};
  z-index: 100;
  max-height: 200px;
  white-space: nowrap;
  border-radius: ${modularScale(-2)};
  animation-duration: 0.2s;
  animation-name: ${fadeIn};
  animation-timing-function: ease;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`;

export interface DropdownProps {
  toggler: React.ReactElement;
  children: React.ReactNode;
  onClick?: (open: boolean) => void;
  open?: boolean;
  placement?: "top" | "bottom" | "left" | "right";
  align?: "start" | "end" | "center";
}
/**
 * A dropdown component. The first child component inside the Dropdown component is the toggle for opening and closing the dropdown menu.
 *
 * ```ts
 *
 * import { Dropdown, DropdownItem } from '@otta/design'
 *
 * ```
 */
export const Dropdown = ({
  toggler,
  children,
  onClick,
  open,
  placement,
  align,
}: DropdownProps) => (
  <DropdownMenu.Root open={open} onOpenChange={onClick}>
    <DropdownMenu.Trigger asChild>{toggler}</DropdownMenu.Trigger>
    <DropdownMenu.Portal>
      <Container side={placement} align={align}>
        {children}
      </Container>
    </DropdownMenu.Portal>
  </DropdownMenu.Root>
);
