export const removeTypename = <T extends { __typename: string }>(
  value: T
): Omit<T, "__typename"> => {
  const { __typename, ...rest } = value;

  return rest;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Field<A> = A | ((args: any) => A);

export type GraphQLMock<A> = {
  [K in keyof A]?: A[K] extends (infer U)[]
    ? Field<GraphQLMock<U>[]>
    : A[K] extends object | null
    ? Field<GraphQLMock<A[K]>>
    : Field<A[K]>;
};
