import { gql, useMutation } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { handleMutationError } from "@hire/errors";
import { UpdateUserJobNotificationUnsubscriptionDocument } from "@hire/schema";
import { Text, Toggle } from "@otta/design";

const Container = styled.div`
  display: flex;
  gap: sm;
`;

export function JobNotificationSubscription({
  jobId,
  subscribed,
}: {
  jobId: string;
  subscribed: boolean;
}) {
  const [updateSubscriptionMutation, { loading }] = useMutation(
    UpdateUserJobNotificationUnsubscriptionDocument,
    {
      onError: handleMutationError,
    }
  );

  const handleToggle = () => {
    updateSubscriptionMutation({
      variables: {
        jobId: jobId,
      },
      update(cache, result) {
        if (
          typeof result.data?.updateUserJobNotificationUnsubscription ===
          "boolean"
        ) {
          cache.writeFragment({
            fragment: gql`
              fragment JobSubscription on Job {
                subscribed
              }
            `,
            id: cache.identify({ __typename: "Job", id: jobId }),
            data: {
              subscribed: !result.data.updateUserJobNotificationUnsubscription,
            },
          });
        }
      },
    });
  };

  return (
    <Container
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Text size={-1} bold>
        Email me about new activity
      </Text>
      <Toggle
        smallToggle
        disabled={loading}
        checked={subscribed}
        onChange={handleToggle}
      />
    </Container>
  );
}
