import { useCallback, useEffect, useState } from "react";
import { matchPath, Location } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { AnyOttacue, getOttacue, setOttacue } from "../utils";

import { Child } from "./Child";

import { palette, pxToRem } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";

const StyledCue = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - ${pxToRem(32)});
  margin: 0 1rem;
  padding: 1rem;
  background-color: ${palette.extended.blue.shade100};
  border: ${pxToRem(1)} solid ${palette.extended.blue.shade500};
  border-top-left-radius: ${pxToRem(10)};
  border-top-right-radius: ${pxToRem(10)};
  box-shadow: 0 0 ${pxToRem(10)} ${pxToRem(2)} rgba(0, 0, 0, 0.05);
  z-index: 15;
  max-width: ${pxToRem(343)};
`;

export const OttaCueComponent = ({
  name,
  payload,
  location,
}: {
  name: string;
  payload: AnyOttacue;
  location: Location;
}): React.ReactElement | null => {
  const [cueHidden, setCueHidden] = useState(() => {
    const existingState = getOttacue(name);

    return existingState && existingState !== "active";
  });

  const dismissCue = useCallback(() => {
    setOttacue(name, "dismissed");
    setCueHidden(true);
    pushAnalyticsEvent({
      eventName: "Company Recruiter Dismissed Ottacue",
      key: name,
    });
  }, [name]);

  const clickCue = useCallback(
    (eventProperties?: Record<string, string>) => {
      setOttacue(name, "dismissed");
      setCueHidden(true);
      pushAnalyticsEvent({
        eventName: "Company Recruiter Clicked Ottacue",
        key: name,
        ...eventProperties,
      });
    },
    [name]
  );

  const active =
    !cueHidden &&
    (payload.paths ?? ["/"]).some(p => matchPath(p, location.pathname));

  useEffect(() => {
    if (active) {
      pushAnalyticsEvent({
        eventName: "Company Recruiter Viewed Ottacue",
        key: name,
      });
    }
  }, [active, name]);

  if (active) {
    return (
      <StyledCue data-testid="ottacue">
        <Child onClick={clickCue} onDismiss={dismissCue} cue={payload} />
      </StyledCue>
    );
  }

  return null;
};
