import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links/Link";

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${palette.extended.blue.shade600};
  :hover {
    color: ${palette.extended.blue.shade800};
`;

export const ArticlePreview = ({
  title,
  url,
}: {
  title: string;
  url: string;
}) => {
  return (
    <Spacing size={-12}>
      <Text bold>{title}</Text>
      <StyledLink to={url} newTab underline size={-1}>
        {url} <Icon icon="external" />
      </StyledLink>
    </Spacing>
  );
};
