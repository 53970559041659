import { createContext } from "react";

export interface IExperimentContext {
  variantViewed(name: string, variant: string): void | (() => void);
  overrides: Record<string, string>;
}

export const ExperimentContext = createContext<IExperimentContext>({
  overrides: {},
  variantViewed() {
    return;
  },
});
