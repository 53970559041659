import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";
import { Logo } from "@otta/design";

const Bezel = styled.div`
  flex-direction: column;
  background-color: white;
  justify-content: stretch;
  max-height: ${pxToRem(800)};
  outline: ${pxToRem(2)} solid #eaeaea;
  box-shadow: ${pxToRem(1)} ${pxToRem(1)} ${pxToRem(8)} #555;
  border: 0.1rem solid lightgray;
  padding: 4rem 0.8rem;
  border-radius: 2rem;
  position: relative;
  height: 85vh;
  display: flex;
`;

const Otta = styled(Logo)`
  position: absolute;
  top: 1.8rem;
  width: 10%;
  fill: #212121;
  left: 45%;
`;

const ScrollContainer = styled.div`
  background-color: ${palette.beige.shade200};
  border: 2px solid ${palette.brand.black};
  overflow-y: scroll;
`;

/**
 * Graphic design is my passion
 */
export function Phone({ children }: React.PropsWithChildren<unknown>) {
  return (
    <Bezel>
      <Otta />
      <ScrollContainer>{children}</ScrollContainer>
    </Bezel>
  );
}
