import styled from "@xstyled/styled-components";
import { InputHTMLAttributes } from "react";

import { DefaultCheckmark } from "../Form/style/Checkmark";

import { palette, pxToRem, modularScale } from "@otta/design-tokens";

const Wrapper = styled.label`
  display: block;
  position: relative;
  user-select: none;
  text-align: left;
  padding-left: xxl;
  cursor: pointer;
  color: ${palette.brand.black};

  input ~ span {
    background-color: ${palette.grayscale.shade200};
  }

  input:checked ~ span {
    background-color: ${palette.brand.yellow};
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: ${modularScale(-5)};
    top: ${modularScale(-10)};
    width: ${modularScale(-8)};
    height: ${modularScale(-3)};
    border: solid ${palette.brand.black};
    border-width: 0 ${pxToRem(2)} ${pxToRem(2)} 0;
    transform: rotate(45deg);
  }
`;

const Input = styled.input`
  position: absolute;
  cursor: pointer;
  opacity: 0;
  height: 0;
  width: 0;
  outline: ${pxToRem(1)} solid ${palette.brand.black};
`;

const Checkmark = styled(DefaultCheckmark)`
  left: ${pxToRem(5)};
  height: ${modularScale(1)};
  width: ${modularScale(1)};
  border-radius: ${pxToRem(4)};
  background-color: ${palette.brand.grey};
`;

interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  id: string;
}

export const Checkbox = ({
  label,
  checked,
  className,
  onChange,
}: ICheckboxProps) => (
  <Wrapper className={className}>
    {label}
    <Input type="checkbox" onChange={onChange} checked={checked} />
    <Checkmark />
  </Wrapper>
);
