import { useEffect, useState } from "react";
import styled from "@xstyled/styled-components";
import { useLocation } from "react-router-dom";

import { Clickable, Text } from "@otta/design";
import { palette, pxToRem, borderRadiusSmall } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { pushAnalyticsEvent } from "@otta/analytics";

const missingSalaryWarningLocalStorageKey = "missing-salary-warning";

const FlexText = styled(Text)`
  display: flex;
  gap: 0.5rem;
`;

const StyledList = styled.ul`
  list-style-type: disc;
  flex-direction: column;
  margin-left: 1rem;
  display: flex;
  gap: 0.5rem;
`;

const Alert = styled.div`
  display: flex;
  background-color: ${palette.extended.orange.shade200};
  padding: 1rem;
  border-radius: ${pxToRem(borderRadiusSmall)};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: xl;
  gap: 1rem;
`;

export const MissingSalaryWarning = () => {
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(
    () => localStorage.getItem(missingSalaryWarningLocalStorageKey) !== "true"
  );

  useEffect(() => {
    if (isOpen) {
      pushAnalyticsEvent({
        eventName: "Company Recruiter Viewed",
        name: "missing-salaries-notification",
        pathName: pathname,
      });
    }
  }, [pathname, isOpen]);

  if (!isOpen) return null;

  const handleClickClose = () => {
    setIsOpen(false);
    localStorage.setItem(missingSalaryWarningLocalStorageKey, "true");
  };

  return (
    <Alert>
      <Wrapper data-testid="missing-salary-warning">
        <ContentWrapper>
          <FlexText size={0} bold>
            <Icon icon="salary-off" />
            Your jobs are missing salaries
          </FlexText>
          <StyledList>
            <li>Add public or hidden salaries.</li>
            <li>
              Hidden salaries aren't shown to candidates. They ensure your job
              is matched with suitable candidates.
            </li>
            <li>Jobs with salaries receive more, high quality applications.</li>
          </StyledList>
        </ContentWrapper>
        <Clickable
          onClick={handleClickClose}
          data-testid="missing-salary-warning-close-button"
          data-analytics-id="missing-salary-warning-close-button"
        >
          <Icon icon="close" size={1} />
        </Clickable>
      </Wrapper>
    </Alert>
  );
};
