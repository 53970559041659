import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";
import { Button } from "@otta/design";

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: xs;
`;
const VariableButton = styled(Button)`
  background-color: ${palette.beige.shade200};
  white-space: nowrap;
`;

interface MessageRequestVariableButtonsProps {
  insertMessageVariable: (variable: string) => void;
}

export const MessageRequestVariableButtons = ({
  insertMessageVariable,
}: MessageRequestVariableButtonsProps): React.ReactElement => (
  <ButtonsContainer>
    {[
      ["{candidateName}", "Candidate name"],
      ["{recruiterName}", "Recruiter name"],
      ["{companyName}", "Company name"],
      ["{jobTitle}", "Job title"],
    ].map(([variable, label]) => (
      <VariableButton
        key={variable}
        onClick={() => insertMessageVariable(variable)}
        type="button"
        level="tertiary"
        size="small"
      >
        {label}
      </VariableButton>
    ))}
  </ButtonsContainer>
);
