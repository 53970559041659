import styled from "@xstyled/styled-components";

import { UNKNOWN_COLOR, CHART_COLORS } from "./graph-helpers";

import { pxToRem } from "@otta/design-tokens";
import { Text } from "@otta/design";

const Legend = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: md;
`;

const LegendItem = styled.li`
  display: flex;
  align-items: center;
  gap: xs;

  &[data-hovered="false"] {
    opacity: 0.3;
    transition: default;
  }
`;

const FilterCircle = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  width: ${pxToRem(12)};
  height: ${pxToRem(12)};
`;

interface ChartData {
  total: number;
  value: string;
  percent: string;
}

export const FilterLegend = ({
  chartData,
  sectionsWithData,
  hoveredItem,
  setHoveredItem,
}: {
  chartData: ChartData[];
  sectionsWithData: Set<string>;
  hoveredItem: string | undefined;
  setHoveredItem: (value: string) => void;
}) => {
  return (
    <Legend>
      {/* We don't map on sectionsWithData because we don't want a mismatch between the bars & the legend */}
      {chartData.map(
        (filter: ChartData, index: number) =>
          sectionsWithData.has(filter.value) && (
            <LegendItem
              data-testid={`legend-${filter.value}`}
              key={filter.value}
              data-hovered={!hoveredItem ? null : hoveredItem === filter.value}
              onMouseEnter={() => setHoveredItem?.(filter.value)}
              onMouseLeave={() => setHoveredItem?.("")}
            >
              <FilterCircle
                color={
                  filter.value === "Unknown"
                    ? UNKNOWN_COLOR
                    : CHART_COLORS[index]
                }
              />
              <Text size={-1}>{filter.value}</Text>
            </LegendItem>
          )
      )}
    </Legend>
  );
};
