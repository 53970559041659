import styled from "@xstyled/styled-components";
import { FocusEvent } from "react";

import { palette } from "@otta/design-tokens";
import { Button, SelectField, Spacing } from "@otta/design";
import { DeleteButton } from "@hire/components/buttons/DeleteButton";
import { Textarea } from "@hire/components/input/Textarea";
import { FieldErrors } from "@hire/components/form/FieldErrors";

interface Bullet {
  value?: string;
  location?: string;
}

interface BulletsProps {
  values: Bullet[];
  onChange: (bullets: Bullet[]) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  fieldName: string;
  errors?: string | (string | undefined)[];
}

const BulletContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 0.25fr;
  justify-items: stretch;
  gap: 15;
  align-items: center;
  padding-bottom: 10;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const locations = [
  { label: "All", value: undefined },
  { label: "UK", value: "UK" },
  { label: "US", value: "US" },
  { label: "EU", value: "EU" },
  { label: "CA", value: "CA" },
];

export function BenefitsBullets({
  values,
  onChange,
  fieldName,
  errors,
  onBlur,
}: BulletsProps) {
  const handleChange = (index: number, bullet: Bullet) => {
    const newValue = [...values];
    newValue[index] = bullet;
    onChange(newValue);
  };

  const handleDelete = (index: number) => {
    const newValue = [...values];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  const handleAdd = () => {
    const newValue = [...values, { value: undefined, location: undefined }];
    onChange(newValue);
  };

  return (
    <Spacing size={-2}>
      {values.map((v, index) => (
        <BulletContainer key={index}>
          <DeleteButton
            onClick={() => handleDelete(index)}
            color={palette.brand.red}
            hoverColor={palette.extended.red.shade800}
            label="Delete benefit"
          />
          <div>
            <Textarea
              key={index}
              name={`${fieldName}`}
              value={v.value}
              data-testid={`${fieldName}-value`}
              onChange={e =>
                handleChange(index, {
                  location: v.location,
                  value: e.target.value,
                })
              }
              onBlur={onBlur}
            />
            <FieldErrors
              errors={
                errors && Array.isArray(errors) ? errors[index] : undefined
              }
            />
          </div>
          <div data-testid={`${fieldName}-select-wrapper`}>
            <SelectField
              inputId={`${fieldName}-select`}
              name="sectorTags"
              defaultValue={locations.find(l => l.value == v.location)}
              options={locations}
              onChange={e =>
                handleChange(index, { value: v.value, location: e?.value })
              }
            />
          </div>
        </BulletContainer>
      ))}

      <ButtonContainer>
        <Button
          level="secondary"
          size="regular"
          onClick={e => {
            e.preventDefault();
            handleAdd();
          }}
        >
          Add benefit
        </Button>
      </ButtonContainer>
    </Spacing>
  );
}
