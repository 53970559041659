import { FormFields } from "./types";

import { CompanyBenefitsFragment, CompanyInput } from "@hire/schema";

export const transformIn = (data: CompanyBenefitsFragment): FormFields => {
  return {
    otherBenefits: data.otherBenefits.map(b => ({
      value: b.value ?? undefined,
      location: b.location ?? undefined,
    })),
  };
};

export const transformOut = (formValues: FormFields): CompanyInput => {
  return {
    otherBenefits: formValues.otherBenefits
      .filter(b => b.value && b.value.trim() !== "")
      .map(b => ({
        value: b.value ?? null,
        location: b.location ?? null,
      })),
  };
};
