import { useEffect } from "react";
import styled from "@xstyled/styled-components";

import { pushAnalyticsEvent } from "@otta/analytics";
import { palette, pxToRem, borderRadius } from "@otta/design-tokens";
import { Text } from "@otta/design";

const WarningContainer = styled.div`
  background: ${palette.extended.orange.shade100};
  border-radius: ${pxToRem(borderRadius)};
  padding: lg;
`;
export const PublicSalaryWarning = ({
  decertificationDate,
}: {
  decertificationDate: string;
}): React.ReactElement => {
  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Company Recruiter Viewed",
      prompt: "Add a salary to remain certified",
    });
  }, []);
  return (
    <WarningContainer>
      <Text>
        Add public salaries to all jobs by <br />
        <strong>{decertificationDate}</strong> to stay certified
      </Text>
    </WarningContainer>
  );
};
