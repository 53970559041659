import styled from "@xstyled/styled-components";

import { Image } from "./icons/Image";
import { ImagePreview } from "./ImagePreview";
import { UploadedFile } from "./types";

import { Spinner } from "@otta/shared-components";
import { palette, pxToRem } from "@otta/design-tokens";
import { Spacing, Text } from "@otta/design";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14;
  margin-top: -4;
  svg {
    width: ${pxToRem(40)};
  }
`;

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: lg;
`;
export const DropzoneInner = ({
  uploading,
  nounPlural,
  nounSingular,
  images,
  maxImages,
  error,
  handleRemove,
}: {
  uploading: boolean;
  nounPlural: string;
  nounSingular: string;
  images: UploadedFile[];
  maxImages: number;
  error: boolean;
  handleRemove: (index: number) => void;
}) => {
  if (uploading) {
    return <Spinner />;
  }

  if (!images.length) {
    return (
      <EmptyStateContainer>
        <IconWrapper>
          <Image fill={palette.brand.black} />
        </IconWrapper>
        <Spacing>
          <Text
            align="center"
            color={error ? palette.brand.red : palette.brand.black}
          >
            Drag or browse to upload {maxImages > 1 ? nounPlural : nounSingular}
          </Text>
        </Spacing>
      </EmptyStateContainer>
    );
  }

  return (
    <ImagePreview
      images={images}
      nounSingular={nounSingular}
      removeFile={handleRemove}
    />
  );
};
