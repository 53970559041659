import { ImportedCandidateFragment } from "@hire/schema";

export const generateImportedCandidateWebsiteLinks = (
  importedCandidate: ImportedCandidateFragment | null
): { id: string; url: string }[] | undefined => {
  if (!importedCandidate) {
    return undefined;
  }

  const urls: string[] = [
    importedCandidate.attachmentUrl,
    importedCandidate.sourcedUrl,
    importedCandidate.portfolioUrl,
  ].filter(<T>(v: T): v is NonNullable<T> => v !== null && v !== undefined);

  return urls.map((url, index) => ({
    id: `${importedCandidate.externalId}-imported-${index}`,
    url,
  }));
};

export const getStageOptions = (
  stages: { id: string; name: string }[],
  applied: boolean
): { value: string; label: string }[] =>
  stages
    .map(({ id, name }) => ({ value: id, label: name }))
    .filter(({ label }) => {
      if (applied && label === "Outreach sent") {
        return false;
      }
      if (!applied && label === "Applied") {
        return false;
      }
      return true;
    });
