import styled, { down, css } from "@xstyled/styled-components";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";

interface CheckMarkProps {
  show?: boolean;
  checked?: boolean;
  handleChange: (checked: boolean) => void;
}

interface LabelProps {
  show?: boolean;
}

const Label = styled.label<LabelProps>`
  position: relative;
  padding-right: xl;
  cursor: pointer;

  input:checked ~ span {
    background-color: ${palette.brand.yellow};
  }

  input:checked ~ span:after {
    display: block;
  }

  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: default;

  ${down(
    "desktop",
    css`
      visibility: visible;
      opacity: 1;
    `
  )}
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const Box = styled.span`
  position: absolute;
  transform: translateY(-50%);

  height: ${pxToRem(17)};
  width: ${pxToRem(17)};
  border-radius: ${pxToRem(2)};

  background-color: ${palette.grayscale.shade200};

  &:after {
    content: "";
    position: absolute;
    display: none;

    left: ${modularScale(-6)};
    top: ${modularScale(-14)};
    width: ${modularScale(-8)};
    height: ${modularScale(-3)};
    border: solid ${palette.brand.black};
    border-width: 0 ${pxToRem(2)} ${pxToRem(2)} 0;
    transform: rotate(45deg);
  }

  &:before {
    content: "";
    position: absolute;
    cursor: pointer;

    border-radius: ${pxToRem(2)};

    height: ${pxToRem(25)};
    width: ${pxToRem(25)};
    left: -4px;
    top: -4px;
  }
`;

export const Checkbox = ({
  checked,
  handleChange,
  show,
}: CheckMarkProps): React.ReactElement => (
  <Label onClick={e => e.stopPropagation()} show={show}>
    <Input
      data-testid="select-item"
      type="checkbox"
      checked={checked}
      onChange={e => handleChange(e.target.checked)}
    />
    <Box />
  </Label>
);
