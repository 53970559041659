import { useCallback, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";

import { Button, InputField, Modal, Text, VerticalSpacing } from "@otta/design";
import {
  HybridWorkingDocument,
  UpdateCompanyDetailsDocument,
} from "@hire/schema";
import { hireAppUser } from "@hire/util/user";
import { pushAnalyticsEvent } from "@otta/analytics";
import { palette, pxToRem } from "@otta/design-tokens";
import { handleMutationError } from "@hire/errors";
import { useCompanyIsActive } from "@hire/hooks/useCompanyIsActive";

const storageKey = "days-in-office";

const Container = styled.div`
  padding: xl;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: sm;
  > button {
    width: 100%;
  }
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: sm;
  background-color: ${palette.brand.white};
`;

const validationSchema = Yup.object().shape({
  min: Yup.number()
    .typeError("Enter a number")
    .integer("Enter a whole number")
    .required("Enter a minimum value")
    .min(0, "Enter a number greater than or equal to 0")
    .max(5, "Enter a number less than or equal to 5"),
  max: Yup.number()
    .typeError("Enter a number")
    .integer("Enter a whole number")
    .nullable()
    .min(
      Yup.ref("min"),
      "Max value must be greater than or equal to minimum value"
    )
    .max(5, "Enter a number less than or equal to 5"),
});

export function HybridWorkingModal() {
  const [dismissed, setDismissed] = useState(false);

  const { data, loading } = useQuery(HybridWorkingDocument);

  const handleClose = useCallback(() => {
    setDismissed(true);
    localStorage.setItem(storageKey, "true");
    pushAnalyticsEvent({
      eventName: "Company Recruiter Viewed Announcement",
      announcementName: storageKey,
    });
  }, []);

  const [mutation] = useMutation(UpdateCompanyDetailsDocument, {
    onCompleted: handleClose,
    onError: handleMutationError,
  });

  const company = hireAppUser(data?.me)?.currentCompany;

  const alreadySeen = useRef(localStorage.getItem(storageKey)).current;

  const form = useFormik<{ min: number | null; max: number | null }>({
    initialValues: {
      min: null,
      max: null,
    },
    validationSchema,
    onSubmit: values => {
      if (company) {
        pushAnalyticsEvent({
          eventName: "Company Recruiter Submitted Days In Office via Modal",
        });
        mutation({
          variables: {
            id: company.id,
            input: {
              minDaysInOffice: values.min,
              maxDaysInOffice: values.max,
            },
          },
        });
      }
    },
  });

  const { active } = useCompanyIsActive();
  if (loading || alreadySeen || !company || !active) {
    return null;
  }

  const needsSetting = !company.minDaysInOffice && !company.maxDaysInOffice;

  return (
    <Modal
      open={needsSetting && !dismissed}
      dismissable={false}
      onOpenChange={o => {
        if (!o) {
          handleClose();
        }
      }}
    >
      <Container>
        <VerticalSpacing>
          <Text size={1} bold>
            Help candidates understand {company.name}&apos;s approach to hybrid
            working
          </Text>
          <Text>
            Add a global policy and we&apos;ll display this on your jobs. You
            can also set individual policies for each job.
          </Text>
          <Text>
            <Text as="span" bold>
              Candidates are 30% more likely to apply
            </Text>{" "}
            to companies who mention flexible working.
          </Text>

          <form onSubmit={form.handleSubmit}>
            <VerticalSpacing>
              <InputWrapper>
                <Text as="label" bold size={-2} htmlFor="min">
                  Minimum office days per week
                </Text>
                <div />
                <Text as="label" bold size={-2} htmlFor="max">
                  Maximum office days per week
                </Text>
                <InputField
                  id="min"
                  type="number"
                  name="min"
                  value={form.values.min?.toString() ?? ""}
                  onChange={form.handleChange("min")}
                  onBlur={form.handleBlur("min")}
                  error={form.touched.min ? form.errors.min : undefined}
                />
                <Text style={{ paddingTop: pxToRem(8) }}>-</Text>
                <InputField
                  id="max"
                  type="number"
                  name="max"
                  value={form.values.max?.toString() ?? ""}
                  onChange={form.handleChange("max")}
                  onBlur={form.handleBlur("max")}
                  error={form.touched.max ? form.errors.max : undefined}
                />
              </InputWrapper>
              <ButtonContainer>
                <Button level="primary" type="submit">
                  Submit
                </Button>
                <Button level="secondary" type="button" onClick={handleClose}>
                  Cancel
                </Button>
              </ButtonContainer>
            </VerticalSpacing>
          </form>
        </VerticalSpacing>
      </Container>
    </Modal>
  );
}
