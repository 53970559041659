import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

import { VisaSponsorshipForm } from "./Form";

import { handleMutationError } from "@hire/errors";
import {
  CompanyVisaSponsorshipDocument,
  CompanyInput,
  UpdateCompanyVisaSponsorshipDocument,
} from "@hire/schema";
import { Text, Spacing } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { CardContainer } from "@hire/components/containers/CardContainer";
import { ReloadableError } from "@hire/components/ReloadableError";

export const VisaSponsorship = () => {
  const { data, loading, refetch } = useQuery(CompanyVisaSponsorshipDocument);
  const [update] = useMutation(UpdateCompanyVisaSponsorshipDocument, {
    onError: handleMutationError,
  });

  const handleSave = useCallback(
    async (input: CompanyInput) => {
      if (!data?.currentUser?.currentCompany?.id) {
        return;
      }

      await update({
        variables: {
          id: data?.currentUser?.currentCompany?.id,
          input,
        },
      });
    },
    [data, update]
  );

  if (loading) {
    return <Loading />;
  }

  if (!data?.currentUser?.currentCompany) {
    return <ReloadableError action={refetch} />;
  }

  return (
    <Spacing>
      <Text size={2} bold>
        Visa sponsorship
      </Text>
      <Text>
        Select where you are able to sponsor visas. We won&apos;t show your jobs
        to candidates who require a visa for locations you don&apos;t sponsor.
      </Text>
      <CardContainer>
        <VisaSponsorshipForm
          data={data.currentUser.currentCompany}
          handleSave={handleSave}
        />
      </CardContainer>
    </Spacing>
  );
};
