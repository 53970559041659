import { useNavigate } from "react-router-dom";

import { YesNoComponent } from "./YesNoComponent";

import { Link } from "@hire/components/links";
import { Text } from "@otta/design";

export function AreYouHiringInAnyOfTheseLocations() {
  const navigate = useNavigate();
  const title = "Are you hiring in any of these locations?";
  const infoContent = (
    <Text>
      Only these roles will be posted. If you have questions,{" "}
      <Link style={{ fontWeight: "normal" }} to="mailto:companies@otta.com">
        contact us
      </Link>
      .
    </Text>
  );

  const bullets = [
    "UK - Remote or London",
    "EU - Remote, Amsterdam, Barcelona, Berlin, Dublin or Paris",
    "US - Remote, Austin, Boston, Chicago, Denver, Los Angeles, Miami, New York or San Francisco Bay Area",
    "Canada - Remote, Toronto or Vancouver",
  ];

  return (
    <YesNoComponent
      step={2}
      title={title}
      infoContent={infoContent}
      bullets={bullets}
      onYesClick={() => {
        navigate("/quiz/categories");
      }}
      onNoClick={() => {
        navigate("/quiz/unsupported");
      }}
    />
  );
}
