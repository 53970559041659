import { useFormik } from "formik";
import { useCallback, useMemo } from "react";
import * as Yup from "yup";

import { transformIn, transformOut } from "./transformers";
import { FormFields } from "./types";

import { CompanyExternalFragment, CompanyInput } from "@hire/schema";
import { MoreInfoFieldWrapper } from "@hire/components/form/MoreInfoFieldWrapper";
import { Button, InputField, Spacing } from "@otta/design";

const glassdoorRegex = /^.*glassdoor(.com|.co.uk).*$/;
const trustpilotRegex = /^.*trustpilot\.com.*$/;

const validationSchema = Yup.object().shape({
  trustpilotUrl: Yup.string()
    .url()
    .matches(trustpilotRegex, "Enter a valid Trustpilot URL")
    .optional(),
  glassdoorUrl: Yup.string()
    .url()
    .matches(glassdoorRegex, "Enter a valid Glassdoor URL")
    .optional(),
});

export const CompanyExternalLinksForm = ({
  data,
  handleSave,
}: {
  data: CompanyExternalFragment;
  handleSave: (input: CompanyInput) => void;
}) => {
  const initialValues = useMemo(() => transformIn(data), [data]);

  const onSubmit = useCallback(
    (values: FormFields) => {
      return handleSave(transformOut(values));
    },
    [handleSave]
  );

  const form = useFormik<FormFields>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <Spacing size={2}>
        <MoreInfoFieldWrapper
          label="Trustpilot URL"
          fieldName="company-trustpilot-url"
          optional
        >
          <InputField
            value={form.values.trustpilotUrl}
            name="company-trustpilot-url"
            onChange={form.handleChange("trustpilotUrl")}
            error={
              form.touched.trustpilotUrl ? form.errors.trustpilotUrl : undefined
            }
          />
        </MoreInfoFieldWrapper>
        <MoreInfoFieldWrapper
          label="Glassdoor URL"
          fieldName="company-glassdoor-url"
          optional
        >
          <InputField
            value={form.values.glassdoorUrl}
            name="company-glassdoor-url"
            onChange={form.handleChange("glassdoorUrl")}
            error={
              form.touched.glassdoorUrl ? form.errors.glassdoorUrl : undefined
            }
          />
        </MoreInfoFieldWrapper>
        <Button type="submit" level="primary" disabled={form.isSubmitting}>
          {form.isSubmitting ? "Saving..." : "Save changes"}
        </Button>
      </Spacing>
    </form>
  );
};
