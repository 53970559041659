import { FundingRound } from "../../types";

import { FundingRoundModal } from "./FundingRoundModal";
import { FundingRoundPreview } from "./FundingRoundPreview";

import { Spacing } from "@otta/design";
import { MoreInfoFieldWrapper } from "@hire/components/form/MoreInfoFieldWrapper";
import { EmptyList, ItemCardList } from "@hire/components/form/ItemCardList";
import { ListItemWrapper } from "@hire/components/form/ItemCard/styled-components";

export const FundingRoundSection = ({
  fundingRounds,
  onChange,
  allowUpdate,
}: {
  fundingRounds: FundingRound[];
  onChange: (value: FundingRound[]) => void;
  allowUpdate: boolean;
}) => {
  return (
    <MoreInfoFieldWrapper
      label="Funding rounds"
      fieldName="fundingRounds"
      guidance="The two most recent funding rounds are shown to candidates."
      optional
    >
      {allowUpdate ? (
        <ItemCardList
          data={fundingRounds}
          onChange={onChange}
          name="funding round"
          renderForm={props => <FundingRoundModal {...props} />}
          renderPreview={person => <FundingRoundPreview {...person} />}
          renderEmpty={() => (
            <EmptyList
              icon="salary"
              text="Add funding rounds to your profile"
            />
          )}
        />
      ) : (
        <Spacing size={1}>
          {fundingRounds.map(round => (
            <ListItemWrapper key={round.id} data-testid="funding-round">
              <FundingRoundPreview {...round} />
            </ListItemWrapper>
          ))}
        </Spacing>
      )}
    </MoreInfoFieldWrapper>
  );
};
