import * as React from "react";
import type { SVGProps } from "react";
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="m21.697 20.398-5.236-5.234c1.133-1.387 1.758-3.133 1.758-5.039A8.126 8.126 0 0 0 10.092 2C5.604 2 2 5.638 2 10.125s3.638 8.125 8.092 8.125a8.08 8.08 0 0 0 5.04-1.76l5.236 5.235c.219.185.461.275.7.275.238 0 .48-.092.663-.275a.913.913 0 0 0-.034-1.327ZM3.876 10.125a6.258 6.258 0 0 1 6.251-6.25 6.258 6.258 0 0 1 6.252 6.25 6.258 6.258 0 0 1-6.252 6.25 6.259 6.259 0 0 1-6.251-6.25Z"
    />
  </svg>
);
export default SvgSearch;
