import styled, { css, up } from "@xstyled/styled-components";

import { Step } from "../Steps";

import { Button, Middle, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links/Link";

const ButtonPostion = styled(Middle)`
  width: 100%;

  ${up(
    "tablet",
    css`
      width: auto;
    `
  )}
`;

interface IntegrationProps {
  handleIntegration(param: boolean): void;
}

export function AshbyEnableIntegration({
  handleIntegration,
}: IntegrationProps): React.ReactElement {
  return (
    <Spacing size={2}>
      <Step idx={1}>
        <Link
          newTab
          to="https://app.ashbyhq.com/admin/integrations/marketplace/otta"
        >
          Select Otta in the Integrations page in Ashby.
        </Link>
      </Step>
      <Step idx={2}>
        <Text>
          Select the{" "}
          <Text as="span" bold>
            Enable Otta
          </Text>{" "}
          button.
        </Text>
      </Step>
      <ButtonPostion>
        <Button
          onClick={() => handleIntegration(true)}
          type="button"
          level="primary"
          data-testid="add-integration-advance-button"
        >
          Continue
        </Button>
      </ButtonPostion>
    </Spacing>
  );
}
