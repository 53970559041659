import styled from "@xstyled/styled-components";
import { Link } from "react-router-dom";

import { palette, pxToRem, borderRadius } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Text } from "@otta/design";

const Container = styled(Link)`
  background: ${palette.brand.white};
  border-radius: ${pxToRem(borderRadius)};
  border: 1px solid ${palette.grayscale.shade400};
  padding: lg;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: lg;
  text-decoration: none;
  color: ${palette.brand.black};
  height: 100%;

  &:hover {
    background-color: ${palette.grayscale.shade50};
    border: 1px solid ${palette.brand.black};
  }
`;

export const AddNewJob = () => (
  <Container to="jobs/create">
    <Icon icon="circle-plus" size={4} />
    <Text bold>Add a new job</Text>
  </Container>
);
