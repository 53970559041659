import styled from "@xstyled/styled-components";

import {
  palette,
  modularScale,
  pxToRem,
  borderRadius,
} from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const Accordion = styled.div`
  height: ${modularScale(2)};
  width: 100%;
  background-color: ${palette.grayscale.shade200};
  border-radius: 0 0 ${pxToRem(borderRadius)} ${pxToRem(borderRadius)};
  display: flex;
  justify-content: center;
  &:hover {
    background-color: ${palette.grayscale.shade400};
  }
`;

const IconWrapper = styled.div`
  background-color: ${palette.brand.white};
  height: ${modularScale(4)};
  width: ${modularScale(4)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  top: -${modularScale()};
`;

interface CardAccordionProps {
  expanded: boolean;
}

export function CardAccordion({
  expanded,
}: CardAccordionProps): React.ReactElement {
  return (
    <Accordion>
      <IconWrapper>
        <Icon icon={expanded ? "chevron-up" : "chevron-down"} />
      </IconWrapper>
    </Accordion>
  );
}
