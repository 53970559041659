import styled from "@xstyled/styled-components";
import { useParams } from "react-router-dom";

import { SourcingPage } from "../enums";

import { Button, Text } from "@otta/design";
import { Link } from "@hire/components/links";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: sm;
`;

export function SourcingPageHeader({
  currentPage,
  numCandidates,
  loading,
  restoreCandidates,
}: {
  currentPage: SourcingPage;
  numCandidates: number;
  loading: boolean;
  restoreCandidates?: () => void;
}) {
  return (
    <Container>
      <Text size={1} bold>
        {!loading &&
          (currentPage === SourcingPage.Search
            ? `${numCandidates} relevant candidates`
            : currentPage === SourcingPage.Seen
            ? `${numCandidates} previously seen candidates`
            : currentPage === SourcingPage.Shortlist
            ? `${numCandidates} shortlisted candidates`
            : null)}
      </Text>
      <ButtonsContainer>
        {currentPage === SourcingPage.Seen && (
          <>
            {restoreCandidates && (
              <Button level="secondary" onClick={restoreCandidates}>
                Add all candidates back to talent pool
              </Button>
            )}
            <NavLinkButton type={SourcingPage.Search} />
          </>
        )}
        {currentPage === SourcingPage.Shortlist && (
          <NavLinkButton type={SourcingPage.Search} />
        )}
        {currentPage === SourcingPage.Search && (
          <>
            <NavLinkButton type={SourcingPage.Seen} />
            <NavLinkButton type={SourcingPage.Shortlist} />
          </>
        )}
      </ButtonsContainer>
    </Container>
  );
}

function NavLinkButton({ type }: { type: SourcingPage }) {
  const { companySlug, jobId } = useParams();

  return (
    <Button
      as={Link}
      level="secondary"
      to={`/${companySlug}/jobs/${jobId}/sourcing/${type}`}
      underline={false}
    >
      {type === SourcingPage.Search
        ? "View new candidates"
        : type === SourcingPage.Seen
        ? "View previously seen"
        : type === SourcingPage.Shortlist
        ? "View shortlist"
        : null}
    </Button>
  );
}
