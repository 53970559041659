import { useQuery } from "@apollo/client";

import { useAnalytics } from "@otta/shared-components";
import { hireAppUser } from "@hire/util/user";
import { CurrentUserQuery, CurrentUserDocument } from "@hire/schema";

type User = NonNullable<CurrentUserQuery["me"]>;

const getUserProperties = (user: User) => {
  const permittedUser = hireAppUser(user);

  return {
    jobPosting: permittedUser?.currentCompany?.scraperType ?? "direct",
    hasAtsIntegration: permittedUser?.currentCompany?.hasAtsIntegration,
    atsName: permittedUser?.currentCompany?.ats ?? undefined,
  };
};

export function useUserAnalytics(): void {
  const { data } = useQuery(CurrentUserDocument, { errorPolicy: "all" });

  return useAnalytics(data?.me, getUserProperties, undefined, {
    enabled: import.meta.env.PROD,
    pushToDataLayer: false,
  });
}
