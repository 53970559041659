import styled, { css, up } from "@xstyled/styled-components";
import { useState } from "react";
import { gql, useMutation, useLazyQuery } from "@apollo/client";

import { Step } from "../Steps";

import {
  UpsertCompanyAtsApiKeyDocument,
  ValidateAtsApiKeyDocument,
  CompanyAts,
  CompanyAtsApiKeyTypeEnum,
  AtsIntegrationDocument,
} from "@hire/schema";
import { Button, ErrorText, Input, Middle, Spacing, Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { CopyPasteMatrix } from "@hire/components/input/CopyPasteMatrix";
import { SelectBox } from "@hire/components/input/SelectBox";
import { CopyableField } from "@hire/components/input/CopyableField";
import { Link } from "@hire/components/links/Link";

const ButtonPostion = styled(Middle)`
  width: 100%;

  ${up(
    "tablet",
    css`
      width: auto;
    `
  )}
`;

interface HarvestAPIKeyProps {
  companyId: string;
}

export function HarvestAPIKey({
  companyId,
}: HarvestAPIKeyProps): React.ReactElement {
  const [apiKey, setApiKey] = useState<string | null>();

  const [validateKey, { called, data, loading: validationLoading }] =
    useLazyQuery(ValidateAtsApiKeyDocument, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        if (data.validateAtsApiKey.status === "OK") {
          upsertCompanyAtsApiKey();
        }
      },
    });

  const isValidKey = data?.validateAtsApiKey?.status === "OK";

  const [upsertCompanyAtsApiKey, { loading }] = useMutation(
    UpsertCompanyAtsApiKeyDocument,
    {
      variables: {
        companyId,
        type: CompanyAtsApiKeyTypeEnum.GreenhouseHarvest,
        key: apiKey as string,
      },
      update: (cache, { data: atsData }) => {
        if (!atsData?.upsertCompanyAtsApiKey) {
          return;
        }

        const cacheData = cache.readQuery({
          query: AtsIntegrationDocument,
        });

        const currentAtsApiKeys =
          cacheData?.currentUser?.currentCompany?.atsApiKeys ?? [];

        cache.writeFragment({
          id: cache.identify({ __typename: "Company", id: companyId }),
          fragment: gql`
            fragment CompanyApiKeys on Company {
              atsApiKeys
            }
          `,
          data: {
            atsApiKeys: [
              ...currentAtsApiKeys.filter(
                key => key.type !== CompanyAtsApiKeyTypeEnum.GreenhouseHarvest
              ),
              atsData.upsertCompanyAtsApiKey,
            ],
          },
        });
      },
    }
  );

  return (
    <Spacing size={2}>
      <Step idx={1}>
        <Link to="https://app.greenhouse.io/configure/dev_center/credentials">
          Open &apos;API Credentials&apos; in Greenhouse (you may need to sign
          in first)
        </Link>
      </Step>
      <Step idx={2}>
        <Text>
          Click the{" "}
          <Text as="span" bold>
            &apos;Create New API Key&apos;
          </Text>{" "}
          button
        </Text>
      </Step>
      <Step idx={3}>
        <CopyPasteMatrix
          helpText="Enter the following fields in the pop-up window."
          rows={[
            {
              label: "API Type",
              element: <SelectBox>Harvest</SelectBox>,
            },
            {
              label: "Partner",
              element: <CopyableField value="Otta" />,
            },
            {
              label: "Description",
              element: <CopyableField value="Otta Integration" />,
            },
          ]}
        />
      </Step>
      <Step idx={4}>
        <Text>
          Click the{" "}
          <Text as="span" bold>
            &apos;Manage Permissions&apos;
          </Text>{" "}
          button.
        </Text>
      </Step>
      <Step idx={5}>
        <Spacing size={-1}>
          <Text>
            Copy the API Key that was created from the box into this field:
          </Text>
          <Input
            value={apiKey || ""}
            onChange={e => setApiKey(e.target.value)}
            onBlur={() => setApiKey(apiKey?.trim() ?? null)}
            type="text"
            name="api-key"
            placeholder="Paste your API key here..."
            data-testid="input-api-key"
          />
        </Spacing>
      </Step>
      <Step idx={6}>
        <Text>
          Click the{" "}
          <Text as="span" bold>
            &apos;I have stored the API Key&apos;
          </Text>{" "}
          button.
        </Text>
      </Step>
      <Step idx={7}>
        <Text>
          Click the checkboxes for{" "}
          <Text as="span" bold>
            &apos;Job Stages&apos;
          </Text>{" "}
          and{" "}
          <Text as="span" bold>
            &apos;Sources&apos;
          </Text>
          .
        </Text>
      </Step>
      <Step idx={8}>
        <Text>
          Click the{" "}
          <Text as="span" bold>
            &apos;Save&apos;
          </Text>{" "}
          button.
        </Text>
      </Step>
      <ButtonPostion>
        <Button
          onClick={() =>
            validateKey({
              variables: {
                ats: CompanyAts.Greenhouse,
                atsApiKey: apiKey as string,
              },
            })
          }
          type="button"
          level="primary"
          data-testid="key-advance-button"
          disabled={!apiKey || loading || validationLoading}
        >
          Save and Continue
        </Button>
      </ButtonPostion>

      {called &&
        !isValidKey &&
        (!validationLoading ? (
          <ErrorText>
            That API key didn&apos;t work for us, make sure that you added the
            correct permissions for this key on Greenhouse.
          </ErrorText>
        ) : (
          <Loading />
        ))}
    </Spacing>
  );
}
