import styled, { css } from "@xstyled/styled-components";

import { Tipbox as DefaultTipbox } from "@otta/design";

const Tipbox = styled(DefaultTipbox)<{ thin: boolean }>`
  width: fit-content;

  ${({ thin }) =>
    thin &&
    css`
      padding: xs xs;
    `}
`;

export const ConditionalTipbox = ({
  condition,
  thin,
  children,
}: {
  condition: boolean;
  thin: boolean;
  children: (string | React.ReactElement)[] | (string | React.ReactElement);
}) => {
  if (condition) {
    return (
      <div>
        <Tipbox level={"error"} thin={thin}>
          {children}
        </Tipbox>
      </div>
    );
  } else {
    return <>{children}</>;
  }
};
