import { FormFields } from "./types";

import { CompanyDiversityFragment, CompanyInput } from "@hire/schema";
import { UPLOADED_IMAGE_BASE_URL } from "@hire/components/form/ImageUploadField";

export const transformIn = (data: CompanyDiversityFragment): FormFields => {
  return {
    diversityAuthorFullName: data.diversityAuthorFullName ?? undefined,
    diversityAuthorTitle: data.diversityAuthorTitle ?? undefined,
    diversityAuthorProfileImagePath:
      data.diversityAuthorProfileImagePath ?? undefined,
    diversityInitiatives: data.diversityInitiatives.map(d => ({
      value: d.value ?? undefined,
      order: d.order ?? undefined,
    })),
  };
};

export const transformOut = (formValues: FormFields): CompanyInput => {
  const { diversityInitiatives, diversityAuthorProfileImagePath, ...rest } =
    formValues;
  return {
    ...rest,
    diversityAuthorProfileImagePath: diversityAuthorProfileImagePath
      ? diversityAuthorProfileImagePath.replace(UPLOADED_IMAGE_BASE_URL, "")
      : null,
    diversityInitiatives: diversityInitiatives
      .filter(i => i.value && i.value.trim() !== "")
      .map((i, index) => ({
        order: index + 1,
        value: i.value ?? "",
      })),
  };
};
