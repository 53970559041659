import { useQuery } from "@apollo/client";
import { useMemo, useState } from "react";

import { SalaryVisibilityButton } from "./SalaryVisibilityButton";
import { SalaryVisibilityModal } from "./SalaryVisibilityModal";

import { CompanyRoleType, Feature, TeamMembersDocument } from "@hire/schema";
import { useAuthorizations } from "@hire/providers/authorization";
import { hireAppUser } from "@hire/util/user";
import { Experiment } from "@hire/constants/experiments";
import { useExperiment } from "@otta/experiments/client";

function useGetCompanyMembers() {
  const { data, loading } = useQuery(TeamMembersDocument);

  const user = hireAppUser(data?.me);

  const companyMembers = useMemo(() => {
    return (user?.currentCompany?.companyRecruiters ?? []).filter(
      recruiter => recruiter.role === CompanyRoleType.Member
    );
  }, [user?.currentCompany?.companyRecruiters]);

  return {
    currentUserId: user?.id,
    companyMembers,
    loading,
  };
}

interface CreateSalaryVisibilityFieldProps {
  hideSalary: boolean;
  handleFieldChanges: (fieldName: string, newValue: boolean | string[]) => void;
  salaryVisibleToIds: string[];
  thin?: boolean;
}

export default function ControlledSalaryVisibilityField({
  hideSalary,
  handleFieldChanges,
  salaryVisibleToIds,
  thin,
}: CreateSalaryVisibilityFieldProps): React.ReactElement | null {
  const [showModal, setShowModal] = useState(false);
  const { features } = useAuthorizations([
    Feature.HideSalaryFromColleagues,
    Feature.Certified,
  ]);

  const canHideSalaryFromColleagues = !!features.get(
    Feature.HideSalaryFromColleagues
  )?.granted;

  const { variant } = useExperiment(Experiment.OttaCertified);
  const isOttaCertified =
    !!features.get(Feature.Certified)?.granted && variant === "variant";

  const { currentUserId, companyMembers, loading } = useGetCompanyMembers();

  const hiddenFromCount = companyMembers.length
    ? companyMembers.length - salaryVisibleToIds.length
    : 0;

  const handleClose = () => {
    setShowModal(false);
  };

  const handleChangeMemberVisibility = (id: string) => {
    if (salaryVisibleToIds.includes(id)) {
      return;
    }

    handleFieldChanges("salaryVisibleToIds", [...salaryVisibleToIds, id]);
  };

  const handleToggleSalaryVisibility = (isVisible: boolean) => {
    handleFieldChanges("hideSalary", isVisible);
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <SalaryVisibilityButton
        hideSalary={hideSalary}
        canViewSalary
        thin={!!thin}
        canHideAllSalary={!!canHideSalaryFromColleagues}
        hiddenFromCount={hiddenFromCount}
        handleShowModal={() => setShowModal(true)}
      />
      <SalaryVisibilityModal
        companyMembers={companyMembers}
        currentUserId={currentUserId}
        isOttaCertified={isOttaCertified}
        hideSalary={hideSalary}
        visibleToIds={salaryVisibleToIds}
        canHideAllSalary={!!canHideSalaryFromColleagues}
        onToggleSalaryVisibility={newValue =>
          handleToggleSalaryVisibility(newValue)
        }
        onChangeMemberVisibility={handleChangeMemberVisibility}
        onClose={handleClose}
        isOpen={showModal}
      />
    </>
  );
}
