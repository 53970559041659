import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

import { CompanyExternalLinksForm } from "./Form";

import { handleMutationError } from "@hire/errors";
import {
  CompanyExternalDocument,
  CompanyInput,
  UpdateCompanyExternalDocument,
} from "@hire/schema";
import { Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { CardContainer } from "@hire/components/containers/CardContainer";
import { ReloadableError } from "@hire/components/ReloadableError";

export const ExternalLinks = () => {
  const { data, loading, refetch } = useQuery(CompanyExternalDocument);
  const [update] = useMutation(UpdateCompanyExternalDocument, {
    onError: handleMutationError,
  });

  const handleSave = useCallback(
    async (input: CompanyInput) => {
      if (!data?.currentUser?.currentCompany?.id) {
        return;
      }

      await update({
        variables: {
          id: data?.currentUser?.currentCompany?.id,
          input,
        },
      });
    },
    [data, update]
  );

  if (loading) {
    return <Loading />;
  }

  if (!data?.currentUser?.currentCompany) {
    return <ReloadableError action={refetch} />;
  }

  return (
    <>
      <Text bold size={2}>
        External links
      </Text>
      <CardContainer>
        <CompanyExternalLinksForm
          data={data.currentUser.currentCompany}
          handleSave={handleSave}
        />
      </CardContainer>
    </>
  );
};
