import { useId } from "react";
import styled from "@xstyled/styled-components";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";
import { Text } from "@otta/design";

const RadioButton = styled.span`
  height: ${modularScale(1)};
  width: ${modularScale(1)};
  border-radius: 50%;
  background-color: ${palette.grayscale.shade200};
  margin-right: sm;
  cursor: pointer;

  &:hover {
    background-color: ${palette.grayscale.shade400};
  }
`;

const Label = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  &:hover span {
    background-color: ${palette.grayscale.shade400};
  }
  input:checked ~ span {
    background-color: ${palette.brand.black};
    border: ${pxToRem(5.5)} solid ${palette.brand.yellow};
  }
`;

const InvisibleRadio = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
`;

const StyledText = styled(Text)`
  display: inline;
  margin-right: 19;
`;

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  size?: number;
  name: string;
  label: string;
}

export function Radio({
  label,
  name,
  size = 0,
  ...rest
}: RadioProps): React.ReactElement {
  const id = useId();
  return (
    <Label htmlFor={id}>
      <InvisibleRadio {...rest} type="radio" id={id} name={name} />
      <RadioButton />
      <StyledText size={size}>{label}</StyledText>
    </Label>
  );
}
