import styled, { css, down } from "@xstyled/styled-components";

import {
  getSalaryStatus,
  getResponseRateStatus,
  getDecertificationDate,
} from "..";

import { Checked } from "@hire/components/icons/checklist/Checked";
import { Unchecked } from "@hire/components/icons/checklist/Unchecked";
import { Warning } from "@hire/components/icons/checklist/Warning";
import { ATSNames } from "@hire/globalConstants";
import { DashboardFragment, Feature } from "@hire/schema";
import { palette, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { VerticalSpacing, Text, Button, Modal, Heading } from "@otta/design";
import { ReachMoreCandidatesImage } from "@hire/components/images/ReachMoreCandidatesImage";
import { StandoutImage } from "@hire/components/images/StandoutImage";
import { ApplyNowImage } from "@hire/components/images/ApplyNowImage";
import { ConditionalLink as DefaultLink } from "@hire/components/links/ConditionalLink";
import { useAuthorizations } from "@hire/providers/authorization";

const OttaCertifiedWrapper = styled.div`
  left: ${pxToRem(10)};
  top: 0;
  position: absolute;
  margin-top: -7;
`;

const ConditionalLink = styled(DefaultLink)`
  width: 100%;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${palette.brand.white};
  position: relative;
`;

const Container = styled.div`
  padding: xl;
`;

const ImageWrapper = styled.div`
  margin-top: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;

  justify-items: center;
  text-align: center;
  align-items: center;

  gap: lg;
  grid-auto-flow: column;

  ${down(
    "tablet",
    css`
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;

      div:nth-child(3) {
        grid-column: 1 / span 2;
        grid-row: 2;
      }
    `
  )}
`;

const Checklist = styled.div<{ showAtsCard: boolean }>`
  display: grid;
  gap: sm;

  grid-template-columns: ${({ showAtsCard }) =>
    showAtsCard ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"};

  justify-items: center;
  text-align: center;
  align-items: center;

  ${down(
    "tablet",
    css`
      grid-template-columns: 1fr;
    `
  )}
`;

const ChecklistItem = styled.div<{ clickable?: boolean }>`
  background-color: ${palette.grayscale.shade50};
  gap: sm;
  padding: sm;
  border-radius: ${pxToRem(8)};
  justify-items: center;
  height: 100%;

  display: flex;

  ${down(
    "tablet",
    css`
      width: 100%;
    `
  )}
  border-bottom: ${pxToRem(4)} solid transparent;

  ${({ clickable }) =>
    clickable &&
    `
      &:hover {
        background-color: ${palette.beige.shade100};
      }`};
`;

const CheckboxIconWrapper = styled.div`
  width: ${pxToRem(18)};
  height: ${pxToRem(18)};
  flex-shrink: 0;
`;

const ImageContainer = styled.div`
  display: grid;
  height: 100%;
  justify-items: center;
  align-items: end;
`;

interface OttaCertifiedModalProps {
  currentCompany: DashboardFragment;
  handleClose: () => void;
  open: boolean;
}

type Item = {
  tip: string;
  description: string;
  checked: boolean;
  path?: string;
  testId: string;
  warning?: boolean;
};

function Checkboxes({
  items,
  showAtsCard,
}: {
  items: Item[];
  showAtsCard: boolean;
}) {
  return (
    <Checklist showAtsCard={showAtsCard} data-testid="modal-checklist">
      {items.map((item: Item) => (
        <ConditionalLink
          key={item.tip}
          link={item.path as string}
          condition={!!item.path && !item.checked}
        >
          <ChecklistItem
            key={item.tip}
            clickable={!!item.path && !item.checked}
            data-testid={item.testId}
          >
            <CheckboxIconWrapper>
              {item.warning ? (
                <Warning data-testid="warning" />
              ) : item.checked ? (
                <Checked data-testid="checked" />
              ) : (
                <Unchecked data-testid="unchecked" />
              )}
            </CheckboxIconWrapper>
            <VerticalSpacing>
              <Text align="left" bold size={-1}>
                {item.tip}
              </Text>
              <Text align="left" size={-1}>
                {item.description}
              </Text>
            </VerticalSpacing>
          </ChecklistItem>
        </ConditionalLink>
      ))}
    </Checklist>
  );
}

export function OttaCertifiedModal({
  currentCompany,
  handleClose,
  open,
}: OttaCertifiedModalProps): React.ReactElement {
  const {
    responseRate,
    jobsWithSalaryStats,
    ats,
    numberOfEndorsements,
    oldestLiveJobWithoutPublicSalary,
  } = currentCompany;

  const { features } = useAuthorizations([
    Feature.Certified,
    Feature.Scraped,
    Feature.AtsIntegration,
  ]);

  const salaryStatus = getSalaryStatus(jobsWithSalaryStats);
  const responseRateStatus = getResponseRateStatus(responseRate);

  const ottaCertified = features.get(Feature.Certified)?.granted;

  const decertificationDate =
    ottaCertified && oldestLiveJobWithoutPublicSalary
      ? getDecertificationDate(
          oldestLiveJobWithoutPublicSalary.firstLiveAt ??
            oldestLiveJobWithoutPublicSalary.insertedAt
        )
      : undefined;

  const Checklist: Item[] = [
    {
      tip:
        salaryStatus.quadrant === 4
          ? "All of your jobs have public salaries"
          : "Add public salaries to your jobs",
      description: decertificationDate
        ? `Add public salaries to all jobs by ${decertificationDate}`
        : "You'll have 7 days to update new jobs",
      checked: salaryStatus.quadrant === 4,
      path: "./jobs",
      testId: "job-salary-criteria",
      warning: !!decertificationDate,
    },

    {
      tip:
        responseRateStatus.quadrant === 4
          ? "Most candidates hear back"
          : "Respond to most candidates",
      description: "We monitor your 2 week response rate",
      checked: responseRateStatus.quadrant === 4,
      path: !features.get(Feature.Scraped)?.granted ? "./jobs" : undefined,
      testId: "response-rate-criteria",
    },
    {
      tip:
        numberOfEndorsements >= 3
          ? "You have 3+ endorsements"
          : "Get three endorsements",
      description: "Show why you're a great place to work",
      checked: numberOfEndorsements >= 3,
      path: "./profile/company/endorsements",
      testId: "endorsements-criteria",
    },
    ...(ats && features.get(Feature.AtsIntegration)?.granted
      ? [
          {
            tip:
              features.get(Feature.AtsIntegration)?.message == null
                ? `${ATSNames[ats]} ATS Connected`
                : `Connect ${ATSNames[ats]} ATS`,
            description: "Enable easy applications",
            checked: !!features.get(Feature.AtsIntegration)?.message,
            path: "./profile/company/ats-integration",
            testId: "ats-integration-criteria",
          },
        ]
      : []),
  ];

  return (
    <Modal
      open={open}
      onOpenChange={handleClose}
      style={{ maxWidth: pxToRem(800) }}
      headerContent={
        <>
          <OttaCertifiedWrapper>
            <Icon icon="certified" data-testid="otta-certified-icon" size={7} />
          </OttaCertifiedWrapper>
          <Heading size={1} style={{ marginLeft: "3rem" }}>
            {ottaCertified ? "You're Otta Certified" : "Get Otta Certified"}
          </Heading>
        </>
      }
    >
      <Container data-testid="otta-certified-modal">
        <VerticalSpacing size={2}>
          {!ottaCertified && (
            <Text>
              We&apos;re shining a spotlight on companies that put candidates
              first. Hire exceptional talent by being exceptional at hiring:
            </Text>
          )}
          <Checkboxes items={Checklist} showAtsCard={!!ats} />
          <Text>
            {ottaCertified
              ? `Because you've demonstrated that you put candidates first, we're helping you:`
              : `In return, we'll help you:`}
          </Text>
          <ImageWrapper>
            <ImageContainer>
              <ReachMoreCandidatesImage />
              <Text bold>Reach more candidates</Text>
            </ImageContainer>

            <ImageContainer>
              <StandoutImage />
              <Text bold>Stand out from the crowd</Text>
            </ImageContainer>

            <ImageContainer>
              <ApplyNowImage />
              <Text bold>Engage more candidates</Text>
            </ImageContainer>
          </ImageWrapper>
          <ButtonWrapper>
            <Button
              level="primary"
              type="button"
              as={"a"}
              data-analytics-id="learn-more-about-otta-certified"
              href="https://otta.com/employers/certified"
              target="_blank"
            >
              Learn more about Otta Certified
            </Button>
          </ButtonWrapper>
        </VerticalSpacing>
      </Container>
    </Modal>
  );
}
