import styled from "@xstyled/styled-components";
import { Navigate } from "react-router-dom";

import { TabLink } from "@hire/components/links";
import { useAuthorizations } from "@hire/providers/authorization";
import { Feature } from "@hire/schema";

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 19;
`;

const LINKS = [
  {
    to: "sourcing",
    label: "Sourcing",
    feature: Feature.ViewSourcingTemplates,
  },
  {
    to: "application",
    label: "Application",
    feature: Feature.ViewRejectionTemplates,
  },
];

const FEATURES = LINKS.map(l => l.feature);

function useTemplateTabs() {
  const { features } = useAuthorizations(FEATURES);

  return LINKS.filter(l => features.get(l.feature)?.granted);
}

export const Tabs = () => {
  const linksToShow = useTemplateTabs();

  if (linksToShow.length < 2) {
    return null;
  }

  return (
    <TabsContainer data-testid="template-tabs">
      {linksToShow.map(l => (
        <TabLink
          key={l.to}
          role="link"
          size={1}
          to={`../${l.to}`}
          relative="path"
        >
          {l.label}
        </TabLink>
      ))}
    </TabsContainer>
  );
};

export const TemplateIndex = () => {
  const [firstLink] = useTemplateTabs();

  if (!firstLink) {
    return <Navigate to=".." replace />;
  }

  return <Navigate to={firstLink.to} replace />;
};
