import styled from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { useMemo, useState } from "react";

import { LightningBolt } from "./LightningBolt";

import { modularScale, palette, pxToRem } from "@otta/design-tokens";
import { Button, Card, Clickable, Modal, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links";
import { AllTemplatesDocument, MessageTemplateTypeEnum } from "@hire/schema";
import { Loading } from "@otta/shared-components";

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  gap: sm;
`;

const ModalContent = styled.div`
  padding: lg 0;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10;
  box-shadow: 0 ${pxToRem(-6)} ${pxToRem(6)} ${palette.grayscale.shade50};
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  gap: lg;
`;

const ScrollableSection = styled.div`
  overflow-y: auto;
  padding: lg;
  flex: 1;
`;

function MessageTemplatePreview({
  name,
  message,
  onClick,
}: {
  name: string;
  message: string;
  onClick(): void;
}): React.ReactElement {
  const [expanded, setExpanded] = useState(false);

  return (
    <Spacing size={-4}>
      <Heading>
        <Text as={Clickable} onClick={onClick} style={{ fontWeight: 700 }}>
          {name}
        </Text>

        <Text
          as={Clickable}
          onClick={() => {
            setExpanded(prevState => !prevState);
          }}
          size={-1}
          bold
        >
          {expanded ? "Hide" : "Show"}
        </Text>
      </Heading>

      {expanded && (
        <Card style={{ backgroundColor: palette.grayscale.shade50 }}>
          <Text style={{ overflowWrap: "break-word", whiteSpace: "pre-line" }}>
            {message}
          </Text>
        </Card>
      )}
    </Spacing>
  );
}

export function TemplatePicker({
  type,
  open,
  onOpenChange,
  onPick,
  onNew,
}: {
  type: MessageTemplateTypeEnum;
  open: boolean;
  onOpenChange(open: boolean): void;
  onPick(message: string): void;
  onNew(): void;
}) {
  const { data, loading } = useQuery(AllTemplatesDocument, {
    variables: { messageTemplateType: type },
  });

  const templates = useMemo(
    () => data?.messageTemplates ?? [],
    [data?.messageTemplates]
  );

  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      scrollable={false}
      headerContent={
        <ModalHeader>
          <LightningBolt style={{ width: 20 }} />
          <Text size={1} bold>
            Application templates
          </Text>
        </ModalHeader>
      }
    >
      <ModalContent>
        <Text
          size={-1}
          style={{
            paddingInline: modularScale(),
            paddingBottom: modularScale(),
          }}
        >
          <Link to="/profile/templates/rejection">
            Manage templates from your profile.
          </Link>{" "}
          You can also make changes to the message after you&apos;ve selected a
          template.
        </Text>
        {loading ? (
          <Loading />
        ) : (
          <>
            <ScrollableSection>
              <Spacing size={4}>
                {templates.map(template => (
                  <MessageTemplatePreview
                    key={template.id}
                    name={template.name}
                    message={template.message}
                    onClick={() => {
                      onPick(template.message);
                    }}
                  />
                ))}
              </Spacing>
            </ScrollableSection>

            <ButtonWrapper>
              <Button level="secondary" type="button" onClick={onNew}>
                Create new template
              </Button>
            </ButtonWrapper>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
