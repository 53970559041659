import { useState } from "react";

import { DefaultTemplateBadge } from "./DefaultTemplateBadge";
import {
  TemplateContainer,
  TemplateBodyText,
  TemplateExpandToggle,
} from "./shared-styled-components";
import { ActionButtons } from "./ActionButtons";

import { Icon } from "@otta/icons";
import { Text, Spacing } from "@otta/design";

const TRUNCATED_CHARACTER_LIMIT = 250;

const truncateMessage = (message: string) => {
  if (message.length <= TRUNCATED_CHARACTER_LIMIT) {
    return message;
  }

  return `${message
    .substring(0, TRUNCATED_CHARACTER_LIMIT)
    .replace(/,\s*$/, "")
    .trimEnd()}...`;
};

export const ViewCard = ({
  onDelete: handleDelete,
  onUpdate: handleUpdate,
  message,
  cardHeading,
  isOttaTemplate,
}: {
  onDelete: () => void;
  onUpdate: () => void;
  message: string;
  cardHeading: string;
  isOttaTemplate: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);
  const truncatedMessage = truncateMessage(message);
  const showToggle = message !== truncatedMessage;

  return (
    <TemplateContainer data-testid="template-card">
      <ActionButtons onDelete={handleDelete} onUpdate={handleUpdate} />
      <Spacing>
        <Text bold as="h3">
          {cardHeading}&nbsp;{isOttaTemplate && <DefaultTemplateBadge />}
        </Text>
        <TemplateBodyText>
          {expanded ? message : truncatedMessage}
        </TemplateBodyText>
      </Spacing>
      {showToggle && (
        <TemplateExpandToggle
          aria-label={`Show more: ${name}`}
          onClick={() => setExpanded(!expanded)}
        >
          show {expanded ? "less" : "more"}
          <Icon icon={`chevron-${expanded ? "up" : "down"}-filled`} />
        </TemplateExpandToggle>
      )}
    </TemplateContainer>
  );
};
