import styled from "@xstyled/styled-components";

import { CandidateFragment } from "@hire/schema";
import { Text, VerticalSpacing } from "@otta/design";
import { HighlightableText } from "@hire/components/HighlightableText";
import { Link } from "@hire/components/links/Link";

const DescriptionContainer = styled.div`
  display: flex;
  gap: md;
`;

const ImageWrapper = styled.div`
  flex: 0 0 150px;
`;

const Image = styled.img`
  object-fit: contain;
  border-radius: 8px;
  width: 100%;
`;

interface CandidateSnippetProps {
  snippet: CandidateFragment["projects"][0];
}

export function CandidateSnippet({
  snippet: { title, projectUrl, description, imagePath },
}: CandidateSnippetProps): React.ReactElement {
  return (
    <VerticalSpacing size={-3}>
      {projectUrl ? (
        <Link to={projectUrl} newTab>
          <Text bold>
            <HighlightableText>{title}</HighlightableText>
          </Text>
        </Link>
      ) : (
        <Text bold>
          <HighlightableText>{title}</HighlightableText>
        </Text>
      )}

      {description && (
        <DescriptionContainer>
          <Text size={-1}>
            <HighlightableText>{description}</HighlightableText>
          </Text>
          {imagePath && (
            <ImageWrapper>
              <Image src={imagePath} alt="upload" />
            </ImageWrapper>
          )}
        </DescriptionContainer>
      )}
    </VerticalSpacing>
  );
}
