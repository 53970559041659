import styled from "@xstyled/styled-components";
import { addDays, format } from "date-fns";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Text } from "@otta/design";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";

const NewMessageSymbol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${modularScale(-1)};
  font-size: ${modularScale(-1)};
  color: ${palette.extended.orange.shade400};
`;

const DeliveredSymbol = styled(Icon)<{ $read: boolean }>`
  display: inline-block;
  margin-left: 6;
  stroke: ${({ $read }) =>
    $read ? palette.brand.green : palette.grayscale.shade600};
  height: ${modularScale(-2)};
  width: auto;
`;

const StyledTooltipWithIcon = styled(TooltipWithIcon)`
  padding: sm;
`;

const ReplyByTag = styled.div<{ isOttaCertified: boolean }>`
  background: ${palette.extended.orange.shade100};
  display: flex;
  white-space: nowrap;
  border-radius: ${pxToRem(4)};
  padding: ${({ isOttaCertified }) =>
    isOttaCertified
      ? `0 ${pxToRem(6)} ${pxToRem(1)} ${pxToRem(4)}`
      : `${pxToRem(3)} ${pxToRem(8)}`};
`;

const OttaCertifiedWrapper = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

const ReplyByText = styled(Text)<{ isOttaCertified: boolean }>`
  font-size: ${modularScale(-2)};
  margin-top: ${({ isOttaCertified }) => (isOttaCertified ? pxToRem(4) : 0)};
  font-weight: bold;
`;

interface ConversationListItemNotificationProps {
  recruiterRead: boolean | undefined | null;
  read?: boolean;
  sentByCompany?: boolean;
  isNewApplication: boolean;
  daysSinceLastContact: number;
  ottaCertified: boolean;
  companyResponseRate: string;
}

export function ConversationListItemNotification({
  recruiterRead,
  read,
  sentByCompany,
  isNewApplication,
  daysSinceLastContact,
  ottaCertified,
  companyResponseRate,
}: ConversationListItemNotificationProps): React.ReactElement | null {
  const companyHasHighResponseRate = parseFloat(companyResponseRate) >= 0.8;
  const showReplyTag =
    isNewApplication &&
    !recruiterRead &&
    daysSinceLastContact >= 7 &&
    daysSinceLastContact < 14;

  const daysUntilResponseRateImpact = 14 - daysSinceLastContact;
  const replyByDate = format(
    addDays(new Date(), daysUntilResponseRateImpact),
    "dd MMM"
  );

  if (showReplyTag) {
    return (
      <StyledTooltipWithIcon
        content={`Reply by ${replyByDate} to ${
          companyHasHighResponseRate ? "maintain" : "improve"
        } your response rate`}
        icon={
          <ReplyByTag isOttaCertified={ottaCertified}>
            {ottaCertified && (
              <OttaCertifiedWrapper>
                <Icon icon="certified" />
              </OttaCertifiedWrapper>
            )}

            <ReplyByText isOttaCertified={ottaCertified}>
              Reply by {replyByDate}
            </ReplyByText>
          </ReplyByTag>
        }
      />
    );
  }
  if (!recruiterRead) {
    return (
      <StyledTooltipWithIcon
        content="Awaiting your response"
        icon={
          <NewMessageSymbol data-testid="new-message-symbol">
            ●
          </NewMessageSymbol>
        }
      />
    );
  }
  if (!sentByCompany) {
    return null;
  }
  return (
    <StyledTooltipWithIcon
      content={read ? "Read" : "Sent"}
      icon={
        <DeliveredSymbol
          icon="checkmark-double"
          data-testid="delivered-symbol"
          $read={!!read}
        />
      }
    />
  );
}
