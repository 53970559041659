import { FormFields } from "./types";

import { CompanyVisaSponsorshipFragment, CompanyInput } from "@hire/schema";

export const transformIn = (
  data: CompanyVisaSponsorshipFragment
): FormFields => {
  return {
    visaSponsorshipCountries: (data.visaSponsorshipCountries ?? []).map(
      vsc => ({
        location: vsc.location,
        regionId: vsc.region.id,
        offersVisa: vsc.offersVisa,
      })
    ),
  };
};

export const transformOut = (formValues: FormFields): CompanyInput => {
  return {
    visaSponsorshipCountries: formValues.visaSponsorshipCountries,
  };
};
