import { palette } from "@otta/design-tokens";

export function Warning(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" {...props}>
      <rect
        x="0.833496"
        y="0.5"
        width="18"
        height="18"
        rx="9"
        fill={palette.extended.orange.shade400}
      />
      <g clipPath="url(#clip0_831_1115)">
        <path
          d="M11.7085 13.5572C11.7085 14.5897 10.8674 15.4297 9.8335 15.4297C8.79961 15.4297 7.9585 14.5897 7.9585 13.5572C7.9585 12.5246 8.79961 11.6846 9.8335 11.6846C10.8674 11.6846 11.7085 12.5246 11.7085 13.5572ZM8.07591 4.13203L8.37948 10.1955C8.39372 10.4802 8.62908 10.7038 8.91452 10.7038H10.7525C11.0379 10.7038 11.2733 10.4802 11.2875 10.1955L11.5911 4.13203C11.6064 3.82645 11.3624 3.57031 11.056 3.57031H8.61095C8.30457 3.57031 8.06059 3.82645 8.07591 4.13203Z"
          fill={palette.extended.orange.shade800}
        />
      </g>
      <defs>
        <clipPath id="clip0_831_1115">
          <rect width="12" height="12" transform="translate(3.8335 3.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
