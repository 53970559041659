import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";

import {
  AllTemplatesDocument,
  CreateMessageTemplateDocument,
  MessageTemplateTypeEnum,
} from "@hire/schema";
import { Modal, Text } from "@otta/design";
import { MessageTemplateForm } from "@hire/pages/CompanySettings/Templates/MessageTemplateForm";
import { handleMutationError } from "@hire/errors";

const ModalContent = styled.div`
  padding: lg;
`;

interface CreateTemplateProps {
  type: MessageTemplateTypeEnum;
  open: boolean;
  onOpenChange(open: boolean): void;
  onCancel(): void;
  onSave(message: string): void;
}

export function CreateTemplate({
  type,
  open,
  onOpenChange,
  onSave,
  onCancel,
}: CreateTemplateProps) {
  const [createTemplate] = useMutation(CreateMessageTemplateDocument, {
    onError: handleMutationError,
    refetchQueries: [
      { query: AllTemplatesDocument, variables: { messageTemplateType: type } },
    ],
  });

  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      headerContent={
        <Text bold size={1}>
          Create a new template
        </Text>
      }
    >
      <ModalContent>
        <MessageTemplateForm
          onSave={async ({ name, message }) => {
            await createTemplate({ variables: { type, message, name } });
            onSave(message);
          }}
          onClose={onCancel}
        />
      </ModalContent>
    </Modal>
  );
}
