import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { useJobInternalId } from "../hooks/useJobInternalId";
import { useStages } from "../hooks/useStages";

import { Form } from "./Form";

import { handleMutationError } from "@hire/errors";
import {
  CreatePipelineStageDocument,
  GetJobPipelineDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { modularScale } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Text, VerticalSpacing } from "@otta/design";
import { useRequiredParams } from "@hire/util/routing";

const CloseButton = styled.button`
  display: flex;
  background: none;
  border: none;
  padding: 0;
  font-size: ${modularScale()};
  cursor: pointer;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddNewStage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { jobId: jobExternalId } = useRequiredParams(["jobId"]);
  const { jobInternalId, loading: jobIdLoading } =
    useJobInternalId(jobExternalId);

  const { stages, loading: stagesLoading } = useStages(jobExternalId);
  const stageOptions = stages
    .filter(({ name }) => name !== "Outreach sent")
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }));

  const [createPipelineStage] = useMutation(CreatePipelineStageDocument, {
    refetchQueries: [
      {
        query: GetJobPipelineDocument,
        variables: { jobId: jobExternalId, workExperienceLimit: 1 },
        fetchPolicy: "network-only",
      },
    ],
    onError: handleMutationError,
  });

  const handleCreateStage = async ({
    name,
    positionAfterStageId,
  }: {
    name: string;
    positionAfterStageId: string;
  }) => {
    await createPipelineStage({
      variables: {
        jobId: jobInternalId,
        insertAfterStageId: positionAfterStageId,
        name: name.trim(),
        workExperienceLimit: 1,
      },
    });
    navigate("..");
  };

  const loading = stagesLoading || jobIdLoading;

  return (
    <VerticalSpacing size={3}>
      <HeadingContainer data-testid="create-stage-drawer">
        <Text size={3} bold>
          Add a stage
        </Text>
        <CloseButton
          data-testid="close-button"
          onClick={() => {
            navigate("..");
          }}
        >
          <Icon icon="close" />
        </CloseButton>
      </HeadingContainer>
      <Text>
        Create a new stage for your hiring process. You can edit the stage name
        after you&apos;ve created it
      </Text>
      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={handleCreateStage} stageOptions={stageOptions} />
      )}
    </VerticalSpacing>
  );
};
