import { useState } from "react";

import { Step } from "../Steps";

import { Button, ErrorText, Middle, Spacing } from "@otta/design";
import { CopyPasteMatrix } from "@hire/components/input/CopyPasteMatrix";
import { SelectBox } from "@hire/components/input/SelectBox";
import { CopyableField } from "@hire/components/input/CopyableField";
import { Link } from "@hire/components/links/Link";

interface Props {
  first?: boolean;
  webhook: {
    key: string;
    requestedTopic: string | null;
    secretKey: string | null;
    lastRequestedAt: string | null;
  };
  loading: boolean;
  refetch(): Promise<unknown>;
}

export function GreenhouseWebhook({
  first,
  webhook,
  loading,
  refetch,
}: Props): React.ReactElement {
  const [progressAttempts, setProgressAttempts] = useState(0);

  const attemptProgress = async () => {
    await refetch();
    setProgressAttempts(progressAttempts + 1);
  };

  return (
    <Spacing size={2}>
      <Step idx={1}>
        <Link to="https://app.greenhouse.io/web_hooks">
          {first
            ? "Open the Greenhouse webhooks form"
            : "Add another webhook from the Greenhouse form"}
        </Link>
      </Step>
      <Step idx={2}>
        <CopyPasteMatrix
          helpText="Copy the following fields into Greenhouse."
          rows={[
            {
              label: "Name this web hook",
              element: (
                <CopyableField
                  value={`Otta Webhook: ${webhook.requestedTopic}`}
                />
              ),
            },
            {
              label: "When",
              element: <SelectBox>{webhook.requestedTopic}</SelectBox>,
            },
            {
              label: "Endpoint URL",
              element: (
                <CopyableField
                  value={`https://api.otta.com/hooks/${webhook.key}`}
                />
              ),
            },
            {
              label: "Secret Key",
              element: <CopyableField value={`${webhook.secretKey}`} />,
            },
          ]}
        />
      </Step>
      <Step idx={3}>
        Click the <b>&apos;Create Web Hook&apos;</b> button
      </Step>
      {progressAttempts > 0 && (
        <ErrorText data-testid="webhook-fail-message">
          We didn&apos;t get a signal, check you&apos;ve entered the fields
          correctly and saved the webhook.
        </ErrorText>
      )}
      <Middle>
        <Button
          onClick={attemptProgress}
          type="button"
          level="primary"
          data-testid="webhook-advance-button"
          disabled={loading}
        >
          {loading
            ? "Checking webhook..."
            : progressAttempts > 0
            ? "Try again"
            : "I've done this"}
        </Button>
      </Middle>
    </Spacing>
  );
}
