import styled from "@xstyled/styled-components";

import { CompanyEmployeeEndorsementsFragment } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { Spacing, Text } from "@otta/design";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

const HighlightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;
const TagsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: md;
`;
export const EndorsementHighlights = ({
  company,
}: {
  company: CompanyEmployeeEndorsementsFragment;
}) => {
  return (
    <HighlightsContainer data-testid="endorsement-highlights-section">
      <Spacing size={1}>
        <Spacing size={-5}>
          <Text size={1} bold>
            Endorsement highlights
          </Text>
          <Text size={-1} color={palette.grayscale.shade600}>
            Visible on your job descriptions
          </Text>
        </Spacing>
        <TagsWrapper>
          {company.responseCountByCategory.map(
            ({ id, categoryName, count }) => (
              <div key={id} style={{ justifySelf: "start" }}>
                <RoundedTag
                  content={categoryName}
                  color={palette.brand.yellow}
                  rightSectionContent={count > 1 ? count : undefined}
                />
              </div>
            )
          )}
        </TagsWrapper>
      </Spacing>
    </HighlightsContainer>
  );
};
