import { ExternalLinkCue } from "../types/ExternalLink";
import { InternalLinkCue } from "../types/InternalLink";
import { SimplePollCue } from "../types/SimplePoll";
import { AnyOttacue } from "../utils";

interface ChildProps {
  cue: AnyOttacue;
  onDismiss: () => void;
  onClick: () => void;
}

export const Child = ({
  cue,
  onClick,
  onDismiss,
}: ChildProps): React.ReactElement | null => {
  switch (cue.type) {
    case "external-link":
      return (
        <ExternalLinkCue
          onClick={() => onClick()}
          onDismiss={onDismiss}
          title={cue.title}
          description={cue.description}
          url={cue.url}
          primaryButtonCopy={cue.primaryButtonCopy}
        />
      );
    case "internal-link":
      return (
        <InternalLinkCue
          onClick={() => onClick()}
          onDismiss={onDismiss}
          title={cue.title}
          description={cue.description}
          url={cue.url}
          primaryButtonCopy={cue.primaryButtonCopy}
        />
      );
    case "simple-poll":
      return (
        <SimplePollCue
          onClick={onClick}
          onDismiss={onDismiss}
          title={cue.title}
          description={cue.description}
        />
      );
    default:
      return null;
  }
};
