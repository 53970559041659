import { CanIntegrate } from "./CanIntegrate";
import { CantIntegrate } from "./CantIntegrate";
import { NotFound } from "./NotFound";

export function NoIntegration({
  atsName,
  atsAuthorizeUrl,
  scraperUrl,
}: {
  atsName: string | null;
  atsAuthorizeUrl: string | null;
  scraperUrl: string | null;
}) {
  if (atsName && typeof atsAuthorizeUrl === "string") {
    return <CanIntegrate atsAuthorizeUrl={atsAuthorizeUrl} atsName={atsName} />;
  }

  if (typeof scraperUrl === "string") {
    return <CantIntegrate scraperUrl={scraperUrl} />;
  }

  return <NotFound />;
}
