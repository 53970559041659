import styled from "@xstyled/styled-components";

import { Divider } from "../../common/Divider";
import { Subheading } from "../../common/Subheading";
import { SectionProps } from "../../common/types";

import { MaybeProxiedImage } from "@hire/components/images/MaybeProxiedImage";
import { Text, Spacing, List } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";

const Portrait = styled(MaybeProxiedImage)`
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  border-radius: 50%;
  object-fit: cover;
`;

const Author = styled.div`
  align-items: center;
  flex-direction: row;
  display: flex;
  gap: 1rem;
`;

export function DiversityInitiatives({
  company: {
    name,
    diversityInitiatives,
    diversityAuthorFullName,
    diversityAuthorProfileImagePath,
    diversityAuthorTitle,
  },
}: SectionProps): React.ReactElement | null {
  return diversityInitiatives.length > 0 ? (
    <Spacing size={0}>
      <Divider />
      <Subheading>Diversity &amp; Inclusion at {name}</Subheading>
      <Author>
        {diversityAuthorProfileImagePath && (
          <Portrait width={100} path={diversityAuthorProfileImagePath} />
        )}
        {!!(diversityAuthorFullName && diversityAuthorTitle) && (
          <Text bold>
            {diversityAuthorFullName} ({diversityAuthorTitle})
          </Text>
        )}
      </Author>

      <List type="bullet">
        {diversityInitiatives.map(({ value }, i) => (
          <li key={i}>{value}</li>
        ))}
      </List>
    </Spacing>
  ) : null;
}
