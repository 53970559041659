import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { Year } from "@hire/components/sliders/ExperienceRangeSlider";
import { Location } from "@hire/schema";

interface JobPerformanceParams {
  experiences: Year[];
  function: string;
  locations: Location[];
  languages?: string[];
}

export function useJobPerformanceParams(): JobPerformanceParams | null {
  const [searchParams] = useSearchParams();

  const locations = useMemo(
    () => searchParams.getAll("locations") ?? [],
    [searchParams]
  ) as Location[];

  const functionId = useMemo(
    () => searchParams.get("functions") ?? "",
    [searchParams]
  );

  const experiences = useMemo(
    () =>
      (searchParams.getAll("experience").slice(0, 2) ?? []).map(
        year => parseInt(year) as Year
      ),
    [searchParams]
  ) as [Year, Year];

  const languages = useMemo(
    () => searchParams.getAll("languages") ?? [],
    [searchParams]
  );

  const result = useMemo(
    () => ({
      locations,
      experiences,
      languages: languages,
      function: functionId,
    }),
    [experiences, locations, functionId, languages]
  );

  if (locations.length > 0 && experiences.length > 0 && functionId !== "") {
    return result;
  } else {
    return null;
  }
}
