import styled from "@xstyled/styled-components";

import { JobFormBullets } from "../JobFormBullets";
import { useJobEditFormikContext } from "../useHandlers";

import { FieldWrapper, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";

// sorry this is not very good at all
const BigField = styled(FieldWrapper)`
  & label {
    font-size: 1.2rem;
  }
`;

const AdviceText = styled(Text)`
  color: ${palette.grayscale.shade600};
  max-width: ${pxToRem(600)};
`;

export function JobInvolves() {
  const form = useJobEditFormikContext();

  return (
    <BigField
      label="What the job involves"
      fieldError={
        form.touched.involvesBullets
          ? (form.errors.involvesBullets as string[])
          : undefined
      }
      required
      advice={
        <AdviceText>
          Give a clear understanding of the day to day work and the core
          responsibilities. For example, ‘Build features from start to finish’
          or ‘Help us scale geographically’.
        </AdviceText>
      }
    >
      {({ field }) => (
        <JobFormBullets
          fieldName="involvesBullets"
          nounSingular="responsibility"
          values={form.values.involvesBullets}
          onChange={v => form.setFieldValue("involvesBullets", v)}
          onBlur={() =>
            form.setFieldTouched(
              "involvesBullets[0]",
              true,
              form.validateOnBlur ?? false
            )
          }
          fieldProps={[field]}
          placeholders={[
            "Build features from start to finish: plan, build, test, deploy, iterate",
            "Get stuck in and solve exciting problems: from scaling to 100,000s of jobs, to launching in cities across the world",
            "Deploy on a daily basis. We use GitHub and continuously deploy to a Kubernetes cluster hosted on Google Cloud Platform",
          ]}
        />
      )}
    </BigField>
  );
}
