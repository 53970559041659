import styled from "@xstyled/styled-components";

import { modularScale, palette, pxToRem } from "@otta/design-tokens";

export const Container = styled.div`
  height: 100%;
  position: relative;
  width: 100%;

  background: ${palette.grayscale.shade50};
  border-radius: ${pxToRem(8)};

  &:hover {
    background: ${palette.grayscale.shade50};
    i {
      display: flex;
    }
  }

  transition: default;
`;

export const CircleIconWrapper = styled.i`
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;

  height: ${pxToRem(32)};
  width: ${pxToRem(32)};
  background: ${palette.beige.shade200};
  border-radius: 50%;

  top: ${modularScale()};
  right: ${modularScale()};
`;
