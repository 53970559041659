import styled from "@xstyled/styled-components";

import { IOption } from "../types";
import { showTechnologiesUsedByJob } from "../util";
import { useJobEditFormikContext } from "../useHandlers";

import { FieldWrapper, SelectField } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";

const SelectFieldContainer = styled.div`
  max-width: ${pxToRem(400)};
`;

export function Technologies({
  technologiesUsedOptions,
}: {
  technologiesUsedOptions: IOption[];
}) {
  const form = useJobEditFormikContext();

  const showTechnologiesSection = showTechnologiesUsedByJob(
    form.values.jobFunction
  );

  if (!showTechnologiesSection) return null;

  return (
    <FieldWrapper
      label="Technologies"
      required={false}
      fieldError={form.errors.technologiesUsed as string}
      advice="When candidates view your job, we'll highlight technologies that match their search preferences. We recommend selecting the 3-5 must-have technologies."
    >
      {({ field }) => (
        <SelectFieldContainer>
          <SelectField
            inputId={field.id}
            aria-label="Technologies used"
            placeholder="Type technologies..."
            value={form.values.technologiesUsed}
            options={technologiesUsedOptions}
            onChange={options =>
              form.setFieldValue("technologiesUsed", options)
            }
            isMulti
            closeMenuOnSelect={false}
            aria-describedby={field["aria-describedby"]}
            aria-invalid={field["aria-invalid"]}
          />
        </SelectFieldContainer>
      )}
    </FieldWrapper>
  );
}
