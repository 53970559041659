import styled from "@xstyled/styled-components";

import { CandidateSavedThisJobOrCompanyBadge } from "./CandidateSavedThisJobOrCompanyBadge";
import { CandidateInterviewingBadge } from "./CandidateInterviewingBadge";
import { CandidateIsNewUserBadge } from "./CandidateIsNewUserBadge";
import { CandidateIsRecentlyActiveBadge } from "./CandidateIsRecentlyActiveBadge";
import { CandidateRespondsQuicklyBadge } from "./CandidateRespondsQuicklyBadge";
import { CandidateBasedInBadge } from "./CandidateBasedInBadge";
import { CandidateLookingNowBadge } from "./CandidateLookingNowBadge";
import { CandidateRequiresVisaBadge } from "./CandidateRequiresVisaBadge";
import { CandidateTechnologyPreferencesBadge } from "./CandidateTechnologyPreferencesBadge";
import { CandidateWantsToWorkInBadge } from "./CandidateWantsToWorkInBadge";
import { CandidateWorksRemotelyBadge } from "./CandidateWorksRemotelyBadge";
import { CandidateConflictingSectorsBadge } from "./CandidateConflictingSectorsBadge";
import { CandidateConflictingTechnologiesBadge } from "./CandidateConflictingTechnologiesBadge";

import { CandidateCardFragment, CandidateCardJobQuery } from "@hire/schema";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: xs;
`;

interface CandidateBadgesProps {
  candidate: CandidateCardFragment;
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
}

export function CandidateBadges({
  candidate,
  job,
}: CandidateBadgesProps): React.ReactElement {
  return (
    <Container>
      <CandidateSavedThisJobOrCompanyBadge candidate={candidate} job={job} />
      <CandidateInterviewingBadge candidate={candidate} />
      <CandidateIsNewUserBadge candidate={candidate} />
      <CandidateIsRecentlyActiveBadge candidate={candidate} />
      <CandidateRespondsQuicklyBadge candidate={candidate} />
      <CandidateLookingNowBadge candidate={candidate} />
      <CandidateWorksRemotelyBadge candidate={candidate} job={job} />
      <CandidateBasedInBadge candidate={candidate} />
      <CandidateWantsToWorkInBadge candidate={candidate} job={job} />
      <CandidateTechnologyPreferencesBadge candidate={candidate} job={job} />
      <CandidateRequiresVisaBadge candidate={candidate} job={job} />
      <CandidateConflictingTechnologiesBadge candidate={candidate} job={job} />
      <CandidateConflictingSectorsBadge candidate={candidate} job={job} />
    </Container>
  );
}
