import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import styled from "@xstyled/styled-components";

import {
  CandidateMessageRequestQuery,
  CurrentUserCompanyDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { modularScale } from "@otta/design-tokens";
import { Spacing, Text, Middle } from "@otta/design";
import { ReloadableError } from "@hire/components/ReloadableError";
import { ExternalLink as DefaultExternalLink } from "@hire/components/links/ExternalLink";

const ExternalLink = styled(DefaultExternalLink)`
  display: flex;
  justify-content: center;
`;

type MessageRequest = NonNullable<
  CandidateMessageRequestQuery["candidate"]
>["messageRequestsSentFromCompany"][0];

interface MessageRequestMessagedRecentlyProps {
  candidateName: string;
  messageRequestsFromCompany: MessageRequest[];
}

const getContent = ({
  messageRequest,
  companySlug,
  jobId,
  currentUserId,
  candidateName,
  isAnotherJob,
}: {
  currentUserId: string;
  jobId: string;
  companySlug: string;
  messageRequest: MessageRequest;
  candidateName: string;
  isAnotherJob?: boolean;
}) => {
  const recruiter = messageRequest.companyRecruiter;
  const conversationId = messageRequest.conversation?.id;

  return {
    title: isAnotherJob
      ? recruiter.id === currentUserId
        ? `You have already messaged ${candidateName} for another job at your company.`
        : `${candidateName} has been messaged by ${recruiter.firstName} for another job at your company.`
      : recruiter.id === currentUserId
      ? `You have already messaged ${candidateName} for this job.`
      : `${candidateName} has been messaged by ${recruiter.firstName} for this job.`,
    // TODO: Update link when messaging tab is completed
    link: `/${companySlug}/jobs/${jobId}/inbox${
      conversationId ? `/${conversationId}` : ""
    }`,
  };
};

export function MessageRequestMessagedRecently({
  candidateName,
  messageRequestsFromCompany,
}: MessageRequestMessagedRecentlyProps): React.ReactElement {
  const { jobId, companySlug } = useParams();
  const { data, loading, refetch } = useQuery(CurrentUserCompanyDocument);

  if (loading) {
    return <Loading />;
  }

  if (!data?.currentUser) {
    return <ReloadableError action={refetch} />;
  }

  const messageRequests = [...messageRequestsFromCompany].reverse();

  const messageRequestForThisJob = messageRequests.find(
    ({ job }) => job.externalId === jobId
  );

  const opts = {
    currentUserId: data.currentUser.id,
    jobId: jobId as string,
    companySlug: companySlug as string,
    candidateName,
  };

  const content = getContent(
    messageRequestForThisJob
      ? { messageRequest: messageRequestForThisJob, ...opts }
      : { messageRequest: messageRequests[0], ...opts, isAnotherJob: true }
  );

  return (
    <Middle style={{ paddingTop: modularScale(2) }}>
      <Spacing size={2}>
        <Text data-cs-mask bold size={1}>
          {content.title}
        </Text>
        <Text>Wait a month before messaging this candidate again.</Text>
        <ExternalLink to={content.link}>View inbox</ExternalLink>
      </Spacing>
    </Middle>
  );
}
