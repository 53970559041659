import { useMemo } from "react";

import { CandidateCardFragment, CandidateCardJobQuery } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import {
  UK_LOCATIONS,
  US_LOCATIONS,
  EU_LOCATIONS,
  CA_LOCATIONS,
} from "@hire/util/locations";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

interface CandidateRequiresVisaBadgeProps {
  candidate: CandidateCardFragment;
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
}

export function CandidateRequiresVisaBadge({
  candidate,
  job,
}: CandidateRequiresVisaBadgeProps): React.ReactElement | null {
  const regions = useMemo(() => {
    const set = new Set<"UK" | "US" | "CA" | "EU">();
    for (const pref of job.locationPreferences) {
      if (UK_LOCATIONS.includes(pref.location)) {
        set.add("UK");
      } else if (US_LOCATIONS.includes(pref.location)) {
        set.add("US");
      } else if (EU_LOCATIONS.includes(pref.location)) {
        set.add("EU");
      } else if (CA_LOCATIONS.includes(pref.location)) {
        set.add("CA");
      }
    }

    return Array.from(set);
  }, [job]);

  const candidateRequiresVisa = !!(
    candidate.visaRequirementCountries.length &&
    regions.every(r =>
      candidate.visaRequirementCountries.map(l => l.location).includes(r)
    )
  );

  return candidateRequiresVisa ? (
    <RoundedTag
      content="Requires a visa"
      icon="location"
      color={palette.extended.red.shade100}
      hoverText="The candidate requires visa sponsorship"
    />
  ) : null;
}
