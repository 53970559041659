import styled, { down, css } from "@xstyled/styled-components";
import { useId, useMemo } from "react";
import { useQuery } from "@apollo/client";

import { JobListMode, JobListStatus, liveStatus } from "./statusTypes";

import { JobLocationsDocument, Location } from "@hire/schema";
import { pxToRem } from "@otta/design-tokens";
import { SelectField, Text } from "@otta/design";
import {
  locationToLocationOption,
  stringToLocation,
} from "@hire/util/locations";
import { hireAppUser } from "@hire/util/user";

const InputContainer = styled.div`
  flex-direction: column;
  display: flex;
  gap: 0.75rem;
  flex: 1 0 0;
`;

const Container = styled.div`
  max-width: ${pxToRem(632)};
  align-items: center;
  display: flex;
  flex-grow: 1;
  gap: 1rem;

  ${down(
    "tablet",
    css`
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      width: 100%;
    `
  )}
`;

interface SearchParams {
  status?: string | undefined;
  locations?: Location[] | undefined;
}

interface FiltersProps {
  mode: JobListMode;
  status: JobListStatus;
  location?: Location | null;
  updateSearchParams: (params: SearchParams) => void;
}

interface IOption {
  label: string;
  value: string;
}

const defaultLocation = { label: "All", value: null };

export const Filters = ({
  mode,
  status,
  location = null,
  updateSearchParams,
}: FiltersProps): React.ReactElement => {
  const { data, loading } = useQuery(JobLocationsDocument);
  const maybePublished = liveStatus(mode);
  const id = useId();

  const statusOptions = useMemo(() => {
    return [
      {
        label:
          maybePublished === JobListStatus.UNPUBLISHED
            ? "Unpublished"
            : "Published",
        value: maybePublished,
      },
      {
        label: "Review",
        value: JobListStatus.REVIEW,
      },
      {
        label: "Draft",
        value: JobListStatus.DRAFT,
      },
      {
        label: "Archived",
        value: JobListStatus.ARCHIVED,
      },
    ];
  }, [maybePublished]);

  const locationOptions = useMemo(() => {
    const options =
      hireAppUser(data?.me)
        ?.currentCompany?.jobLocations.map(l => locationToLocationOption(l))
        .filter((l): l is IOption => !!l) ?? [];

    return [defaultLocation, ...options];
  }, [data?.me]);

  const currentStatus = useMemo(
    () => statusOptions.find(s => s.value === status),
    [status, statusOptions]
  );

  return (
    <Container>
      <InputContainer>
        <Text as="label" bold htmlFor={`${id}-location`}>
          Filter by location
        </Text>
        <SelectField
          inputId={`${id}-location`}
          options={locationOptions}
          defaultValue={
            locationOptions.find(option => option.value === location) ??
            defaultLocation
          }
          isLoading={loading}
          onChange={v => {
            const location = v?.value ? stringToLocation(v?.value) : null;

            if (location) {
              updateSearchParams({ locations: [location] });
            } else {
              updateSearchParams({ locations: [] });
            }
          }}
        />
      </InputContainer>
      <InputContainer>
        <Text as="label" bold htmlFor={`${id}-status`}>
          Filter by job status
        </Text>
        <SelectField
          onChange={s => updateSearchParams({ status: s?.value })}
          inputId={`${id}-status`}
          options={statusOptions}
          value={currentStatus}
        />
      </InputContainer>
    </Container>
  );
};
