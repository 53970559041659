import { createContext, useContext, useEffect, useId, useState } from "react";
import styled, { css } from "@xstyled/styled-components";
import * as RadixTabs from "@radix-ui/react-tabs";
import { motion } from "framer-motion";

import { palette } from "@otta/design-tokens";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: lg;
`;

const TabsListWrapper = styled(RadixTabs.List)`
  display: flex;
  gap: lg;

  white-space: nowrap;
  overflow-x: auto;

  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const indicatorStyle = css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
`;

const Tab = styled(RadixTabs.Trigger)`
  all: unset;
  position: relative;
  padding: sm 0;
  cursor: pointer;

  /* Trick to prevent the tabs to jump when we set the font-weight to bold, see https://github.com/WTTJ/otta/pull/858 */
  display: inline-block;
  &::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &[data-state="active"] {
    font-weight: bold;
  }

  &::before {
    content: "";
    ${indicatorStyle};
    background-color: ${palette.brand.grey};
    opacity: 0;
    transition: default;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const TabIndicator = styled(motion.span)`
  ${indicatorStyle}
  background-color: ${palette.brand.yellow};
`;

type Tab = {
  id: string;
  title: string;
  content: React.ReactNode;
};

const TabContext = createContext<{
  tabs: Tab[];
  activeTab: string;
}>({ tabs: [], activeTab: "" });

const TabList = () => {
  const layoutId = useId();

  const { tabs, activeTab } = useContext(TabContext);

  return (
    <TabsListWrapper>
      {tabs.map(({ id, title }) => (
        <Tab key={id} value={id} title={title}>
          {title}
          {activeTab === id && <TabIndicator layoutId={layoutId} />}
        </Tab>
      ))}
    </TabsListWrapper>
  );
};

const TabPanels = () => {
  const { tabs } = useContext(TabContext);

  return (
    <>
      {tabs.map(({ id, content }) => (
        <RadixTabs.Content key={id} value={id}>
          {content}
        </RadixTabs.Content>
      ))}
    </>
  );
};

/**
 * ```ts
 *
 * import { Tabs } from '@otta/design'
 *
 * ```
 *
 * Wrapper around [Radix Tabs](https://www.radix-ui.com/themes/docs/components/tabs) to provide a consistent design.
 * You can use it without children or with Tabs.List and Tabs.Panels to have more control over the layout.
 *
 * You can use `currentTab` to control it yourself or let the component handle it.
 */
export const Tabs = ({
  tabs,
  currentTab,
  children,
  onChange,
}: {
  tabs: Tab[];
  currentTab?: string;
  children?: React.ReactNode;
  onChange?: (tab: string) => void;
}) => {
  const [activeTab, setActiveTab] = useState(currentTab ?? tabs[0]?.id);

  useEffect(() => {
    if (currentTab) {
      setActiveTab(currentTab);
    }
  }, [currentTab]);

  const handleValueChange = (tab: string) => {
    setActiveTab(tab);
    onChange?.(tab);
  };

  if (tabs.length === 0) return null;

  return (
    <TabContext.Provider
      value={{
        tabs,
        activeTab,
      }}
    >
      <RadixTabs.Root value={activeTab} onValueChange={handleValueChange}>
        {children ? (
          children
        ) : (
          <Content>
            <TabList />
            <TabPanels />
          </Content>
        )}
      </RadixTabs.Root>
    </TabContext.Provider>
  );
};

Tabs.List = TabList;
Tabs.Panels = TabPanels;
