import { useFormikContext } from "formik";
import styled from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { JobFormFields } from "../types";

import { SectionProps } from "./common/types";
import { Header } from "./sections/Header";
import { Role } from "./sections/Role";
import { Company } from "./sections/Company";
import { Phone } from "./Phone";
import { Photos } from "./sections/Photos";
import { OttasTake } from "./sections/OttasTake";

import { Loading } from "@otta/shared-components";
import { JobCardCompanyDocument } from "@hire/schema";
import { pxToRem } from "@otta/design-tokens";

const JobCard = styled.div`
  width: ${pxToRem(370)};
  flex-direction: column;
  overflow: hidden;
  display: flex;
  margin: auto;
  gap: 2rem;
`;

export function Preview(): React.ReactElement {
  const { values } = useFormikContext<JobFormFields>();
  const { data, loading } = useQuery(JobCardCompanyDocument);

  const props: SectionProps | undefined = useMemo(() => {
    const company = data?.currentUser?.currentCompany;
    return company ? { company, fields: values } : undefined;
  }, [data, values]);

  if (loading || !props) {
    return <Loading />;
  }

  return (
    <Phone>
      <JobCard>
        <Header {...props} />
        <Role {...props} />
        <OttasTake {...props} />
        <Company {...props} />
        <Photos {...props} />
      </JobCard>
    </Phone>
  );
}
