import { intersectingTags } from "./utils";

import {
  CandidateCardFragment,
  CandidateCardJobQuery,
  TechnologyPreference,
} from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

interface CandidateConflictingTechnologiesBadgeProps {
  candidate: CandidateCardFragment;
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
}

export function CandidateConflictingTechnologiesBadge({
  candidate,
  job,
}: CandidateConflictingTechnologiesBadgeProps): React.ReactElement | null {
  const userDislikedTechnologiesIds = new Set(
    candidate.technologiesUsedPreferences
      .filter(p => p.technologyPreference === TechnologyPreference.Negative)
      .map(p => p.technologyUsed.id)
  );

  const conflictingTechnologies = intersectingTags(
    job.technologiesUsed,
    userDislikedTechnologiesIds
  );

  return conflictingTechnologies.length > 0 ? (
    <RoundedTag
      icon="triangle-exclamation"
      content={`Dislikes: ${conflictingTechnologies.join(", ")}`}
      color={palette.extended.red.shade100}
    />
  ) : null;
}
