import { Section } from "../../common/Section";
import { SectionProps } from "../../common/types";

import { CompanyPhotos, ExamplePhotos } from "./CompanyPhotos";

import { useCompanyIsActive } from "@hire/hooks/useCompanyIsActive";
import { Loading } from "@otta/shared-components";

export function Photos(props: SectionProps) {
  const { active, loading } = useCompanyIsActive();

  if (loading) {
    return <Loading />;
  } else if (active && props.company.photos.length > 0) {
    return (
      <Section>
        <CompanyPhotos {...props} />
      </Section>
    );
  } else {
    return (
      <Section>
        <ExamplePhotos />
      </Section>
    );
  }
}
