import styled from "@xstyled/styled-components";

import { IOption } from "../types";
import { useJobEditFormikContext } from "../useHandlers";

import { FieldWrapper, SelectField } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

const SelectFieldContainer = styled.div`
  max-width: ${pxToRem(400)};
`;

export function LanguageRequirements({
  languageRequirementOptions,
}: {
  languageRequirementOptions: IOption[];
}) {
  const form = useJobEditFormikContext();
  const companyName = useWelcomeToTheJungle();

  return (
    <FieldWrapper
      label="Language"
      advice={`Jobs on ${companyName} require English. Add other required languages here and we'll only show your job to relevant candidates.`}
      fieldError={form.errors.languageRequirements as string[]}
    >
      {({ field }) => (
        <SelectFieldContainer>
          <SelectField
            placeholder="Type or select..."
            value={form.values.languageRequirements}
            options={languageRequirementOptions}
            aria-describedby={field["aria-describedby"]}
            aria-invalid={field["aria-invalid"]}
            inputId={field.id}
            onChange={options =>
              form.setFieldValue("languageRequirements", options)
            }
            isMulti
            closeMenuOnSelect={false}
          />
        </SelectFieldContainer>
      )}
    </FieldWrapper>
  );
}
