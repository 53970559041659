import styled from "@xstyled/styled-components";

import { CompanyMarketBullet } from "@hire/schema";
import { palette, pxToRem } from "@otta/design-tokens";
import { Spacing, Text, Tipbox } from "@otta/design";
import { Link } from "@hire/components/links/Link";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

const MainCopyContainer = styled.div`
  background: ${palette.beige.shade200};
  padding: lg;
  border-radius: ${pxToRem(8)};
`;

const Paragraph = styled.p`
  color: ${palette.grayscale.shade600};
`;

export const OttasTake = ({ data }: { data: CompanyMarketBullet[] }) => {
  const companyName = useWelcomeToTheJungle();

  return (
    <Spacing>
      <Tipbox level="information">
        This is {companyName}&apos;s opinion, written to help candidates
        understand more about your company. It&apos;s not intended as a
        statement of fact, but was written to be as accurate as possible at the
        time of publishing.
      </Tipbox>
      <MainCopyContainer>
        <Spacing>
          {data.map(({ value, id }) => (
            <Paragraph key={id}>{value}</Paragraph>
          ))}
        </Spacing>
      </MainCopyContainer>
      <Text>
        If our take contains inaccurate or out of date information,{" "}
        <Link newTab to="https://form.typeform.com/to/LlkXCRCv">
          complete this form to let us know
        </Link>
        .
      </Text>
    </Spacing>
  );
};
