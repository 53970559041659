import styled, { css, up } from "@xstyled/styled-components";

import { Icon } from "@otta/icons";
import { Clickable, Text } from "@otta/design";

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const Arrows = styled(FlexBox)`
  gap: xl;
`;

const DesktopOnlyText = styled(Text)`
  display: none;
  ${up(
    "tablet",
    css`
      display: block;
    `
  )}
`;

export const NavigationArrows = ({
  onNext,
  onPrevious,
}: {
  onNext?: () => void;
  onPrevious?: () => void;
}) => {
  return (
    <Arrows>
      {onPrevious && (
        <FlexBox as={Clickable} onClick={onPrevious}>
          <Icon icon="chevron-left" />
          <DesktopOnlyText bold>Previous</DesktopOnlyText>
        </FlexBox>
      )}
      {onNext && (
        <FlexBox as={Clickable} onClick={onNext}>
          <DesktopOnlyText bold>Next</DesktopOnlyText>
          <Icon icon="chevron-right" />
        </FlexBox>
      )}
    </Arrows>
  );
};
