import styled from "@xstyled/styled-components";

import { SourcingPage } from "../enums";

import { Button, Text, VerticalSpacing, Middle } from "@otta/design";
import { Link } from "@hire/components/links";

const Container = styled.div`
  padding: 4xl 0;
`;

const getContent = (type: SourcingPage) => {
  if (type === SourcingPage.Search) {
    return {
      title: "No candidates remaining",
      description:
        "New candidates join every day. Change filters or check back soon to see more candidates for this job",
      buttonText: "View shortlisted candidates",
      buttonLink: "../shortlist",
    };
  }
  if (type === SourcingPage.Seen) {
    return {
      title: "You haven't discarded any candidates yet",
      description: "Once you discard candidates, they'll appear here.",
      buttonText: "View new candidates",
      buttonLink: `../search`,
    };
  }
  return {
    title: "You haven't shortlisted any candidates yet",
    description: "Once you shortlist candidates, they'll appear here.",
    buttonText: "View new candidates",
    buttonLink: `../search`,
  };
};

interface NoCandidatesMessageProps {
  type: SourcingPage;
}

export function NoCandidatesMessage({
  type,
}: NoCandidatesMessageProps): React.ReactElement {
  const content = getContent(type);

  return (
    <Middle maxWidth={450}>
      <Container>
        <VerticalSpacing size={1}>
          <Text bold size={3}>
            {content.title}
          </Text>
          <Text>{content.description}</Text>
          <div>
            <Link to={content.buttonLink}>
              <Button level="primary">{content.buttonText}</Button>
            </Link>
          </div>
        </VerticalSpacing>
      </Container>
    </Middle>
  );
}
