import { ExperienceSummaryContainer } from "./shared";

import { CandidateCardFragment } from "@hire/schema";
import { Icon } from "@otta/icons";
import { Text } from "@otta/design";

const qualityPreferences = (companyQualityPreferences: string[]) => {
  const [first, ...rest] = companyQualityPreferences;
  const content = rest.length ? `${rest.join(", ")}, and ${first}` : first;
  return content;
};

interface CandidateLookingForCompanyQualitiesProps {
  candidate: CandidateCardFragment;
}

export function CandidateLookingForCompanyQualities({
  candidate,
}: CandidateLookingForCompanyQualitiesProps): React.ReactElement | null {
  if (candidate.companyQualitiesPreferences.length === 0) {
    return null;
  }

  const companyQualities = candidate.companyQualitiesPreferences
    .slice(0, 10)
    .map(p => p.value);

  return (
    <ExperienceSummaryContainer>
      <Icon icon="search" />
      <Text size={-1}>
        <Text as="span" bold>
          Looking for:{" "}
        </Text>
        <Text as="span">{qualityPreferences(companyQualities)}</Text>
      </Text>
    </ExperienceSummaryContainer>
  );
}
