import { useField, useFormikContext } from "formik";

import { Range, RangeProps } from ".";

function toNumber(thing: string) {
  const parsed = parseInt(thing);
  return isNaN(parsed) ? null : parsed;
}

/**
 * Formik adapter for the range field,
 * connects up event handlers and errors
 */
export function FormikRange({
  min,
  max,
  minName,
  maxName,
  className,
}: RangeProps & {
  minName: string;
  maxName: string;
}) {
  const { setFieldValue } = useFormikContext();
  const [formikMin] = useField(minName);
  const [formikMax] = useField(maxName);

  return (
    <Range
      min={{
        ...min,
        ...formikMin,
        value: formikMin.value ?? "",
        onChange: e => setFieldValue(minName, toNumber(e.target.value)),
      }}
      max={{
        ...max,
        ...formikMax,
        value: formikMax.value ?? "",
        onChange: e => setFieldValue(maxName, toNumber(e.target.value)),
      }}
      className={className}
    />
  );
}
