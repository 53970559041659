import { useMutation } from "@apollo/client";

import { handleMutationError } from "@hire/errors";
import { DeleteCompanyAtsApiKeysDocument } from "@hire/schema";
import { Button } from "@otta/design";

export function ResetATSKeys({
  companyId,
}: {
  companyId: string;
}): React.ReactElement {
  const [mutate, { loading }] = useMutation(DeleteCompanyAtsApiKeysDocument, {
    variables: {
      companyId,
    },
    onError: handleMutationError,
  });

  return (
    <Button level="destructive" onClick={() => mutate()} disabled={loading}>
      Reset API keys
    </Button>
  );
}
