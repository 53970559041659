import { useContext, useEffect, useMemo } from "react";
import {
  useFlagsStatus,
  useFlag,
  useVariant,
} from "@unleash/proxy-client-react";

import { ExperimentContext } from "./context";

type Experiment<Payload> = {
  variant: string | undefined;
  loading: boolean;
  payload: Payload;
};

interface ExperimentOptions {
  when?: boolean;
}

export function useExperiment<
  Payload extends string | Record<string, unknown> | undefined = undefined
>(name: string, { when = true }: ExperimentOptions = {}): Experiment<Payload> {
  const enabled = useFlag(name);
  const { flagsReady } = useFlagsStatus();
  const { name: variantName, enabled: hasVariants, payload } = useVariant(name);

  const { variantViewed, overrides } = useContext(ExperimentContext);

  const override = overrides[name];

  const variant = useMemo(() => {
    if (override) {
      return override;
    }

    if (!enabled || !when) {
      return "control";
    }

    if (!hasVariants) {
      return "variant";
    }

    return variantName;
  }, [override, enabled, when, hasVariants, variantName]);

  useEffect(() => {
    if (flagsReady && variant && when) {
      return variantViewed(name, variant);
    }
  }, [variant, name, variantViewed, when, flagsReady]);

  const parsedPayload = useMemo<Payload>(() => {
    if (payload?.type === "json") {
      return JSON.parse(payload.value);
    }

    return payload?.value;
  }, [payload?.type, payload?.value]);

  return {
    variant,
    loading: !flagsReady,
    payload: parsedPayload,
  };
}
