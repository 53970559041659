import styled from "@xstyled/styled-components";

import { JobFormBullets } from "../JobFormBullets";
import { useJobEditFormikContext } from "../useHandlers";

import { FieldWrapper, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";

// sorry this is not very good at all
const BigField = styled(FieldWrapper)`
  gap: 0;
  & label {
    font-size: 1.2rem;
  }
`;

const AdviceText = styled(Text)`
  margin: 0.5rem 0 xl 0;
  color: ${palette.grayscale.shade600};
  max-width: ${pxToRem(600)};
`;

export function ApplicationProcess() {
  const form = useJobEditFormikContext();

  return (
    <BigField
      label="Application process"
      fieldError={form.errors.applicationProcessBullets as string[]}
      advice={
        <AdviceText>
          Outline the steps in your application process so candidates have a
          clear idea of what to expect. For example, ‘Video call with internal
          recruiter (30 mins)’.
        </AdviceText>
      }
    >
      {({ field }) => (
        <JobFormBullets
          fieldName="applicationProcessBullets"
          values={form.values.applicationProcessBullets}
          onChange={v => form.setFieldValue("applicationProcessBullets", v)}
          nounSingular="a step"
          fieldProps={[field]}
          placeholders={[
            "1. Apply with your profile",
            "2. Video call with internal recruiter (30min) ",
          ]}
        />
      )}
    </BigField>
  );
}
