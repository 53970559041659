import { SectionProps } from "../../common/types";

import { Text } from "@otta/design";

export function CompanySubtitle({
  company: { shortDescription },
}: SectionProps): React.ReactElement | null {
  return shortDescription ? (
    <Text>{shortDescription}</Text>
  ) : (
    <Text>[Short description of what your company does]</Text>
  );
}
