import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

export const ProfileContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  min-height: 200px;

  @media (min-width: 1300px) {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
  }
`;

export const ProfileColumn = styled.div`
  text-align: left;
  padding: lg;

  &:first-child {
    border-right: 1px solid ${palette.brand.grey};
  }
`;
