import * as Yup from "yup";

// used in the yup mapping below, just defaulting to `bound`
function map(bound: number): (t: unknown) => number {
  return t => (t as number) ?? bound;
}

type Options = {
  required?: boolean;
};

/**
 * This is used in two places
 * If you need this logic for something that isn't office days then by all means abstract it
 * don't let me stand in your way
 */
export function officeDaysSchema(opts?: Options): Yup.AnyObjectSchema {
  const min = 0;
  const max = 5;
  const error = `must be between ${min} and ${max}`;
  const minError = `Minimum number of days per week in office ${error}`;
  const maxError = `Maximum number of days per week in office ${error}`;
  const requiredError = "Enter a minimum number of days per week in office";

  return Yup.object().shape({
    minDaysInOffice: (opts?.required
      ? Yup.number().required(requiredError)
      : Yup.number().nullable()
    )
      .max(Yup.ref("maxDaysInOffice", { map: map(max) }), minError)
      .min(min, minError)
      .typeError(minError),
    maxDaysInOffice: Yup.number()
      .nullable()
      .min(Yup.ref("minDaysInOffice", { map: map(min) }), maxError)
      .max(max, maxError)
      .typeError(maxError)
      .test({
        message: requiredError,
        test: (value, context) =>
          !(
            value &&
            context.parent["minDaysInOffice"] !== 0 &&
            !context.parent["minDaysInOffice"]
          ),
      }),
  });
}
