import { useQuery } from "@apollo/client";
import { Fragment, ReactElement, useCallback, useMemo } from "react";
import styled, { css } from "@xstyled/styled-components";

import { VisaSponsorshipCountry } from "../types";

import { RegionsDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { palette } from "@otta/design-tokens";
import { Text } from "@otta/design";
import { Radio } from "@hire/components/input/Radio";

const radioButtonOptions: {
  display: string;
  id: string;
  value: boolean | null;
}[] = [
  { display: "Yes", id: "yes", value: true },
  { display: "No", id: "no", value: false },
  { display: "Not sure", id: "not_sure", value: null },
];

const VisaSponsorshipFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

const border = css`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${palette.brand.grey};
    padding-bottom: 28;
    margin-bottom: 28;
  }
`;

const TableLabel = styled(Text)`
  text-align: left;
  padding-right: 40;
  margin-bottom: 40;
`;

const RegionLabel = styled(Text).attrs({ bold: true, align: "left" })`
  padding-right: 40;
  ${border}
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 40;

  ${border}
`;

interface VisaSponsorshipFieldProps {
  onChange: (input: Array<VisaSponsorshipCountry>) => void;
  values: Array<VisaSponsorshipCountry>;
}

const isValidVSC = (
  input: Omit<VisaSponsorshipCountry, "offersVisa"> & {
    offersVisa: boolean | null;
  }
): input is VisaSponsorshipCountry => input.offersVisa !== null;

export function VisaSponsorshipField({
  onChange,
  values,
}: VisaSponsorshipFieldProps): ReactElement | null {
  const { data, loading } = useQuery(RegionsDocument);

  const regionSponsorshipMap = useMemo(() => {
    const regions = data?.regions ?? [];

    return regions.map(region => ({
      location: region.name,
      offersVisa:
        values.find(vsc => vsc.regionId === region.id)?.offersVisa ?? null,
      regionId: region.id,
    }));
  }, [values, data?.regions]);

  const handleChange = useCallback(
    (regionId: string, value: boolean | null) => {
      const tempMap = [...regionSponsorshipMap];
      const indexToUpdate = tempMap.findIndex(vsc => vsc.regionId === regionId);
      tempMap[indexToUpdate].offersVisa = value;
      onChange(tempMap.filter(isValidVSC));
    },
    [onChange, regionSponsorshipMap]
  );

  if (loading || !data) {
    return <Loading />;
  }

  return (
    <VisaSponsorshipFieldWrapper>
      <TableLabel bold>Location</TableLabel>
      <TableLabel bold>Visa sponsorship available</TableLabel>

      {data.regions.map(region => (
        <Fragment key={region.name}>
          <RegionLabel>{region.displayName}</RegionLabel>

          <Row>
            {radioButtonOptions.map(({ id, display, value }) => (
              <Radio
                key={id}
                label={display}
                onChange={() => handleChange(region.id, value)}
                value={id}
                checked={
                  regionSponsorshipMap.find(r => region.id === r.regionId)
                    ?.offersVisa === value
                }
                name={`${region.name}-${id}`}
                data-testid={`${region.name}-${id}`}
              />
            ))}
          </Row>
        </Fragment>
      ))}
    </VisaSponsorshipFieldWrapper>
  );
}
