import { Outlet } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";
import { useEffect } from "react";

import { NavBar } from "./NavBar";
import { NAV_BAR_WIDTH } from "./constants";

import { pxToRem } from "@otta/design-tokens";
import { useAuthorization } from "@hire/providers/authorization";
import { Feature } from "@hire/schema";
import { pushEventToDataLayer } from "@otta/analytics";

const Container = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;

  ${up(
    "tablet",
    css`
      flex-direction: row;
    `
  )}
`;

const PageContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const NavContainer = styled.div`
  position: relative;
  ${up(
    "tablet",
    css`
      width: ${pxToRem(NAV_BAR_WIDTH)};
    `
  )}
`;

export const MainLayout = () => {
  const { granted: jobSlotsEnabled } = useAuthorization(Feature.JobSlots);

  useEffect(() => {
    if (jobSlotsEnabled) {
      pushEventToDataLayer({
        event: "job_slots_enabled",
      });
    }
  }, [jobSlotsEnabled]);

  return (
    <Container data-testid="app-container">
      <NavContainer>
        <NavBar />
      </NavContainer>
      <PageContainer data-testid="base-page-container">
        <Outlet />
      </PageContainer>
    </Container>
  );
};
