import styled from "@xstyled/styled-components";

import { Button, Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { pxToRem } from "@otta/design-tokens";

const TextContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const AddOptionalButton = ({
  onClick,
  text,
}: {
  onClick: () => void;
  text: string;
}) => {
  return (
    <Button
      level="secondary"
      onClick={onClick}
      style={{ display: "flex", gap: pxToRem(8) }}
      type="button"
    >
      <Icon icon="plus" />
      <TextContainer>
        <Text size={-1} bold>
          {text}
        </Text>
        <Text size={-1} inline style={{ fontStyle: "italic" }}>
          optional
        </Text>
      </TextContainer>
    </Button>
  );
};
