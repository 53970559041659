import { useQuery } from "@apollo/client";
import { createContext, useContext, useMemo } from "react";

import {
  Feature,
  HasAccessDocument,
  HasJobAccessDocument,
  JobFeature,
} from "@hire/schema";
import { useCurrentUser } from "@hire/hooks/useCurrentUser";

interface AuthorizationContext {
  loading: boolean;
  features:
    | boolean
    | Map<Feature, { granted: boolean; message: string | null }>;
}

const Context = createContext<AuthorizationContext>({
  loading: true,
  features: new Map(),
});

interface JobAuthorizationContext {
  loading: boolean;
  features:
    | boolean
    | Map<JobFeature, { granted: boolean; message: string | null }>;
}

const JobContext = createContext<JobAuthorizationContext>({
  loading: true,
  features: new Map(),
});

export function AuthorizationProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [currentUser] = useCurrentUser();
  const { data, loading } = useQuery(HasAccessDocument, {
    variables: { features: Object.values<Feature>(Feature) },
    skip: !currentUser,
  });

  const features = useMemo(
    () =>
      new Map(
        data?.hasAccess.map(a => [
          a.feature,
          {
            granted: a.granted,
            message: a.message,
          },
        ])
      ),
    [data?.hasAccess]
  );

  return (
    <Context.Provider value={{ loading, features }}>
      {children}
    </Context.Provider>
  );
}

export function JobAuthorizationProvider({
  jobId,
  children,
}: {
  jobId: string;
  children: React.ReactNode;
}): React.ReactElement {
  const { data, loading } = useQuery(HasJobAccessDocument, {
    variables: { jobId, features: Object.values<JobFeature>(JobFeature) },
  });

  const features = useMemo(
    () =>
      new Map(
        data?.companyJob?.hasAccess?.map(a => [
          a.feature,
          {
            granted: a.granted,
            message: a.message,
          },
        ])
      ),
    [data]
  );

  return (
    <JobContext.Provider value={{ loading, features }}>
      {children}
    </JobContext.Provider>
  );
}

export function MockAuthorizationProvider({
  access = false,
  children,
}: {
  access?:
    | boolean
    | Partial<
        Record<Feature, boolean | { granted: boolean; message: string | null }>
      >;
  children: React.ReactNode;
}) {
  const features = useMemo(
    () =>
      typeof access === "boolean"
        ? access
        : new Map<Feature, { granted: boolean; message: string | null }>(
            Object.entries(access).map(([name, granted]) => [
              name as Feature,
              typeof granted === "boolean"
                ? { granted, message: null }
                : granted,
            ])
          ),
    [access]
  );

  return (
    <Context.Provider
      value={{
        loading: false,
        features,
      }}
    >
      {children}
    </Context.Provider>
  );
}
export function MockJobAuthorizationProvider({
  access = {},
  children,
}: {
  access?: Partial<
    Record<JobFeature, { granted: boolean; message: string | null }>
  >;
  children: React.ReactNode;
}) {
  const features = useMemo(
    () =>
      new Map<JobFeature, { granted: boolean; message: string | null }>(
        Object.entries(access) as [
          JobFeature,
          { granted: boolean; message: string | null }
        ][]
      ),
    [access]
  );

  return (
    <JobContext.Provider
      value={{
        loading: false,
        features,
      }}
    >
      {children}
    </JobContext.Provider>
  );
}

export const useAuthorization = (name: Feature) => {
  const { loading, features } = useContext(Context);

  if (typeof features === "boolean") {
    return { loading, granted: features, message: null };
  }

  const feature = features.get(name) ?? {
    granted: false,
    message: `Feature ${name} not found`,
  };

  return { ...feature, loading };
};

export const useJobAuthorization = (
  name: JobFeature
): { loading: boolean; granted: boolean; message: string | null } => {
  const { loading, features } = useContext(JobContext);

  if (typeof features === "boolean") {
    return { loading, granted: features, message: null };
  }

  const feature = features.get(name) ?? {
    granted: false,
    message: `Job feature ${name} not found`,
  };

  return { ...feature, loading };
};

export function useAuthorizations<T extends Feature[]>(
  names: T
): {
  features: Map<T[number], { granted: boolean; message: string | null }>;
  loading: boolean;
} {
  const { loading, features } = useContext(Context);

  const collected = useMemo(
    () =>
      new Map(
        names.map(name => {
          if (typeof features === "boolean") {
            return [name, { granted: features, message: null }];
          }

          const feature = features.get(name) ?? {
            granted: false,
            message: `Feature ${name} not found`,
          };

          return [name, feature];
        })
      ),
    [features, names]
  );

  return { features: collected, loading };
}

export const useJobAuthorizations = (names: JobFeature[]) => {
  const { loading, features } = useContext(JobContext);

  const collected = useMemo(
    () =>
      new Map(
        names.map(name => {
          if (typeof features === "boolean") {
            return [name, { granted: features, message: null }];
          }

          const feature = features.get(name) ?? {
            granted: false,
            message: `Feature ${name} not found`,
          };

          return [name, feature];
        })
      ),
    [features, names]
  );

  return { features: collected, loading };
};
