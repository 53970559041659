import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import * as Sentry from "@sentry/browser";
import { useLazyQuery } from "@apollo/client";
import styled from "@xstyled/styled-components";
import { useNavigate } from "react-router-dom";
import { useCookie } from "react-use";

import { MarketingConsent } from "./MarketingConsent";
import { TermsConditionsConsent } from "./TermsAndConditionsConsent";

import { CurrentUserDocument } from "@hire/schema";
import {
  VerticalSpacing,
  InputField,
  PasswordField,
  Button as DefaultButton,
  ErrorText,
} from "@otta/design";
import { hireAppUser } from "@hire/util/user";
import { workEmailRegex } from "@hire/validation/strings";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Link } from "@hire/components/links";

const Button = styled(DefaultButton)`
  width: 100%;
`;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Fill in this field").min(2),
  lastName: Yup.string().required("Fill in this field").min(2),
  email: Yup.string()
    .email("Must be a valid email")
    .required("Fill in this field")
    .matches(workEmailRegex, "Use your work email"),
  password: Yup.string().required("Fill in this field").min(8).max(200),
  marketingConsent: Yup.string().required("Fill in this field"),
  termsConditionsConsent: Yup.boolean().required("Fill in this field"),
});

interface SignupFormFields {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  marketingConsent: "true" | "false" | null;
  termsConditionsConsent: boolean | null;
}

interface SignupFormProps {
  signupUrl: string;
  companyUrlSafeName: string;
  email?: string;
}

export function SignupForm({
  email,
  signupUrl,
  companyUrlSafeName,
}: SignupFormProps): React.ReactElement {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<"CONFLICT" | "SSO_ENFORCED" | boolean>(
    false
  );

  const [queryUser, { data }] = useLazyQuery(CurrentUserDocument, {
    fetchPolicy: "network-only",
  });

  const [csrf] = useCookie(import.meta.env.VITE_CSRF_COOKIE);

  useEffect(() => {
    const urlSafeName = hireAppUser(data?.me)?.currentCompany?.urlSafeName;

    if (urlSafeName) {
      navigate(`../${urlSafeName.toLowerCase()}`);
    }
  }, [data, navigate]);

  const handleSubmit = useCallback(
    async ({
      email,
      password,
      firstName,
      lastName,
      marketingConsent,
      termsConditionsConsent,
    }: SignupFormFields) => {
      setLoading(true);
      setError(false);

      try {
        const response = await fetch(signupUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...(csrf ? { "X-CSRF-Token": csrf } : {}),
          },
          body: JSON.stringify({
            email,
            password,
            firstName,
            lastName,
            marketingConsent,
            termsConditionsConsent,
            role: "company_recruiter",
            currentCompanyId: companyUrlSafeName,
          }),
          credentials: "include",
        });

        if (response.status === 409) {
          return setError("CONFLICT");
        }

        if (response.status === 403) {
          return setError("SSO_ENFORCED");
        }

        if (!response.ok || response.status !== 201) {
          throw new Error("Unexpected response from api");
        }

        pushAnalyticsEvent({
          eventName: "company-recruiter-sign-up",
          method: "website",
        });

        const gqlResponse = await queryUser();

        if (!gqlResponse.data?.me) {
          throw new Error("No user returned after signing up");
        }
      } catch (error) {
        Sentry.captureException(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [signupUrl, companyUrlSafeName, queryUser, csrf]
  );

  const form = useFormik<SignupFormFields>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: email ?? "",
      password: "",
      termsConditionsConsent: null,
      marketingConsent: null,
    },
    validationSchema,
    onSubmit: async props => handleSubmit(props),
  });

  return (
    <VerticalSpacing>
      <InputField
        type="text"
        label="First name"
        name="firstName"
        value={form.values.firstName}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.firstName ? form.errors.firstName : undefined}
      />
      <InputField
        type="text"
        label="Last name"
        name="lastName"
        value={form.values.lastName}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.lastName ? form.errors.lastName : undefined}
      />
      <InputField
        type="text"
        label="Work email"
        name="email"
        value={form.values.email}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.email ? form.errors.email : undefined}
      />
      <PasswordField
        label="Password"
        name="password"
        value={form.values.password}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={!!(form.touched.password && form.errors.password)}
        requirements={[
          {
            text: "Enter at least 8 characters",
            validate: (input?: string) =>
              input ? input.length >= 8 && input.length <= 200 : false,
          },
        ]}
      />
      <MarketingConsent
        name="marketingConsent"
        value={form.values.marketingConsent}
        onChange={form.handleChange}
        error={
          form.touched.marketingConsent
            ? form.errors.marketingConsent
            : undefined
        }
      />
      <TermsConditionsConsent
        name="termsConditionsConsent"
        checked={!!form.values.termsConditionsConsent}
        onChange={value => form.setFieldValue("termsConditionsConsent", value)}
        error={
          form.touched.termsConditionsConsent
            ? form.errors.termsConditionsConsent
            : undefined
        }
      />

      {error && (
        <ErrorText>
          {error === "CONFLICT" ? (
            <>
              This email is already used, try another or{" "}
              <Link
                to={`/login/${companyUrlSafeName}`}
                style={{ display: "inline" }}
              >
                sign in
              </Link>
              .
            </>
          ) : error === "SSO_ENFORCED" ? (
            "Your company is enforcing SSO, sign up with SSO instead."
          ) : (
            "Something went wrong. Try again."
          )}
        </ErrorText>
      )}
      <Button level="primary" onClick={form.submitForm} disabled={loading}>
        {loading ? "Loading..." : "Sign up with password"}
      </Button>
    </VerticalSpacing>
  );
}
