import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { MessageTemplateValue } from "./MessageRequestTemplateSelector";

import { handleMutationError } from "@hire/errors";
import {
  AllTemplatesDocument,
  CreateMessageTemplateDocument,
  MessageTemplate,
  MessageTemplateTypeEnum,
} from "@hire/schema";
import {
  Button as DefaultButton,
  VerticalSpacing,
  InputField,
} from "@otta/design";

const Button = styled(DefaultButton)`
  width: 100%;
  display: block;
`;
export const messageTemplateMutationOptions = {
  awaitRefetchQueries: true,
  refetchQueries: [
    {
      query: AllTemplatesDocument,
      variables: { messageTemplateType: MessageTemplateTypeEnum.Sourcing },
    },
  ],
  onError: handleMutationError,
};

interface FormFields {
  templateName: string;
}

interface MessageRequestCreateTemplateFormProps {
  allTemplates: MessageTemplate[];
  message: string;
  onCreated: (value: MessageTemplateValue) => void;
  onClose: () => void;
}

export function MessageRequestCreateTemplateForm({
  allTemplates,
  message,
  onCreated,
  onClose,
}: MessageRequestCreateTemplateFormProps): React.ReactElement {
  const [createMessageTemplateMutation] = useMutation(
    CreateMessageTemplateDocument,
    messageTemplateMutationOptions
  );

  const allTemplateNames = allTemplates.map(template => template.name);

  const handleSubmit = (values: FormFields) => {
    if (!message || !values.templateName) {
      return;
    }

    createMessageTemplateMutation({
      variables: {
        message,
        name: values.templateName,
        type: MessageTemplateTypeEnum.Sourcing,
      },
      onError: handleMutationError,
      onCompleted: data => {
        toast.success("Template created");

        onClose();
        onCreated({
          id: data.createMessageTemplate.id,
          name: data.createMessageTemplate.name,
        });
      },
    });
  };

  const form = useFormik<FormFields>({
    initialValues: {
      templateName: "",
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      templateName: Yup.string()
        .required("Template name is required")
        .label("Template name")
        .test({
          name: "name-already-exists",
          message: ({ label }) => `${label} already exists`,
          test: value => !allTemplateNames.includes(value),
        }),
    }),
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <VerticalSpacing size={-4}>
        <InputField
          name="templateName"
          type="text"
          value={form.values.templateName}
          label="Template name"
          onChange={form.handleChange}
          error={
            form.touched.templateName ? form.errors.templateName : undefined
          }
        />
        <Button level="primary" type="submit" disabled={!message}>
          Create new template
        </Button>

        <Button level="secondary" type="button" onClick={onClose}>
          Cancel
        </Button>
      </VerticalSpacing>
    </form>
  );
}
