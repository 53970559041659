import { createContext, useCallback, useEffect, useState } from "react";

interface SensitiveInfoProviderProps {
  children: React.ReactNode;
}

interface SensitiveInfoContext {
  setSensitiveInfoSetting: (isActive: boolean) => void;
  sensitiveFieldsHidden: boolean;
}

export const SensitiveInfoContext = createContext<SensitiveInfoContext>({
  setSensitiveInfoSetting: noop => noop,
  sensitiveFieldsHidden: false,
});

export function SensitiveInfoProvider({
  children,
}: SensitiveInfoProviderProps): React.ReactElement {
  const [sensitiveFieldsHidden, setSensitiveFieldsHidden] = useState(false);

  const setFields = useCallback((isActive: boolean) => {
    localStorage.setItem("otta-sensitive-fields-hidden", `${isActive}`);
    setSensitiveFieldsHidden(isActive);
  }, []);

  const setSensitiveInfoSetting = useCallback(
    (isActive: boolean) => {
      setFields(isActive);
    },
    [setFields]
  );

  useEffect(() => {
    const localStorageSetting = localStorage.getItem(
      "otta-sensitive-fields-hidden"
    );
    setFields(
      localStorageSetting
        ? JSON.parse(localStorageSetting?.toLowerCase())
        : false
    );
  }, []);

  return (
    <SensitiveInfoContext.Provider
      value={{ setSensitiveInfoSetting, sensitiveFieldsHidden }}
    >
      {children}
    </SensitiveInfoContext.Provider>
  );
}
