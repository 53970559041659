import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";

import { Button, Heading, InputField, Spacing } from "@otta/design";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { SendUserEmailVerificationDocument } from "@hire/schema";
import { handleMutationError } from "@hire/errors";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email address")
    .required("Enter your email address")
    .min(5, "Email address must be at least 5 characters"),
});

export function SendEmail() {
  const [sendEmailMutation, { called, loading, error }] = useMutation(
    SendUserEmailVerificationDocument,
    {
      onError: handleMutationError,
    }
  );

  const form = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    async onSubmit(values) {
      await sendEmailMutation({ variables: { email: values.email } });
    },
  });

  if (called && !loading && !error) {
    return (
      <ResponsiveCenteredContainer maxWidth={550}>
        <Spacing>
          <Heading bold size={3} align="center">
            Email sent
          </Heading>
          <p>
            If you have an account with us, you will receive an email with a
            link to verify your email address.
          </p>
        </Spacing>
      </ResponsiveCenteredContainer>
    );
  }

  return (
    <ResponsiveCenteredContainer maxWidth={550}>
      <Spacing>
        <Heading bold size={3} align="center">
          Confirm your email
        </Heading>
        <form onSubmit={form.handleSubmit}>
          <Spacing>
            <InputField
              name="email"
              type="email"
              label="Email"
              autoFocus
              autoComplete="email"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.email}
              error={form.touched.email ? form.errors.email : undefined}
              disabled={form.isSubmitting}
            />
            <Button type="submit" level="primary" disabled={form.isSubmitting}>
              Send verification email
            </Button>
          </Spacing>
        </form>
      </Spacing>
    </ResponsiveCenteredContainer>
  );
}
