import { GreenhouseForm } from "./GreenhouseForm";
import { AshbyForm } from "./AshbyForm";

import { CompanyAts, CompanyAtsApiKeyTypeEnum } from "@hire/schema";
import { ErrorText } from "@otta/design";
import { Experiment } from "@hire/constants/experiments";
import { useExperiment } from "@otta/experiments/client";

export function IntegrationForm({
  ats,
  companyId,
  atsApiKeys,
  refetch,
  refetching,
  trackingCode,
  webhooks,
}: {
  refetching: boolean;
  refetch(): Promise<unknown>;
  ats: CompanyAts;
  companyId: string;
  atsApiKeys: { type: CompanyAtsApiKeyTypeEnum }[];
  webhooks: {
    requestedTopic: string | null;
    key: string;
    secretKey: string | null;
    lastRequestedAt: string | null;
  }[];
  trackingCode: string | null;
}) {
  const { variant } = useExperiment(Experiment.AshbyIntegration);
  const ashbyEnabled = variant === "variant";

  const showAshby = ashbyEnabled && ats === CompanyAts.Ashby;
  if (ats === CompanyAts.Greenhouse) {
    return (
      <GreenhouseForm
        companyId={companyId}
        atsApiKeys={atsApiKeys}
        refetch={refetch}
        refetching={refetching}
        trackingCode={trackingCode}
        webhooks={webhooks}
      />
    );
  } else if (showAshby) {
    return (
      <AshbyForm
        atsApiKeys={atsApiKeys}
        refetch={refetch}
        refetching={refetching}
        webhooks={webhooks}
      />
    );
  }
  return <ErrorText>Sorry, something went wrong.</ErrorText>;
}
