import { useMemo } from "react";
import { Props } from "react-select";

import { Location } from "@hire/schema";
import { SelectField } from "@otta/design";
import {
  CA_LOCATIONS,
  EU_LOCATIONS,
  REMOTE_LOCATIONS,
  UK_LOCATIONS,
  US_LOCATIONS,
  locationToLocationOption,
} from "@hire/util/locations";

// version of locationToLocationOption that is compatible with flatMap
function toOptionList(loc: Location): { label: string; value: Location }[] {
  const mapped = locationToLocationOption(loc);
  return mapped
    ? [{ label: mapped.label, value: mapped.value as Location }]
    : [];
}

function notRemote(v: Location): boolean {
  return !v.startsWith("REMOTE_");
}

type FieldProps = Omit<
  Props<{ value: Location; label: string }, true>,
  "options" | "value"
> & {
  value: Location[];
};

function useOptions() {
  return useMemo(
    () => [
      {
        label: "Fully remote",
        options: REMOTE_LOCATIONS.flatMap(toOptionList),
      },
      {
        label: "United States",
        options: US_LOCATIONS.filter(notRemote).flatMap(toOptionList),
      },
      {
        label: "Europe",
        options: [...EU_LOCATIONS, ...UK_LOCATIONS]
          .sort()
          .filter(notRemote)
          .flatMap(toOptionList),
      },
      {
        label: "Canada",
        options: CA_LOCATIONS.filter(notRemote).flatMap(toOptionList),
      },
    ],
    []
  );
}

export function LocationField({ value, ...props }: FieldProps) {
  const opts = useOptions();

  const values = useMemo(
    () => opts.flatMap(o => o.options).filter(o => value.includes(o.value)),
    [opts, value]
  );

  return (
    <SelectField
      options={opts}
      value={values}
      {...props}
      isMulti
      closeMenuOnSelect={false}
    ></SelectField>
  );
}
