import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import styled, { css, up } from "@xstyled/styled-components";

import { Step } from "../Steps";

import {
  UpsertCompanyAtsApiKeyDocument,
  CompanyAtsApiKeyTypeEnum,
  AtsIntegrationDocument,
} from "@hire/schema";
import { Button, Input, Middle, Spacing, Text } from "@otta/design";
import { CopyPasteMatrix } from "@hire/components/input/CopyPasteMatrix";
import { CopyableField } from "@hire/components/input/CopyableField";
import { SelectBox } from "@hire/components/input/SelectBox";
import { Link } from "@hire/components/links/Link";

const ButtonPostion = styled(Middle)`
  width: 100%;

  ${up(
    "tablet",
    css`
      width: auto;
    `
  )}
`;

interface GreenhouseAPIStepProps {
  companyId: string;
}

export function JobBoardAPIKey({
  companyId,
}: GreenhouseAPIStepProps): React.ReactElement {
  const [apiKey, setApiKey] = useState<string | null>();

  const [upsertCompanyAtsApiKey, { loading }] = useMutation(
    UpsertCompanyAtsApiKeyDocument,
    {
      variables: {
        type: CompanyAtsApiKeyTypeEnum.GreenhouseJobBoard,
        companyId,
        key: apiKey as string,
      },
      update: (cache, { data: atsData }) => {
        if (!atsData?.upsertCompanyAtsApiKey) {
          return;
        }

        const cacheData = cache.readQuery({
          query: AtsIntegrationDocument,
        });

        const currentAtsApiKeys =
          cacheData?.currentUser?.currentCompany?.atsApiKeys ?? [];

        cache.writeFragment({
          id: cache.identify({ __typename: "Company", id: companyId }),
          fragment: gql`
            fragment CompanyApiKeys on Company {
              atsApiKeys
            }
          `,
          data: {
            atsApiKeys: [
              ...currentAtsApiKeys.filter(
                key => key.type !== CompanyAtsApiKeyTypeEnum.GreenhouseJobBoard
              ),
              atsData.upsertCompanyAtsApiKey,
            ],
          },
        });
      },
    }
  );

  return (
    <Spacing size={2}>
      <Step idx={1}>
        <Link to="https://app.greenhouse.io/configure/dev_center/credentials">
          Open &apos;API Credentials&apos; in Greenhouse (you may need to sign
          in first)
        </Link>
      </Step>
      <Step idx={2}>
        <Text>
          Click the{" "}
          <Text as="span" bold>
            &apos;Create New API Key&apos;
          </Text>{" "}
          button
        </Text>
      </Step>
      <Step idx={3}>
        <CopyPasteMatrix
          helpText="Enter the following fields in the pop-up window."
          rows={[
            { label: "API Type", element: <SelectBox>Job Board</SelectBox> },
            {
              label: "Description",
              element: <CopyableField value="Otta Integration" />,
            },
          ]}
        />
      </Step>
      <Step idx={4}>
        <Text>
          Click the{" "}
          <Text as="span" bold>
            &apos;Create&apos;
          </Text>{" "}
          button.
        </Text>
      </Step>
      <Step idx={5}>
        <Spacing size={-1}>
          <Text>
            Copy the API Key that was created from the box into this field:
          </Text>
          <Input
            value={apiKey || ""}
            onChange={e => setApiKey(e.target.value)}
            onBlur={() => setApiKey(apiKey?.trim() ?? null)}
            type="text"
            name="api-key"
            placeholder="Paste your API key here..."
            data-testid="input-api-key"
          />
        </Spacing>
      </Step>
      <Step idx={6}>
        <Text>
          Click the{" "}
          <Text as="span" bold>
            &apos;I have stored the API Key&apos;
          </Text>{" "}
          button.
        </Text>
      </Step>
      <ButtonPostion>
        <Button
          onClick={() => upsertCompanyAtsApiKey()}
          type="button"
          level="primary"
          data-testid="key-advance-button"
          disabled={!apiKey || loading}
        >
          Save and Continue
        </Button>
      </ButtonPostion>
    </Spacing>
  );
}
