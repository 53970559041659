import { Spacing, Text } from "@otta/design";
import { CopyTextField } from "@hire/components/CopyTextField";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

export const PageHeading = ({ urlSafeName }: { urlSafeName: string }) => {
  return (
    <Spacing>
      <Text size={2} bold>
        Employee endorsements
      </Text>
      <Text>
        Endorsements are a positive space for existing employees to share what
        makes your company great.
      </Text>
      <CopyTextField
        value={`${
          import.meta.env.VITE_SEARCH_APP_HOST
        }/companies/${urlSafeName}/write-an-endorsement`}
        label="Share this link for employees to add endorsements:"
        dataId="copy-endorsements-link"
      />

      <RoundedTag
        icon="clock"
        content={
          <Text size={-1}>
            Endorsements are typically published&nbsp;
            <strong>2 business days</strong>
            &nbsp;after submission.
          </Text>
        }
        center
      />
    </Spacing>
  );
};
