import { Text } from "@otta/design";

export const Header = ({
  numberOfCandidates,
}: {
  numberOfCandidates: number;
}): React.ReactElement => (
  <Text bold>
    Message {numberOfCandidates}{" "}
    {numberOfCandidates > 1 ? "candidates" : "candidate"}
  </Text>
);
