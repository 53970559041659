import styled from "@xstyled/styled-components";

import { Currency } from "@hire/schema";
import { CurrencyMoneyRange } from "@otta/design";
import { CURRENCY_OPTIONS } from "@hire/util/currencies";
import { SalaryRange } from "@hire/util/salaries";
import { palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const IconButton = styled.div`
  display: flex;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
`;

export interface AccessibilityProps {
  "aria-describedby": string;
  "aria-invalid": boolean;
}

export const SalaryRangeField = ({
  value,
  onBlur: handleBlur,
  onChange: handleChange,
  onRemove: handleRemove,
  min,
  max,
}: {
  value: {
    minAmount: number | null;
    maxAmount: number | null;
    currency: Currency;
  };
  onBlur?: () => void;
  onChange: (newSalaryRange: SalaryRange) => void;
  onRemove?: () => void;
  min?: AccessibilityProps;
  max?: AccessibilityProps;
}): React.ReactElement => {
  return (
    <Row>
      <CurrencyMoneyRange
        values={value}
        options={CURRENCY_OPTIONS}
        placeholders={{
          minAmount: "40,000",
          maxAmount: "70,000",
        }}
        onChange={(salaryRange: SalaryRange) => {
          handleChange(salaryRange);
        }}
        onBlur={handleBlur}
        min={min}
        max={max}
      />
      {!!handleRemove && (
        <IconButton
          role="button"
          onClick={handleRemove}
          aria-label="Remove on target earnings"
        >
          <Icon icon="delete" style={{ color: palette.brand.red }} size={2} />
        </IconButton>
      )}
    </Row>
  );
};
