import Skeleton from "react-loading-skeleton";

import { ProfileColumn, ProfileContainer } from "./shared";

import { Spacing } from "@otta/design";

export function CandidateLoading(): React.ReactElement {
  return (
    <ProfileContainer>
      <ProfileColumn>
        <Skeleton count={4} />
      </ProfileColumn>
      <ProfileColumn>
        <Spacing size={-4}>
          <Skeleton count={2} />
          <Skeleton count={2} />
        </Spacing>
      </ProfileColumn>
    </ProfileContainer>
  );
}
