import { Draggable } from "@hello-pangea/dnd";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useContext } from "react";

import {
  isImportedCandidatePipelineItem,
  isOttaCandidatePipelineItem,
} from "../../../helpers";
import { CandidateDrawerContext } from "../../../Candidate/ImportedCandidateProfile/CandidateDrawerProvider";

import { ImportedCandidate } from "./ImportedCandidate";
import { OttaCandidate } from "./OttaCandidate";

import { CandidatePipelineItemFragment, StageType } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { Card } from "@otta/design";

interface PipelineItemProps {
  candidateItem: CandidatePipelineItemFragment;
  stageType: StageType;
  index: number;
  stageName: string;
  selectedItems: string[];
  toggleItemSelection: (itemIds: string[]) => void;
}

const StyledCard = styled(Card)<{
  isDragging: boolean;
  transform: string | undefined;
}>`
  cursor: grab;
  position: relative;
  padding: md;
  margin-bottom: sm;
  box-shadow: 0px 2px 4px rgba(195, 195, 186, 0.05);
  transition: default;
  transition-property: box-shadow;
  &:hover {
    box-shadow: 0px 4px 8px rgba(195, 195, 186, 0.3);
  }

  ${({ isDragging, transform }) =>
    isDragging &&
    transform &&
    `
      transform: ${transform} rotate(3deg) !important;
      background: ${palette.grayscale.shade50};
      box-shadow: 0px 4px 8px rgba(195, 195, 186, 0.3);
      `}

  &:hover {
    label {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const PipelineItem = ({
  candidateItem: item,
  stageType,
  stageName,
  index,
  selectedItems,
  toggleItemSelection,
}: PipelineItemProps): React.ReactElement => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setCurrentCandidatePipelineItem } = useContext(
    CandidateDrawerContext
  );

  const handleCardClick = () => {
    const candidateId = item.candidate?.externalId;
    if (candidateId) {
      navigate({
        pathname: `candidate/${candidateId}/profile`,
        search: searchParams.toString(),
      });
    } else {
      setCurrentCandidatePipelineItem(item);
      navigate({
        pathname: `candidate/${item.importedCandidate?.externalId}/profile/imported`,
        search: searchParams.toString(),
      });
    }
  };

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        return (
          <StyledCard
            data-testid={`pipeline-item-${stageName}`}
            isDragging={snapshot.isDragging}
            transform={provided.draggableProps.style?.transform}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={{
              ...provided.draggableProps.style,
            }}
            onClick={handleCardClick}
          >
            {isOttaCandidatePipelineItem(item) && (
              <OttaCandidate
                item={item}
                stageType={stageType}
                isSelected={selectedItems.includes(item.id)}
                selectionModeEnabled={selectedItems.length > 0}
                toggleItemSelection={toggleItemSelection}
              />
            )}
            {isImportedCandidatePipelineItem(item) && (
              <ImportedCandidate item={item} stageType={stageType} />
            )}
          </StyledCard>
        );
      }}
    </Draggable>
  );
};
