import styled from "@xstyled/styled-components";

import { BulkMessageData } from "../types";

import { Form } from "./Form";
import { Header } from "./Header";

import { Loading } from "@otta/shared-components";
import { pxToRem } from "@otta/design-tokens";
import { Modal } from "@otta/design";

const ModalContents = styled.div`
  padding: 28;
`;

interface BulkActionModalProps {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  stageOptions: { value: string; label: string }[];
  numCandidates: number;
  loading: boolean;
  messageState: BulkMessageData;
  onSubmit: (data: BulkMessageData) => void;
}

export const BulkActionModal = ({
  open,
  onOpenChange,
  stageOptions,
  numCandidates,
  loading,
  messageState,
  onSubmit,
}: BulkActionModalProps): React.ReactElement => {
  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      headerContent={<Header numberOfCandidates={numCandidates} />}
      style={{ overflow: "hidden", maxHeight: pxToRem(900) }}
    >
      <ModalContents>
        {loading ? (
          <Loading />
        ) : (
          <Form
            onSubmit={onSubmit}
            stageOptions={stageOptions}
            messageState={messageState}
            numCandidates={numCandidates}
            handleClose={() => {
              onOpenChange(false);
            }}
          />
        )}
      </ModalContents>
    </Modal>
  );
};
