import styled, { css, down } from "@xstyled/styled-components";

import { JobBroadcastQuery } from "@hire/schema";
import {
  palette,
  modularScale,
  pxToRem,
  borderRadius,
  boxShadow,
} from "@otta/design-tokens";
import {
  VerticalSpacing,
  Text,
  Spacing,
  Paragraph,
  Logo,
  Button,
  Card,
} from "@otta/design";
import { CircularImg } from "@hire/pages/CompanySettings/Team/CircularImg";
import { getSalaryInfo } from "@hire/util/salaries";
import { OttaCertifiedBadge } from "@hire/components/OttaCertfiedBadge";
import { pluraliser } from "@hire/util/strings";
import { Link } from "@hire/components/links";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 28;
`;

const StyledButton = styled(Button)`
  ${down(
    "tablet",
    css`
      padding: sm md;
    `
  )}
  font-size: ${modularScale()};
`;
const WrapperCard = styled(Card)`
  border-radius: ${pxToRem(borderRadius)};
`;

const PreviewCard = styled.div`
  border-radius: ${pxToRem(borderRadius)};
  background: white;
  margin-top: xl;
  box-shadow: ${boxShadow};
`;
const ProfileImageWrapper = styled.div`
  margin-right: lg;
`;

const EmailHeader = styled.div`
  padding: lg;
  text-align: left;
`;

const YellowContainer = styled.div`
  padding: lg;
  border-radius: 0 0 ${pxToRem(borderRadius)} ${pxToRem(borderRadius)};
  background-color: ${palette.brand.yellow};
`;

const InnerCard = styled.div`
  border-radius: ${pxToRem(borderRadius)};
  padding: lg;
  background-color: ${palette.brand.white};
`;

const MessageContainer = styled.div`
  border-radius: ${pxToRem(8)};
  padding: lg;
  background-color: ${palette.grayscale.shade50};
`;

const OttaLogo = styled(Logo)`
  height: ${modularScale(2)};
  width: auto;
`;

const CompanyLogo = styled.img`
  height: ${modularScale(2)};
  width: auto;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledParagraph = styled(Paragraph)`
  text-align: left;
  white-space: pre-wrap;
`;

const RecruiterInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FakeButton = styled.div`
  padding: sm lg;
  background-color: ${palette.brand.yellow};
  text-align: center;
  border-radius: ${pxToRem(40)};
  font-size: ${modularScale()};
  font-weight: bold;
`;

const FakeLink = styled(Text)`
  text-decoration: underline;
`;

export interface JobBroadcastProps {
  numberCandidates: number | undefined;
  job: NonNullable<JobBroadcastQuery["companyJob"]>;
  recruiter: NonNullable<JobBroadcastQuery["currentUser"]>;
  company: NonNullable<
    NonNullable<JobBroadcastQuery["currentUser"]>["currentCompany"]
  >;
  submitLoading: boolean;
  ottaCertified: boolean;
}

export function PreviewJobBroadcast({
  job,
  recruiter,
  company,
  onCancelPreview,
  numberCandidates,
  message,
  onSubmit,
  submitLoading,
  ottaCertified,
}: {
  message: string;
  onCancelPreview: () => void;
  onSubmit: () => void;
} & JobBroadcastProps): React.ReactElement {
  const salaryInfo = getSalaryInfo(job.salaryRange, job.hideSalary);
  const companyName = useWelcomeToTheJungle();

  return (
    <VerticalSpacing size={1}>
      <WrapperCard>
        <Text align="left" bold size={0}>
          Here&apos;s how your broadcast will appear to candidates
        </Text>
        <PreviewCard>
          <EmailHeader>
            <VerticalSpacing size={-4}>
              <Text size={-1}>
                News from {company.name} about a job you saved
              </Text>
              <Text bold size={-1}>
                {companyName}{" "}
                <Text as="span" size={-1} color={palette.grayscale.shade400}>
                  &lt;hello@otta.com&gt;
                </Text>
              </Text>
              <Text size={-1} color={palette.grayscale.shade400}>
                to CandidateName
              </Text>
            </VerticalSpacing>
          </EmailHeader>
          <YellowContainer>
            <VerticalSpacing>
              <LogoWrapper>
                <OttaLogo />
              </LogoWrapper>
              <InnerCard>
                <VerticalSpacing>
                  <Text align="left">Hey &#123;candidateName&#125;</Text>
                  <Text align="left" data-cs-mask>
                    Here&apos;s an update from {recruiter.firstName} at{" "}
                    {company.name} about a job that you saved recently:
                  </Text>
                  <MessageContainer>
                    <Spacing>
                      {company.logoPath && (
                        <LogoWrapper>
                          <CompanyLogo src={company.logoPath} />
                        </LogoWrapper>
                      )}
                      {ottaCertified && (
                        <LogoWrapper>
                          <OttaCertifiedBadge content="Certified Company" />
                        </LogoWrapper>
                      )}
                      <Text align="center" bold>
                        {job.title}
                        {job.subtitle && ` (${job.subtitle})`}{" "}
                        {salaryInfo && `• ${salaryInfo}`}
                      </Text>
                      <StyledParagraph>{message}</StyledParagraph>
                      <RecruiterInfoContainer>
                        {recruiter.profileImagePath &&
                          !recruiter.profileImagePath.includes("default") && (
                            <ProfileImageWrapper>
                              <CircularImg
                                src={recruiter.profileImagePath}
                                width="40px"
                                alt="Profile Image"
                              />
                            </ProfileImageWrapper>
                          )}
                        <Text data-cs-mask>
                          {recruiter.firstName} {recruiter.lastName}
                          {recruiter.currentTitle
                            ? `, ${recruiter.currentTitle} at ${company.name}`
                            : `, ${company.name}`}
                        </Text>
                      </RecruiterInfoContainer>
                    </Spacing>
                  </MessageContainer>
                  <Text align="left">
                    Interested? Apply for this job at {company.name} now.
                  </Text>
                  <FakeButton>Apply to {company.name}</FakeButton>
                  <FakeLink bold align="center" size={-1}>
                    I&apos;ve already applied for this job
                  </FakeLink>
                </VerticalSpacing>
              </InnerCard>
              <Text align="left" size={-1}>
                This email is from {companyName} - if you have any questions or
                feedback, let us know by replying to{" "}
                <Link to="mailto:hello@otta.com">hello@otta.com</Link>
              </Text>
              <Text align="left" size={-1}>
                Unsubscribe from all saved job updates
              </Text>
              <Text align="left" size={-1}>
                {companyName} is a trading name of etc etc
              </Text>
            </VerticalSpacing>
          </YellowContainer>
        </PreviewCard>
        <ButtonsContainer>
          <StyledButton
            level="secondary"
            onClick={onCancelPreview}
            type="button"
          >
            Edit your message
          </StyledButton>
          <StyledButton
            level="primary"
            onClick={onSubmit}
            disabled={submitLoading}
            data-analytics-id="broadcast-send-button"
          >
            {submitLoading
              ? "Loading..."
              : `Send to ${numberCandidates} ${pluraliser(
                  numberCandidates ?? 0,
                  "candidate"
                )}`}
          </StyledButton>
        </ButtonsContainer>
      </WrapperCard>
    </VerticalSpacing>
  );
}
