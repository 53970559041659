import { useQuery } from "@apollo/client";

import { CurrentUserDocument } from "@hire/schema";
import { hireAppUser } from "@hire/util/user";

export function useCurrentUser() {
  const { data, loading, error } = useQuery(CurrentUserDocument);

  return [hireAppUser(data?.me), { loading, error }] as const;
}
