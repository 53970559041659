import styled from "@xstyled/styled-components";
import { useContext } from "react";

import { ProfileColumn, ProfileContainer } from "../shared";
import { CandidateCardActions } from "../interfaces";

import { CandidateWorkedWithTechnologies } from "./CandidateWorkedWithTechnologies";
import { CandidateLookingForCompanyQualities } from "./CandidateLookingForCompanyQualities";
import { CandidateWorkExpSummary } from "./CandidateWorkExpSummary";
import { CandidateEducationSummary } from "./CandidateEducationSummary";
import { CandidateBadges } from "./CandidateBadges";
import { CandidateActionButtons } from "./CandidateActionButtons";
import { CandidateAlreadyMessaged } from "./CandidateAlreadyMessaged";

import { CandidateCardFragment, CandidateCardJobQuery } from "@hire/schema";
import { Text, VerticalSpacing } from "@otta/design";
import { CandidateProfileLinks } from "@hire/components/CandidateProfileLinks";
import { SensitiveInfoContext } from "@hire/providers/SensitiveInfo";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface CandidateSummaryProps extends CandidateCardActions {
  candidate: CandidateCardFragment;
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
  hideHeader?: boolean;
}

export function CandidateSummary({
  candidate,
  job,
  hideHeader,
  ...rest
}: CandidateSummaryProps): React.ReactElement {
  const { sensitiveFieldsHidden } = useContext(SensitiveInfoContext);
  return (
    <ProfileContainer>
      <ProfileColumn>
        <VerticalSpacing>
          <VerticalSpacing>
            {!hideHeader && (
              <HeaderContainer>
                <Text
                  data-cs-mask
                  data-sensitive-field={sensitiveFieldsHidden}
                  bold
                  size={1}
                >
                  {candidate.firstName} {candidate.lastName}
                </Text>
                <CandidateProfileLinks candidate={candidate} />
              </HeaderContainer>
            )}
            <CandidateBadges candidate={candidate} job={job} />
            <CandidateActionButtons candidate={candidate} {...rest} />
            <CandidateAlreadyMessaged candidate={candidate} />
          </VerticalSpacing>
        </VerticalSpacing>
      </ProfileColumn>
      <ProfileColumn>
        <VerticalSpacing size={-4}>
          <CandidateWorkExpSummary candidate={candidate} />
          <CandidateEducationSummary candidate={candidate} />
          <CandidateWorkedWithTechnologies candidate={candidate} job={job} />
          <CandidateLookingForCompanyQualities candidate={candidate} />
        </VerticalSpacing>
      </ProfileColumn>
    </ProfileContainer>
  );
}
