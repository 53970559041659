import { useQuery } from "@apollo/client";
import styled, { css, up } from "@xstyled/styled-components";
import { useMemo } from "react";

import { ATSConnected } from "./ATSConnected";
import { AddNewJob } from "./AddNewJob";
import { JobCard } from "./Job";
import { ViewAllJobs } from "./ViewAllJobs";

import { Feature, JobsPreviewDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { pxToRem } from "@otta/design-tokens";
import { Text } from "@otta/design";
import { useAuthorizations } from "@hire/providers/authorization";
import { hireAppUser } from "@hire/util/user";
import { useRecentlyViewedJobs } from "@hire/hooks/useRecentlyViewedJobs";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

const Row = styled.div`
  display: flex;
  gap: lg;
  flex-direction: column;
  ${up(
    "tablet",
    css`
      flex-direction: row;
    `
  )}
`;

const Item = styled.div`
  max-width: ${pxToRem(450)};
  overflow: hidden;
  flex: 1;
`;

const MAX_JOBS = 3;

export const JobsPreview = () => {
  const { features } = useAuthorizations([Feature.Scraped, Feature.CreateJob]);

  const { data, loading } = useQuery(JobsPreviewDocument);

  const company = hireAppUser(data?.me)?.currentCompany;

  const companyName = useWelcomeToTheJungle();

  const jobs = useMemo(() => company?.listJobs ?? [], [company?.listJobs]);
  const total = company?.totalJobs ?? 0;

  const recentlyViewedJobIds = useRecentlyViewedJobs();
  const sortedJobs = useMemo(
    () =>
      [...jobs]
        .sort(
          (a, b) =>
            recentlyViewedJobIds.findIndex(x => x === b.externalId) -
            recentlyViewedJobIds.findIndex(x => x === a.externalId)
        )
        .slice(0, MAX_JOBS),
    [jobs, recentlyViewedJobIds]
  );

  if (loading) {
    return <Loading />;
  }

  if (!sortedJobs.length && features.get(Feature.Scraped)?.granted) {
    return <ATSConnected />;
  }

  const canPostJob = features.get(Feature.CreateJob)?.granted;

  if (!sortedJobs.length && !canPostJob) {
    return <Text>You don&apos;t have any jobs on {companyName}</Text>;
  }

  const jobCards = sortedJobs.map(j => (
    <Item key={j.id}>
      <JobCard
        externalId={j.externalId}
        title={j.title}
        subtitle={j.subtitle}
        postedAt={j.firstLiveAt}
        applications={j.numberNewApplicants ?? 0}
        unread={j.numberUnreadMessages ?? 0}
        hasAccess={j.hasAccess}
      />
    </Item>
  ));

  if (jobCards.length < MAX_JOBS) {
    return (
      <Row>
        {jobCards}
        {canPostJob && (
          <Item>
            <AddNewJob />
          </Item>
        )}
      </Row>
    );
  }

  const additionalJobs = total - MAX_JOBS;

  return (
    <Row>
      {jobCards}
      {additionalJobs > 0 && <ViewAllJobs total={additionalJobs} />}
    </Row>
  );
};
