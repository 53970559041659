import styled from "@xstyled/styled-components";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { JobSlotsModal } from "../JobSlotsModal";

import { JobInfoDocument, JobInfoQuery } from "@hire/schema";
import { Button, Card, Text } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const IconContainer = styled.div`
  width: ${pxToRem(24)};
  display: inline;
  margin-right: sm;
`;

const ButtonContainer = styled.div`
  margin-top: xl;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1em;
`;

const LineItem = styled(Text)<{ hasMargin?: boolean }>`
  display: flex;
  align-items: center;
  margin: ${({ hasMargin }) => hasMargin && `lg 0`};
`;

const StyledButton = styled(Button)`
  width: 50%;
`;

export const UNFREEZE_JOB_LOCAL_STORAGE_ENTRY = "20240628_UNFREEZE_JOB";

export function UnfreezeJob() {
  const { jobExternalId } = useParams();
  const { data: jobData, loading: jobLoading } = useQuery(JobInfoDocument, {
    variables: { jobId: jobExternalId as string },
  });

  if (!jobExternalId || jobLoading || !jobData?.companyJob) {
    return null;
  }

  return <UnfreezeJobComponent job={jobData.companyJob} />;
}

const UnfreezeJobComponent = ({
  job,
}: {
  job: NonNullable<JobInfoQuery["companyJob"]>;
}) => {
  const { companySlug } = useParams<"companySlug">();

  return (
    <JobSlotsModal name="unfreeze-job" redirectOnClose={`/${companySlug}/jobs`}>
      <Text size={2} bold style={{ marginBottom: pxToRem(24) }}>
        Introducing job slots
      </Text>
      <Card style={{ backgroundColor: palette.beige.shade200 }}>
        <LineItem>
          <IconContainer>
            <Icon icon="play-pause" size={2} />
          </IconContainer>
          <div>
            <Text bold inline>
              {`No commitment. `}
            </Text>
            Pay monthly, cancel online anytime.
          </div>
        </LineItem>

        <LineItem hasMargin>
          <IconContainer>
            <Icon icon="salary" size={2} />
          </IconContainer>
          <div>
            <Text bold inline>
              {`Pay for what you need. `}
            </Text>
            Flex up and down with your hiring plans.
          </div>
        </LineItem>

        <LineItem>
          <IconContainer>
            <Icon icon="users" size={2} />
          </IconContainer>
          <div>
            <Text bold inline>
              {`Over 2 million candidates. `}
            </Text>
            An application sent every 3 seconds.
          </div>
        </LineItem>
      </Card>

      <Text style={{ margin: `${pxToRem(24)} 0` }}>
        Our pricing is changing.
      </Text>
      <Text>
        To hire for your
        <Text bold inline>
          {` ${job.title} `}
        </Text>
        role, purchase a job slot.
      </Text>
      <ButtonContainer>
        <StyledButton
          level="primary"
          as={Link}
          to={`/${companySlug}/jobs/pricing/${job.externalId}`}
          onClick={() => {
            localStorage.setItem(UNFREEZE_JOB_LOCAL_STORAGE_ENTRY, "true");
          }}
        >
          Continue to pricing
        </StyledButton>
        <StyledButton
          level="destructive"
          as={Link}
          to="../why-otta"
          state={{ redirect: `/${companySlug}/jobs/pricing/${job.externalId}` }}
          onClick={() => {
            localStorage.setItem(UNFREEZE_JOB_LOCAL_STORAGE_ENTRY, "true");
          }}
        >
          Maybe later
        </StyledButton>
      </ButtonContainer>
    </JobSlotsModal>
  );
};
