import styled from "@xstyled/styled-components";

import { palette, modularScale } from "@otta/design-tokens";

interface IInputProps {
  error?: boolean;
}

export const Input = styled.input<IInputProps>`
  display: block;
  width: 100%;
  border-radius: 5px;
  font-size: ${modularScale()};
  font-family: inherit;
  padding: sm md;
  border: ${({ error }) =>
    error
      ? `2px solid ${palette.brand.red}`
      : `1px solid ${palette.grayscale.shade400}`};
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[aria-invalid="true"] {
    border: 2px solid ${palette.brand.red};
  }

  &:focus {
    margin: -1px;
    border: 2px solid ${palette.brand.black};
    outline: none;
  }

  ::placeholder {
    color: ${palette.grayscale.shade400};
  }
`;
