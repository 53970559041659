import styled from "@xstyled/styled-components";

type AlignType = "center" | "start" | "end";

export const ButtonWrapper = styled.div<{ align: AlignType }>`
  display: flex;
  justify-content: ${({ align }) => align};
  width: 100%;
  gap: sm;
`;
