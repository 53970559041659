import { useMutation, useQuery } from "@apollo/client";

import { CurrentUserDocument, DeleteCurrentUserDocument } from "@hire/schema";
import { Button, Card, Middle, Spacing, Text } from "@otta/design";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";

export function DeleteMyAccount(): React.ReactElement | null {
  const { data } = useQuery(CurrentUserDocument);

  const [deleteMutation] = useMutation(DeleteCurrentUserDocument);

  const userEmail = data?.me?.email;

  const handleDelete = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this account? This cannot be undone."
      )
    ) {
      const emailPrompt = window.prompt("Type your email to confirm");
      if (emailPrompt === userEmail) {
        await deleteMutation();
        window.alert("Your account has been successfully deleted.");
        window.location.reload();
      } else {
        window.alert("Incorrect email.");
      }
    }
  };

  if (!userEmail) {
    return null;
  }

  return (
    <ResponsiveCenteredContainer>
      <Middle maxWidth={390}>
        <Card>
          <Spacing size={2}>
            <Text bold size={1} align="center">
              Delete account
            </Text>
            <Text align="center">
              This will delete your account and all associated information.{" "}
              <strong>This cannot be undone</strong>.
            </Text>
            <Button type="button" level="destructive" onClick={handleDelete}>
              Delete account
            </Button>
          </Spacing>
        </Card>
      </Middle>
    </ResponsiveCenteredContainer>
  );
}
