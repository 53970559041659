import { Navigate, useSearchParams } from "react-router-dom";

import { CandidatesList, CandidatesListProps } from ".";

import { Pagination } from "@otta/design";

const CANDIDATES_PER_PAGE = 10;

export function PaginatedCandidatesList({
  candidates,
  ...rest
}: CandidatesListProps): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();

  const p = searchParams.get("page");

  const currPage = Math.max(p ? parseInt(p) : 1, 1);

  const batch = candidates.slice((currPage - 1) * 10, currPage * 10);
  const numCandidates = candidates.length;
  const numPages = Math.max(1, Math.ceil(numCandidates / CANDIDATES_PER_PAGE));

  if (currPage > numPages) {
    return <Navigate to={`?page=${numPages}`} replace={true} />;
  }

  const handlePageChange = (newPage: number) => {
    const newParams = new URLSearchParams(searchParams);

    newParams.set("page", newPage.toString());
    setSearchParams(newParams);

    window.scrollTo(0, 0);
  };

  return (
    <>
      <CandidatesList candidates={batch} {...rest} />
      {numCandidates > 0 && (
        <Pagination
          total={numPages}
          current={currPage}
          onChange={handlePageChange}
        />
      )}
    </>
  );
}
