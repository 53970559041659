import { DetailsFormFields } from "./types";

import { ImportedCandidateInput } from "@hire/schema";

export const transformOut = (
  input: DetailsFormFields,
  emailAddress: string,
  jobId: string
): ImportedCandidateInput => {
  return {
    ...input,
    emailAddress,
    jobId,
    phoneNumber: input.phoneNumber ?? null,
    sourcedUrl: input.sourcedUrl ?? null,
    portfolioUrl: input.portfolioUrl ?? null,
    currentEmployer: input.currentEmployer ?? null,
    source: input.source ?? null,
    attachmentUrl: input.attachmentUrl ?? null,
  };
};
