import styled from "@xstyled/styled-components";

import { TemplateVariables } from "../types";

import { palette } from "@otta/design-tokens";
import { Button, Text } from "@otta/design";

const VariableSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10;
`;
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5;
  flex-direction: row;
  flex-wrap: wrap;
`;
const VariableButton = styled(Button)`
  background-color: ${palette.brand.grey};
  white-space: nowrap;
`;
const TemplateVariablesMap = {
  "Candidate name": TemplateVariables.CandidateName,
  "Recruiter name": TemplateVariables.RecruiterName,
  "Company name": TemplateVariables.CompanyName,
  "Job title": TemplateVariables.JobTitle,
};

export const VariableButtonSection = ({
  onClick: handleClick,
}: {
  onClick: (variable: TemplateVariables) => void;
}): React.ReactElement => {
  return (
    <VariableSectionWrapper>
      <Text>Add: </Text>
      <ButtonsContainer>
        {Object.entries(TemplateVariablesMap).map(([name, value], index) => (
          <VariableButton
            key={`variable-${index}`}
            onClick={() => handleClick(value)}
            type="button"
            level="tertiary"
            size="small"
          >
            {name}
          </VariableButton>
        ))}
      </ButtonsContainer>
    </VariableSectionWrapper>
  );
};
