export const MONTH_OPTIONS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
].map((label, i) => ({ label, value: `${i + 1}` }));

export const ROUND_OPTIONS = [
  "Angel",
  "Convertible",
  "Early VC",
  "Grant",
  "Growth equity VC",
  "Late VC",
  "Seed",
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Series E",
  "Series F",
  "Series G",
  "Series H",
  "Series I",
].map(label => ({ label, value: label }));
