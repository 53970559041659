import { Question, SelectQuestion } from "./types";

import { JobApplicationQuestionAnswersQuery } from "@hire/schema";
import { Paragraph, Text, VerticalSpacing } from "@otta/design";
import { Link } from "@hire/components/links";

export function NewQuestionResponses({
  jobApplication,
}: {
  jobApplication: JobApplicationQuestionAnswersQuery["jobApplication"];
}): React.ReactElement {
  const sections = jobApplication?.atsQuestions?.sections;
  return (
    <VerticalSpacing>
      {sections?.map(section => {
        return (
          <VerticalSpacing size={-1} key={section.id}>
            <Text bold>{section.label}</Text>
            {section.questions.map(
              question =>
                question.__typename !== "AtsInformation" && (
                  <VerticalSpacing size={-1} key={question.id}>
                    <Text bold align="left">
                      {question.label}
                    </Text>
                    <Paragraph size={-1} align="left">
                      {answerValue(question)}
                    </Paragraph>
                  </VerticalSpacing>
                )
            )}
          </VerticalSpacing>
        );
      })}
    </VerticalSpacing>
  );
}

export function answerValue(
  question: Question
): string | React.ReactElement | null {
  switch (question.__typename) {
    case "NumberAtsQuestion":
      return question.numberAnswer;
    case "TextAtsQuestion":
      return question.textAnswer;
    case "BooleanAtsQuestion":
      return question.booleanAnswer === true
        ? "Yes"
        : question.booleanAnswer === false
        ? "No"
        : null;
    case "DateTimeAtsQuestion":
      return question.dateTimeAnswer;
    case "SelectAtsQuestion":
      return getSelectAnswers(question) ?? "No options selected";
    case "ConsentAtsQuestion":
      return question.consentAnswer ? "Accepted" : "Not accepted";
    case "FileAtsQuestion":
      return question.fileAnswer ? (
        <Text as={Link} to={question.fileAnswer.url}>
          {question.fileAnswer.name}
        </Text>
      ) : null;
    default:
      return null;
  }
}

function getSelectAnswers(question: SelectQuestion) {
  const selectionMap = new Map(
    question.options.map(({ localId, label }) => [localId, label])
  );

  if (question.selectAnswers?.length === 0) {
    return undefined;
  }

  const answerValues = question.selectAnswers?.map(answer => {
    if (answer.stringValue) {
      return `${selectionMap.get(answer.idValue)}: ${answer.stringValue}`;
    } else {
      return selectionMap.get(answer.idValue);
    }
  });

  const answerString = answerValues?.join(", ");
  return answerString;
}
