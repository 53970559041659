import styled from "@xstyled/styled-components";
import { toast } from "react-toastify";

import { Text } from "@otta/design";
import { ContactIcon } from "@hire/pages/JobPage/Pipeline/Candidate/CandidateHeader/components/ContactIcon";
import { CandidateProfileLinks } from "@hire/components/CandidateProfileLinks";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: md;
`;

interface CandidateHeaderInfoProps {
  candidate: {
    id: string;
    firstName: string;
    lastName: string;
    email: string | null;
    phoneNumber: string | null;
    websiteLinks: { id: string; url: string }[];
    linkedinUrl: string | null;
  };
}

const copyToClipboard = (contactType: string, content: string | null) => {
  if (!content) {
    return;
  }
  navigator.clipboard.writeText(content);
  toast.success(`${contactType} copied to clipboard`);
};

export function CandidateHeaderInfo({ candidate }: CandidateHeaderInfoProps) {
  return (
    <Container>
      <Text data-cs-mask bold size={1}>
        {candidate.firstName} {candidate.lastName}
      </Text>
      {candidate.websiteLinks && (
        <CandidateProfileLinks
          candidate={{
            websiteLinks: candidate.websiteLinks,
            linkedinUrl: candidate.linkedinUrl,
          }}
        />
      )}

      {candidate.phoneNumber && (
        <ContactIcon
          content={candidate.phoneNumber}
          onClick={() => copyToClipboard("Phone number", candidate.phoneNumber)}
          iconName="phone"
        />
      )}
      {candidate.email && (
        <ContactIcon
          content={candidate.email}
          onClick={() => copyToClipboard("Email", candidate.email)}
          iconName="message"
        />
      )}
    </Container>
  );
}
