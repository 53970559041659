import styled, { css, up } from "@xstyled/styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";

import { NavigationArrows } from "./NavigationArrows";
import { ContactDetails } from "./ContactDetails";

import { palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Text } from "@otta/design";
import { CompanyConversationQuery } from "@hire/schema";

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const NavContainer = styled(FlexBox)`
  justify-content: space-between;
  padding: md xl;
  background: ${palette.grayscale.shade50};
  gap: xl;
`;

const RightNav = styled(FlexBox)`
  ${up(
    "tablet",
    css`
      gap: 4xl;
    `
  )}
  gap: 28;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const NavigationStrip = ({
  candidate,
  onNext,
  onPrevious,
}: {
  candidate: NonNullable<
    NonNullable<CompanyConversationQuery["getJobPipelineItem"]>[
      | "candidate"
      | "importedCandidate"]
  >;

  onNext?: () => void;
  onPrevious?: () => void;
}): React.ReactElement => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleClose = () => {
    navigate({
      pathname: `..`,
      search: searchParams.toString(),
    });
  };
  return (
    <NavContainer>
      <Text data-cs-mask size={1} bold>
        {candidate.firstName} {candidate.lastName}
      </Text>
      <ContactDetails candidate={candidate} />
      <div style={{ flex: 1 }} />
      <RightNav>
        <NavigationArrows onNext={onNext} onPrevious={onPrevious} />
        <StyledIcon icon="close" onClick={handleClose} />
      </RightNav>
    </NavContainer>
  );
};
