import { SourcingPreferencesFieldProps } from "./interfaces";

import { Currency } from "@hire/schema";
import { Icon } from "@otta/icons";
import { VerticalSpacing, Label, Text, MoneyField } from "@otta/design";
import { CURRENCY_OPTIONS } from "@hire/util/currencies";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";

export function MaxSalaryField({
  preferences,
  onChange,
}: SourcingPreferencesFieldProps): React.ReactElement {
  const currentValue = {
    currency: preferences.maxSalary?.currency ?? Currency.Gbp,
    amount: preferences.maxSalary?.amount
      ? Number(preferences.maxSalary.amount)
      : null,
  };

  return (
    <VerticalSpacing size={-4}>
      <TooltipWithIcon
        content="Use this to filter out candidates with mismatched salary expectations"
        icon={<Icon icon="circle-info" size={1} />}
      >
        <Label htmlFor="maxSalary">
          <Text bold>Maximum salary</Text>
        </Label>
      </TooltipWithIcon>
      <MoneyField
        name="maxSalary"
        value={currentValue}
        options={CURRENCY_OPTIONS}
        onChange={salary => {
          onChange({
            maxSalary: {
              amount: salary.amount?.toString() ?? "0",
              currency: salary.currency,
            },
          });
        }}
      />
    </VerticalSpacing>
  );
}
