import { ApolloLink, useApolloClient } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createContext, useEffect, useState } from "react";

interface IMaintenanceProviderProps {
  children: React.ReactNode;
}

const Context = createContext(false);

export function Provider({
  children,
}: IMaintenanceProviderProps): React.ReactElement {
  const client = useApolloClient();

  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    const oldLink = client.link;
    const errorLink = onError(({ graphQLErrors }) => {
      if (graphQLErrors?.some(({ message }) => message === "Maintenance")) {
        setIsMaintenance(true);
      }
    });

    client.setLink(ApolloLink.from([errorLink, oldLink]));

    return () => {
      client.setLink(oldLink);
    };
  }, [client]);

  return <Context.Provider value={isMaintenance}>{children}</Context.Provider>;
}

// Temp. comment out hook, as we will want to use this in App.tsx when we add in Maintenance Mode

// export function useMaintenance(): boolean {
//   return useContext(Context);
// }
