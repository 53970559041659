import styled from "@xstyled/styled-components";
import { useNavigate, useParams } from "react-router-dom";

import { pxToRem } from "@otta/design-tokens";
import { Button, Spacing, Text } from "@otta/design";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { JobEditForm } from "@hire/components/form/JobEditForm";

const PageWrapper = styled(ResponsiveCenteredContainer)`
  text-align: left;
  max-width: ${pxToRem(1000)};
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CreateJob = () => {
  const navigate = useNavigate();
  const { companySlug } = useParams();
  return (
    <PageWrapper>
      <Spacing>
        <TopSection>
          <Text size={1} bold>
            Create job
          </Text>
          <Button level="secondary" onClick={() => navigate("..")}>
            Cancel
          </Button>
        </TopSection>
        <JobEditForm onSubmitRedirect={`/${companySlug}/jobs`} />
      </Spacing>
    </PageWrapper>
  );
};
