import { CandidateWorkExperience } from "./CandidateWorkExperience";

import { CandidateCardFragment, CandidateCardJobQuery } from "@hire/schema";
import { Text } from "@otta/design";
import { Separator } from "@hire/components/Separator";

interface CandidateWorkExperiencesProps {
  candidate: CandidateCardFragment;
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
}

export function CandidateWorkExperiences({
  candidate,
  job,
}: CandidateWorkExperiencesProps): React.ReactElement | null {
  if (candidate.workExperiences.length === 0) {
    return null;
  }

  return (
    <>
      <Separator />
      <Text size={1}>Employment history</Text>
      {candidate.workExperiences.map(workExp => (
        <CandidateWorkExperience
          key={workExp.id}
          workExperience={workExp}
          job={job}
        />
      ))}
    </>
  );
}
