import styled from "@xstyled/styled-components";

import { formatDatesToFrom, formatDuration } from "./utils";
import { DatesWrapper, TagsContainer } from "./shared";

import { CandidateCardJobQuery, CandidateFragment } from "@hire/schema";
import {
  palette,
  modularScale,
  pxToRem,
  borderRadius,
} from "@otta/design-tokens";
import { Logo, Text, List, VerticalSpacing } from "@otta/design";
import { HighlightableText } from "@hire/components/HighlightableText";
import { Link } from "@hire/components/links/Link";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

const LogoWrapper = styled.div`
  display: inline-block;
  width: ${modularScale(3)};
  margin-left: xs;
`;

const CompanyOnOttaContainer = styled.div`
  display: inline-flex;
  align-items: center;
  padding: ${modularScale(-10)} sm;
  background: ${palette.brand.grey};
  border-radius: ${pxToRem(borderRadius)};
`;

const TitleContainer = styled.div`
  display: flex;
  gap: md;
  align-items: center;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const compareTechnologyByPreference =
  (jobTechnologies: Set<string>) => (a: { id: string }, b: { id: string }) => {
    const preferenceA = jobTechnologies.has(a.id);
    const preferenceB = jobTechnologies.has(b.id);
    if (preferenceA && !preferenceB) {
      return -1;
    }
    if (preferenceB && !preferenceA) {
      return 1;
    }
    return b.id.localeCompare(a.id);
  };

interface CandidateWorkExperienceProps {
  workExperience: CandidateFragment["workExperiences"][0];
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
}

export function CandidateWorkExperience({
  workExperience: {
    companyUrl,
    companyName,
    companyOnOtta,
    startDate,
    endDate,
    companyDescription,
    title,
    sectorTags,
    descriptions,
    technologiesUsed: candidateTechnologies,
  },
  job: { technologiesUsed: jobTechnologies },
}: CandidateWorkExperienceProps): React.ReactElement {
  const datesToFrom = formatDatesToFrom({
    startDate,
    endDate,
    dateFormat: "MMM yy",
  });

  const duration = formatDuration(startDate, endDate);

  const jobTechnologiesSet = new Set(jobTechnologies.map(({ id }) => id));

  const sortedTechnologies = [...candidateTechnologies].sort(
    compareTechnologyByPreference(jobTechnologiesSet)
  );

  return (
    <VerticalSpacing size={-3}>
      <SpaceBetween>
        <TitleContainer>
          {companyUrl ? (
            <Link to={companyUrl} newTab>
              <Text bold>
                <HighlightableText>{companyName}</HighlightableText>
              </Text>
            </Link>
          ) : (
            <Text bold>
              <HighlightableText>{companyName}</HighlightableText>
            </Text>
          )}
          {companyOnOtta && <CompanyOnOttaTag />}
        </TitleContainer>
        {datesToFrom && <DatesWrapper size={-1}>{datesToFrom}</DatesWrapper>}
      </SpaceBetween>

      {title && (
        <Text bold>
          <HighlightableText>{title}</HighlightableText>
        </Text>
      )}

      {duration && <Text size={-1}>{duration}</Text>}

      {companyDescription && (
        <Text size={-1} color={palette.grayscale.shade600}>
          <HighlightableText>{companyDescription}</HighlightableText>
        </Text>
      )}

      {sectorTags.length > 0 && (
        <Text color={palette.grayscale.shade600} size={-1}>
          {sectorTags.map((sector, idx) => (
            <Text key={sector.id} as="span">
              <HighlightableText>{sector.value}</HighlightableText>
              {idx === sectorTags.length - 1 ? "" : " · "}
            </Text>
          ))}
        </Text>
      )}

      {sortedTechnologies.length > 0 && (
        <TagsContainer>
          {sortedTechnologies.map(tech => (
            <RoundedTag
              key={tech.id}
              content={
                <Text size={-1}>
                  <HighlightableText>{tech.value}</HighlightableText>
                </Text>
              }
              color={
                jobTechnologiesSet.has(tech.id)
                  ? palette.brand.yellow
                  : palette.beige.shade200
              }
            />
          ))}
        </TagsContainer>
      )}

      {descriptions.length > 0 && (
        <List type="bullet" size={-1}>
          {descriptions.map(({ id, value }) => (
            <li key={id}>
              <HighlightableText>{value}</HighlightableText>
            </li>
          ))}
        </List>
      )}
    </VerticalSpacing>
  );
}

function CompanyOnOttaTag() {
  return (
    <CompanyOnOttaContainer data-testid="otta-company-tag">
      <Text as="span" size={-1} bold>
        Company on
      </Text>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
    </CompanyOnOttaContainer>
  );
}
