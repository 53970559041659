import styled from "@xstyled/styled-components";

import { Link, LinkProps } from "./Link";

import { Icon } from "@otta/icons";

const LinkContainer = styled.div`
  display: flex;
  gap: xs;
`;

interface ExternalLinkProps extends LinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
}

export const ExternalLink = ({
  to,
  children,
  className,
  ...props
}: ExternalLinkProps): React.ReactElement => (
  <LinkContainer className={className}>
    <Icon icon="external" />
    <Link newTab to={to} {...props}>
      {children}
    </Link>
  </LinkContainer>
);
