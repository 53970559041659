import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import styled from "@xstyled/styled-components";

import { handleMutationError } from "@hire/errors";
import { ForgotPasswordDocument } from "@hire/schema";
import { Button, InputField, VerticalSpacing } from "@otta/design";

interface ForgotPasswordFields {
  email: string;
}

const ButtonWrapper = styled.div`
  text-align: center;
`;

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Fill in this field"),
});
export function ForgotPasswordForm() {
  const [submitted, setSubmitted] = useState(false);

  const [update, { loading }] = useMutation(ForgotPasswordDocument, {
    onCompleted: () => {
      setSubmitted(true);
    },
    onError: handleMutationError,
  });

  const handleSave = useCallback(
    async (input: ForgotPasswordFields) => {
      await update({
        variables: {
          email: input.email,
        },
      });
    },
    [update]
  );
  const form = useFormik<ForgotPasswordFields>({
    initialValues: { email: "" },
    validationSchema,
    onSubmit: handleSave,
  });
  return (
    <form onSubmit={form.handleSubmit}>
      <VerticalSpacing>
        <InputField
          type="text"
          label="Work email"
          name="email"
          value={form.values.email}
          onChange={form.handleChange("email")}
          onBlur={form.handleBlur("email")}
          error={form.touched.email ? form.errors.email : undefined}
        />
        <ButtonWrapper>
          <Button
            type={submitted ? "button" : "submit"}
            level={submitted ? "secondary" : "primary"}
          >
            {submitted
              ? "Done! Check your email"
              : loading
              ? "Loading"
              : "Reset password"}
          </Button>
        </ButtonWrapper>
      </VerticalSpacing>
    </form>
  );
}
