import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { OptionProps } from "react-select";

import { AuthContainer } from "./Login";

import { palette } from "@otta/design-tokens";
import { Card, Heading, SelectField, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links";
import { CompaniesWithPreApprovalDocument } from "@hire/schema";

const Container = styled(AuthContainer)`
  max-width: 450px;
`;

const StyledOption = styled.div<{ $selected: boolean }>`
  padding: 10px;
  background-color: ${({ $selected }) =>
    $selected ? palette.extended.yellow.shade100 : palette.brand.white};
  cursor: pointer;
  &:hover {
    background-color: ${palette.brand.grey};
  }
`;

function Option({
  data,
  selectOption,
  isSelected,
}: OptionProps<{ label: string; value: string; url?: string }, false>) {
  return (
    <StyledOption onClick={() => selectOption(data)} $selected={isSelected}>
      <Text>
        <Text as="span" bold>
          {data.label}
        </Text>
        {data.url && <> | {data.url}</>}
      </Text>
    </StyledOption>
  );
}

export function SelectCompany(): React.ReactElement {
  const navigate = useNavigate();

  const { data, loading } = useQuery(CompaniesWithPreApprovalDocument);

  const companies = useMemo(
    () =>
      data?.companies.map(c => ({
        label: c.name,
        value: c.urlSafeName,
        url: c.websiteUrl
          ?.replace(/(http(s?))?(:\/\/)?(www.)?/, "")
          .replace(/\/(.*)/, "")
          .replace(/\?(.*)/, ""),
      })) ?? [],
    [data]
  );
  return (
    <Container>
      <Card>
        <Spacing size={2}>
          <Heading size={4}>Welcome</Heading>
          <Spacing size={-5}>
            <Text as="label" htmlFor="company">
              Select your company to sign in
            </Text>
            <SelectField
              inputId="company"
              components={{ Option }}
              isLoading={loading}
              options={companies}
              value={null}
              onChange={option => {
                if (option) {
                  navigate(
                    `/login/${encodeURIComponent(option.value.toLowerCase())}`
                  );
                }
              }}
            />
            <Link
              to="/quiz"
              size={-1}
              data-analytics-id="company-not-in-this-list"
            >
              My company isn&apos;t in this list
            </Link>
          </Spacing>
        </Spacing>
      </Card>
    </Container>
  );
}
