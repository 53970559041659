import styled from "@xstyled/styled-components";
import { Formik, useField } from "formik";
import { useMutation } from "@apollo/client";

import {
  Button,
  FieldWrapper,
  Modal,
  Spacing,
  Text,
  TextareaField,
  VerticalSpacing,
} from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { FieldErrors } from "@hire/components/form/FieldErrors";
import { ReportFakeProfileDocument } from "@hire/schema";

const Container = styled.div`
  padding: xl;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const CloseButton = styled(Button)`
  width: ${pxToRem(200)};
`;

function Reason(): React.ReactElement {
  const [props, meta] = useField("reason");
  return (
    <FieldWrapper
      label="Why do you think this profile is fake?"
      fieldError={meta.touched ? meta.error : undefined}
    >
      {({ field }) => <TextareaField {...field} {...props} />}
    </FieldWrapper>
  );
}

export function FakeProfileModal({
  handleClose,
  open,
  jobId,
  suspectEmail,
}: {
  handleClose: () => void;
  open: boolean;
  jobId: string;
  suspectEmail: string;
}): React.ReactElement | null {
  const [submit, { data, loading: submitLoading, error }] = useMutation(
    ReportFakeProfileDocument
  );

  return (
    <Modal
      style={{ width: pxToRem(450) }}
      onOpenChange={handleClose}
      open={open}
    >
      <Container>
        <VerticalSpacing>
          {data ? (
            <>
              <Text bold size={1}>
                Thank you for your report
              </Text>
              <Text>
                We've received your report and will investigate this profile.
                Thank you for helping us to identify fake profiles.
              </Text>
              <CloseButton level="primary" onClick={handleClose}>
                Close
              </CloseButton>
            </>
          ) : (
            <>
              <Text bold size={1}>
                Report fake profile
              </Text>
              <Formik
                onSubmit={data => submit({ variables: data })}
                initialValues={{
                  suspectEmail,
                  jobId,
                  reason: undefined,
                }}
              >
                {form => (
                  <Spacing size={1}>
                    <FieldErrors errors={error?.message} />
                    <Reason />
                    <ButtonContainer>
                      <CloseButton
                        level="secondary"
                        disabled={submitLoading}
                        onClick={handleClose}
                      >
                        Cancel
                      </CloseButton>
                      <CloseButton
                        type="submit"
                        level="primary"
                        disabled={submitLoading}
                        onClick={() => form.handleSubmit()}
                      >
                        Report profile
                      </CloseButton>
                    </ButtonContainer>
                  </Spacing>
                )}
              </Formik>
            </>
          )}
        </VerticalSpacing>
      </Container>
    </Modal>
  );
}
