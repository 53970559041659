import { Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { LoginForm } from "./LoginForm";
import { SamlLoginButton } from "./SamlLoginButton";

import { AuthStrategyType, CompanyAuthDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { Card, Heading, VerticalSpacing, Text } from "@otta/design";
import { Link } from "@hire/components/links/Link";
import { Separator } from "@hire/components/Separator";
import { useRequiredParams } from "@hire/util/routing";

export const AuthContainer = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;

export function Login(): React.ReactElement {
  const { companySlug } = useRequiredParams(["companySlug"]);

  const { data, loading } = useQuery(CompanyAuthDocument, {
    variables: { urlSafeName: companySlug },
  });

  if (loading) {
    return <Loading />;
  }

  if (!data?.company) {
    return <Navigate to="/" replace />;
  }

  const { authStrategies } = data.company;

  const samlStrategy = authStrategies.find(
    s => s.type === AuthStrategyType.Saml
  );

  const passwordStrategy = authStrategies.find(
    s => s.type === AuthStrategyType.Password
  );

  return (
    <AuthContainer>
      <VerticalSpacing size={1}>
        <Heading size={4} align="center">
          Sign in
        </Heading>
        <Card>
          <VerticalSpacing size={1}>
            {samlStrategy && (
              <SamlLoginButton
                type="login"
                loginUrl={samlStrategy.loginUrl}
                companyUrlSafeName={companySlug}
              />
            )}
            {samlStrategy && passwordStrategy && <Separator />}
            {passwordStrategy && (
              <LoginForm loginUrl={passwordStrategy.loginUrl} />
            )}
            <Text align="center">
              <Link to="/forgot-password">Forgot your password?</Link>
            </Text>
          </VerticalSpacing>
        </Card>
        <Text align="center">
          Don&apos;t have an account?{" "}
          <Link to={`/signup/${companySlug}`}>Sign up</Link>
        </Text>
      </VerticalSpacing>
    </AuthContainer>
  );
}
