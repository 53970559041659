export const intersectingTags = (
  tags: { id: string; value: string }[],
  set: Set<string>
) => {
  return tags.reduce((acc, tag) => {
    if (set.has(tag.id)) {
      return [...acc, tag.value];
    }

    return acc;
  }, [] as string[]);
};
