import { useQuery, useMutation } from "@apollo/client";
import { useMemo } from "react";

import {
  CompanyRoleType,
  MeDocument,
  UpdateSalaryVisibilityDocument,
  UpdateViewSalaryPermissionDocument,
  ViewJobSalaryRecruitersDocument,
  ViewJobSalaryRecruitersQuery,
} from "@hire/schema";

export function useGetCompanyMembers(jobExternalId: string) {
  const { data: currentUserData, loading: currentUserLoading } =
    useQuery(MeDocument);
  const { data, loading, refetch } = useQuery(ViewJobSalaryRecruitersDocument, {
    variables: { jobId: jobExternalId },
  });
  const { updateVisibilityMutation, updatePermissions } = useUpdatePermissions(
    data?.companyJob ?? null
  );
  const currentUserId = currentUserData?.me?.id;
  const hideSalary = !!data?.companyJob?.hideSalary;
  const company = data?.companyJob?.company;

  const companyMembers = useMemo(() => {
    return (company?.companyRecruiters ?? []).filter(
      recruiter => recruiter.role === CompanyRoleType.Member
    );
  }, [company?.companyRecruiters]);

  const recruiterIdsThatCanView = useMemo(() => {
    return companyMembers
      .filter(member => !!member.viewSalaryForJob)
      .map(member => member.id);
  }, [companyMembers]);

  const hiddenFromCount = companyMembers.filter(
    recruiter => !recruiter.viewSalaryForJob
  ).length;

  return {
    currentUserId,
    companyMembers,
    recruiterIdsThatCanView,
    hiddenFromCount,
    hideSalary,
    loading: loading || currentUserLoading,
    refetch,
    updateVisibilityMutation,
    updatePermissions,
  };
}

function useUpdatePermissions(job: ViewJobSalaryRecruitersQuery["companyJob"]) {
  const [updateVisibilityMutation] = useMutation(
    UpdateSalaryVisibilityDocument,
    {
      optimisticResponse: {
        updateSalaryVisibility: job
          ? {
              ...job,
              hideSalary: !job.hideSalary,
              company: {
                ...job.company,
                companyRecruiters: job.company.companyRecruiters.map(
                  recruiter => ({
                    ...recruiter,
                    viewSalaryForJob: true,
                  })
                ),
              },
            }
          : null,
      },
    }
  );

  const [updatePermissions] = useMutation(UpdateViewSalaryPermissionDocument, {
    optimisticResponse({ userIds }) {
      return {
        updateViewSalaryPermission: job
          ? {
              ...job,
              company: {
                ...job.company,
                companyRecruiters: job.company.companyRecruiters.map(
                  recruiter => ({
                    ...recruiter,
                    viewSalaryForJob:
                      recruiter.role === CompanyRoleType.Admin ||
                      userIds.includes(recruiter.id),
                  })
                ),
              },
            }
          : null,
      };
    },
  });

  return { updateVisibilityMutation, updatePermissions };
}
