import { useEffect } from "react";

import { Button, Middle, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links";
import { modularScale } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";

export function CandidateAccount(): React.ReactElement {
  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "User Viewed Account No Access Page",
      name: "candidate-viewing-hire-app",
    });
  }, []);
  return (
    <Middle maxWidth={480} style={{ padding: modularScale() }}>
      <Spacing size={2}>
        <Text as="h2" bold align="center" size={2}>
          You can&apos;t access this page
        </Text>
        <Text align="center">
          You are logged in to your candidate account. The page you are trying
          to access is for companies.
        </Text>
        <Spacing>
          <Button
            level="primary"
            as="a"
            href={import.meta.env.VITE_SEARCH_APP_HOST}
            style={{ width: "100%" }}
          >
            Back to candidate dashboard
          </Button>
          <Button
            as="a"
            level="destructive"
            href="/logout"
            style={{ width: "100%" }}
          >
            Log out
          </Button>
        </Spacing>
        <Text align="center">
          If this doesn&apos;t look right,{" "}
          <Link to="mailto:hello@otta.com?subject=Convert candidate account to company account">
            email us at hello@otta.com
          </Link>
          .
        </Text>
      </Spacing>
    </Middle>
  );
}
