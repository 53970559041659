import { ATSConnected } from "./Connected";
import { IntegrationForm } from "./IntegrationForm";

import { AtsIntegrationQuery, CompanyAts } from "@hire/schema";
import { Card, Paragraph, Spacing, Text } from "@otta/design";
import { Experiment } from "@hire/constants/experiments";
import { useExperiment } from "@otta/experiments/client";

type IntegrationProps = Pick<
  NonNullable<
    NonNullable<AtsIntegrationQuery["currentUser"]>["currentCompany"]
  >,
  "atsApiKeys" | "trackingCode" | "atsWebhooks" | "atsAuthorizeUrl"
> & {
  ats: CompanyAts;
  atsName: string;
  companyId: string;
  refetch(): Promise<unknown>;
  refetching: boolean;
  hasCompletedAtsIntegration: boolean;
};

export function Integration({
  ats,
  atsName,
  atsApiKeys,
  trackingCode,
  atsWebhooks,
  atsAuthorizeUrl,
  companyId,
  refetch,
  refetching,
  hasCompletedAtsIntegration,
}: IntegrationProps) {
  const hasKeys = atsApiKeys.length > 0;
  const { variant } = useExperiment(Experiment.AshbyIntegration);

  const ashbyEnabled = variant === "variant";
  const showAshbyMessage = !ashbyEnabled && ats === CompanyAts.Ashby;

  if (!hasCompletedAtsIntegration) {
    return !showAshbyMessage ? (
      <Spacing>
        <Card>
          <Spacing>
            <Text align="left" bold as="h3" size={1}>
              Complete your {atsName} integration
            </Text>
            <Paragraph align="left">
              It only takes a few minutes to complete your {atsName}{" "}
              integration. We have outlined the steps below. Begin by logging in
              to {atsName} in a new window. We recommend having that window open
              next to this one so that you can easily jump between them.
            </Paragraph>
          </Spacing>
        </Card>
        <IntegrationForm
          ats={ats}
          companyId={companyId}
          atsApiKeys={atsApiKeys}
          refetch={refetch}
          refetching={refetching}
          trackingCode={trackingCode}
          webhooks={atsWebhooks}
        />
      </Spacing>
    ) : (
      <Spacing>
        <Card>
          <Spacing>
            <Text bold as="h3" size={1}>
              Your Ashby ATS integration is coming soon!
            </Text>
            <Paragraph>
              We're close to adding Ashby as one of our integrations and we will
              update you once it's ready.
            </Paragraph>
          </Spacing>
        </Card>
      </Spacing>
    );
  }

  return (
    <ATSConnected
      companyId={companyId}
      showResetKeys={hasKeys}
      atsAuthorizeUrl={atsAuthorizeUrl}
      atsName={atsName}
    />
  );
}
