import { useState } from "react";

import { MessageTemplateForm } from "../MessageTemplateForm";

import { DeleteCard } from "./components/DeleteCard";
import { ViewCard } from "./components/ViewCard";

import { MessageTemplateDataFragment } from "@hire/schema";

export const TemplateCard = ({
  template,
  onDelete: handleDelete,
  onSave: handleSave,
}: {
  template: MessageTemplateDataFragment;
  onDelete: (id: string) => void;
  onSave: (newTemplateData: {
    name: string;
    message: string;
    id: string;
  }) => void;
}) => {
  const [showDeleteCard, setShowDeleteCard] = useState(false);
  const [showUpdateCard, setShowUpdateCard] = useState(false);

  const { id, name, message, showToAll } = template;

  const handleUpdate = (values: { name: string; message: string }) => {
    handleSave({ ...values, id });
    setShowUpdateCard(false);
  };

  if (showDeleteCard) {
    return (
      <DeleteCard
        cardHeading={name}
        onCancel={() => setShowDeleteCard(false)}
        onDelete={() => handleDelete(id)}
      />
    );
  }

  if (showUpdateCard) {
    return (
      <MessageTemplateForm
        templateData={template}
        onClose={() => setShowUpdateCard(false)}
        onSave={handleUpdate}
        isUpdate
      />
    );
  }

  return (
    <ViewCard
      onDelete={() => setShowDeleteCard(true)}
      onUpdate={() => setShowUpdateCard(true)}
      message={message}
      isOttaTemplate={showToAll}
      cardHeading={name}
    />
  );
};
