import styled from "@xstyled/styled-components";

import { PlaceholderWrapper } from "./PlaceholderWrapper";

import { Text } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const Description = styled(Text)`
  max-width: ${pxToRem(300)};
`;

export const MissingDataPlaceholder = ({
  size = "normal",
}: {
  size?: "small" | "normal";
}) => {
  return (
    <PlaceholderWrapper>
      <Icon size={2} icon="clock" />
      <Text size={size === "small" ? -1 : 1} bold>
        Not enough data
      </Text>
      <Description size={size === "small" ? -1 : 0}>
        We&apos;re waiting to have enough data to show you. Try coming back
        tomorrow.
      </Description>
    </PlaceholderWrapper>
  );
};
