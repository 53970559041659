import { useState } from "react";
import styled, { css, up } from "@xstyled/styled-components";
import { Link, Outlet, useNavigate } from "react-router-dom";

import { FilterableJobList } from "./FilterableJobList";
import { PublishedJobList } from "./PublishedJobList";
import { JobListMode } from "./statusTypes";
import { MissingSalaryWarning } from "./MissingSalaryWarning";

import { Feature } from "@hire/schema";
import { palette, pxToRem } from "@otta/design-tokens";
import { Button, Text, Tipbox } from "@otta/design";
import { useAuthorizations } from "@hire/providers/authorization";
import { RemovedJobsExplanation } from "@hire/components/RemovedJobsModal";
import { PausedProUpsellPage } from "@hire/components/ProUpsell";
import { Icon } from "@otta/icons";
import { Loading } from "@otta/shared-components";

const ActionButton = styled(Button)`
  display: flex;
  gap: 0.5rem;
`;

const JobsPageContainer = styled.div`
  flex: 1;
  display: flex;
  background-color: ${palette.brand.white};
  min-height: 100%;
`;

const NotificationSidebar = styled.div`
  background-color: ${palette.beige.shade200};
  max-width: ${pxToRem(416)};
  padding: 1.5rem;
  gap: 1rem;

  display: none;
  ${up(
    "desktop",
    css`
      display: block;
    `
  )}

  &:empty {
    display: none;
  }
`;

const JobsListContainer = styled.div`
  overflow-y: auto;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

function CreateButton({
  onClick,
}: {
  onClick: () => void;
}): React.ReactElement {
  return (
    <ActionButton
      data-testid="create-job-button"
      onClick={() => onClick()}
      level="secondary"
    >
      <Icon icon="plus" />
      <Text bold>Create new job</Text>
    </ActionButton>
  );
}

function SwapButton(): React.ReactElement {
  return (
    <ActionButton level="secondary" to="choose-job-slots" as={Link}>
      <Icon icon="arrow-right-arrow-left" />
      <Text bold>Swap published jobs</Text>
    </ActionButton>
  );
}

export const JobsList = () => {
  const [showPausedModal, setShowPausedModal] = useState(false);
  const [hasMissingSalary, setHasMissingSalary] = useState(false);

  const { features, loading: featuresLoading } = useAuthorizations([
    Feature.CreateJob,
    Feature.ViewJobs,
    Feature.JobSlots,
  ]);

  const hasJobSlots = !!features.get(Feature.JobSlots)?.granted;

  const jobListMode: JobListMode = hasJobSlots
    ? JobListMode.ONLY_PAUSED_JOBS
    : JobListMode.ALL_JOBS;

  const navigate = useNavigate();
  const viewJobsFeature = features.get(Feature.ViewJobs);
  const createJobFeature = features.get(Feature.CreateJob);

  const isPaused = createJobFeature?.message === "paused";
  const showCreate = isPaused || createJobFeature?.granted;

  const createButton = showCreate && (
    <CreateButton
      onClick={() => {
        if (createJobFeature?.message === "paused") {
          setShowPausedModal(true);
        } else {
          navigate("./create");
        }
      }}
    />
  );

  const actionButtons = (
    <ButtonContainer>
      {createButton}
      {hasJobSlots && <SwapButton />}
    </ButtonContainer>
  );

  return (
    <>
      <JobsPageContainer data-testid="jobs-list-container">
        <JobsListContainer>
          {hasJobSlots && viewJobsFeature?.granted && (
            <PublishedJobList
              actionButtons={actionButtons}
              onMissingSalary={() => setHasMissingSalary(true)}
            />
          )}
          {featuresLoading ? (
            <Loading />
          ) : viewJobsFeature?.message === "paused" ? (
            <PausedProUpsellPage campaign="jobs" />
          ) : viewJobsFeature?.granted ? (
            <FilterableJobList
              mode={jobListMode}
              actionButtons={!hasJobSlots && actionButtons}
              onMissingSalary={() => setHasMissingSalary(true)}
            />
          ) : (
            <Tipbox level="error">
              You don't have permission to view this page.
            </Tipbox>
          )}
        </JobsListContainer>
        <NotificationSidebar>
          {hasMissingSalary && <MissingSalaryWarning />}
        </NotificationSidebar>
      </JobsPageContainer>
      <RemovedJobsExplanation enabled={showPausedModal} campaign="jobs" />
      <Outlet />
    </>
  );
};
