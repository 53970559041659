import { toast } from "react-toastify";
import styled, { css, up } from "@xstyled/styled-components";

import { generateImportedCandidateWebsiteLinks } from "../utils";

import { ContactIcon } from "./ContactIcon";

import { CompanyConversationQuery } from "@hire/schema";
import { CandidateProfileLinks } from "@hire/components/CandidateProfileLinks";
import { pxToRem } from "@otta/design-tokens";

const ContactDetailsWrapper = styled.div`
  display: flex;
`;

const LinkWrapper = styled.div<{ removeMargin: boolean }>(
  ({ removeMargin }) => css`
    margin-right: ${removeMargin ? "0" : pxToRem(16)};
    display: flex;

    div:first-child {
      margin-left: 0;
    }

    ${up(
      "tablet",
      css`
        margin-right: ${removeMargin ? "0" : pxToRem(16)};
      `
    )}
  `
);

const ContactLinkWrapper = styled.div`
  margin-right: lg;
`;

export const ContactDetails = ({
  candidate,
}: {
  candidate: NonNullable<
    NonNullable<CompanyConversationQuery["getJobPipelineItem"]>[
      | "candidate"
      | "importedCandidate"]
  >;
}): React.ReactElement => {
  const isImportedCandidate = candidate?.__typename === "ImportedCandidate";

  const websiteLinks = isImportedCandidate
    ? generateImportedCandidateWebsiteLinks(candidate)
    : candidate.websiteLinks;

  const hasLinks = websiteLinks?.length;

  const linkedinUrl = isImportedCandidate ? undefined : candidate.linkedinUrl;
  const phoneNumber = candidate.phoneNumber;
  const email = isImportedCandidate ? candidate.emailAddress : candidate.email;

  const handleCopyToClipboard = (
    contactType: string,
    content: string | null
  ) => {
    if (!content) {
      return;
    }
    navigator.clipboard.writeText(content);
    toast.success(`${contactType} copied to clipboard`);
  };

  return (
    <ContactDetailsWrapper>
      <LinkWrapper removeMargin={!hasLinks}>
        {websiteLinks && (
          <CandidateProfileLinks candidate={{ websiteLinks, linkedinUrl }} />
        )}
      </LinkWrapper>

      {phoneNumber && (
        <ContactLinkWrapper>
          <ContactIcon
            content={phoneNumber}
            onClick={() => handleCopyToClipboard("Phone number", phoneNumber)}
            iconName="phone"
          />
        </ContactLinkWrapper>
      )}
      {email && (
        <ContactLinkWrapper>
          <ContactIcon
            content={email}
            onClick={() => handleCopyToClipboard("Email", email)}
            iconName="message"
          />
        </ContactLinkWrapper>
      )}
    </ContactDetailsWrapper>
  );
};
