import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

interface ITextareaProps {
  error?: boolean;
}

export const Textarea = styled.textarea<ITextareaProps>`
  display: block;
  width: 100%;
  border-radius: 5px;
  border: 1px solid
    ${({ error }) => (error ? palette.brand.red : palette.grayscale.shade400)};
  font-size: 16px;
  padding: sm md;
  font-family: inherit;

  &[aria-invalid="true"] {
    border: 2px solid ${palette.brand.red};
    outline: none;
  }

  &:focus {
    border: 2px solid ${palette.brand.black};
    outline: none;
  }

  ::placeholder {
    color: ${palette.grayscale.shade400};
  }
`;
