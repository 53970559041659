import {
  CandidateCardFragment,
  CandidateCardJobQuery,
  TechnologyPreference,
} from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

function sortByJobTechnology<
  T extends {
    id: string;
    value: string;
  }
>(a: T, b: T, jobTechnologies: Set<string>) {
  const jobTechnologyA = jobTechnologies.has(a.id);
  const jobTechnologyB = jobTechnologies.has(b.id);

  if (jobTechnologyA && !jobTechnologyB) {
    return -1;
  }
  if (!jobTechnologyB && jobTechnologyB) {
    return 1;
  }

  return 0;
}

const postivePreferences = [
  TechnologyPreference.Positive,
  TechnologyPreference.VeryPositive,
];

interface CandidateTechnologyPreferencesBadgeProps {
  candidate: CandidateCardFragment;
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
}

export function CandidateTechnologyPreferencesBadge({
  candidate,
  job,
}: CandidateTechnologyPreferencesBadgeProps): React.ReactElement | null {
  const jobTechnologyIds = new Set(job.technologiesUsed.map(t => t.id));
  const technologies = candidate.technologiesUsedPreferences
    .filter(up => postivePreferences.includes(up.technologyPreference))
    .map(up => up.technologyUsed)
    .sort((a, b) => sortByJobTechnology(a, b, jobTechnologyIds))
    .map(t => t.value);

  const fullText = `Wants to work with ${technologies.join(", ")}`;

  const shouldTruncate = fullText.length > 50;

  const text = shouldTruncate ? fullText.substring(0, 50) + "..." : fullText;

  return technologies.length > 0 ? (
    <RoundedTag
      content={text}
      color={palette.brand.green}
      icon="technology"
      hoverText={shouldTruncate ? fullText : undefined}
    />
  ) : null;
}
