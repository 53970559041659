import { useMemo } from "react";
import styled from "@xstyled/styled-components";

import { Feature } from "@hire/schema";
import { DeleteButton } from "@hire/components/buttons/DeleteButton";
import { EditButton } from "@hire/components/buttons/EditButton";
import { useAuthorizations } from "@hire/providers/authorization";

const FloatingWrapper = styled.div`
  float: right;
  display: flex;
  gap: sm;
`;

export const ActionButtons = ({
  onDelete: handleDelete,
  onUpdate: handleUpdate,
}: {
  onDelete: () => void;
  onUpdate: () => void;
}) => {
  const { loading, features } = useAuthorizations([
    Feature.DeleteMessageTemplate,
    Feature.UpdateMessageTemplate,
  ]);

  const grantedActions = useMemo(() => {
    const actions = [
      {
        feature: Feature.UpdateMessageTemplate,
        element: <EditButton onClick={handleUpdate} />,
      },
      {
        feature: Feature.DeleteMessageTemplate,
        element: <DeleteButton onClick={handleDelete} />,
      },
    ] as const;
    return actions.filter(f => features.get(f.feature)?.granted);
  }, [handleDelete, handleUpdate, features]);

  if (loading || !grantedActions.length) {
    return null;
  }

  return (
    <FloatingWrapper>
      {grantedActions.map(({ element, feature }) => (
        <div key={feature}>{element}</div>
      ))}
    </FloatingWrapper>
  );
};
