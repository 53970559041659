import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";

import { Feature } from "@hire/schema";
import { AttentionGetter } from "@hire/components/AttentionGetter";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { SubNavBar } from "@hire/components/layout/SubNavBar";
import { useAuthorizations } from "@hire/providers/authorization";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 100vw;

  ${up(
    "tablet",
    css`
      grid-template-columns: auto 1fr;
    `
  )}
`;

export const CompanySettings = () => {
  const { loading, features } = useAuthorizations([
    Feature.TeamMembers,
    Feature.ViewMessageTemplates,
    Feature.AtsIntegration,
    Feature.SsoSettings,
    Feature.JobSlots,
  ]);

  const hasAtsUrlButNotIntegrated =
    features.get(Feature.AtsIntegration)?.message === "not integrated";

  const links = useMemo(() => {
    const links = [
      {
        content: "Billing",
        to: "billing",
      },
      {
        content: "Team members",
        to: "team",
        feature: Feature.TeamMembers,
      },
      {
        content: "Message templates",
        to: "templates",
        feature: Feature.ViewMessageTemplates,
      },
      {
        content: "ATS integration",
        to: "ats",
        badge: hasAtsUrlButNotIntegrated ? <AttentionGetter /> : undefined,
      },
      {
        content: "Report fake profile",
        to: "report-fake-profile",
      },
      { content: "SSO", to: "sso", feature: Feature.SsoSettings },
    ] as const;

    return links.filter(f => {
      if ("feature" in f) {
        const feature = features.get(f.feature);

        return feature?.granted || feature?.message;
      }

      return true;
    });
  }, [features, hasAtsUrlButNotIntegrated]);

  return (
    <GridContainer>
      <SubNavBar
        data-testid="settings-nav"
        loading={loading}
        sections={[{ links }]}
      ></SubNavBar>
      <ResponsiveCenteredContainer>
        <Outlet />
      </ResponsiveCenteredContainer>
    </GridContainer>
  );
};
