import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";

export const TextWithElipsis = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;
