import { useMemo } from "react";

import { SourcingPreferencesFieldProps } from "./interfaces";

import { WorkExperienceCompaniesQuery } from "@hire/schema";
import { Icon } from "@otta/icons";
import { VerticalSpacing, Label, SelectField, Text } from "@otta/design";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";

interface CompanyNamesFieldProps extends SourcingPreferencesFieldProps {
  optionsData?: WorkExperienceCompaniesQuery;
}

export function CompanyNamesField({
  preferences,
  optionsData,
  onChange,
}: CompanyNamesFieldProps): React.ReactElement {
  const { companyOptions } = useMemo(() => {
    const options = (optionsData?.workExperienceCompanies ?? []).map(name => ({
      label: name,
      value: name,
    }));

    return { companyOptions: options };
  }, [optionsData]);

  return (
    <VerticalSpacing size={-4}>
      <TooltipWithIcon
        content="See candidates with previous experience at these companies. Ordered by the ones most frequently represented."
        icon={<Icon icon="circle-info" size={1} />}
      >
        <Label htmlFor="companyNames">
          <Text bold>Companies</Text>
        </Label>
      </TooltipWithIcon>
      <SelectField
        isMulti
        placeholder="Type companies"
        options={companyOptions}
        value={companyOptions.filter(({ value }) =>
          (preferences.companyNames || []).find(
            (name: string) => name === value
          )
        )}
        onChange={vals => {
          onChange({ companyNames: vals.map(({ value }) => value) });
        }}
      />
    </VerticalSpacing>
  );
}
