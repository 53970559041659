import styled from "@xstyled/styled-components";

import { Icon } from "@otta/icons";
import { palette } from "@otta/design-tokens";

const ErrorContainerGrid = styled.div`
  color: ${palette.brand.red};
  grid-template-columns: min-content auto;
  grid-auto-flow: row;
  display: grid;
  gap: 0.5rem;

  & > * {
    grid-column: 2;
  }
`;

const ErrorIcon = styled(Icon)`
  align-self: flex-start;
  font-size: 1.3rem;
  grid-column: 1;
  grid-row: 1;
  &:only-child {
    display: none;
  }
`;

/**
 * The idea with this is you provide it with child <ErrorText> when there are errors
 * and nothing if there aren't - with no children the (!) icon will magically vanish
 */
export function ErrorContainer({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>): React.ReactElement {
  return (
    <ErrorContainerGrid className={className}>
      <ErrorIcon icon="circle-exclamation-filled" />
      {children}
    </ErrorContainerGrid>
  );
}
