import Ajv, { JSONSchemaType } from "ajv";
const ajv = new Ajv();
interface ExternalLinkCue {
  type: "external-link";
  url: string;
  description: string;
  title: string;
  primaryButtonCopy?: string;
  paths?: string[];
}

interface InternalLinkCue {
  type: "internal-link";
  url: string;
  description: string;
  title: string;
  primaryButtonCopy: string;
  paths?: string[];
}

interface SimplePollCue {
  type: "simple-poll";
  description?: string;
  title: string;
  paths?: string[];
}

export type AnyOttacue = ExternalLinkCue | InternalLinkCue | SimplePollCue;

const schema: JSONSchemaType<AnyOttacue> = {
  oneOf: [
    {
      type: "object",
      properties: {
        type: { type: "string", const: "external-link" },
        url: { type: "string", pattern: "^http(s?)://.*$" },
        description: { type: "string", minLength: 8 },
        title: { type: "string", minLength: 8 },
        primaryButtonCopy: { type: "string", minLength: 2, nullable: true },
        paths: { type: "array", items: { type: "string" }, nullable: true },
      },
      required: ["type", "url", "description", "title"],
      additionalProperties: false,
    },
    {
      type: "object",
      properties: {
        type: { type: "string", const: "internal-link" },
        url: { type: "string", pattern: "^/.*$" },
        description: { type: "string", minLength: 8 },
        title: { type: "string", minLength: 8 },
        primaryButtonCopy: { type: "string", minLength: 2 },
        paths: { type: "array", items: { type: "string" }, nullable: true },
      },
      required: ["type", "url", "description", "title", "primaryButtonCopy"],
      additionalProperties: false,
    },
    {
      type: "object",
      properties: {
        type: { type: "string", const: "simple-poll" },
        description: { type: "string", minLength: 8, nullable: true },
        title: { type: "string", minLength: 8 },
        paths: { type: "array", items: { type: "string" }, nullable: true },
      },
      required: ["type", "title"],
      additionalProperties: false,
    },
  ],
};

const validate = ajv.compile(schema);

function getValidationError(): string {
  const errors = validate.errors;

  if (errors && errors[0]?.message) {
    return errors[0].message;
  } else {
    return "No error found!";
  }
}

function validateCue(payload: AnyOttacue): AnyOttacue {
  const valid = validate(payload);

  if (valid) {
    return payload;
  } else {
    throw new Error(`Invalid ottacue payload: ${getValidationError()}`);
  }
}

export function parseAndValidateCue(payload: string): AnyOttacue | never {
  const parsed = JSON.parse(payload);

  switch (parsed.type) {
    case "external-link":
      return validateCue(parsed as ExternalLinkCue);
    case "internal-link":
      return validateCue(parsed as InternalLinkCue);
    case "simple-poll":
      return validateCue(parsed as SimplePollCue);
    default:
      throw new Error(`Invalid cue type ${parsed.type}`);
  }
}

const getOttacues = (): Record<string, string> => {
  const ottacues = localStorage.getItem("ottacues");
  return ottacues ? JSON.parse(ottacues) : {};
};

export const setOttacue = (name: string, state: string): void => {
  const ottacues = getOttacues();
  ottacues[name] = state;
  localStorage.setItem("ottacues", JSON.stringify(ottacues));
};

export const getOttacue = (name: string): string | null => {
  const ottacues = getOttacues();
  return name in ottacues ? ottacues[name] : null;
};
