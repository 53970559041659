import styled from "@xstyled/styled-components";

import { PersonProgressing } from "../../types";

import { Text } from "@otta/design";
import { Link } from "@hire/components/links/Link";
import { palette, pxToRem } from "@otta/design-tokens";

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${palette.extended.blue.shade600};
  :hover {
    color: ${palette.extended.blue.shade800};
`;

export const PersonPreview = (props: PersonProgressing) => {
  const { name, linkedinUrl, bio } = props;
  return (
    <>
      <Text bold>{name}</Text>
      <Text style={{ margin: `${pxToRem(8)} 0` }}>{bio}</Text>
      {linkedinUrl && (
        <Text>
          <StyledLink newTab to={linkedinUrl}>
            {linkedinUrl}
          </StyledLink>
        </Text>
      )}
    </>
  );
};
