import { useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";
import styled from "@xstyled/styled-components";
import { format } from "date-fns";

import {
  CompanySubscriptionPortalAction,
  CreateManageYourBillingLinkDocument,
} from "@hire/schema";
import { Button, Clickable } from "@otta/design";

const UnderlinedClickable = styled(Clickable)`
  text-decoration: underline;
`;

function usePortalMutation(action?: CompanySubscriptionPortalAction): {
  callback: () => void;
  loading: boolean;
} {
  const [run, { loading }] = useMutation(CreateManageYourBillingLinkDocument);
  const callback = useCallback(() => {
    run({
      variables: {
        action: action ?? null,
        returnPath: "settings/billing",
      },
    }).then(link => {
      const url = link.data?.createCompanyCustomerPortalSession?.url;
      url && window.open(url, "_blank", "noreferrer");
    });
  }, [run, action]);

  return useMemo(
    () => ({
      loading,
      callback,
    }),
    [loading, callback]
  );
}

export function CancelButton({
  cancelAt,
}: {
  cancelAt: string | null;
}): React.ReactElement {
  const { callback, loading } = usePortalMutation(
    CompanySubscriptionPortalAction.SubscriptionCancel
  );

  return (
    <Button
      type="button"
      level="destructive"
      onClick={callback}
      disabled={loading || !!cancelAt}
    >
      {loading ? (
        <>Loading&hellip;</>
      ) : cancelAt ? (
        `Cancels ${format(new Date(cancelAt), "d MMM")}`
      ) : (
        "Cancel plan"
      )}
    </Button>
  );
}

export function UpdateSubscriptionButton(): React.ReactElement {
  const { callback, loading } = usePortalMutation(
    CompanySubscriptionPortalAction.SubscriptionUpdate
  );
  return (
    <Button
      type="button"
      level="secondary"
      disabled={loading}
      onClick={callback}
    >
      {loading ? <>Loading&hellip;</> : "Change number of job slots"}
    </Button>
  );
}

export function UpdateCardDetailsLink(): React.ReactElement {
  const { callback, loading } = usePortalMutation(
    CompanySubscriptionPortalAction.PaymentMethodUpdate
  );
  return (
    <UnderlinedClickable onClick={callback}>
      {loading ? <>Loading&hellip;</> : "View or change payment method"}
    </UnderlinedClickable>
  );
}

export function PortalLink({
  children,
}: React.PropsWithChildren<unknown>): React.ReactElement {
  const { callback, loading } = usePortalMutation();
  return (
    <UnderlinedClickable onClick={callback}>
      {loading ? <>Loading&hellip;</> : children}
    </UnderlinedClickable>
  );
}
