import { useQuery } from "@apollo/client";

import {
  GetPipelineStagesDocument,
  JobPipelineStagesFragment,
} from "@hire/schema";

export const useStages = (
  jobId: string
): { stages: JobPipelineStagesFragment[]; loading: boolean } => {
  const { data, loading } = useQuery(GetPipelineStagesDocument, {
    variables: { jobId },
  });

  return {
    stages: data?.getJobPipeline?.candidatePipelineStages ?? [],
    loading,
  };
};
