import { Navigate, Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { CandidateAccount } from "./CandidateAccount";
import { NoCompany } from "./NoCompany";
import { RequiresEmailVerification } from "./RequiresVerification";
import { ArchivedCompany } from "./ArchivedCompany";
import { RequiresActivation } from "./RequiresActivation";

import {
  CompanyWorkflowStatus,
  CurrentUserDocument,
  CompanyJobSlotDataDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { useRequiredParams } from "@hire/util/routing";
import { hireAppUser } from "@hire/util/user";

type ActivatedRecruiterProps = {
  allowIncompleteCompanies?: boolean;
};

export function ActivatedRecruiter({
  allowIncompleteCompanies,
}: ActivatedRecruiterProps) {
  const { companySlug } = useRequiredParams(["companySlug"]);
  const { data, loading } = useQuery(CurrentUserDocument);
  const { data: jobSlotsData, loading: jobSlotsLoading } = useQuery(
    CompanyJobSlotDataDocument
  );

  const company = hireAppUser(jobSlotsData?.me)?.currentCompany;
  const paidJobSlots = company?.jobSlotData?.paid ?? 0;

  if (loading || jobSlotsLoading) {
    return <Loading />;
  }

  if (!data?.me) {
    return (
      <Navigate to={`/login/${encodeURIComponent(companySlug)}`} replace />
    );
  }

  if (
    data.me.__typename !== "CurrentCompanyRecruiter" &&
    data.me.__typename !== "CurrentAdmin"
  ) {
    return <CandidateAccount />;
  }

  if (!data.me.currentCompany) {
    return <NoCompany />;
  }

  const currentCompanySlug = data.me.currentCompany.urlSafeName.toLowerCase();

  if (currentCompanySlug !== companySlug) {
    return <Navigate to={`../${currentCompanySlug}`} replace />;
  }

  if (
    data.me.currentCompany.workflowStatus ===
      CompanyWorkflowStatus.PreApproval &&
    paidJobSlots === 0
  ) {
    return <Navigate to={`../quiz`} replace />;
  }

  if (!data.me.verifiedEmail) {
    return <RequiresEmailVerification email={data.me.email} />;
  }

  if (
    data.me.currentCompany.workflowStatus === CompanyWorkflowStatus.Archived
  ) {
    return <ArchivedCompany />;
  }

  if (
    !data.me.activated ||
    (!allowIncompleteCompanies &&
      data.me.currentCompany.workflowStatus !==
        CompanyWorkflowStatus.Completed &&
      data.me.currentCompany.workflowStatus !== CompanyWorkflowStatus.Changed &&
      paidJobSlots <= 0)
  ) {
    return <RequiresActivation companyName={data.me.currentCompany.name} />;
  }

  return <Outlet />;
}
