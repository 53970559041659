import styled from "@xstyled/styled-components";

import { useJobEditFormikContext } from "../../useHandlers";

import { FormikRange } from "@hire/components/form/Range/formik";
import { FieldWrapper } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";

const NarrowRange = styled(FormikRange)`
  max-width: ${pxToRem(400)};
`;

export function OfficeDays() {
  const form = useJobEditFormikContext();
  const errors = form.errors.requiredOfficeDaysRange;
  const touched = form.touched.requiredOfficeDaysRange ?? false;
  return (
    <FieldWrapper
      label="Days per week in office"
      advice="Enter a minimum and maximum number between 0 and 5."
      minError={touched ? errors?.minDaysInOffice : undefined}
      maxError={touched ? errors?.maxDaysInOffice : undefined}
      required
    >
      {({ min, max }) => (
        <NarrowRange
          minName="requiredOfficeDaysRange.minDaysInOffice"
          maxName="requiredOfficeDaysRange.maxDaysInOffice"
          min={min}
          max={max}
        />
      )}
    </FieldWrapper>
  );
}
