export interface TemplateFormData {
  name: string;
  message: string;
}

export enum TemplateVariables {
  CandidateName = "{candidateName}",
  RecruiterName = "{recruiterName}",
  CompanyName = "{companyName}",
  JobTitle = "{jobTitle}",
}
