import { useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { VerifyEmailDocument } from "@hire/schema";
import { Spinner } from "@otta/shared-components";
import { Button, Heading, Spacing } from "@otta/design";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { ReloadableError } from "@hire/components/ReloadableError";

export function Verify({ token, mode }: { token: string; mode?: string }) {
  const navigate = useNavigate();

  const [verify, { loading, error, called }] = useMutation(VerifyEmailDocument);

  useEffect(() => {
    if (mode === "jwt") {
      verify({ context: { emailToken: token } });
    } else {
      verify({ variables: { token } });
    }
  }, [token, verify, mode]);

  if (
    error?.message ===
    "This email verification token has expired. Request another."
  ) {
    return (
      <ReloadableError
        title="This link has expired"
        body="Email verification links expire after 2 hours and can only be used once."
        action={() => {
          navigate(".", { replace: true });
        }}
      />
    );
  }

  if (error) {
    return <ReloadableError action={() => verify({ variables: { token } })} />;
  }

  if (!called || loading) {
    return <Spinner />;
  }

  return (
    <ResponsiveCenteredContainer>
      <Spacing>
        <Heading bold size={3} align="center">
          Email verified
        </Heading>
        <Button as={Link} to="/login" level="primary">
          Click to login
        </Button>
      </Spacing>
    </ResponsiveCenteredContainer>
  );
}
