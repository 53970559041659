import { createContext } from "react";

export interface HighlightableTextContextProps {
  keywords: string[];
}

export const HighlightableTextContext =
  createContext<HighlightableTextContextProps>({
    keywords: [],
  });

interface HighlightableTextProviderProps {
  children: React.ReactNode;
  keywords: string[];
}

export function HighlightableTextProvider({
  keywords,
  children,
}: HighlightableTextProviderProps): React.ReactElement {
  return (
    <HighlightableTextContext.Provider
      value={{ keywords: keywords.map(w => w.toLowerCase()) }}
    >
      {children}
    </HighlightableTextContext.Provider>
  );
}
