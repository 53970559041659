import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useMemo } from "react";

import { updateApplicationResponse } from "../../Inbox/InboxCandidate/cache";

import { CandidateHeader } from "./CandidateHeader";
import { ConversationMessageInput } from "./CandidateConversation/ConversationMessageInput";

import {
  CompanyConversationDocument,
  ConversationMessagesDocument,
  CreateCandidateConversationMessageDocument,
  UpdateCompanyJobApplicationDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { useRequiredParams } from "@hire/util/routing";
import { ReloadableError } from "@hire/components/ReloadableError";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { pushAnalyticsEvent } from "@otta/analytics";
import { handleMutationError } from "@hire/errors";
import { useCurrentUser } from "@hire/hooks/useCurrentUser";

export function PipelineCandidate() {
  const navigate = useNavigate();
  const location = useLocation();

  const { controls } = useOutletContext<{
    controls: (id: string) => {
      next?: () => void;
      previous?: () => void;
    };
  }>();

  const { jobId, candidateId } = useRequiredParams(["jobId", "candidateId"]);

  const [user] = useCurrentUser();

  const {
    data,
    loading: conversationLoading,
    refetch,
  } = useQuery(CompanyConversationDocument, {
    variables: {
      jobId,
      candidateId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [updateJobApplication] = useMutation(
    UpdateCompanyJobApplicationDocument,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: CompanyConversationDocument,
          variables: { jobId, candidateId },
        },
      ],
      onError: handleMutationError,
      update: updateApplicationResponse,
    }
  );

  const [createMessage] = useMutation(
    CreateCandidateConversationMessageDocument,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: CompanyConversationDocument,
          variables: { jobId, candidateId },
        },
        {
          query: ConversationMessagesDocument,
          variables: { jobId, candidateId },
        },
      ],
      onError: handleMutationError,
    }
  );

  const { next, previous } = useMemo(
    () => controls(candidateId),
    [candidateId, controls]
  );

  const conversation = data?.companyRecruiterConversation;

  const canReply = useMemo(
    () =>
      !location.pathname.includes("notes") &&
      (!conversation?.sourced ||
        !!conversation?.hasAcceptedRequest ||
        !!conversation?.jobApplication),
    [conversation, location]
  );

  if (conversationLoading) {
    return <Loading />;
  }

  if (!data?.getJobPipelineItem || !conversation || !user) {
    return (
      <ResponsiveCenteredContainer>
        <ReloadableError action={refetch} />
      </ResponsiveCenteredContainer>
    );
  }

  const isUnrespondedApplication =
    conversation.jobApplication && !conversation.latestMessage;

  return (
    <>
      <CandidateHeader
        pipelineItem={data.getJobPipelineItem}
        applied={!!data.companyRecruiterConversation?.jobApplication?.applied}
        appliedAt={data.companyRecruiterConversation?.appliedAt ?? undefined}
        sourcedAt={data.companyRecruiterConversation?.sourcedAt ?? undefined}
        conversation={!!data.companyRecruiterConversation}
        showConversationTab={!isUnrespondedApplication}
        onNext={next}
        onPrevious={previous}
        jobId={jobId}
      />

      <Outlet context={{ conversation }} />

      {canReply && (
        <ConversationMessageInput
          key={`${conversation.job.externalId}:${conversation.recipient.externalId}`}
          jobId={conversation.job.externalId}
          candidateId={conversation.recipient.externalId}
          templates={!!isUnrespondedApplication}
          name={conversation.recipient.firstName}
          companyName={conversation.job.company.name}
          jobTitle={conversation.job.title}
          recruiterFirstName={user?.firstName}
          onSend={async message => {
            pushAnalyticsEvent({
              eventName: "Company Recruiter clicked Send message (Pipeline)",
              jobId: conversation.job.externalId,
              companyId: conversation.job.company.id,
              candidateId: conversation.recipient.externalId,
            });

            if (isUnrespondedApplication) {
              await updateJobApplication({
                variables: {
                  id: conversation.jobApplication!.id,
                  input: {
                    message,
                    accepted: true,
                  },
                },
              });

              navigate("conversation");
            } else if (conversation.conversationId) {
              await createMessage({
                variables: {
                  content: message,
                  conversationId: conversation.conversationId,
                },
              });
            }
          }}
        />
      )}
    </>
  );
}
