import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";
import { Icon, OttaIconsId } from "@otta/icons";
import { Spacing, Text } from "@otta/design";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export function EmptyList({
  icon,
  text,
}: {
  text: string;
  icon: OttaIconsId;
}): React.ReactElement {
  return (
    <Container>
      <Spacing>
        <Icon
          icon={icon}
          style={{ fontSize: pxToRem(40), color: palette.brand.yellow }}
        />
        <Text>{text}</Text>
      </Spacing>
    </Container>
  );
}
