import styled, { css, up } from "@xstyled/styled-components";

import { StageSelect } from "../../StageSelect";
import { StageOption } from "../../types";

import { pxToRem } from "@otta/design-tokens";

const BulkStageSelectWrapper = styled.div`
  display: flex;
  gap: sm;
  flex-direction: column;
  max-width: ${pxToRem(625)};
  padding-right: lg;

  ${up(
    "tablet",
    css`
      flex-direction: row;
      align-items: center;
      padding-right: 0;
      width: auto;
    `
  )}
`;

export const BulkStageSelectInput = ({
  onBulkMoveItems: handleBulkMoveItems,
  selectedBulkStageOption,
  bulkStageOptions,
}: {
  bulkStageOptions: StageOption[];
  selectedBulkStageOption: StageOption;
  onBulkMoveItems: (selectedOption: StageOption | null) => void;
}): React.ReactElement => {
  return (
    <BulkStageSelectWrapper>
      <StageSelect
        stageOptions={bulkStageOptions}
        currentStageId={selectedBulkStageOption.value}
        onChange={handleBulkMoveItems}
        label="Move to stage:"
        minWidth={360}
        name="move-bulk-candidates-to-stage"
        boldLabel
      />
    </BulkStageSelectWrapper>
  );
};
