import { ImportedCandidateFragment } from "@hire/schema";
import { Text, VerticalSpacing } from "@otta/design";

interface ExplainerTextProps {
  emailAddress: string;
  importedCandidate?: ImportedCandidateFragment | null;
  currentStageName?: string;
}

export const ExplainerText = ({
  importedCandidate,
  currentStageName,
  emailAddress,
}: ExplainerTextProps): React.ReactElement => {
  return (
    <>
      {!importedCandidate && (
        <Text>
          Enter as much of the candidate&apos;s information as you can.
        </Text>
      )}
      {importedCandidate && !currentStageName && (
        <Text>
          You&apos;ve added a candidate with this email address before - you can
          edit their details, or try a different email address.
        </Text>
      )}
      {currentStageName && (
        <Text>
          This candidate is already in this pipeline at the{" "}
          <i>{currentStageName}</i> stage. You can edit their details, or try a
          different email address.
        </Text>
      )}

      <VerticalSpacing size={-10}>
        <Text bold>Email address</Text>
        <Text>{emailAddress}</Text>
      </VerticalSpacing>
    </>
  );
};
