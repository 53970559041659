import { Routes, Route, Navigate } from "react-router-dom";

import { UserJobNotificationUnsubscription } from "./UserJobNotificationUnsubscription";

export function Callback(): React.ReactElement {
  return (
    <Routes>
      <Route
        path="job-notification-unsubscription/:externalId/:recruiterId"
        element={<UserJobNotificationUnsubscription />}
      />
      <Route element={<Navigate replace to="/" />} />
    </Routes>
  );
}
