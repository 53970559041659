import styled from "@xstyled/styled-components";

import { MessageTemplate } from "@hire/schema";
import {
  SelectField,
  Label as DefaultLabel,
  VerticalSpacing,
} from "@otta/design";

const Label = styled(DefaultLabel)`
  margin-right: xs;
`;

export interface MessageTemplateValue {
  id: string;
  name: string;
}

interface MessageTemplateOptionValue extends MessageTemplateValue {
  message: string;
}

interface MessageRequestTemplateSelectorProps {
  allTemplates: MessageTemplate[];
  value?: MessageTemplateValue;
  onChange: (data: {
    messageTemplate?: MessageTemplateValue;
    message?: string;
  }) => void;
}

export const MessageRequestTemplateSelector = ({
  allTemplates,
  onChange,
  value,
}: MessageRequestTemplateSelectorProps): React.ReactElement => {
  const templateOptions = allTemplates
    .map(({ id, message, name }) => ({
      value: { id, message, name },
      label: name,
    }))
    .sort((a, b) =>
      a.label.localeCompare(b.label, "en", {
        numeric: true,
        sensitivity: "base",
      })
    );

  const handleChange = (
    option: {
      label: string;
      value: MessageTemplateOptionValue;
    } | null
  ) => {
    if (option) {
      onChange({
        message: option.value.message,
        messageTemplate: {
          id: option.value.id,
          name: option.value.name,
        },
      });
    } else {
      onChange({
        message: "",
        messageTemplate: undefined,
      });
    }
  };

  return (
    <VerticalSpacing size={-4}>
      <Label>Templates</Label>

      <SelectField<MessageTemplateOptionValue>
        isClearable
        options={templateOptions}
        value={templateOptions.find(({ value: v }) => v.id === value?.id)}
        onChange={handleChange}
      />
    </VerticalSpacing>
  );
};
