import { SectionProps } from "../../common/types";

import { Text } from "@otta/design";
import { palette } from "@otta/design-tokens";

export function OtherCompensation({
  fields: { otherCompensation },
}: SectionProps): React.ReactElement | null {
  return otherCompensation ? (
    <Text color={palette.grayscale.shade600}>{otherCompensation}</Text>
  ) : null;
}
