import { SourcingPreferencesFieldProps } from "./interfaces";

import { Spacing, Label, Text, SelectField } from "@otta/design";
import { regions } from "@otta/locations";

const REGION_OPTIONS = Object.entries(regions).map(([value, label]) => ({
  label,
  value,
}));

export function RegionsField({
  preferences,
  onChange,
}: SourcingPreferencesFieldProps): React.ReactElement {
  const selectedRegions = preferences.regions || [];

  return (
    <Spacing size={-4}>
      <Label htmlFor="regions">
        <Text bold>Country candidate is based in</Text>
      </Label>
      <SelectField
        isMulti
        inputId="regions"
        placeholder="Select country"
        options={REGION_OPTIONS}
        value={REGION_OPTIONS.filter(({ value }) =>
          selectedRegions.find(r => r === value)
        )}
        onChange={vals => {
          const values = vals.map(({ value }) => value);

          if (!(values.length === 1 && ["CA", "US"].includes(values[0]))) {
            onChange({
              regions: values,
              subregions: [],
            });
          } else {
            onChange({ regions: values });
          }
        }}
      />
    </Spacing>
  );
}
