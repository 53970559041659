import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useEffect, useMemo, useRef } from "react";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";

import { handleMutationError } from "@hire/errors";
import {
  GetJobPipelineDocument,
  StageType,
  UpdatePipelineStageDocument,
} from "@hire/schema";
import { useStages } from "@hire/pages/JobPage/Pipeline/hooks/useStages";
import { NonNullDisableableStage } from "@hire/pages/JobPage/Pipeline/types";
import { ErrorText, Input } from "@otta/design";

const StyledInput = styled(Input)`
  width: 90%;
  padding: 0 xs;
`;

interface EditModeProps {
  stage: NonNullDisableableStage;
  jobExternalId: string;
  jobInternalId: string;
  onFinished: () => void;
}

export const EditMode = ({
  stage,
  jobExternalId,
  jobInternalId,
  onFinished,
}: EditModeProps) => {
  const { stages } = useStages(jobExternalId);
  const otherStageNamesLowercase = useMemo(
    () => stages.map(stage => stage.name.toLocaleLowerCase()),
    [stages]
  );

  const [updateStageMutation, { loading: updating }] = useMutation(
    UpdatePipelineStageDocument,
    {
      refetchQueries: [
        {
          query: GetJobPipelineDocument,
          variables: { jobId: jobExternalId, workExperienceLimit: 1 },
          fetchPolicy: "network-only",
        },
      ],
      onError: handleMutationError,
    }
  );

  const handleNameChange = async (newName: string) => {
    if (newName !== stage.name && stage.stageType === StageType.Custom) {
      await updateStageMutation({
        variables: {
          jobId: jobInternalId,
          stageId: stage.id,
          name: newName.trim(),
          workExperienceLimit: 1,
        },
      });

      onFinished();
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required()
      .max(30)
      .test(
        "must-be-unique",
        "A stage with this name already exists",
        value => !otherStageNamesLowercase.includes(value.toLocaleLowerCase())
      )
      .label("Stage name"),
  });

  const form = useFormik<{ name: string }>({
    initialValues: { name: stage.name },
    validationSchema,
    onSubmit: ({ name: newName }) => {
      handleNameChange(newName);
    },
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <form onSubmit={form.handleSubmit} onBlur={onFinished}>
      <StyledInput
        name="name"
        aria-label="Name"
        ref={inputRef}
        disabled={updating}
        value={form.values.name}
        onChange={form.handleChange("name")}
        onBlur={form.handleBlur("name")}
      />
      {form.touched.name && form.errors.name && (
        <ErrorText>{form.errors.name}</ErrorText>
      )}
    </form>
  );
};
