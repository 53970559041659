import { format, parseJSON } from "date-fns";
import styled from "@xstyled/styled-components";

import { CompanyConversationQuery } from "@hire/schema";
import { Text } from "@otta/design";

const StyledText = styled(Text)`
  margin-right: 18;
`;

export const AppliedSourcedDate = ({
  pipelineItem,
  appliedAt,
  sourcedAt,
}: {
  pipelineItem: NonNullable<CompanyConversationQuery["getJobPipelineItem"]>;
  appliedAt?: string;
  sourcedAt?: string;
}): React.ReactElement => {
  const appliedSourcedText = appliedAt
    ? `Applied: ${format(parseJSON(appliedAt), "dd/MM/yy")}`
    : sourcedAt
    ? `Sourced: ${format(parseJSON(sourcedAt), "dd/MM/yy")}`
    : pipelineItem.importedCandidate
    ? `Added on: ${format(
        parseJSON(pipelineItem.movedToStageAt),
        "dd/MM/yy"
      )} ${
        pipelineItem.importedCandidate?.source &&
        `(${pipelineItem.importedCandidate.source})`
      }`
    : "";

  return (
    <StyledText size={-1} data-testid="applied-sourced-text">
      {appliedSourcedText}
    </StyledText>
  );
};
