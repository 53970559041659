import { TagsContainer } from "./shared";

import { CandidateCardFragment } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { Text } from "@otta/design";
import { Separator } from "@hire/components/Separator";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

interface CandidateLanguagesProps {
  candidate: CandidateCardFragment;
}

export function CandidateLanguages({
  candidate,
}: CandidateLanguagesProps): React.ReactElement | null {
  if (candidate.languageRequirementPreferences.length === 0) {
    return null;
  }

  return (
    <>
      <Separator />
      <Text bold size={-1}>
        Languages
      </Text>
      <TagsContainer>
        {candidate.languageRequirementPreferences.slice(0, 10).map(lp => (
          <RoundedTag
            key={lp.languageRequirementId}
            color={palette.beige.shade200}
            content={lp.languageRequirement.value}
          />
        ))}
      </TagsContainer>
    </>
  );
}
