import { SectionProps } from "../../common/types";
import { Blobs, Blob } from "../../common/Blob";

import { locationToLocationOption } from "@hire/util/locations";

export function JobLocations({
  fields: { locationPreferences },
}: SectionProps): React.ReactElement | null {
  return locationPreferences.length > 0 ? (
    <Blobs>
      {locationPreferences.map((l, i) => (
        <Blob bold={i === 0} key={i}>
          {locationToLocationOption(l)?.label ?? ""}
        </Blob>
      ))}
    </Blobs>
  ) : (
    <Blobs>
      <Blob key={0}>[Location]</Blob>
    </Blobs>
  );
}
