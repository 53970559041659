import styled from "@xstyled/styled-components";

import { useJobEditFormikContext } from "../useHandlers";

import { Checkbox, Fieldset } from "@otta/design";
import { ExperienceSlider } from "@hire/components/sliders/ExperienceRangeSlider";

const ExperienceSliderContainer = styled.div`
  position: relative;
  margin: xl 35 70 35;
`;

export function Experience() {
  const form = useJobEditFormikContext();

  return (
    <Fieldset
      legend="Experience level"
      advice={
        <>
          Select 1-2 experience levels to receive the most relevant
          applications. You can select more if required.
        </>
      }
    >
      <Checkbox
        label="This job is an internship"
        name="internship"
        checked={form.values.internship}
        onChange={() =>
          form.setFieldValue("internship", !form.values.internship)
        }
      />
      {!form.values.internship && (
        <ExperienceSliderContainer>
          <ExperienceSlider
            value={form.values.experienceRange}
            onChange={newValue => {
              form.setFieldValue("experienceRange", newValue);
            }}
          />
        </ExperienceSliderContainer>
      )}
    </Fieldset>
  );
}
