import { useOutletContext } from "react-router-dom";

import { ConversationMessages } from "./ConversationMessages";

import { CompanyConversationQuery } from "@hire/schema";

export function CandidateConversation({
  inPipeline = false,
}: {
  inPipeline?: boolean;
}) {
  const { conversation } = useOutletContext<{
    conversation: CompanyConversationQuery["companyRecruiterConversation"];
  }>();
  if (!conversation) {
    return null;
  }

  return (
    <ConversationMessages inPipeline={inPipeline} conversation={conversation} />
  );
}
