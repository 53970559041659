import styled from "@xstyled/styled-components";

import { Icon, OttaIconsId } from "@otta/icons";
import { Clickable, Text } from "@otta/design";

const Contact = styled(Clickable)`
  display: flex;
  gap: xs;
  align-items: center;
`;

interface ContactIconProps {
  onClick: () => void;
  content: string;
  iconName: OttaIconsId;
}

export const ContactIcon = ({
  onClick: handleClick,
  content,
  iconName,
  ...props
}: ContactIconProps): React.ReactElement => {
  return (
    <Contact {...props} onClick={handleClick}>
      <Icon icon={iconName} size={1} />
      <Text size={-1} bold>
        {content}
      </Text>
    </Contact>
  );
};
