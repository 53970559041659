import { CandidateCardFragment } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { regions, subregions } from "@otta/locations";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

const countriesWithAlternativeName: Record<string, string> = {
  NL: "the Netherlands",
  US: "the US",
  GB: "the UK",
};

const removeThePrefix = (region: string) => {
  return region.split("the ").pop();
};

const getRegionSubregionText = (
  region: string,
  subregion: string | null,
  hasConfirmedLocation: boolean | null
) => {
  const openingText = hasConfirmedLocation ? "Based in" : "Signed up from";

  let regionLabel = countriesWithAlternativeName[region] || regions[region];

  if (["CA", "US"].includes(region) && subregion !== null) {
    const displaySubregion = subregions[region][subregion];
    if (displaySubregion) {
      regionLabel = `${displaySubregion}, ${removeThePrefix(regionLabel)}`;
    }
  }

  return `${openingText} ${regionLabel}`;
};

interface CandidateBasedInBadgeProps {
  candidate: CandidateCardFragment;
}

export function CandidateBasedInBadge({
  candidate,
}: CandidateBasedInBadgeProps): React.ReactElement | null {
  if (!candidate.region) {
    return null;
  }

  const text = getRegionSubregionText(
    candidate.region,
    candidate.subregion,
    candidate.hasConfirmedLocation
  );

  return (
    <RoundedTag content={text} icon="location" color={palette.brand.green} />
  );
}
