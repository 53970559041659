import { createContext, useContext } from "react";

interface InboxContext {
  refetchConversations: () => void;
}

const InboxContext = createContext<InboxContext>({
  refetchConversations: () => {
    return;
  },
});

export function InboxProvider({
  refetch,
  children,
}: {
  refetch: () => void;
  children: React.ReactNode;
}) {
  return (
    <InboxContext.Provider value={{ refetchConversations: refetch }}>
      {children}
    </InboxContext.Provider>
  );
}

export function useRefetchConversations() {
  return useContext(InboxContext).refetchConversations;
}
