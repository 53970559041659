import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { FundingFormFields } from "../../types";

import { TopInvestorsDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { SelectField } from "@otta/design";
import { MoreInfoFieldWrapper } from "@hire/components/form/MoreInfoFieldWrapper";

interface InvestorsSectionProps {
  disabled: boolean;
  onChange: (value: FundingFormFields["investors"]) => void;
  value: FundingFormFields["investors"];
}

export const InvestorsSection = ({
  onChange: handleChange,
  value,
  disabled,
}: InvestorsSectionProps) => {
  const { data, loading } = useQuery(TopInvestorsDocument, {
    skip: disabled,
  });

  const investorsOptions: FundingFormFields["investors"] = useMemo(
    () =>
      data?.topInvestors.map(({ value, id }) => ({
        label: value,
        value: id,
      })) ?? [],
    [data?.topInvestors]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <MoreInfoFieldWrapper
      label="Investors"
      fieldName="investors-select"
      optional
    >
      <SelectField
        isMulti
        closeMenuOnSelect={false}
        inputId="investors-select"
        value={value}
        onChange={value => handleChange([...value] || [])}
        options={investorsOptions}
        isDisabled={disabled}
      />
    </MoreInfoFieldWrapper>
  );
};
