import { SourcingPreferencesFieldProps } from "./interfaces";

import { Checkbox } from "@otta/design";

export function MustIncludeAllKeywordsField({
  onChange,
  preferences,
}: SourcingPreferencesFieldProps): React.ReactElement {
  return (
    <Checkbox
      label="Must include all keywords"
      checked={!!preferences.mustIncludeAllKeywords}
      onChange={checked => {
        onChange({ mustIncludeAllKeywords: checked });
      }}
    />
  );
}
