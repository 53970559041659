import styled from "@xstyled/styled-components";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";

import { CandidateCard } from "../CandidateCard";
import { NoCandidatesMessage } from "../NoCandidatesMessage";
import { SourcingPage } from "../../enums";
import { CandidateCardActions } from "../CandidateCard/interfaces";
import { NoMoreDiversityMatchesCard } from "../NoMoreDiversityMatchesCard";

import { CandidateCardJobDocument } from "@hire/schema";
import { VerticalSpacing } from "@otta/design";

const Container = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  padding: lg;
`;

export interface CandidatesListProps extends CandidateCardActions {
  type: SourcingPage;
  candidates: {
    externalId: string;
    diversityMatch?: boolean;
  }[];
  showDiversityBanner?: boolean;
}

export function CandidatesList({
  type,
  candidates,
  showDiversityBanner,
  ...rest
}: CandidatesListProps): React.ReactElement {
  const { jobId } = useParams();
  const { data } = useQuery(CandidateCardJobDocument, {
    variables: {
      id: jobId as string,
    },
  });

  const job = data?.companyJob ?? null;

  const firstNotDiversityMatch = useMemo(() => {
    return candidates.findIndex(c => !c.diversityMatch);
  }, [candidates]);

  return (
    <Container>
      {candidates.length === 0 ? (
        <NoCandidatesMessage type={type} />
      ) : (
        <VerticalSpacing>
          {candidates.map((c, index) => (
            <>
              {showDiversityBanner && firstNotDiversityMatch === index && (
                <NoMoreDiversityMatchesCard
                  cardType={index === 0 ? "NO_MATCHES" : "END_OF_MATCHES"}
                />
              )}
              <CandidateCard
                key={c.externalId}
                candidate={c}
                type={type}
                job={job}
                {...rest}
              />
            </>
          ))}
        </VerticalSpacing>
      )}
    </Container>
  );
}
