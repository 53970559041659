import styled from "@xstyled/styled-components";

import { Link } from "./links/Link";

import { modularScale } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 ${modularScale(-10)};
`;

const IconWrapper = styled.div`
  width: ${modularScale()};
  height: ${modularScale()};
`;

interface WebsiteIconLinkProps {
  url: string;
}

export function WebsiteIconLink({
  url,
  ...props
}: WebsiteIconLinkProps): React.ReactElement {
  return (
    <Wrapper {...props}>
      <Link to={url} newTab>
        <IconWrapper>
          <WebsiteIcon url={url} />
        </IconWrapper>
      </Link>
    </Wrapper>
  );
}

function WebsiteIcon({ url }: { url: string }) {
  if (url.includes("github")) {
    return <Icon icon="github" data-testid="github-icon" />;
  } else if (url.includes("dribbble")) {
    return <Icon icon="dribbble" data-testid="dribbble-icon" />;
  } else if (url.includes("linkedin")) {
    return <Icon icon="linkedin" data-testid="linkedin-icon" />;
  } else {
    return <Icon icon="link" data-testid="link-icon" />;
  }
}
