// SVG created by Mark Russell 2022
export function LightningBolt(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      {...props}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.51279 0L0.75 8.93145H3.6401V7.06855H6.51279V0Z"
        fill="black"
      />
      <path
        d="M3.6401 16L9.40288 7.06855H6.51279V8.93145H3.6401V16Z"
        fill="black"
      />
      <path d="M6.51279 8.93145V7.06855H3.6401V8.93145H6.51279Z" fill="black" />
    </svg>
  );
}
