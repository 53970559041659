function imageProxyUrl(path: string, width: number): string {
  return !path.startsWith("https://")
    ? `https://images.otta.com/search/width_${width}/${path}`
    : path;
}

export function MaybeProxiedImage({
  path,
  alt,
  width,
  className,
}: {
  path: string;
  alt?: string;
  width: number;
  className?: string;
}): React.ReactElement | null {
  return (
    <img src={imageProxyUrl(path, width)} alt={alt} className={className} />
  );
}
