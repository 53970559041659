import { rpxTransformers } from "@xstyled/styled-components";

export * from "./animations";

export const theme = {
  space: {
    xxs: 2,
    xs: 4,
    sm: 8,
    md: 12,
    lg: 16,
    xl: 24,
    xxl: 32,
    "3xl": 48,
    "4xl": 64,
    "5xl": 96,
    "6xl": 128,
    "7xl": 192,
  },
  screens: {
    mobile: 0,
    tablet: 768,
    desktop: 1024,
  },
  transitions: {
    default: "200ms ease-in-out",
  },
  transformers: {
    // Transforms px values to rem
    ...rpxTransformers,
  },
} as const;

export type Theme = typeof theme;
