import { useMemo, useState } from "react";

import { WizardStep } from "../WizardStep";

import { AshbyAPIKey } from "./AshbyAPIKey";
import { AshbyWebhook } from "./Webhook";
import { AshbyEnableIntegration } from "./AshbyEnableIntegration";

import { CompanyAtsApiKeyTypeEnum } from "@hire/schema";
import { Spacing } from "@otta/design";

interface AshbyFormProps {
  refetching: boolean;
  refetch(): Promise<unknown>;
  atsApiKeys: { type: CompanyAtsApiKeyTypeEnum }[];
  webhooks: {
    key: string;
    requestedTopic: string | null;
    secretKey: string | null;
    lastRequestedAt: string | null;
  }[];
}

const DEFAULT_WEBHOOKS = [
  {
    key: "webhook1",
    lastRequestedAt: null,
    requestedTopic: "Application Submitted",
    secretKey: null,
  },
  {
    key: "webhook2",
    lastRequestedAt: null,
    requestedTopic: "Candidate Application Changed Stage",
    secretKey: null,
  },
];

export function AshbyForm({
  atsApiKeys,
  webhooks: webhooksProp,
  refetch,
  refetching,
}: AshbyFormProps) {
  const apiKey = useMemo(
    () => atsApiKeys.find(key => key.type === CompanyAtsApiKeyTypeEnum.Ashby),
    [atsApiKeys]
  );
  const [hasEnabledIntegration, setHasEnabledIntegration] =
    useState<boolean>(false);
  const webhooks = webhooksProp.length > 0 ? webhooksProp : DEFAULT_WEBHOOKS;

  const handleSetIntegration = (bool: boolean) => {
    setHasEnabledIntegration(bool);
  };

  const firstUnrequestedWebhook = useMemo(
    () => webhooks.findIndex(webhook => !webhook.lastRequestedAt),
    [webhooks]
  );

  return (
    <Spacing>
      <WizardStep
        title="Step 1 - Enable integration"
        complete={!!apiKey}
        expanded={!apiKey && !hasEnabledIntegration}
      >
        <AshbyEnableIntegration handleIntegration={handleSetIntegration} />
      </WizardStep>
      <WizardStep
        title="Step 2 - Create API Key"
        complete={!!apiKey}
        expanded={!apiKey && hasEnabledIntegration}
      >
        <AshbyAPIKey />
      </WizardStep>
      {webhooks.map((webhook, index) => (
        <WizardStep
          key={webhook.key}
          title={`Step ${index + 3} - Add ${webhook?.requestedTopic} Webhook`}
          complete={!!webhook.lastRequestedAt}
          expanded={!!apiKey && firstUnrequestedWebhook === index}
        >
          <AshbyWebhook
            webhook={webhook}
            loading={refetching}
            refetch={refetch}
          />
        </WizardStep>
      ))}
    </Spacing>
  );
}
