import styled from "@xstyled/styled-components";

import { PublicSalaryWarning } from "./PublicSalaryWarning";

import { palette, pxToRem } from "@otta/design-tokens";
import {
  Card as DefaultCard,
  Text,
  VerticalSpacing,
  CircularProgress,
} from "@otta/design";
import { useExperiment } from "@otta/experiments/client";
import { Experiment } from "@hire/constants/experiments";

const IconAbsoluteWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const IconWrapper = styled.div`
  height: ${pxToRem(50)};
  width: ${pxToRem(50)};
  display: flex;

  > svg {
    min-width: 100%;
  }
`;

const Card = styled(DefaultCard)`
  color: ${palette.brand.black};
  border: none;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${palette.grayscale.shade50};
  }
  > div {
    height: 100%;
  }
`;

const AbsoluteContainer = styled.div`
  position: relative;
  height: ${pxToRem(200)};
  width: 100%;
`;

const CircularProgressWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  > svg {
    height: 100%;
  }
`;

interface MeasureProps {
  title: string;
  description: string;
  tip: string;
  icon: React.ComponentType;
  fillQuandrant: 0 | 1 | 2 | 3 | 4;
  dataTestId?: string;
  onClick?: () => void;
  dataAnalyticsId?: string;
  decertificationDate?: string;
}

export function Measure({
  title,
  icon: Icon,
  tip,
  description,
  fillQuandrant,
  dataTestId,
  dataAnalyticsId,
  onClick,
  decertificationDate,
}: MeasureProps): React.ReactElement {
  const { variant } = useExperiment(Experiment.OttaCertified);

  return (
    <Card
      as="button"
      data-testid={dataTestId}
      onClick={onClick}
      data-analytics-id={dataAnalyticsId}
    >
      <VerticalSpacing size={-2}>
        <Text bold align="center">
          {title}
        </Text>
        <AbsoluteContainer>
          <CircularProgressWrapper>
            <CircularProgress quadrants={fillQuandrant} />
          </CircularProgressWrapper>
          <IconAbsoluteWrapper>
            <IconWrapper>
              <Icon />
            </IconWrapper>
          </IconAbsoluteWrapper>
        </AbsoluteContainer>
        {decertificationDate && variant === "variant" ? (
          <PublicSalaryWarning decertificationDate={decertificationDate} />
        ) : (
          <>
            <Text bold align="center">
              {description}
            </Text>
            <Text align="center">{tip}</Text>
          </>
        )}
      </VerticalSpacing>
    </Card>
  );
}
