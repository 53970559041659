import { CandidateCardFragment } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

const ONE_DAY = 60 * 60 * 24;

interface CandidateRespondsQuicklyBadgeProps {
  candidate: CandidateCardFragment;
}

export function CandidateRespondsQuicklyBadge({
  candidate,
}: CandidateRespondsQuicklyBadgeProps): React.ReactElement | null {
  const candidateRespondsQuickly = !!(
    candidate.averageResponseTime && candidate.averageResponseTime < ONE_DAY
  );
  return candidateRespondsQuickly ? (
    <RoundedTag
      content="Responds quickly"
      icon="responds-quickly"
      color={palette.brand.green}
      hoverText="The candidate typically responds within 24 hours"
    />
  ) : null;
}
