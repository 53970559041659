import { yearsAndMonths } from "../utils";

import { ExperienceSummaryContainer } from "./shared";

import { CandidateCardFragment } from "@hire/schema";
import { Icon } from "@otta/icons";
import { Text } from "@otta/design";
import { HighlightableText } from "@hire/components/HighlightableText";

interface CandidateWorkExpSummaryProps {
  candidate: CandidateCardFragment;
}

export function CandidateWorkExpSummary({
  candidate,
}: CandidateWorkExpSummaryProps): React.ReactElement | null {
  if (candidate.workExperiences.length === 0) {
    return null;
  }

  return (
    <ExperienceSummaryContainer>
      <Icon icon="industry" />
      <div>
        {candidate.workExperiences
          .slice(0, 3)
          .map(({ id, companyName, title, startDate, endDate }) => {
            const duration = yearsAndMonths(startDate, endDate);
            return (
              <div key={id}>
                <Text size={-1}>
                  <Text as="span">
                    <HighlightableText>
                      {`${title}, ${companyName}`}
                    </HighlightableText>
                  </Text>
                  {duration && <Text as="span"> ({duration})</Text>}
                </Text>
              </div>
            );
          })}
      </div>
    </ExperienceSummaryContainer>
  );
}
