import { Fragment, PropsWithChildren } from "react";
import { useMedia } from "react-use";

import { MobileFooter } from "./MobileFooter";

import { pxToEm } from "@otta/design-tokens";
import { theme } from "@otta/design";

export function ResponsiveButtonContainer({
  children,
  breakpoint,
}: PropsWithChildren<{ breakpoint?: number }>) {
  const isDesktop = useMedia(
    `(min-width: ${pxToEm(breakpoint ?? theme.screens.tablet)})`
  );

  const Wrapper = isDesktop ? Fragment : MobileFooter;
  return <Wrapper>{children}</Wrapper>;
}
