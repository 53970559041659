import styled from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { useOutletContext } from "react-router-dom";

import { QuestionResponses } from "./QuestionResponses";

import {
  CandidateCardDocument,
  CandidateCardJobDocument,
  CompanyConversationQuery,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { VerticalSpacing } from "@otta/design";
import { CandidateSummary } from "@hire/pages/JobPage/Sourcing/components/CandidateCard/CandidateSummary";
import { CandidateExpanded } from "@hire/pages/JobPage/Sourcing/components/CandidateCard/CandidateExpanded";
import { useRequiredParams } from "@hire/util/routing";

const Container = styled.div`
  overflow-y: scroll;
  padding: lg;
`;

export function CandidateProfile() {
  const { conversation } = useOutletContext<{
    conversation: CompanyConversationQuery["companyRecruiterConversation"];
  }>();
  const { jobId, candidateId } = useRequiredParams(["jobId", "candidateId"]);

  const { data: jobData } = useQuery(CandidateCardJobDocument, {
    variables: {
      id: jobId,
    },
  });

  const { data: candidateData } = useQuery(CandidateCardDocument, {
    variables: {
      candidateId,
      jobId,
    },
  });

  if (!jobData?.companyJob || !candidateData?.candidate) {
    return <Loading />;
  }

  return (
    <Container>
      <VerticalSpacing>
        {conversation?.jobApplication && (
          <QuestionResponses
            jobApplicationId={conversation.jobApplication.id}
            questionResponses={
              conversation.jobApplication.questionResponses ?? []
            }
          />
        )}
        <CandidateSummary
          candidate={candidateData.candidate}
          job={jobData.companyJob}
          hideHeader
        />
        <CandidateExpanded
          candidate={candidateData.candidate}
          job={jobData.companyJob}
        />
      </VerticalSpacing>
    </Container>
  );
}
