import { useQuery } from "@apollo/client";

import { Form } from "./Form";

import { CurrentUserProfileDocument } from "@hire/schema";
import { Card, Text, Spacing } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { ReloadableError } from "@hire/components/ReloadableError";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";

export const UserProfile = () => {
  const { data, loading, refetch } = useQuery(CurrentUserProfileDocument);

  if (loading) {
    return <Loading />;
  }

  if (!data?.currentUser) {
    return <ReloadableError action={refetch} />;
  }

  return (
    <ResponsiveCenteredContainer>
      <Spacing>
        <Text size={2} bold>
          Your profile
        </Text>
        <Card>
          <Form data={data.currentUser} />
        </Card>
      </Spacing>
    </ResponsiveCenteredContainer>
  );
};
