import { ErrorText, Spacing } from "@otta/design";

export const FieldErrors = ({
  errors,
}: {
  errors?: string | (string | undefined)[];
}) => {
  if (errors === undefined) {
    return null;
  }

  if (typeof errors === "string") {
    return <ErrorText>{errors}</ErrorText>;
  }

  return (
    <Spacing size={-12}>
      {errors.map((e, i) => (
        <ErrorText key={i}>{e}</ErrorText>
      ))}
    </Spacing>
  );
};
