import { useCallback, useMemo, useState } from "react";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ApolloClient,
  MutationUpdaterFn,
  useApolloClient,
  useMutation,
  useQuery,
} from "@apollo/client";

import { useSourcingPreferences } from "./components/SourcingPreferences/useSourcingPreferences";
import { CandidatesList } from "./components/CandidatesList";
import { SourcingPage } from "./enums";
import { SourcingPageHeader } from "./components/SourcingPageHeader";
import {
  CandidatesContainer,
  PageContainer,
  SpinnerWrapper,
} from "./components/shared";

import { handleMutationError } from "@hire/errors";
import {
  CreateCandidateReactionDocument,
  PersonalisedCandidatesDocument,
  PersonalisedCandidatesPreferences,
} from "@hire/schema";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Middle, VerticalSpacing, Button } from "@otta/design";
import { Spinner } from "@otta/shared-components";
import { HighlightableTextProvider } from "@hire/components/HighlightableText/HighlightableTextProvider";
import { ReloadableError } from "@hire/components/ReloadableError";

const BATCH_SIZE = 5;

function extractKeywords(
  preferences?: PersonalisedCandidatesPreferences
): string[] {
  if (preferences?.queryString) {
    const matches = preferences.queryString.matchAll(/"[^"]+"|\w+/g);
    return Array.from(matches).map(m => m[0].replace(/"/g, ""));
  } else {
    return preferences?.keywords ?? [];
  }
}

const removeCandidateFromCache =
  (
    candidateId: string,
    variables: {
      jobId: string;
      preferences?: PersonalisedCandidatesPreferences;
    }
  ): MutationUpdaterFn =>
  cache => {
    cache.updateQuery(
      {
        query: PersonalisedCandidatesDocument,
        variables,
      },
      data => {
        if (!data?.companyJob) {
          return null;
        }

        return {
          companyJob: {
            ...data.companyJob,
            personalisedCandidates: {
              __typename: "PersonalisedCandidatesResult" as const,
              candidates:
                data.companyJob.personalisedCandidates.candidates.filter(
                  ({ externalId: cacheId }) => cacheId !== candidateId
                ),
            },
          },
        };
      }
    );
  };

const removeCandidatesFromCache = (
  client: ApolloClient<object>,
  candidateIds: string[],
  variables: {
    jobId: string;
    preferences?: PersonalisedCandidatesPreferences;
  }
) => {
  const data = client.readQuery({
    query: PersonalisedCandidatesDocument,
    variables,
  });

  if (!data?.companyJob) {
    return;
  }

  client.writeQuery({
    query: PersonalisedCandidatesDocument,
    variables,
    data: {
      companyJob: {
        ...data.companyJob,
        personalisedCandidates: {
          __typename: "PersonalisedCandidatesResult" as const,
          candidates: data.companyJob.personalisedCandidates.candidates.filter(
            ({ externalId: cacheId }) => !candidateIds.includes(cacheId)
          ),
        },
      },
    },
  });
};

export function SearchCandidates(): React.ReactElement {
  const [batchRemaining, setBatchRemaining] = useState(BATCH_SIZE);
  const [loadingSeeNextBatch, setLoadingSeeNextBatch] = useState(false);
  const { jobId } = useParams();
  const client = useApolloClient();
  const navigate = useNavigate();

  const { preferences, loading: preferencesLoading } = useSourcingPreferences(
    jobId as string
  );

  const queryVariables = useMemo(
    () => ({ jobId: jobId as string, preferences }),
    [jobId, preferences]
  );

  const { data, loading, refetch } = useQuery(PersonalisedCandidatesDocument, {
    variables: queryVariables,
    skip: !preferences,
    fetchPolicy: "network-only",
  });

  const [createReaction] = useMutation(CreateCandidateReactionDocument);

  const allCandidates = useMemo(
    () => data?.companyJob?.personalisedCandidates.candidates ?? [],
    [data]
  );

  const batch = useMemo(() => {
    return allCandidates.slice(0, batchRemaining);
  }, [allCandidates, batchRemaining]);

  const shortlistCandidate = useCallback(
    (candidateId: string) =>
      createReaction({
        variables: {
          candidateId,
          jobId: queryVariables.jobId,
          direction: true,
        },
        update: removeCandidateFromCache(candidateId, queryVariables),
        onError: handleMutationError,
        onCompleted: () => {
          setBatchRemaining(curr => (curr > 1 ? curr - 1 : BATCH_SIZE));
          pushAnalyticsEvent({
            eventName: "Company Recruiter Shortlisted Candidate",
            jobId: queryVariables.jobId,
            candidateId: candidateId,
          });
          toast.success("Candidate shortlisted");
        },
      }),
    [createReaction, queryVariables]
  );

  const messageCandidate = useCallback(
    (candidateId: string) => {
      pushAnalyticsEvent({
        eventName: "Company Recruiter Messaged Candidate",
        jobId: jobId,
        candidateId: candidateId,
      });
      navigate({
        pathname: `${candidateId}/message`,
        search: location.search,
      });
    },
    [jobId, navigate]
  );

  const handleSeeNextBatch = useCallback(async () => {
    setLoadingSeeNextBatch(true);

    await Promise.all(
      batch.map(c =>
        createReaction({
          variables: {
            candidateId: c.externalId,
            jobId: queryVariables.jobId,
            direction: false,
          },
        })
      )
    );

    removeCandidatesFromCache(
      client,
      batch.map(c => c.externalId),
      queryVariables
    );

    setBatchRemaining(BATCH_SIZE);
    setLoadingSeeNextBatch(false);
  }, [batch, createReaction, queryVariables, client]);

  const handleAfterMessage = useCallback(
    (candidateId: string) => {
      shortlistCandidate(candidateId);
      navigate({
        pathname: `.`,
        search: location.search,
      });
    },
    [navigate, shortlistCandidate]
  );

  const hasDiversityPreferences =
    (preferences?.genders ?? []).length > 0 ||
    (preferences?.ethnicities ?? []).length > 0;

  const keywords: string[] = useMemo(
    () => extractKeywords(preferences),
    [preferences]
  );

  return (
    <PageContainer>
      <Outlet context={{ onAfterMessage: handleAfterMessage }} />
      <CandidatesContainer>
        <SourcingPageHeader
          currentPage={SourcingPage.Search}
          numCandidates={allCandidates.length}
          loading={loading || preferencesLoading}
        />
        <Middle>
          <VerticalSpacing>
            {loading || preferencesLoading || loadingSeeNextBatch ? (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            ) : data ? (
              <>
                <HighlightableTextProvider keywords={keywords}>
                  <CandidatesList
                    candidates={batch}
                    type={SourcingPage.Search}
                    shortlistCandidate={shortlistCandidate}
                    messageCandidate={messageCandidate}
                    showDiversityBanner={hasDiversityPreferences}
                  />
                </HighlightableTextProvider>
                <Button level="secondary" onClick={handleSeeNextBatch}>
                  See next batch
                </Button>
              </>
            ) : (
              <ReloadableError action={refetch} />
            )}
          </VerticalSpacing>
        </Middle>
      </CandidatesContainer>
    </PageContainer>
  );
}
