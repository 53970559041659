import styled from "@xstyled/styled-components";

import { StageType } from "@hire/schema";
import { Text, VerticalSpacing } from "@otta/design";
import { ImportedCandidatePipelineItem } from "@hire/pages/JobPage/Pipeline/types";

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImportedCandidate = ({
  item,
}: {
  item: ImportedCandidatePipelineItem;
  stageType: StageType;
}): React.ReactElement => {
  return (
    <VerticalSpacing size={-4}>
      <TitleContainer>
        <Text
          data-cs-mask
          bold
        >{`${item.importedCandidate?.firstName} ${item.importedCandidate?.lastName}`}</Text>
      </TitleContainer>
      <Text>{item.importedCandidate?.currentEmployer}</Text>
    </VerticalSpacing>
  );
};
