import { useState } from "react";
import styled from "@xstyled/styled-components";

import { SectionProps } from "../../common/types";

import { Clickable, Text } from "@otta/design";
import { Icon } from "@otta/icons";

const ShowMoreText = styled(Text)`
  display: flex;
  gap: 0.5rem;
`;

const FlexText = styled(Text)`
  margin-top: 0.5rem;
  display: flex;
  gap: 0.2rem;
`;

const FlexStrong = styled.strong`
  display: inline-flex;
  gap: 0.2rem;
`;

export function JobHybridInfo({
  fields: { locationInformation, locationRestrictions },
}: SectionProps): React.ReactElement | null {
  const [show, setShow] = useState(false);

  if (locationInformation.trim() === "") {
    return null;
  }

  return show ? (
    <>
      <Text size={-1}>{locationInformation}</Text>
      {!!locationRestrictions && (
        <FlexText size={-1}>
          <FlexStrong>
            <Icon icon="circle-info" /> Restrictions:
          </FlexStrong>
          {locationRestrictions}
        </FlexText>
      )}
    </>
  ) : (
    <Clickable onClick={() => setShow(true)}>
      <ShowMoreText>
        <span style={{ textDecoration: "underline" }}>
          More information about location
        </span>
        <Icon icon="chevron-down" />
      </ShowMoreText>
    </Clickable>
  );
}
