import { useFormik } from "formik";
import styled from "@xstyled/styled-components";
import * as Yup from "yup";

import {
  transformIn,
  transformInMoneyField,
  transformOut,
} from "./transformers";
import { MONTH_OPTIONS, ROUND_OPTIONS } from "./options";

import {
  Spacing,
  InputField,
  Button,
  SelectField,
  MoneyField,
  ErrorText,
} from "@otta/design";
import {
  FundingRound,
  FundingRoundModalForm,
} from "@hire/pages/CompanyProfile/InvestorsAndFunding/types";
import { MoreInfoFieldWrapper } from "@hire/components/form/MoreInfoFieldWrapper";
import { CURRENCY_OPTIONS } from "@hire/util/currencies";
import { FormModalProps } from "@hire/components/form/ItemCardList";
import { ButtonWrapper } from "@hire/components/buttons/ButtonWrapper";

const SubFormWrapper = styled.div`
  padding: lg;
`;
const DateFieldsWrapper = styled.div`
  display: flex;
  align-items: start;
  gap: lg;
`;

const validationSchema = Yup.object().shape({
  month: Yup.object().required("Fill in this field"),
  year: Yup.date()
    .typeError("Enter a valid year")
    .min(new Date("1900").getFullYear(), "Enter a valid year")
    .max(new Date().getFullYear(), "Enter a valid year")
    .required("Fill in this field"),
  funding: Yup.object({
    amount: Yup.number().nullable(),
    currency: Yup.string(),
  }).test("amount is entered", "Fill in this field", ({ amount }) => !amount),
  fundingAmount: Yup.number().required("Fill in this field"),
  fundingCurrency: Yup.string().required("Fill in this field"),
  round: Yup.object({ label: Yup.string(), value: Yup.string() }),
});

export const FundingRoundModal = ({
  onSave,
  onClose,
  initialValues: data,
}: FormModalProps<FundingRound>) => {
  const initialValues: FundingRoundModalForm = transformIn(data);

  const handleSubmit = (values: FundingRoundModalForm) => {
    const formattedValues = transformOut(values);
    if (formattedValues) {
      onSave(formattedValues);
      form.resetForm();
    }
  };

  const form = useFormik<FundingRoundModalForm>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    form.resetForm();
    onClose();
  };

  return (
    <form onSubmit={form.handleSubmit} data-testid="funding-round-subform">
      <SubFormWrapper>
        <Spacing>
          <DateFieldsWrapper>
            <MoreInfoFieldWrapper label="Month" fieldName="month">
              <>
                <SelectField
                  value={form.values.month}
                  inputId="month"
                  options={MONTH_OPTIONS}
                  maxMenuHeight={140}
                  onChange={e => {
                    if (e) {
                      form.setFieldValue("month", {
                        label: e.label,
                        value: e.value,
                      });
                    }
                  }}
                />
                {form.touched.month && (
                  <ErrorText>{form.errors.month}</ErrorText>
                )}
              </>
            </MoreInfoFieldWrapper>
            <InputField
              name="year"
              label="Year"
              value={form.values.year}
              onChange={form.handleChange("year")}
              error={form.touched.year ? form.errors.year : undefined}
            />
          </DateFieldsWrapper>
          <MoreInfoFieldWrapper label="Round type" fieldName="round">
            <>
              <SelectField
                value={form.values.round}
                inputId="round"
                options={ROUND_OPTIONS}
                maxMenuHeight={140}
                onChange={e => {
                  if (e) {
                    form.setFieldValue("round", {
                      label: e.label,
                      value: e.value,
                    });
                  }
                }}
              />
              {form.touched.round && <ErrorText>{form.errors.round}</ErrorText>}
            </>
          </MoreInfoFieldWrapper>
          <MoreInfoFieldWrapper label={"Funding amount"} fieldName={"funding"}>
            <MoneyField
              name="funding"
              value={transformInMoneyField(
                form.values.fundingAmount,
                form.values.fundingCurrency
              )}
              options={CURRENCY_OPTIONS}
              placeholder="300,000"
              onChange={({ amount, currency }) => {
                amount && form.setFieldValue("fundingAmount", amount);
                form.setFieldValue("fundingCurrency", currency);
              }}
              error={
                form.touched.fundingAmount
                  ? form.errors.fundingAmount
                  : form.touched.fundingCurrency
                  ? form.errors.fundingCurrency
                  : undefined
              }
            />
          </MoreInfoFieldWrapper>
          <ButtonWrapper align="start">
            <Button level="secondary" type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button level="primary" type="submit" disabled={form.isSubmitting}>
              Confirm
            </Button>
          </ButtonWrapper>
        </Spacing>
      </SubFormWrapper>
    </form>
  );
};
