import { useState } from "react";
import styled, { css, up } from "@xstyled/styled-components";
import { useMedia } from "react-use";

import { NavItem, NavText } from "./nav-styled-components";

import { palette, pxToEm } from "@otta/design-tokens";
import { theme } from "@otta/design";

const NavItemContainer = styled.li`
  list-style: none;
`;

const SubNav = styled.ul<{ $open?: boolean }>`
  display: ${({ $open }) => ($open ? "flex" : "none")};
  flex-direction: column;
  background: ${palette.brand.grey};
  gap: 2px;
  ${NavItem}.active + & {
    display: flex;
  }

  ${up(
    "tablet",
    css`
      display: none !important;
    `
  )}
`;

export interface NavBarItemProps {
  route: string;
  name: React.ReactNode;
  icon: React.ReactElement;
  analyticsId?: string;
  end?: boolean;
  onClick?: () => void;
  subnav?: React.ComponentType<{ onClick?: () => void }>;
}

export const NavBarItem = ({
  route,
  name,
  icon,
  end,
  onClick,
  analyticsId,
  subnav: SubNavComponent,
}: NavBarItemProps) => {
  const [subNavOpen, setSubNavOpen] = useState(false);
  const isDesktop = useMedia(`(min-width: ${pxToEm(theme.screens.tablet)})`);

  return (
    <NavItemContainer>
      <NavItem
        to={route}
        end={end}
        data-analytics-id={analyticsId}
        onClick={e => {
          if (!isDesktop && SubNavComponent) {
            e.preventDefault();
            setSubNavOpen(!subNavOpen);
          } else if (onClick) {
            onClick();
          }
        }}
      >
        {icon}
        <NavText>{name}</NavText>
      </NavItem>

      {SubNavComponent && (
        <SubNav $open={subNavOpen}>
          <SubNavComponent onClick={onClick} />
        </SubNav>
      )}
    </NavItemContainer>
  );
};
