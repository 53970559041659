import { palette } from "@otta/design-tokens";

export function Unchecked(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 100 100"
      {...props}
    >
      <circle
        cx="50"
        cy="50"
        r="48"
        stroke={palette.grayscale.shade400}
        strokeWidth="4"
        fill="none"
      />
    </svg>
  );
}
