import { useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";

import { CompanyDetailsForm } from "./Form";

import { handleMutationError } from "@hire/errors";
import {
  CompanyDetailsDocument,
  CompanyDetailsOptionsDocument,
  CompanyInput,
  UpdateCompanyDetailsDocument,
} from "@hire/schema";
import { Text } from "@otta/design";
import { Loading } from "@otta/shared-components";
import { ReloadableError } from "@hire/components/ReloadableError";
import { CardContainer } from "@hire/components/containers/CardContainer";

export const CompanyDetails = () => {
  const detailsQuery = useQuery(CompanyDetailsDocument);
  const optionsQuery = useQuery(CompanyDetailsOptionsDocument);

  const [update] = useMutation(UpdateCompanyDetailsDocument, {
    onError: handleMutationError,
  });

  const handleSave = useCallback(
    async (input: CompanyInput) => {
      if (!detailsQuery.data?.currentUser?.currentCompany?.id) {
        return;
      }

      await update({
        variables: {
          id: detailsQuery.data?.currentUser?.currentCompany?.id,
          input,
        },
      });
    },
    [detailsQuery.data, update]
  );

  if (detailsQuery.loading || optionsQuery.loading) {
    return <Loading />;
  }

  if (!detailsQuery.data?.currentUser?.currentCompany || !optionsQuery.data) {
    return (
      <ReloadableError
        action={() => {
          if (detailsQuery.error) {
            detailsQuery.refetch();
          }
          if (optionsQuery.error) {
            optionsQuery.refetch();
          }
        }}
      />
    );
  }

  return (
    <>
      <Text bold size={2} align="center">
        Company details
      </Text>
      <CardContainer>
        <CompanyDetailsForm
          data={detailsQuery.data.currentUser.currentCompany}
          options={optionsQuery.data}
          handleSave={handleSave}
        />
      </CardContainer>
    </>
  );
};
