import { CandidateCardFragment } from "@hire/schema";
import { Text } from "@otta/design";
import { Separator } from "@hire/components/Separator";
import { HighlightableText } from "@hire/components/HighlightableText";

interface CandidateInterestsProps {
  candidate: CandidateCardFragment;
}

export function CandidateInterests({
  candidate,
}: CandidateInterestsProps): React.ReactElement | null {
  if (!candidate.profileInterests) {
    return null;
  }

  return (
    <>
      <Separator />
      <Text size={-1}>
        <Text as="span" bold>
          Interests:
        </Text>{" "}
        <HighlightableText>{candidate.profileInterests}</HighlightableText>
      </Text>
    </>
  );
}
