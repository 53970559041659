export const ReachMoreCandidatesImage = (): React.ReactElement => {
  return (
    <svg width="119" height="98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <g clipPath="url(#b)">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 68.26 15.629)"
            fill="#F7F7F4"
          />
          <g clipPath="url(#c)">
            <rect
              width="63.818"
              height="43.134"
              rx="4"
              transform="matrix(-.93864 .34491 0 1 66.177 18.605)"
              fill="#F7F7F4"
            />
            <rect
              width="42.73"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 56.28 39.938)"
              fill="#A2A292"
            />
            <rect
              width="52.719"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 60.968 49.828)"
              fill="#A2A292"
            />
            <rect
              width="36.071"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 53.155 60.441)"
              fill="#A2A292"
            />
            <rect
              width="22.198"
              height="8.848"
              rx="2"
              transform="matrix(-.93864 .34491 0 1 46.384 30.305)"
              fill="#A2A292"
            />
            <rect
              width="32.741"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 51.592 44.426)"
              fill="#A2A292"
            />
            <rect
              width="40.51"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 55.239 54.7)"
              fill="#A2A292"
            />
            <rect
              width="27.192"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 48.988 64.738)"
              fill="#A2A292"
            />
          </g>
        </g>
      </g>
      <g filter="url(#d)">
        <g clipPath="url(#e)">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 72.745 15.629)"
            fill="#F7F7F4"
          />
          <g clipPath="url(#f)">
            <rect
              width="63.818"
              height="43.134"
              rx="4"
              transform="matrix(-.93864 .34491 0 1 70.662 18.605)"
              fill="#F7F7F4"
            />
            <rect
              width="42.73"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 60.765 39.938)"
              fill="#A2A292"
            />
            <rect
              width="52.719"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 65.453 49.828)"
              fill="#A2A292"
            />
            <rect
              width="36.071"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 57.64 60.441)"
              fill="#A2A292"
            />
            <rect
              width="22.198"
              height="8.848"
              rx="2"
              transform="matrix(-.93864 .34491 0 1 50.868 30.305)"
              fill="#A2A292"
            />
            <rect
              width="32.741"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 56.077 44.426)"
              fill="#A2A292"
            />
            <rect
              width="40.51"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 59.723 54.7)"
              fill="#A2A292"
            />
            <rect
              width="27.192"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 53.473 64.738)"
              fill="#A2A292"
            />
          </g>
        </g>
      </g>
      <g filter="url(#g)">
        <g clipPath="url(#h)">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 76.92 15.629)"
            fill="#F7F7F4"
          />
          <g clipPath="url(#i)">
            <rect
              width="63.818"
              height="43.134"
              rx="4"
              transform="matrix(-.93864 .34491 0 1 74.837 18.605)"
              fill="#F7F7F4"
            />
            <rect
              width="42.73"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 64.94 39.938)"
              fill="#A2A292"
            />
            <rect
              width="52.719"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 69.628 49.828)"
              fill="#A2A292"
            />
            <rect
              width="36.071"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 61.815 60.441)"
              fill="#A2A292"
            />
            <rect
              width="22.198"
              height="8.848"
              rx="2"
              transform="matrix(-.93864 .34491 0 1 55.044 30.305)"
              fill="#A2A292"
            />
            <rect
              width="32.741"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 60.252 44.426)"
              fill="#A2A292"
            />
            <rect
              width="40.51"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 63.899 54.7)"
              fill="#A2A292"
            />
            <rect
              width="27.192"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 57.648 64.738)"
              fill="#A2A292"
            />
          </g>
        </g>
      </g>
      <g filter="url(#j)">
        <g clipPath="url(#k)">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 81.096 15.629)"
            fill="#F7F7F4"
          />
          <g clipPath="url(#l)">
            <rect
              width="63.818"
              height="43.134"
              rx="4"
              transform="matrix(-.93864 .34491 0 1 79.013 18.605)"
              fill="#F7F7F4"
            />
            <rect
              width="42.73"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 69.116 39.938)"
              fill="#A2A292"
            />
            <rect
              width="52.719"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 73.804 49.828)"
              fill="#A2A292"
            />
            <rect
              width="36.071"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 65.99 60.441)"
              fill="#A2A292"
            />
            <rect
              width="22.198"
              height="8.848"
              rx="2"
              transform="matrix(-.93864 .34491 0 1 59.22 30.305)"
              fill="#A2A292"
            />
            <rect
              width="32.741"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 64.428 44.426)"
              fill="#A2A292"
            />
            <rect
              width="40.51"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 68.074 54.7)"
              fill="#A2A292"
            />
            <rect
              width="27.192"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 61.824 64.738)"
              fill="#A2A292"
            />
          </g>
        </g>
      </g>
      <g filter="url(#m)">
        <g clipPath="url(#n)">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 85.272 15.629)"
            fill="#F7F7F4"
          />
          <g clipPath="url(#o)">
            <rect
              width="63.818"
              height="43.134"
              rx="4"
              transform="matrix(-.93864 .34491 0 1 83.188 18.605)"
              fill="#F7F7F4"
            />
            <rect
              width="42.73"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 73.291 39.938)"
              fill="#A2A292"
            />
            <rect
              width="52.719"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 77.979 49.828)"
              fill="#A2A292"
            />
            <rect
              width="36.071"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 70.166 60.441)"
              fill="#A2A292"
            />
            <rect
              width="22.198"
              height="8.848"
              rx="2"
              transform="matrix(-.93864 .34491 0 1 63.395 30.305)"
              fill="#A2A292"
            />
            <rect
              width="32.741"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 68.603 44.426)"
              fill="#A2A292"
            />
            <rect
              width="40.51"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 72.25 54.7)"
              fill="#A2A292"
            />
            <rect
              width="27.192"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 65.999 64.738)"
              fill="#A2A292"
            />
          </g>
        </g>
      </g>
      <g filter="url(#p)">
        <g clipPath="url(#q)">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 89.447 15.629)"
            fill="#F7F7F4"
          />
          <g clipPath="url(#r)">
            <rect
              width="63.818"
              height="43.134"
              rx="4"
              transform="matrix(-.93864 .34491 0 1 87.363 18.605)"
              fill="#F7F7F4"
            />
            <rect
              width="42.73"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 77.466 39.938)"
              fill="#A2A292"
            />
            <rect
              width="52.719"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 82.154 49.828)"
              fill="#A2A292"
            />
            <rect
              width="36.071"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 74.341 60.441)"
              fill="#A2A292"
            />
            <rect
              width="22.198"
              height="8.848"
              rx="2"
              transform="matrix(-.93864 .34491 0 1 67.57 30.305)"
              fill="#A2A292"
            />
            <rect
              width="32.741"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 72.778 44.426)"
              fill="#A2A292"
            />
            <rect
              width="40.51"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 76.425 54.7)"
              fill="#A2A292"
            />
            <rect
              width="27.192"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 70.174 64.738)"
              fill="#A2A292"
            />
          </g>
        </g>
      </g>
      <g filter="url(#s)">
        <g clipPath="url(#t)">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 93.622 15.629)"
            fill="#F7F7F4"
          />
          <g clipPath="url(#u)">
            <rect
              width="63.818"
              height="43.134"
              rx="4"
              transform="matrix(-.93864 .34491 0 1 91.539 18.605)"
              fill="#F7F7F4"
            />
            <rect
              width="42.73"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 81.642 39.938)"
              fill="#A2A292"
            />
            <rect
              width="52.719"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 86.33 49.828)"
              fill="#A2A292"
            />
            <rect
              width="36.071"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 78.517 60.441)"
              fill="#A2A292"
            />
            <rect
              width="22.198"
              height="8.848"
              rx="2"
              transform="matrix(-.93864 .34491 0 1 71.745 30.305)"
              fill="#A2A292"
            />
            <rect
              width="32.741"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 76.954 44.426)"
              fill="#A2A292"
            />
            <rect
              width="40.51"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 80.6 54.7)"
              fill="#A2A292"
            />
            <rect
              width="27.192"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 74.35 64.738)"
              fill="#A2A292"
            />
          </g>
        </g>
      </g>
      <g filter="url(#v)">
        <g clipPath="url(#w)">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93863 .34491 0 1 97.798 15.629)"
            fill="#F7F7F4"
          />
          <g clipPath="url(#x)">
            <rect
              width="63.818"
              height="43.134"
              rx="4"
              transform="matrix(-.93863 .34491 0 1 95.714 18.605)"
              fill="#F7F7F4"
            />
            <rect
              width="42.73"
              height="1.659"
              rx=".83"
              transform="matrix(-.93863 .34491 0 1 85.817 39.938)"
              fill="#A2A292"
            />
            <rect
              width="52.719"
              height="1.659"
              rx=".83"
              transform="matrix(-.93863 .34491 0 1 90.505 49.828)"
              fill="#A2A292"
            />
            <rect
              width="36.071"
              height="1.659"
              rx=".83"
              transform="matrix(-.93863 .34491 0 1 82.692 60.441)"
              fill="#A2A292"
            />
            <rect
              width="22.198"
              height="8.848"
              rx="2"
              transform="matrix(-.93863 .34491 0 1 75.92 30.305)"
              fill="#A2A292"
            />
            <rect
              width="32.741"
              height="1.659"
              rx=".83"
              transform="matrix(-.93863 .34491 0 1 81.13 44.426)"
              fill="#A2A292"
            />
            <rect
              width="40.51"
              height="1.659"
              rx=".83"
              transform="matrix(-.93863 .34491 0 1 84.776 54.7)"
              fill="#A2A292"
            />
            <rect
              width="27.192"
              height="1.659"
              rx=".83"
              transform="matrix(-.93863 .34491 0 1 78.525 64.738)"
              fill="#A2A292"
            />
          </g>
        </g>
      </g>
      <g filter="url(#y)">
        <g clipPath="url(#z)">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 101.973 15.629)"
            fill="#F7F7F4"
          />
          <g clipPath="url(#A)">
            <rect
              width="63.818"
              height="43.134"
              rx="4"
              transform="matrix(-.93864 .34491 0 1 99.89 18.605)"
              fill="#F7F7F4"
            />
            <rect
              width="42.73"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 89.993 39.938)"
              fill="#A2A292"
            />
            <rect
              width="52.719"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 94.68 49.828)"
              fill="#A2A292"
            />
            <rect
              width="36.071"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 86.868 60.441)"
              fill="#A2A292"
            />
            <rect
              width="22.198"
              height="8.848"
              rx="2"
              transform="matrix(-.93864 .34491 0 1 80.096 30.305)"
              fill="#A2A292"
            />
            <rect
              width="32.741"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 85.305 44.426)"
              fill="#A2A292"
            />
            <rect
              width="40.51"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 88.951 54.7)"
              fill="#A2A292"
            />
            <rect
              width="27.192"
              height="1.659"
              rx=".83"
              transform="matrix(-.93864 .34491 0 1 82.7 64.738)"
              fill="#A2A292"
            />
          </g>
        </g>
      </g>
      <g filter="url(#B)">
        <rect
          width="68.257"
          height="47.558"
          rx="4"
          transform="matrix(-.93864 .34491 0 1 106.148 3.262)"
          fill="#F7F7F4"
        />
        <g clipPath="url(#C)">
          <rect
            width="63.818"
            height="43.134"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 104.065 6.238)"
            fill="#FFCD00"
          />
          <g clipPath="url(#D)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="m86.355 11.746-5.416 13.275a5.385 5.385 0 0 0-2.376-1.087c-1.013-.199-2.107-.157-3.275.113l-.722-1.397 1.916-4.995c.622-1.622 1.942-2.995 3.39-3.527l6.483-2.382Zm-25.002 9.187 5.467 9.23a15.086 15.086 0 0 1 2.336-2.772 13.89 13.89 0 0 1 4.8-2.949l-2.528-4.89c-.615-1.19-1.945-1.606-3.408-1.069l-6.667 2.45Zm12.5 4.531c1.552-.57 2.946-.726 4.183-.468a4.663 4.663 0 0 1 2.939 1.928c.708 1.013 1.083 2.334 1.083 3.82 0 1.488-.375 3.084-1.084 4.618a13.318 13.318 0 0 1-2.938 4.087 12.159 12.159 0 0 1-4.182 2.605c-1.551.57-2.945.726-4.183.468a4.664 4.664 0 0 1-2.938-1.927c-.709-1.013-1.083-2.334-1.083-3.821s.374-3.083 1.083-4.617a13.32 13.32 0 0 1 2.938-4.087 12.162 12.162 0 0 1 4.183-2.606Zm1.563 5.51-1.207-2.335c-.14-.27-.57-.112-.71.26l-1.207 3.222a.657.657 0 0 1-.309.352l-2.861 1.412c-.331.163-.464.646-.22.797l2.116 1.3c.1.06.144.19.118.341l-.562 3.207c-.064.371.284.512.574.232l2.515-2.418c.118-.114.263-.167.382-.14l2.514.57c.291.066.64-.33.574-.654l-.561-2.794a.566.566 0 0 1 .117-.429l2.116-2.854c.245-.33.112-.716-.22-.636l-2.86.691c-.136.033-.253-.014-.31-.124Z"
              fill="#000"
            />
          </g>
          <rect
            width="42.73"
            height="1.659"
            rx=".83"
            transform="matrix(-.93864 .34491 0 1 94.168 37.395)"
            fill="#E5B800"
          />
          <rect
            width="32.741"
            height="1.659"
            rx=".83"
            transform="matrix(-.93864 .34491 0 1 89.48 41.883)"
            fill="#E5B800"
          />
        </g>
        <g clipPath="url(#E)">
          <path
            d="m79.274 54.567.354.636.792-.168c.142-.03.199.136.096.281l-.573.807.135.792c.025.143-.126.312-.251.287l-.709-.137-.708.658c-.126.117-.276.059-.252-.102l.136-.892-.573-.386c-.103-.069-.047-.277.096-.351l.792-.414.354-.897c.063-.16.247-.229.31-.114Z"
            fill="#000"
          />
        </g>
        <g clipPath="url(#F)">
          <path
            d="m73.65 56.352.354.636.792-.167c.142-.03.2.135.096.28l-.573.807.136.792c.024.143-.126.312-.252.287l-.708-.137-.709.658c-.126.117-.276.059-.251-.102l.135-.892-.573-.386c-.103-.069-.046-.277.096-.35l.792-.415.354-.896c.064-.16.247-.23.311-.115Z"
            fill="#000"
          />
        </g>
        <g clipPath="url(#G)">
          <path
            d="m76.41 49.841.708 1.272 1.584-.335c.284-.06.398.271.192.561l-1.146 1.613.27 1.585c.05.286-.25.625-.502.575l-1.417-.275-1.417 1.316c-.252.234-.552.117-.504-.205l.272-1.784-1.147-.77c-.206-.139-.092-.555.193-.703l1.584-.829.707-1.792c.127-.32.495-.458.623-.229Z"
            fill="#000"
          />
        </g>
      </g>
      <defs>
        <clipPath id="b">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 68.26 15.629)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="c">
          <rect
            width="63.818"
            height="43.134"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 66.177 18.605)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="e">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 72.745 15.629)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="f">
          <rect
            width="63.818"
            height="43.134"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 70.662 18.605)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="h">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 76.92 15.629)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="i">
          <rect
            width="63.818"
            height="43.134"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 74.837 18.605)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="k">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 81.096 15.629)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="l">
          <rect
            width="63.818"
            height="43.134"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 79.013 18.605)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="n">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 85.272 15.629)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="o">
          <rect
            width="63.818"
            height="43.134"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 83.188 18.605)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="q">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 89.447 15.629)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="r">
          <rect
            width="63.818"
            height="43.134"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 87.363 18.605)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="t">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 93.622 15.629)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="u">
          <rect
            width="63.818"
            height="43.134"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 91.539 18.605)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="w">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93863 .34491 0 1 97.798 15.629)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="x">
          <rect
            width="63.818"
            height="43.134"
            rx="4"
            transform="matrix(-.93863 .34491 0 1 95.714 18.605)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="z">
          <rect
            width="68.257"
            height="47.558"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 101.973 15.629)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="A">
          <rect
            width="63.818"
            height="43.134"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 99.89 18.605)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="C">
          <rect
            width="63.818"
            height="43.134"
            rx="4"
            transform="matrix(-.93864 .34491 0 1 104.065 6.238)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="D">
          <path
            fill="#fff"
            transform="matrix(-.93864 .34491 0 1 86.355 11.746)"
            d="M0 0h26.637v26.544H0z"
          />
        </clipPath>
        <clipPath id="E">
          <path
            fill="#fff"
            transform="matrix(-.93864 .34491 0 1 80.68 53.793)"
            d="M0 0h3.327v3.327H0z"
          />
        </clipPath>
        <clipPath id="F">
          <path
            fill="#fff"
            transform="matrix(-.93864 .34491 0 1 75.056 55.578)"
            d="M0 0h3.327v3.327H0z"
          />
        </clipPath>
        <clipPath id="G">
          <path
            fill="#fff"
            transform="matrix(-.93864 .34491 0 1 79.221 48.29)"
            d="M0 0h6.653v6.653H0z"
          />
        </clipPath>
        <filter
          id="a"
          x=".192"
          y="11.629"
          width="80.069"
          height="87.102"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_862_1173"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_862_1173"
            result="shape"
          />
        </filter>
        <filter
          id="d"
          x="4.676"
          y="11.629"
          width="80.069"
          height="87.102"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_862_1173"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_862_1173"
            result="shape"
          />
        </filter>
        <filter
          id="g"
          x="8.852"
          y="11.629"
          width="80.069"
          height="87.102"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_862_1173"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_862_1173"
            result="shape"
          />
        </filter>
        <filter
          id="j"
          x="13.027"
          y="11.629"
          width="80.069"
          height="87.102"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_862_1173"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_862_1173"
            result="shape"
          />
        </filter>
        <filter
          id="m"
          x="17.203"
          y="11.629"
          width="80.069"
          height="87.102"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_862_1173"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_862_1173"
            result="shape"
          />
        </filter>
        <filter
          id="p"
          x="21.378"
          y="11.629"
          width="80.069"
          height="87.102"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_862_1173"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_862_1173"
            result="shape"
          />
        </filter>
        <filter
          id="s"
          x="25.553"
          y="11.629"
          width="80.069"
          height="87.102"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_862_1173"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_862_1173"
            result="shape"
          />
        </filter>
        <filter
          id="v"
          x="29.729"
          y="11.629"
          width="80.069"
          height="87.102"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_862_1173"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_862_1173"
            result="shape"
          />
        </filter>
        <filter
          id="y"
          x="33.904"
          y="11.629"
          width="80.069"
          height="87.102"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_862_1173"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_862_1173"
            result="shape"
          />
        </filter>
        <filter
          id="B"
          x="38.08"
          y="-.738"
          width="80.069"
          height="87.102"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_862_1173"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_862_1173"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
