import { SectionProps } from "../../common/types";

import { List } from "@otta/design";

export function JobInvolves({
  fields: { involvesBullets },
}: SectionProps): React.ReactElement {
  return involvesBullets.some(v => !!v.value) ? (
    <List type="bullet">
      {involvesBullets.map(({ value }, i) => (
        <li key={i}>{value}</li>
      ))}
    </List>
  ) : (
    <List type="bullet">
      <li key={0}>[List of responsibilities]</li>
    </List>
  );
}
