import { MutationUpdaterFn, gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { CertifiedOverview } from "./CertifiedOverview";
import { JobOverview } from "./JobOverview";
import { ApplicationResponseModal } from "./CertifiedOverview/ApplicationResponseModal";
import { OttaCertifiedModal } from "./CertifiedOverview/OttaCertifiedModal";

import {
  DashboardDocument,
  Feature,
  RefreshOttaCertifiedStatusDocument,
  RefreshOttaCertifiedStatusMutation,
} from "@hire/schema";
import { hireAppUser } from "@hire/util/user";
import { Loading } from "@otta/shared-components";
import { Spacing } from "@otta/design";
import { Confetti } from "@hire/components/Confetti";
import { RemovedJobsExplanation } from "@hire/components/RemovedJobsModal";
import { useAuthorizations } from "@hire/providers/authorization";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";
import { Experiment } from "@hire/constants/experiments";
import { useExperiment } from "@otta/experiments/client";

const handleMutationUpdate =
  (company: {
    id: string;
    __typename: "Company";
  }): MutationUpdaterFn<RefreshOttaCertifiedStatusMutation> =>
  (cache, mutationResult) => {
    const status = mutationResult?.data?.refreshOttaCertifiedStatus;

    if (status === null) {
      return null;
    }

    return cache.writeFragment({
      id: cache.identify({ ...company }),
      fragment: gql`
        fragment CompanyStatus on Company {
          ottaCertified
        }
      `,
      data: {
        ottaCertified: status,
      },
    });
  };

export const Dashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showOttaCertifiedModal, setShowOttaCertifiedModal] = useState(false);

  const { variant } = useExperiment(Experiment.OttaCertified);

  const { data, loading } = useQuery(DashboardDocument, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first", // prevent flicker from otta certified refresh
  });

  const [mutation, { data: refreshData }] = useMutation(
    RefreshOttaCertifiedStatusDocument
  );

  const currentUser = hireAppUser(data?.me);

  const { features } = useAuthorizations([Feature.Home, Feature.Scraped]);

  useEffect(() => {
    if (currentUser?.currentCompany) {
      mutation({
        update: handleMutationUpdate(currentUser.currentCompany),
      });
    }
  }, [mutation, currentUser?.currentCompany]);

  useEffect(() => {
    if (variant !== "variant") {
      return;
    }

    if (
      refreshData?.refreshOttaCertifiedStatus &&
      !localStorage.getItem("seen-certified-confetti")
    ) {
      localStorage.setItem("seen-certified-confetti", "true");
      setShowConfetti(true);
      setShowOttaCertifiedModal(true);
    }
  }, [refreshData, variant]);

  if (loading) {
    return <Loading />;
  }

  const currentCompany = currentUser?.currentCompany;

  if (!currentCompany) {
    return null;
  }

  return (
    <>
      <JobOverview />
      <ResponsiveCenteredContainer maxWidth={1400} align="left">
        <Spacing size={1}>
          <CertifiedOverview
            currentCompany={currentCompany}
            showModal={() => setShowModal(true)}
            showOttaCertifiedModal={() => setShowOttaCertifiedModal(true)}
          />
        </Spacing>

        <ApplicationResponseModal
          receivesApplicationsWithinOtta={
            !features.get(Feature.Scraped)?.granted
          }
          handleClose={() => setShowModal(false)}
          showModal={showModal}
        />
        {variant === "variant" && (
          <OttaCertifiedModal
            open={showOttaCertifiedModal}
            currentCompany={currentCompany}
            handleClose={() => setShowOttaCertifiedModal(false)}
          />
        )}

        <RemovedJobsExplanation
          enabled={features.get(Feature.Home)?.message === "paused"}
          campaign="dashboard"
        />
        {showConfetti && variant === "variant" && (
          <Confetti onComplete={() => setShowConfetti(false)} />
        )}
      </ResponsiveCenteredContainer>
    </>
  );
};
