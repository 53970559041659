import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCallback, useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { ChangePasswordDocument } from "@hire/schema";
import { Button, InputField, VerticalSpacing } from "@otta/design";

interface ResetPasswordFields {
  password: string;
}

const ButtonWrapper = styled.div`
  text-align: center;
`;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Fill in this field")
    .min(8, "Enter at least 8 characters"),
});

export function ResetPasswordForm({ token }: { token: string | undefined }) {
  const [submitted, setSubmitted] = useState(false);
  const { companySlug } = useParams();
  const navigate = useNavigate();

  const [changePasswordMutation, { loading }] = useMutation(
    ChangePasswordDocument,
    {
      onCompleted: () => {
        setSubmitted(true);
      },
    }
  );

  const handleSave = useCallback(
    async (input: ResetPasswordFields) => {
      if (!token) {
        return;
      }
      await changePasswordMutation({
        variables: {
          token,
          password: input.password,
        },
      });
    },
    [changePasswordMutation, token]
  );

  const form = useFormik<ResetPasswordFields>({
    initialValues: { password: "" },
    validationSchema,
    onSubmit: handleSave,
  });

  return (
    <form onSubmit={form.handleSubmit}>
      <VerticalSpacing>
        <InputField
          type="password"
          name="password"
          label="New Password"
          placeholder="p4ssw0rd!"
          onChange={form.handleChange("password")}
          onBlur={form.handleBlur("password")}
          error={form.touched.password ? form.errors.password : undefined}
        />
        <ButtonWrapper>
          <Button
            type={submitted ? "button" : "submit"}
            level={submitted ? "secondary" : "primary"}
            onClick={
              submitted ? () => navigate(`/login/${companySlug}`) : undefined
            }
          >
            {submitted
              ? "Done! Click to sign in"
              : loading
              ? "Loading"
              : "Change password"}
          </Button>
        </ButtonWrapper>
      </VerticalSpacing>
    </form>
  );
}
