import { Divider } from "../../common/Divider";
import { Subheading } from "../../common/Subheading";
import { SectionProps } from "../../common/types";

import { List, Spacing } from "@otta/design";

export function CompanyBenefits({
  company: { otherBenefits },
}: SectionProps): React.ReactElement | null {
  return otherBenefits.length > 0 ? (
    <Spacing size={0}>
      <Divider />
      <Subheading>Company benefits</Subheading>
      <List type="bullet">
        {otherBenefits.map(({ value }, i) => (
          <li key={i}>{value}</li>
        ))}
      </List>
    </Spacing>
  ) : null;
}

export function ExampleCompanyBenefits(): React.ReactElement {
  return (
    <Spacing size={0}>
      <Divider />
      <Subheading>[Example] Company benefits</Subheading>
      <List type="bullet">
        <li key={0}>Enhanced family leave</li>
        <li key={1}>Monthly self-care stipend</li>
        <li key={2}>Travel stipends</li>
        <li key={3}>Workspace / home office grant</li>
      </List>
    </Spacing>
  );
}
