import { format, intervalToDuration, parseJSON } from "date-fns";
import { useContext, useEffect, useMemo, useState } from "react";
import styled from "@xstyled/styled-components";
import { toast } from "react-toastify";
import { useQuery } from "@apollo/client";

import { ContactIcon } from "../CandidateHeader/components/ContactIcon";

import { CandidateDrawerContext } from "./CandidateDrawerProvider";

import {
  GetJobPipelineDocument,
  ImportedCandidate,
  JobFeature,
} from "@hire/schema";
import { palette, pxToRem } from "@otta/design-tokens";
import { Spacing, Text } from "@otta/design";
import { WebsiteIconLink } from "@hire/components/WebsiteIconLink";
import { useJobAuthorization } from "@hire/providers/authorization";
import { CandidateHeaderStageSelector } from "@hire/pages/JobPage/Inbox/InboxCandidate/CandidateHeader";
import { useRequiredParams } from "@hire/util/routing";

interface RowStyleProps {
  justifyItems?: string;
}

interface RowItemStyleProps {
  expand?: boolean;
}

const Row = styled.div<RowStyleProps>`
  display: flex;
  justify-content: ${props => {
    switch (props.justifyItems) {
      case "end":
        return "flex-end";
      case "gap":
        return "space-between";
      default:
        return "flex-start";
    }
  }};
  flex-wrap: wrap;
  gap: lg;
`;

const RowItem = styled.div<RowItemStyleProps>`
  flex-grow: ${props => {
    return props.expand ? "1" : "0";
  }};
`;

const ImportedCandidateProfileWrapper = styled.div`
  padding: 40;
`;

const LinkWrap = styled.ul`
  display: flex;
  margin: 0;
  list-style: none;
  gap: lg;
`;

const CandidateName = styled.h2`
  font-size: ${pxToRem(28)};
`;
const BackgroundSection = styled.div`
  background-color: ${palette.beige.shade200};
  border-radius: 4px;
  padding: lg;
`;

export function ImportedCandidateProfile() {
  const { granted: canUseJobPipeline } = useJobAuthorization(
    JobFeature.Pipeline
  );
  const { jobId } = useRequiredParams(["jobId"]);
  const { candidatePipelineItem } = useContext(CandidateDrawerContext);
  const [candidate, setCandidate] = useState<
    ImportedCandidate | null | undefined
  >(candidatePipelineItem?.importedCandidate);

  const { data: jobPipelineData } = useQuery(GetJobPipelineDocument, {
    variables: { jobId, workExperienceLimit: 1 },
    skip: !canUseJobPipeline || !jobId,
  });

  const currentPipelineStageId = useMemo(
    () =>
      jobPipelineData?.getJobPipeline?.candidatePipelineStages?.find(stage =>
        stage.candidatePipelineItems?.find(item =>
          item.id === candidatePipelineItem?.id ? item.id : ""
        )
      )?.id,
    [
      candidatePipelineItem?.id,
      jobPipelineData?.getJobPipeline?.candidatePipelineStages,
    ]
  );

  useEffect(() => {
    setCandidate(candidatePipelineItem?.importedCandidate);
  }, [candidatePipelineItem]);

  const daysInStage = intervalToDuration({
    start: parseJSON(candidate?.insertedAt ?? new Date()),
    end: new Date(),
  })?.days;

  const copyToClipboard = (contactType: string, content: string | null) => {
    if (!content) {
      return;
    }
    navigator.clipboard.writeText(content);
    toast.success(`${contactType} copied to clipboard`);
  };

  return (
    <ImportedCandidateProfileWrapper>
      <Spacing size={3}>
        <Row>
          {candidate?.insertedAt && (
            <Text data-testid="imported-added-on" size={-1} as="span">
              Added {format(parseJSON(candidate?.insertedAt), "dd/MM/yy")}
            </Text>
          )}
          {candidate?.source && (
            <Text data-testid="imported-added-via" size={-1} as="span">
              Via: {candidate?.source}
            </Text>
          )}
          {candidatePipelineItem?.movedToStageAt && (
            <Text data-testid="imported-days-in-stage" size={-1} as="span">
              {daysInStage}d at stage
            </Text>
          )}
        </Row>
        <Row justifyItems="gap">
          <CandidateName data-cs-mask data-testid="imported-candidate-name">
            {candidate?.firstName} {candidate?.lastName}
          </CandidateName>
          {canUseJobPipeline &&
            currentPipelineStageId &&
            candidatePipelineItem?.id &&
            jobId && (
              <CandidateHeaderStageSelector
                jobId={jobId}
                currentStageId={currentPipelineStageId}
                candidateItemId={candidatePipelineItem?.id}
                stages={
                  jobPipelineData?.getJobPipeline?.candidatePipelineStages ??
                  null
                }
              />
            )}
        </Row>
        <Row>
          {(candidate?.portfolioUrl ||
            candidatePipelineItem?.candidate?.linkedinUrl) && (
            <RowItem>
              <LinkWrap>
                {candidate?.portfolioUrl && (
                  <li>
                    <WebsiteIconLink
                      data-testid="imported-portfolio-url"
                      url={candidate?.portfolioUrl}
                    />
                  </li>
                )}
                {candidatePipelineItem?.candidate?.linkedinUrl && (
                  <li>
                    <WebsiteIconLink
                      url={candidatePipelineItem?.candidate.linkedinUrl}
                    />
                  </li>
                )}
              </LinkWrap>
            </RowItem>
          )}

          {candidate?.phoneNumber && (
            <RowItem>
              <ContactIcon
                data-testid="imported-phone"
                content={candidate.phoneNumber}
                onClick={() =>
                  copyToClipboard("Phone number", candidate.phoneNumber)
                }
                iconName="phone"
              />
            </RowItem>
          )}
          {candidate?.emailAddress && (
            <RowItem>
              <ContactIcon
                data-testid="imported-email"
                content={candidate?.emailAddress}
                onClick={() =>
                  copyToClipboard("Email", candidate?.emailAddress)
                }
                iconName="message"
              />
            </RowItem>
          )}
        </Row>
        <Row>
          <RowItem expand>
            <BackgroundSection>
              <Text data-testid="imported-added-by" size={-1}>
                Candidate added by {candidate?.importingRecruiterName}
              </Text>
            </BackgroundSection>
          </RowItem>
        </Row>
        {candidate?.currentEmployer && (
          <Row>
            <RowItem>
              <Text data-testid="imported-current-company">
                Current company:{" "}
                <Text as="span" bold>
                  {candidate?.currentEmployer}
                </Text>
              </Text>
            </RowItem>
          </Row>
        )}
      </Spacing>
    </ImportedCandidateProfileWrapper>
  );
}
