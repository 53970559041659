import { Command } from "cmdk";

import { Text } from "@otta/design";
import { Icon } from "@otta/icons";

export function Jobs({
  jobs,
  onSelect,
}: {
  jobs: { id: string; externalId: string; title: string }[];
  onSelect(job: string): void;
}) {
  return (
    <Command.Group heading="Jobs">
      {jobs.map(job => (
        <Command.Item
          key={job.id}
          value={`${job.externalId} ${job.title}`}
          onSelect={() => {
            onSelect(job.id);
          }}
        >
          <Icon icon="industry" size={1} />
          <Text>{job.title}</Text>
        </Command.Item>
      ))}
    </Command.Group>
  );
}
