import {
  Outlet,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import styled, { down, css } from "@xstyled/styled-components";

import { modularScale, pxToRem } from "@otta/design-tokens";

type Size = "large" | "small";

const DrawerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: fixed;

  min-height: 100%;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.64);
`;

const ContentContainer = styled.div<{ size: Size }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow-y: auto;

  width: ${({ size }) => (size === "small" ? pxToRem(392) : modularScale(23))};

  padding: ${({ size }) => (size === "small" ? pxToRem(40) : "initial")};

  ${down(
    "tablet",
    css`
      min-width: unset;
      width: 100%;
    `
  )};
`;

export const SideDrawer = ({ size }: { size: Size }): React.ReactElement => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const context = useOutletContext();

  return (
    <DrawerContainer
      data-testid="pipeline-item-drawer"
      onClick={() => {
        navigate({
          pathname: ".",
          search: searchParams.toString(),
        });
      }}
    >
      <ContentContainer
        size={size}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Outlet context={context} />
      </ContentContainer>
    </DrawerContainer>
  );
};
