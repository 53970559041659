import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";
import { Clickable } from "@otta/design";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

const Container = styled.div`
  display: flex;
  gap: xs;
`;

const Tag = styled(RoundedTag)<{ active?: boolean }>`
  background-color: ${palette.beige.shade200};

  &:hover {
    background-color: ${palette.beige.shade400};
  }

  ${({ active }) =>
    active &&
    `
    background-color: ${palette.brand.yellow};

    &:hover {
      background-color: ${palette.extended.yellow.shade600};
    }`}
`;

interface ConversationFilterTypeProps {
  composeSearch(key: string, value?: string): void;
  receivesApplicationsWithinOtta: boolean;
  showApplications: boolean;
  showSourced: boolean;
}

export function ConversationTypeFilter({
  composeSearch,
  receivesApplicationsWithinOtta,
  showApplications,
  showSourced,
}: ConversationFilterTypeProps) {
  return (
    <Container>
      {receivesApplicationsWithinOtta && (
        <Clickable
          onClick={() =>
            composeSearch("applied", showApplications ? undefined : "true")
          }
        >
          <Tag content="Applications" icon="apply" active={showApplications} />
        </Clickable>
      )}
      <Clickable
        onClick={() =>
          composeSearch("sourced", showSourced ? undefined : "true")
        }
      >
        <Tag content="Sourced" icon="sourcing" active={showSourced} />
      </Clickable>
    </Container>
  );
}
