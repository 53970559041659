export const baseFontSize = 16;

type TypographyLevel = "body" | "newBody" | "heading";

interface FontFamilyDefinition {
  name: string;
  src: {
    weight: number | string;
    sources: { format: string; url: string }[];
  }[];
}

export const family: Record<TypographyLevel, FontFamilyDefinition> = {
  body: {
    name: "'DM Sans'",
    src: [
      {
        weight: 400,
        sources: [
          {
            format: "woff2",
            url: "https://static.otta.com/fonts/dm-sans/dm-sans-regular-v1.200.woff2",
          },
          {
            format: "woff",
            url: "https://static.otta.com/fonts/dm-sans/dm-sans-regular-v1.200.woff",
          },
          {
            format: "truetype",
            url: "https://static.otta.com/fonts/dm-sans/dm-sans-regular-v1.200.ttf",
          },
        ],
      },
      {
        weight: 700,
        sources: [
          {
            format: "woff2",
            url: "https://static.otta.com/fonts/dm-sans/dm-sans-bold-v1.200.woff2",
          },
          {
            format: "woff",
            url: "https://static.otta.com/fonts/dm-sans/dm-sans-bold-v1.200.woff",
          },
          {
            format: "truetype",
            url: "https://static.otta.com/fonts/dm-sans/dm-sans-bold-v1.200.ttf",
          },
        ],
      },
    ],
  },
  newBody: {
    name: "'Noto Sans'",
    src: [
      {
        weight: "100 900",
        sources: [
          {
            format: "woff2",
            url: "https://fonts.gstatic.com/s/notosans/v36/o-0bIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjc5aDdu3mhPy1Fig.woff2",
          },
        ],
      },
    ],
  },
  heading: {
    name: "'Fann Grotesque'",
    src: [
      {
        weight: 400,
        sources: [
          {
            format: "woff2",
            url: "https://static.otta.com/fonts/FannGrotesque-Medium.woff2",
          },
          {
            format: "woff",
            url: "https://static.otta.com/fonts/FannGrotesque-Medium.woff",
          },
          {
            format: "truetype",
            url: "https://static.otta.com/fonts/FannGrotesque-Medium.ttf",
          },
        ],
      },
      {
        weight: 700,
        sources: [
          {
            format: "woff2",
            url: "https://static.otta.com/fonts/FannGrotesque-Bold.woff2",
          },
          {
            format: "woff",
            url: "https://static.otta.com/fonts/FannGrotesque-Bold.woff",
          },
          {
            format: "truetype",
            url: "https://static.otta.com/fonts/FannGrotesque-Bold.ttf",
          },
        ],
      },
    ],
  },
};
