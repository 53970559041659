import styled, { css, down } from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";

interface CardProps {
  completed?: boolean;
}

const Card = styled.div<CardProps>`
  flex: 1 0 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: xl;
  padding: lg;
  border-radius: ${pxToRem(16)};
  text-align: center;
  background-color: ${palette.brand.white};
  &[data-completed="true"] {
    background-color: ${palette.brand.green};
  }

  ${down(
    "tablet",
    css`
      flex-direction: column;
      gap: lg;
    `
  )}
`;

export default Card;
