import styled from "@xstyled/styled-components";

import { CircleIconWrapper, Container } from "./styled";

import { Button, Text, VerticalSpacing } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const NotesContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 3xl;
`;

const ContentWrapper = styled(VerticalSpacing)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  max-width: ${pxToRem(465)};

  i {
    color: ${palette.grayscale.shade400};
  }
`;

export const CreateNote = ({
  onCreate: handleCreateNote,
}: {
  onCreate: () => void;
}): React.ReactElement => {
  return (
    <NotesContainer
      role="button"
      onClick={handleCreateNote}
      data-testid="note-creation-container"
    >
      <CircleIconWrapper>
        <Icon icon="edit" />
      </CircleIconWrapper>
      <ContentWrapper size={3}>
        <Icon icon="document" size={7} />
        <Text size={1} bold>
          No notes on this candidate yet.
        </Text>
        <Text align="center">
          Create a shared note that can be viewed and updated by all hiring
          managers.
        </Text>
        <Button level="primary">Create a shared note</Button>
      </ContentWrapper>
    </NotesContainer>
  );
};
