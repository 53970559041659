import { useQuery } from "@apollo/client";
import styled from "@xstyled/styled-components";

import { StartSSO } from "./StartSSO";
import { ConfigureSSO } from "./ConfigureSSO";

import { CompanySamlDataDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { Spacing, Text } from "@otta/design";

const AlignLeftContainer = styled.div`
  text-align: left;
`;

export function SSO(): React.ReactElement {
  const { data, loading } = useQuery(CompanySamlDataDocument);

  const currentCompany = data?.currentUser?.currentCompany;
  const samlData = currentCompany?.samlData;

  return (
    <Spacing>
      <Text size={2} bold>
        Single Sign On
      </Text>
      <Text align="center">
        Sign in with your company&apos;s identity provider
      </Text>
      <AlignLeftContainer>
        {loading ? (
          <Loading />
        ) : samlData ? (
          <ConfigureSSO samlData={samlData} />
        ) : currentCompany ? (
          <StartSSO />
        ) : null}
      </AlignLeftContainer>
    </Spacing>
  );
}
