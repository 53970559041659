import { intersectingTags } from "./utils";

import { CandidateCardFragment, CandidateCardJobQuery } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

interface CandidateConflictingSectorsBadgeProps {
  candidate: CandidateCardFragment;
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
}

export function CandidateConflictingSectorsBadge({
  candidate,
  job,
}: CandidateConflictingSectorsBadgeProps): React.ReactElement | null {
  const userDislikedSectorIds = new Set(
    candidate.sectorTagPreferences
      .filter(s => !s.preference)
      .map(s => s.sectorTagId)
  );

  const conflictingSectors = intersectingTags(
    job.company.sectorTags,
    userDislikedSectorIds
  );

  return conflictingSectors.length > 0 ? (
    <RoundedTag
      content={`Dislikes: ${conflictingSectors.join(", ")}`}
      icon="triangle-exclamation"
      color={palette.extended.red.shade100}
    />
  ) : null;
}
