import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Heading } from "@otta/design";

const CertifiedPillWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1 10 1 5;
  border-radius: ${pxToRem(15)};
  overflow: hidden;
  background: ${palette.extended.yellow.shade100};
`;

const CertifiedIcon = styled(Icon)`
  margin-top: -7;
`;

export const CertifiedPill = () => {
  return (
    <CertifiedPillWrapper>
      <CertifiedIcon icon="certified" size={2} />
      <Heading size={-1}>Otta certified</Heading>
    </CertifiedPillWrapper>
  );
};
