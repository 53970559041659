import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";

const IconButton = styled.div<{
  color: string;
  hoverCover: string;
}>`
  color: ${({ color }) => color};
  cursor: pointer;

  &:hover {
    color: ${({ hoverCover }) => hoverCover};
  }
`;

export const EditButton = ({
  size = 1,
  color = palette.grayscale.shade600,
  hoverColor = palette.brand.black,
  onClick: handleClick,
}: {
  size?: number;
  hoverColor?: string;
  color?: string;
  onClick: () => void;
}) => {
  return (
    <IconButton
      role="button"
      color={color}
      hoverCover={hoverColor}
      onClick={handleClick}
      data-testid="edit-button"
      aria-label="Edit"
    >
      <Icon icon="edit" size={size} />
    </IconButton>
  );
};
