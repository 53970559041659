import { MutationUpdaterFn } from "@apollo/client";

import {
  MessageTemplateTypeEnum,
  CreateMessageTemplateMutation,
  AllTemplatesDocument,
  DeleteMessageTemplateMutation,
  UpdateMessageTemplateMutation,
} from "@hire/schema";

export const handleCreateMutationUpdate =
  (
    messageTemplateType: MessageTemplateTypeEnum
  ): MutationUpdaterFn<CreateMessageTemplateMutation> =>
  (cache, { data: templateData }) => {
    const variables = { messageTemplateType };

    const cacheData = cache.readQuery({
      query: AllTemplatesDocument,
      variables,
    });

    if (!cacheData?.messageTemplates || !templateData?.createMessageTemplate) {
      return;
    }

    const newData = [...cacheData.messageTemplates];

    newData.unshift(templateData?.createMessageTemplate);

    cache.writeQuery({
      query: AllTemplatesDocument,
      variables,
      data: {
        messageTemplates: newData,
      },
    });
  };

export const handleDeleteMutationUpdate =
  (
    messageTemplateType: MessageTemplateTypeEnum
  ): MutationUpdaterFn<DeleteMessageTemplateMutation> =>
  (cache, { data: templateData }) => {
    const variables = { messageTemplateType };

    const cacheData = cache.readQuery({
      query: AllTemplatesDocument,
      variables,
    });

    if (!cacheData?.messageTemplates || !templateData?.deleteMessageTemplate) {
      return;
    }

    const newData = cacheData.messageTemplates.filter(
      ({ id: templateId }) =>
        templateData.deleteMessageTemplate?.id !== templateId
    );

    cache.writeQuery({
      query: AllTemplatesDocument,
      variables,
      data: {
        messageTemplates: newData,
      },
    });
  };

export const handleUpdateMutationUpdate =
  (
    messageTemplateType: MessageTemplateTypeEnum
  ): MutationUpdaterFn<UpdateMessageTemplateMutation> =>
  (cache, { data: templateData }) => {
    const variables = { messageTemplateType };

    const cacheData = cache.readQuery({
      query: AllTemplatesDocument,
      variables,
    });

    if (!cacheData?.messageTemplates || !templateData?.updateMessageTemplate) {
      return;
    }

    const newData = cacheData.messageTemplates.map(template => {
      return templateData.updateMessageTemplate?.id === template.id
        ? templateData.updateMessageTemplate
        : template;
    });

    cache.writeQuery({
      query: AllTemplatesDocument,
      variables,
      data: {
        messageTemplates: newData,
      },
    });
  };
