import styled from "@xstyled/styled-components";

import { WebsiteIconLink } from "@hire/components/WebsiteIconLink";

const Container = styled.div`
  display: inline-flex;
  margin-left: xs;
`;

interface CandidateProfileLinksProps {
  candidate: {
    websiteLinks: { id: string; url: string }[];
    linkedinUrl?: string | null;
  };
}

export function CandidateProfileLinks({
  candidate,
}: CandidateProfileLinksProps): React.ReactElement | null {
  const hasLinkedInUrl = (candidate.websiteLinks ?? []).some(({ url }) =>
    url.includes("linkedin")
  );

  return (
    <Container>
      {candidate.linkedinUrl && !hasLinkedInUrl && (
        <WebsiteIconLink url={candidate.linkedinUrl} />
      )}
      {candidate.websiteLinks.map(({ id, url }) => (
        <WebsiteIconLink key={id} url={url} />
      ))}
    </Container>
  );
}
