import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";

export const DatesWrapper = styled(Text)`
  white-space: nowrap;
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: xs;
  flex-wrap: wrap;
`;
