import styled, { css, up } from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";

export const ResponsiveCenteredContainer = styled.div<{
  maxWidth?: number;
  align?: "left" | "center" | "right";
}>`
  width: 100%;
  max-width: ${({ maxWidth = 1000 }) => pxToRem(maxWidth)};
  margin: 0 auto;
  text-align: ${({ align = "center" }) => align};
  padding: lg 6;

  ${up(
    "tablet",
    css`
      padding: lg;
    `
  )}
`;
