import { useMemo } from "react";
import Select, {
  ActionMeta,
  Props as ReactSelectProps,
  Theme,
} from "react-select";
import styled, { css } from "@xstyled/styled-components";

import { StageOption } from "./types";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";

const StageName = styled.div<{ minWidth?: number }>`
  display: flex;
  align-items: center;

  ${({ minWidth }) =>
    minWidth
      ? css`
          min-width: ${pxToRem(minWidth)};
          width: 100%;
          gap: lg;
        `
      : css`
          width: ${pxToRem(210)};
          gap: 5;
        `}
`;

const StyledLabel = styled.label<{ bold: boolean }>`
  font-size: ${modularScale()};
  white-space: nowrap;
  font-weight: ${bold => (bold ? "bold" : "normal")};
`;

const StyledSelect = styled(Select<StageOption>)`
  width: 100%;
`;

type StyleFunction<C, P> = (base: C, props: P) => C;

function composeStyles<C, P>(
  ...fns: (StyleFunction<C, P> | undefined)[]
): StyleFunction<C, P> {
  return (base, props) =>
    fns.reduce<C>((acc, fn) => (fn ? fn(acc, props) : acc), base);
}

interface SelectProps extends ReactSelectProps<StageOption, false> {
  relativePosition?: boolean;
  stageOptions: StageOption[];
  currentStageId: string;
  label?: string;
  name?: string;
  minWidth?: number;
  boldLabel?: boolean;
}

export const StageSelect = ({
  stageOptions,
  currentStageId,
  onChange: handleChange,
  label = "Stage:",
  minWidth,
  name = "stage-select-dropdown",
  boldLabel,
  styles,
}: SelectProps): React.ReactElement => {
  const currentValue = useMemo(
    () => stageOptions.find((v: StageOption) => v.value === currentStageId),
    [currentStageId, stageOptions]
  );

  const onChange = (
    option: StageOption | null,
    action: ActionMeta<StageOption>
  ) => {
    if (option && handleChange) {
      handleChange(option, action);
    }
  };

  const composedStyles = {
    valueContainer: composeStyles(styles?.valueContainer, base => ({
      ...base,
      padding: `${modularScale(-8)} ${modularScale(-7)}`,
    })),
    control: composeStyles(styles?.control, base => ({
      ...base,
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
      ":active": {
        border: `${pxToRem(1)} solid ${palette.brand.black}`,
      },
    })),
  };

  const themeCallback = (originalTheme: Theme) => ({
    ...originalTheme,
    borderRadius: 4,
    colors: {
      ...originalTheme.colors,
      primary: palette.brand.black,
      primary25: palette.beige.shade200,
      danger: palette.brand.white,
      dangerLight: palette.grayscale.shade600,
    },
  });

  return (
    <StageName data-testid={name} minWidth={minWidth}>
      <StyledLabel html-for={name} bold={!!boldLabel}>
        {label}
      </StyledLabel>
      <StyledSelect
        name={name}
        isClearable={false}
        options={stageOptions}
        value={currentValue}
        onChange={onChange}
        styles={composedStyles}
        theme={themeCallback}
      />
    </StageName>
  );
};
