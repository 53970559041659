import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import styled from "@xstyled/styled-components";

import { LightningBolt } from "./LightningBolt";
import { TemplatePicker } from "./TemplatePicker";
import { CreateTemplate } from "./CreateTemplate";

import { MessageTemplateTypeEnum } from "@hire/schema";
import { palette, modularScale } from "@otta/design-tokens";
import { Button, Spacing, Text } from "@otta/design";
import { Textarea } from "@hire/components/input/Textarea";
import { useMessageStorage } from "@hire/providers/MessageStorage";

const Container = styled.div`
  grid-area: message-input;
  padding: md;
  border-top: 1px solid ${palette.grayscale.shade200};
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: lg;
`;

const validationSchema = Yup.object().shape({
  content: Yup.string().required("Required"),
});

const stringifyVariables = (
  template: string,
  {
    candidateFirstName,
    jobTitle,
    companyName,
    recruiterName,
  }: {
    candidateFirstName: string;
    jobTitle: string;
    companyName: string;
    recruiterName: string;
  }
): string => {
  const mapOfVariables: Record<string, string> = {
    "{candidateName}": candidateFirstName,
    "{jobTitle}": jobTitle,
    "{recruiterName}": recruiterName,
    "{companyName}": companyName,
  };

  const regEx = new RegExp(Object.keys(mapOfVariables).join("|"), "g");

  const stringifiedMessage = template.replaceAll(
    regEx,
    matched => mapOfVariables[matched]
  );
  return stringifiedMessage;
};

interface ConversationMessageInputProps {
  jobId: string;
  candidateId: string;
  name: string;
  jobTitle: string;
  companyName: string;
  recruiterFirstName: string;
  templates?: boolean;
  onSend: (message: string) => Promise<void>;
}

export function ConversationMessageInput({
  jobId,
  candidateId,
  name,
  jobTitle,
  companyName,
  recruiterFirstName,
  templates,
  onSend,
}: ConversationMessageInputProps): React.ReactElement {
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const { getStoredMessage, setStoredMessage, clearStoredMessage } =
    useMessageStorage();

  const form = useFormik({
    onSubmit: async ({ content }, { resetForm }) => {
      await onSend(content);

      clearStoredMessage(jobId, candidateId);

      resetForm();
    },
    initialValues: {
      content: getStoredMessage(jobId, candidateId) ?? "",
    },
    validationSchema,
  });

  const templateVariables = {
    candidateFirstName: name,
    jobTitle,
    companyName,
    recruiterName: recruiterFirstName,
  };

  return (
    <>
      <Container>
        <Spacing size={-2}>
          {templates && (
            <Spacing size={-4}>
              <Row>
                <Text data-cs-mask bold>
                  Reply to {name}
                </Text>
                <Button
                  level="secondary"
                  size="small"
                  onClick={() => {
                    setShowTemplateModal(true);
                  }}
                >
                  <LightningBolt style={{ height: modularScale() }} />
                  <span style={{ marginLeft: 4 }}>Application templates</span>
                </Button>
              </Row>
              <Text size={-1}>
                If you are rejecting this candidate, you can move them to
                'Archived' after responding.
              </Text>
            </Spacing>
          )}
          <form onSubmit={form.handleSubmit}>
            <Grid>
              <Textarea
                name="content"
                onChange={e => {
                  form.handleChange(e);
                  setStoredMessage(jobId, candidateId, e.target.value);
                }}
                value={form.values.content}
                onBlur={form.handleBlur}
                placeholder="Enter message here..."
              />
              <Button
                level="primary"
                type="submit"
                disabled={form.isSubmitting || !form.isValid}
              >
                Send
              </Button>
            </Grid>
          </form>
        </Spacing>
      </Container>
      <TemplatePicker
        type={MessageTemplateTypeEnum.Rejection}
        onPick={message => {
          form.setFieldValue(
            "content",
            stringifyVariables(message, templateVariables)
          );
          setShowTemplateModal(false);
        }}
        open={showTemplateModal}
        onOpenChange={setShowTemplateModal}
        onNew={() => {
          setShowTemplateModal(false);
          setShowCreateTemplateModal(true);
        }}
      />
      <CreateTemplate
        type={MessageTemplateTypeEnum.Rejection}
        onSave={message => {
          form.setFieldValue(
            "content",
            stringifyVariables(message, templateVariables)
          );
          setShowCreateTemplateModal(false);
        }}
        open={showCreateTemplateModal}
        onOpenChange={setShowCreateTemplateModal}
        onCancel={() => {
          setShowTemplateModal(true);
          setShowCreateTemplateModal(false);
        }}
      />
    </>
  );
}
