import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";
import styled from "@xstyled/styled-components";

import { handleMutationError } from "@hire/errors";
import {
  CurrentUserDocument,
  JobTitleDocument,
  UpdateUserJobNotificationUnsubscriptionDocument,
} from "@hire/schema";
import { Spacing, Button, Text } from "@otta/design";
import { Loading } from "@otta/shared-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  height: 100%;
  padding-top: 72;
`;

export function UserJobNotificationUnsubscription(): React.ReactElement {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { externalId, recruiterId } = useParams();
  const emailToken = searchParams.get("token");

  const { data: jobData, loading: jobLoading } = useQuery(JobTitleDocument, {
    context: { emailToken },
    variables: {
      externalId: externalId as string,
    },
  });
  const { data } = useQuery(CurrentUserDocument);
  const loggedIn = !!data?.me;

  const [updateNotificationUnsubscription] = useMutation(
    UpdateUserJobNotificationUnsubscriptionDocument,
    {
      context: { emailToken },
      errorPolicy: "all",
      onError: handleMutationError,
    }
  );

  const job = jobData?.publicJob;

  useEffect(() => {
    if (job?.id) {
      updateNotificationUnsubscription({
        variables: {
          jobId: job.id,
          unsubscribe: true,
        },
      });
    }
  }, [job, recruiterId, updateNotificationUnsubscription]);

  if (!job || jobLoading) {
    return <Loading />;
  }

  const { title, subtitle } = job;

  return (
    <Container>
      <Spacing>
        <Text size={2} bold>
          {`You've switched off notifications for ${title}`}
          {subtitle && ` - ${subtitle}`}
        </Text>
        <Text>
          You can manage notifications for other roles from your jobs dashboard.
        </Text>
        {loggedIn ? (
          <Button
            data-testid="change-button"
            level="primary"
            onClick={() => navigate("/")}
          >
            Return to Dashboard
          </Button>
        ) : (
          <Button
            data-testid="change-button"
            level="primary"
            onClick={() => navigate("/login?redirect=jobs")}
          >
            Log in
          </Button>
        )}
      </Spacing>
    </Container>
  );
}
