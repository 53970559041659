import { getYear, parseISO } from "date-fns";

import { ExperienceSummaryContainer } from "./shared";

import { CandidateCardFragment } from "@hire/schema";
import { Icon } from "@otta/icons";
import { Text } from "@otta/design";
import { HighlightableText } from "@hire/components/HighlightableText";

interface CandidateEducationSummaryProps {
  candidate: CandidateCardFragment;
}

export function CandidateEducationSummary({
  candidate,
}: CandidateEducationSummaryProps): React.ReactElement | null {
  if (candidate.educationExperiences.length === 0) {
    return null;
  }

  return (
    <ExperienceSummaryContainer>
      <Icon icon="education" />
      <div>
        {candidate.educationExperiences
          .slice(0, 2)
          .map(({ id, institutionName, fieldOfStudy, endDate }) => (
            <div key={id}>
              <Text size={-1}>
                <Text as="span">
                  <HighlightableText>{institutionName}</HighlightableText>
                </Text>
                {fieldOfStudy && (
                  <Text as="span">
                    {" "}
                    &mdash;{" "}
                    <HighlightableText>{fieldOfStudy}</HighlightableText>
                  </Text>
                )}
                {endDate && (
                  <Text as="span"> ({getYear(parseISO(endDate))})</Text>
                )}
              </Text>
            </div>
          ))}
      </div>
    </ExperienceSummaryContainer>
  );
}
