import styled, { css } from "@xstyled/styled-components";

import { palette, modularScale } from "@otta/design-tokens";
import { FieldContainer, Text, TextareaField } from "@otta/design";

const MAX_CHARS = 2500;

const CharacterCounter = styled(Text)<{ redText: boolean }>`
  text-align: right;
  ${({ redText }) =>
    redText &&
    css`
      color: ${palette.brand.red};
    `}
`;

const LargeTextarea = styled(TextareaField)`
  min-height: ${modularScale(15)};
  min-width: 100%;
  max-width: 100%;
  resize: vertical;
`;

export const MessageField = ({
  value,
  onChange: handleChange,
  onBlur: handleBlur,
  updateCursorPosition,
  error,
}: {
  value: string;
  onChange: (newValue: string) => void;
  onBlur?: React.ComponentProps<typeof LargeTextarea>["onBlur"];
  updateCursorPosition: (newPos: number) => void;
  error?: string;
}) => {
  return (
    <FieldContainer>
      <LargeTextarea
        value={value}
        name="message"
        error={error}
        onBlur={e => {
          handleBlur && handleBlur(e);
          updateCursorPosition(e.target.selectionStart);
        }}
        onChange={e => {
          handleChange(e.target.value);
          updateCursorPosition(e.target.selectionStart);
        }}
        aria-label="message content"
      />
      <CharacterCounter size={-1} redText={value.length > MAX_CHARS}>
        Characters: {value.length}/{MAX_CHARS}
      </CharacterCounter>
    </FieldContainer>
  );
};
