import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";
import { Text } from "@otta/design";

export const CHART_COLORS = [
  palette.brand.yellow,
  palette.brand.teal,
  palette.brand.orange,
  palette.brand.blue,
  palette.brand.pink,
  palette.brand.green,
  palette.brand.purple,
];

export const UNKNOWN_COLOR = palette.beige.shade400;

export type ShortFilterOptions = "genders" | "ethnicities" | "experienceLevels";

export type FilterOptions = ShortFilterOptions | "all";

// styles
export const GraphHeader = styled(Text)`
  writing-mode: vertical-lr;
  text-orientation: sideways-right;
  transform: rotate(180deg);
  padding-top: 40;
`;

export const BarHeader = styled(Text)`
  text-align: center;
  position: absolute;
  top: calc(100% + ${pxToRem(4)});
  white-space: nowrap;
`;
