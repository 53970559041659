import styled from "@xstyled/styled-components";

import { SectionProps } from "../../common/types";
import { Blob, Blobs } from "../../common/Blob";

import { palette } from "@otta/design-tokens";
import { Experiment } from "@hire/constants/experiments";
import { useExperiment } from "@otta/experiments/client";

const YellowBlob = styled(Blob)`
  background-color: ${palette.brand.yellow};
`;

const VerticalBlobs = styled(Blobs)`
  flex-direction: column;
`;

export function CompanyState({
  company: { ottaCertified },
}: SectionProps): React.ReactElement | null {
  const { variant } = useExperiment(Experiment.OttaCertified);

  return (
    <VerticalBlobs>
      {ottaCertified && variant === "variant" && (
        <YellowBlob bold icon="certified">
          Otta Certified
        </YellowBlob>
      )}
      <Blob icon="clock" bold>
        Open for applications
      </Blob>
    </VerticalBlobs>
  );
}
