import { useQuery } from "@apollo/client";

import { PageHeading } from "./PageHeading";
import { NoEndorsements } from "./NoEndorsements";
import { EndorsementHighlights } from "./EndorsementHighlights";
import { EndorsementList } from "./EndorsementList";

import { CompanyEmployeeEndorsementsDocument } from "@hire/schema";
import { Spacing } from "@otta/design";
import { CardContainer } from "@hire/components/containers/CardContainer";

export const Endorsements = () => {
  const { data } = useQuery(CompanyEmployeeEndorsementsDocument);

  const company = data?.currentUser?.currentCompany;

  if (!company) {
    return null;
  }

  const hasEndorsements = company.employeeSurveys.length > 0;
  return (
    <>
      <PageHeading urlSafeName={company.urlSafeName} />
      <CardContainer>
        {hasEndorsements ? (
          <Spacing size={2}>
            <EndorsementHighlights company={company} />
            <EndorsementList company={company} />
          </Spacing>
        ) : (
          <NoEndorsements companyName={company.name} />
        )}
      </CardContainer>
    </>
  );
};
