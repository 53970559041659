import { useMemo } from "react";

const STORAGE_KEY = "recently-viewed-jobs-v1";

const getRecentlyViewedJobs = (): Array<string> => {
  const jobExternalIds = localStorage.getItem(STORAGE_KEY);
  return jobExternalIds ? JSON.parse(jobExternalIds) : [];
};

export const setRecentlyViewedJob = (jobExternalId: string): void => {
  const jobExternalIds = getRecentlyViewedJobs();
  const filtered = jobExternalIds.filter(x => x !== jobExternalId).slice(0, 5);
  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify([...filtered, jobExternalId])
  );
};

export function useRecentlyViewedJobs() {
  return useMemo(() => getRecentlyViewedJobs(), []);
}
