import { SourcingPreferencesFieldProps } from "./interfaces";

import { Spacing, Label, Text, SelectField } from "@otta/design";
import { subregions } from "@otta/locations";

export function SubregionsField({
  onChange,
  preferences,
}: SourcingPreferencesFieldProps): React.ReactElement | null {
  const regions = preferences.regions || [];

  if (regions.length !== 1 || !["CA", "US"].includes(regions[0])) {
    return null;
  }

  const region = regions[0] as "CA" | "US";
  const filteredSubRegions = subregions[region];
  const selectedSubRegions = preferences.subregions || [];
  const subRegionOptions = Object.entries(filteredSubRegions).map(
    ([value, label]) => ({ label, value })
  );

  return (
    <Spacing size={-4}>
      <Label htmlFor="subRegions">
        <Text bold>
          {region === "US" ? "State " : "Province/territory "}
          candidate is based in
        </Text>
      </Label>
      <SelectField
        isMulti
        inputId="subRegions"
        placeholder={`Select ${
          region === "US" ? "state" : "province/territory"
        }`}
        options={subRegionOptions}
        value={subRegionOptions.filter(({ value }) =>
          selectedSubRegions.find(r => r === value)
        )}
        onChange={vals => {
          onChange({ subregions: vals.map(({ value }) => value) });
        }}
      />
    </Spacing>
  );
}
