import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export function useRedirect(): (company: string) => string {
  const [params] = useSearchParams();
  const redirectParam = params.get("redirect");

  return useCallback(
    (companySlug: string) =>
      redirectParam
        ? `/${companySlug.toLowerCase()}${redirectParam}`
        : `/${companySlug.toLowerCase()}`,
    [redirectParam]
  );
}
