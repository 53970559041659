import styled from "@xstyled/styled-components";
import { useParams } from "react-router-dom";
import { formatDistanceStrict, parseISO } from "date-fns";

import { CandidateCardFragment } from "@hire/schema";
import { Icon } from "@otta/icons";
import { Text } from "@otta/design";

const Container = styled.div`
  display: flex;
  gap: sm;
`;

interface CandidateAlreadyMessagedProps {
  candidate: CandidateCardFragment;
}

export function CandidateAlreadyMessaged({
  candidate: { messageRequestsSentFromCompany = [] },
}: CandidateAlreadyMessagedProps): React.ReactElement | null {
  const { jobId } = useParams();

  const lastMessagedJob =
    messageRequestsSentFromCompany.length > 0
      ? messageRequestsSentFromCompany[
          messageRequestsSentFromCompany.length - 1
        ]
      : undefined;

  if (!lastMessagedJob) {
    return null;
  }

  const formattedTime = formatDistanceStrict(
    parseISO(lastMessagedJob.insertedAt),
    new Date(),
    {
      addSuffix: true,
    }
  );

  const formattedJobText =
    messageRequestsSentFromCompany.length === 1
      ? lastMessagedJob.job.externalId === jobId
        ? "this job"
        : lastMessagedJob.job.title
      : messageRequestsSentFromCompany.length > 0
      ? "multiple jobs"
      : undefined;

  return (
    <Container>
      <Icon icon="checkmark" />
      {lastMessagedJob && (
        <Text size={-1} bold>
          Messaged {formattedTime} for {formattedJobText}
        </Text>
      )}
    </Container>
  );
}
