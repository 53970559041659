import styled from "@xstyled/styled-components";
import { AnimatePresence, motion } from "framer-motion";

import { RecruiterList } from "../RecruiterList";

import { CompanyRecruiterFragment } from "@hire/schema";
import { palette, pxToRem } from "@otta/design-tokens";
import { Spacing, Text, Button, Toggle, Tipbox } from "@otta/design";
import { BaseModal } from "@hire/components/modals/BaseModal";

const ModalContent = styled.div`
  padding: lg;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${palette.brand.white};
  position: relative;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  min-height: ${pxToRem(50)};
`;

const SwitchWrapper = styled.div`
  justify-self: center;
  cursor: pointer;
`;

interface SalaryVisibilityModalProps {
  isOttaCertified: boolean;
  hideSalary: boolean;
  canHideAllSalary: boolean;
  companyMembers: CompanyRecruiterFragment[];
  visibleToIds: string[];
  currentUserId?: string;
  onClose: () => void;
  onToggleSalaryVisibility: (hideSalary: boolean) => void;
  onChangeMemberVisibility: (id: string) => void;
  isOpen: boolean;
}

export function SalaryVisibilityModal({
  isOttaCertified,
  hideSalary,
  canHideAllSalary,
  companyMembers,
  visibleToIds,
  currentUserId,
  onClose: handleClose,
  onToggleSalaryVisibility: handleToggleSalaryVisibility,
  onChangeMemberVisibility: handleChangeMemberVisibility,
  isOpen,
}: SalaryVisibilityModalProps): React.ReactElement | null {
  const showChangePermissions =
    hideSalary && canHideAllSalary && companyMembers.length > 0;

  const salaryWarningMessage = isOttaCertified ? (
    <>
      You&apos;ll lose <strong>Otta Certification</strong> if you add a hidden
      salary
    </>
  ) : (
    <>
      Hidden salaries are <strong>half as effective</strong> as public salaries
    </>
  );

  return (
    <BaseModal
      open={isOpen}
      onOpenChange={handleClose}
      heading="Change salary visibility"
    >
      <ModalContent>
        <Spacing>
          <Text>
            You can hide salary information from candidates and colleagues
          </Text>
          <GridWrapper>
            <Text bold>Salary visible to candidates</Text>
            <SwitchWrapper>
              <Toggle
                aria-label="Salary visible to candidates"
                checked={!hideSalary}
                onChange={value => handleToggleSalaryVisibility(!value)}
              />
            </SwitchWrapper>
          </GridWrapper>
          <Tipbox level={"error"}>
            <Text size={-1}>{salaryWarningMessage}</Text>
          </Tipbox>
          <AnimatePresence>
            {showChangePermissions && (
              <motion.div
                style={{ overflow: "hidden" }}
                initial={{ height: 0 }}
                animate={{
                  height: "auto",
                }}
                transition={{
                  ease: "easeInOut",
                  duration: 1,
                }}
                exit={{ height: 0 }}
              >
                <Spacing>
                  <Text bold>Hide salary from colleagues</Text>
                  <Text>
                    Admins will always see salary information. Manage user
                    permissions in your profile.
                  </Text>
                  <RecruiterList
                    currentUserId={currentUserId}
                    companyMembers={companyMembers}
                    visibleToIds={visibleToIds}
                    onChange={handleChangeMemberVisibility}
                  />
                </Spacing>
              </motion.div>
            )}
          </AnimatePresence>
          <ButtonWrapper>
            <Button
              data-testid="cancel-button"
              level="primary"
              type="button"
              onClick={handleClose}
            >
              Close
            </Button>
          </ButtonWrapper>
        </Spacing>
      </ModalContent>
    </BaseModal>
  );
}
