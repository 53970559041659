import styled from "@xstyled/styled-components";

import { SectionProps } from "../../common/types";

import { MaybeProxiedImage } from "@hire/components/images/MaybeProxiedImage";
import { Text, Spacing } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";

const Photo = styled(MaybeProxiedImage)`
  max-width: 100%;
`;

const ExampleArea = styled.div`
  height: ${pxToRem(160)};
  background-color: ${palette.brand.yellow};
  justify-content: center;
  border-radius: 0.5rem;
  align-items: center;
  display: flex;
`;

export function CompanyPhotos({
  company: { photos },
}: SectionProps): React.ReactElement | null {
  return photos.length > 0 ? (
    <Spacing size={0}>
      {photos.map(({ path }, i) => (
        <Photo key={i} path={path} width={400} />
      ))}
    </Spacing>
  ) : null;
}

export function ExamplePhotos(): React.ReactElement {
  return (
    <ExampleArea>
      <Text bold align="center">
        [Photos]
      </Text>
    </ExampleArea>
  );
}
