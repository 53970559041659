export function mapQueryResultToSelectOption<Field extends string>(
  displayFieldName: Field
) {
  return (
    data: { id: string } & { [key in Field]: string }
  ): { label: string; value: string } => ({
    label: data[displayFieldName],
    value: data.id,
  });
}
