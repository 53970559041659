import { CompanyDetailsFormFields } from "./types";

import { CompanyDetailsFragment, CompanyInput } from "@hire/schema";
import { UPLOADED_IMAGE_BASE_URL } from "@hire/components/form/ImageUploadField";
import { removeTypename } from "@hire/util/graphql";

export const transformIn = (
  data: CompanyDetailsFragment
): CompanyDetailsFormFields => {
  return {
    name: data.name,
    websiteUrl: data.websiteUrl ?? undefined,
    shortDescription: data.shortDescription ?? undefined,
    sectorTags: data.sectorTags.map(s => ({ value: s.id, label: s.value })),
    logoPath: data.logoPath ?? undefined,
    faviconPath: data.faviconPath ?? undefined,
    mission: data.mission ?? undefined,
    hqAddress: data.hqAddress ?? undefined,
    values: data.values?.map(v => ({
      id: v.id,
      order: v.order ?? undefined,
      value: v.value ?? undefined,
    })),
    articles: data.articles.map(a => ({ title: a.title, url: a.url })),
    photos: data.photos.map(p => removeTypename(p)),
    requiredOfficeDaysRange: {
      minDaysInOffice: data.minDaysInOffice ?? undefined,
      maxDaysInOffice: data.maxDaysInOffice ?? undefined,
    },
  };
};

export const transformOut = (
  formValues: CompanyDetailsFormFields
): CompanyInput => {
  const {
    requiredOfficeDaysRange,
    photos,
    sectorTags,
    values,
    logoPath,
    faviconPath,
    ...rest
  } = formValues;

  return {
    ...rest,
    minDaysInOffice: requiredOfficeDaysRange.minDaysInOffice ?? null,
    maxDaysInOffice: requiredOfficeDaysRange.maxDaysInOffice ?? null,
    logoPath: logoPath && logoPath.replace(UPLOADED_IMAGE_BASE_URL, ""),
    faviconPath:
      faviconPath && faviconPath.replace(UPLOADED_IMAGE_BASE_URL, ""),
    photos: photos.map(p => ({
      ...p,
      path: p.path.replace(UPLOADED_IMAGE_BASE_URL, ""),
    })),
    sectorTags: sectorTags.map(s => ({
      value: s.label,
      id: s.value,
    })),
    values: values
      .filter(v => v.value && v.value.trim() !== "")
      .map(v => ({ order: v.order, value: v.value })),
  };
};
