import { toast } from "react-toastify";

import {
  CandidatePipelineItemFragment,
  CandidatePipelineStageFragment,
  StageType,
} from "@hire/schema";
import {
  ImportedCandidatePipelineItem,
  OttaCandidatePipelineItem,
} from "@hire/pages/JobPage/Pipeline/types";

export const DEFAULT_BULK_STAGE_OPTION = {
  value: "",
  label: "Don't move candidates",
};

export const isOttaCandidatePipelineItem = (
  pipelineItem: CandidatePipelineItemFragment
): pipelineItem is OttaCandidatePipelineItem => pipelineItem.candidate !== null;

export const isImportedCandidatePipelineItem = (
  pipelineItem: CandidatePipelineItemFragment
): pipelineItem is ImportedCandidatePipelineItem =>
  pipelineItem.importedCandidate !== null;

export const sortCandidatesByRecency = (
  candidates: CandidatePipelineItemFragment[]
): CandidatePipelineItemFragment[] =>
  [...candidates].sort((a, b) => {
    const aDate: number = a.jobApplication?.appliedAt
      ? new Date(a.jobApplication.appliedAt).getTime()
      : a.latestMessage?.insertedAt
      ? new Date(a.latestMessage.insertedAt).getTime()
      : 0;

    const bDate: number = b.jobApplication?.appliedAt
      ? new Date(b.jobApplication.appliedAt).getTime()
      : b.latestMessage?.insertedAt
      ? new Date(b.latestMessage.insertedAt).getTime()
      : 0;

    return bDate - aDate;
  });

export const getBulkStageOptions = (
  stages: CandidatePipelineStageFragment[]
): { value: string; label: string }[] => [
  DEFAULT_BULK_STAGE_OPTION,
  ...stages
    .filter(
      stage =>
        stage.stageType !== StageType.Applied &&
        stage.stageType !== StageType.Sourced
    )
    .map(({ id, name }) => ({ value: id, label: name })),
];

export const handleCompleteBulkMove = ({
  candidateIds,
  stageName,
}: {
  candidateIds: string[];
  stageName: string;
}): void => {
  const content =
    candidateIds.length > 1
      ? "candidates have been moved to"
      : "candidate has been moved to";

  toast.success(`${candidateIds.length} ${content} ${stageName}`, {
    autoClose: 2500,
  });
};
