import { useEffect, useMemo, useState } from "react";
import styled from "@xstyled/styled-components";
import { useMutation, useQuery } from "@apollo/client";

import { pushAnalyticsEvent } from "@otta/analytics";
import { Button, Checkbox, SelectField, Spacing, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import {
  AppliedSourcedCandidatesDocument,
  UpdateJobOfferDocument,
} from "@hire/schema";
import { handleMutationError } from "@hire/errors";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

const Success = styled.div`
  border-left: 2px solid ${palette.brand.yellow};
  padding: xl 0 xl xl;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: md;
`;

interface CloseApplicationsProps {
  jobId: string;
  archive(): void;
  onCancel(): void;
}

export function CloseApplications({
  jobId,
  archive,
  onCancel,
}: CloseApplicationsProps) {
  const [madeAHire, setMadeAHire] = useState<boolean | null>(null);
  const [foundOnOtta, setFoundOnOtta] = useState<boolean | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  const companyName = useWelcomeToTheJungle();

  const { data, loading } = useQuery(AppliedSourcedCandidatesDocument, {
    skip: !foundOnOtta,
    variables: { jobId },
  });

  const [updateJobOfferMutation] = useMutation(UpdateJobOfferDocument, {
    onError: handleMutationError,
  });

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Company Recruiter Viewed Close Applications Page",
    });
  }, []);

  const setHireRadio = (status: boolean) => {
    setUserId(null);
    setFoundOnOtta(null);
    setMadeAHire(status);
  };

  const setFoundRadio = (status: boolean) => {
    setUserId(null);
    setFoundOnOtta(status);
  };

  const isValid = typeof foundOnOtta === "boolean" || madeAHire === false;

  const handleCloseApplications = async () => {
    if (userId) {
      await updateJobOfferMutation({
        variables: {
          userId,
          jobId,
          input: {
            accepted: true,
          },
        },
      });
    }

    archive();
  };

  const options = useMemo(
    () =>
      data?.companyJob?.candidates?.map(candidate => ({
        label: candidate.firstName + " " + candidate.lastName,
        value: candidate.id,
      })) ?? [],
    [data?.companyJob?.candidates]
  );

  return (
    <Spacing size={1}>
      <div data-testid="hired">
        <Spacing>
          <Text bold>Did you make a hire for this role?</Text>
          <Checkbox
            checked={madeAHire === true}
            onChange={() => setHireRadio(true)}
            label="Yes"
          />
          <Checkbox
            checked={madeAHire === false}
            onChange={() => setHireRadio(false)}
            label="No"
          />
        </Spacing>
      </div>

      {madeAHire && (
        <Success>
          <Spacing size={2}>
            <div data-testid="on-otta">
              <Spacing>
                <Text bold>
                  Great news! Did you find this candidate on {companyName}?
                </Text>
                <Checkbox
                  checked={foundOnOtta === true}
                  onChange={() => setFoundRadio(true)}
                  label="Yes"
                />
                <Checkbox
                  checked={foundOnOtta === false}
                  onChange={() => setFoundRadio(false)}
                  label="No"
                />
              </Spacing>
            </div>

            {foundOnOtta && (
              <div data-testid="candidate">
                <Spacing>
                  <Text bold>
                    Which candidate did you decide to hire? (optional)
                  </Text>
                  <Text as="label" htmlFor="candidate">
                    Candidate name
                  </Text>

                  <SelectField
                    data-cs-mask
                    inputId="candidate"
                    options={options}
                    value={options.find(o => o.value === userId) ?? null}
                    isLoading={loading}
                    onChange={e => setUserId(e?.value ?? null)}
                    menuPlacement="top"
                  />
                </Spacing>
              </div>
            )}
          </Spacing>
        </Success>
      )}
      <ButtonWrapper>
        {isValid && (
          <Button
            level="primary"
            onClick={handleCloseApplications}
            data-analytics-id={"close-applications"}
          >
            Close applications
          </Button>
        )}
        <Button level="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonWrapper>
    </Spacing>
  );
}
