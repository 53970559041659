import { Divider } from "../../common/Divider";
import { Subheading } from "../../common/Subheading";
import { SectionProps } from "../../common/types";

import { List, Spacing } from "@otta/design";

export function CompanyValues({
  company: { values },
}: SectionProps): React.ReactElement | null {
  return values.length === 0 ? (
    <Spacing size={0}>
      <Divider />
      <Subheading>Company values</Subheading>
      <List type="bullet">
        {values.map(({ value }, i) => (
          <li key={i}>{value}</li>
        ))}
      </List>
    </Spacing>
  ) : null;
}

export function ExampleCompanyValues(): React.ReactElement {
  return (
    <Spacing size={0}>
      <Divider />
      <Subheading>[Example] Company values</Subheading>
      <List type="bullet">
        <li key={0}>Customer obsession rather than competitor focus</li>
        <li key={1}>Passion for invention</li>
        <li key={2}>Commitment to operational excellence</li>
        <li key={3}>Long-term thinking</li>
      </List>
    </Spacing>
  );
}
