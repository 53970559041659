import { CandidateSnippet } from "./CandidateSnippet";

import { CandidateCardFragment } from "@hire/schema";
import { Text } from "@otta/design";
import { Separator } from "@hire/components/Separator";

interface CandidateSnippetsProps {
  candidate: CandidateCardFragment;
}

export function CandidateSnippets({
  candidate,
}: CandidateSnippetsProps): React.ReactElement | null {
  if (candidate.projects.length === 0) {
    return null;
  }

  return (
    <>
      <Separator />
      <Text size={1}>Snippets</Text>
      {candidate.projects.map(project => (
        <CandidateSnippet key={project.id} snippet={project} />
      ))}
    </>
  );
}
