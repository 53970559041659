import { Navigate } from "react-router-dom";

import {
  useAuthorization,
  useJobAuthorization,
} from "./providers/authorization";
import { PausedProUpsellPage, ProUpsellPage } from "./components/ProUpsell";
import { Feature, JobFeature } from "./schema";
import { MaintenancePage } from "./components/Maintenance";

export const HasAccess = ({
  feature,
  redirect,
  children,
}: {
  feature: Feature;
  redirect?: string;
  children: React.ReactElement;
}): React.ReactElement | null => {
  const { granted, loading } = useAuthorization(feature);

  if (loading) {
    return null;
  }

  if (granted) {
    return <>{children}</>;
  }

  if (redirect) {
    return <Navigate to={redirect} replace />;
  }

  return null;
};

function featureToCampaign(feature: JobFeature): string | undefined {
  switch (feature) {
    case JobFeature.Sourcing:
      return "page_sourcing";
    case JobFeature.Pipeline:
      return "page_pipeline";
    default:
      return undefined;
  }
}

export const HasJobAccess = ({
  feature,
  children,
  noAccessRedirect,
}: {
  feature: JobFeature;
  children: React.ReactNode;
  noAccessRedirect?: string;
}): React.ReactElement | null => {
  const { granted, message, loading } = useJobAuthorization(feature);

  if (loading) {
    return null;
  }

  if (granted) {
    return <>{children}</>;
  }

  if (message === "upsell") {
    return <ProUpsellPage campaign={featureToCampaign(feature)} />;
  }

  if (message === "maintenance") {
    return <MaintenancePage />;
  }

  if (message === "paused") {
    return <PausedProUpsellPage campaign={featureToCampaign(feature)} />;
  }

  if (noAccessRedirect) {
    return <Navigate to={noAccessRedirect} replace />;
  }

  return null;
};
