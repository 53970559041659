import { Navigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { SamlLoginButton } from "./SamlLoginButton";
import { SignupForm } from "./SignupForm";
import { AuthContainer } from "./Login";

import { AuthStrategyType, CompanyAuthDocument } from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { Card, Heading, VerticalSpacing, Text } from "@otta/design";
import { Link } from "@hire/components/links/Link";
import { Separator } from "@hire/components/Separator";
import { useRequiredParams } from "@hire/util/routing";

export function Signup(): React.ReactElement {
  const { companySlug } = useRequiredParams(["companySlug"]);
  const [params] = useSearchParams();

  const { data, loading } = useQuery(CompanyAuthDocument, {
    variables: { urlSafeName: companySlug },
  });

  if (loading) {
    return <Loading />;
  }

  if (!data?.company) {
    return <Navigate to="/" replace />;
  }

  const { authStrategies } = data.company;

  const samlStrategy = authStrategies.find(
    s => s.type === AuthStrategyType.Saml
  );

  const passwordStrategy = authStrategies.find(
    s => s.type === AuthStrategyType.Password
  );

  return (
    <AuthContainer>
      <VerticalSpacing size={1}>
        <Heading size={4} align="center">
          Sign up
        </Heading>
        <Card>
          <VerticalSpacing size={1}>
            {samlStrategy && (
              <SamlLoginButton
                type="signup"
                loginUrl={samlStrategy.loginUrl}
                companyUrlSafeName={companySlug}
              />
            )}
            {samlStrategy && passwordStrategy && <Separator />}
            {passwordStrategy && (
              <SignupForm
                email={params.get("email") ?? undefined}
                signupUrl={passwordStrategy.signupUrl}
                companyUrlSafeName={companySlug}
              />
            )}
          </VerticalSpacing>
        </Card>
        <Text>
          <Text align="center">
            Already have an account? Sign in{" "}
            <Link to={`/login/${companySlug}`}>here</Link>
          </Text>
        </Text>
      </VerticalSpacing>
    </AuthContainer>
  );
}
