import styled from "@xstyled/styled-components";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { BreadCrumbs } from "./Breadcrumbs";
import { OttasTakeModal } from "./OttasTakeModal";
import { useRequireLoginState } from "./useRequireLoginState";

import { Button, Heading, InputField, Spacing, Text } from "@otta/design";
import * as Banner from "@hire/components/Banner";
import { pxToRem } from "@otta/design-tokens";
import { Link } from "@hire/components/links";
import { useAnswers } from "@hire/components/layout/QuizLayout";
import {
  CompaniesWithPreApprovalDocument,
  CompaniesWithPreApprovalQuery,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

const BannerContent = styled(Banner.Content)`
  gap: md;
`;

const SeeExampleLink = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
`;

const companyFromDomain = (
  data: CompaniesWithPreApprovalQuery | undefined,
  domain: string
) => {
  return data?.companies.find(
    c => c.websiteUrl?.replace("https://", "")?.toLowerCase() === domain
  );
};

const validationSchema = (data?: CompaniesWithPreApprovalQuery | undefined) =>
  Yup.object().shape({
    companyName: Yup.string().required("This field is required"),
    websiteUrl: Yup.string()
      .required("This field is required")
      .matches(
        /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%\\+.~#()?&//=]*)/i,
        "Enter a valid URL"
      )
      .test(
        "not-existing-company-domain",
        d => {
          const company = companyFromDomain(data, d.value);
          const companyName = useWelcomeToTheJungle();

          return (
            <>
              This company is already on {companyName}. Sign in{" "}
              <Link
                to={`/signup/${company?.urlSafeName}`}
                data-testid="existing-signup-link"
              >
                here
              </Link>
              .
            </>
          );
        },
        domain => {
          const company = companyFromDomain(data, domain);
          if (company) {
            return false;
          }
          return true;
        }
      ),
  });

export const CompanyDetails = () => {
  const [exampleModalVisible, setexampleModalVisible] = useState(false);
  const [answers, setAnswers] = useAnswers();
  const { data } = useQuery(CompaniesWithPreApprovalDocument);
  const navigate = useNavigate();

  const form = useFormik({
    initialValues: {
      companyName: "",
      websiteUrl: "",
    },
    validationSchema: validationSchema(data),
    async onSubmit(values) {
      setAnswers({
        ...answers,
        companyName: values.companyName,
        websiteUrl: values.websiteUrl,
      });

      navigate("/quiz/signup");
    },
  });

  const { loading } = useRequireLoginState({
    state: "logged-out",
    redirect: "/quiz",
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <BreadCrumbs currentBreadcrumb="Company" />
      <Spacing size={2}>
        <Heading size={2}>Tell us about your company</Heading>

        <Banner.Info>
          <BannerContent>
            <Text>
              We'll set you up for success with a detailed company profile. You
              can edit this later.
            </Text>
            <Text size={0}>
              We'll also put in a good word for you with candidates.{" "}
              <SeeExampleLink
                onClick={() => {
                  setexampleModalVisible(true);
                }}
                inline
              >
                See example
              </SeeExampleLink>
              .
            </Text>
          </BannerContent>
        </Banner.Info>

        <form onSubmit={form.handleSubmit}>
          <Spacing size={2}>
            <InputField
              disabled={form.isSubmitting}
              label="Company name"
              name="companyName"
              value={form.values.companyName}
              onChange={form.handleChange("companyName")}
              onBlur={form.handleBlur("companyName")}
              error={
                form.touched.companyName ? form.errors.companyName : undefined
              }
            />
            <InputField
              disabled={form.isSubmitting}
              label="Company website"
              name="websiteUrl"
              value={form.values.websiteUrl}
              onChange={form.handleChange("websiteUrl")}
              onBlur={form.handleBlur("websiteUrl")}
              error={
                form.touched.websiteUrl ? form.errors.websiteUrl : undefined
              }
            />
          </Spacing>
          <Button
            style={{ marginTop: pxToRem(24) }}
            disabled={form.isSubmitting}
            level="primary"
            type="submit"
          >
            Continue to account creation
          </Button>
        </form>
      </Spacing>

      <Text style={{ marginTop: pxToRem(12) }}>
        <Text inline bold>
          No commitment.
        </Text>{" "}
        Pay monthly, cancel online anytime.
      </Text>

      <OttasTakeModal
        open={exampleModalVisible}
        closeModal={() => setexampleModalVisible(false)}
      />
    </>
  );
};
