import { parseISO, format } from "date-fns";

import { QuestionResponseDataFragment } from "@hire/schema";
import { VerticalSpacing, Text, Paragraph } from "@otta/design";
import { ExternalLink } from "@hire/components/links/ExternalLink";

export interface QuestionResponse {
  id: string;
  question: string;
  data: QuestionResponseDataFragment["data"];
}

const formatDisplayValue = (
  responseData: QuestionResponseDataFragment["data"]
) => {
  switch (responseData.__typename) {
    case "BooleanResponse":
      return responseData.booleanValue ? "Yes" : "No";
    case "DateResponse":
      return format(parseISO(responseData.date), "dd-MMM-yyyy");
    case "DecimalResponse":
      return responseData.decimalValue;
    case "SingleChoiceResponse":
      return responseData.label;
    case "MultipleChoiceResponse":
      return responseData.choices.length > 0
        ? responseData.choices.map(({ label }) => label).join(", ")
        : "No options selected";
    case "StringResponse":
      return responseData.stringValue;
    case "UrlResponse":
      return responseData.url;
    default:
      return null;
  }
};

interface QuestionResponsesProps {
  questionResponses: QuestionResponse[];
}

export function OldQuestionResponses({
  questionResponses,
}: QuestionResponsesProps): React.ReactElement {
  return (
    <VerticalSpacing>
      {questionResponses.map(({ id, question, data }) => {
        return (
          <VerticalSpacing size={-1} key={id}>
            <Text bold align="left">
              {question}
            </Text>
            {data.__typename === "FileResponse" ? (
              <ExternalLink to={data.fileUrl}>{data.name}</ExternalLink>
            ) : (
              <Paragraph size={-1} align="left">
                {formatDisplayValue(data)}
              </Paragraph>
            )}
          </VerticalSpacing>
        );
      })}
    </VerticalSpacing>
  );
}
