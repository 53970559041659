import styled, { down, css } from "@xstyled/styled-components";
import { To, useLocation } from "react-router-dom";
import { createContext, useContext, useEffect } from "react";

import { Alert, AlertType, Button } from "@otta/design";
import { Link } from "@hire/components/links/Link";
import { pushAnalyticsEvent } from "@otta/analytics";

/**
 * The intended use of this is
 * import * as Banner from "@hire/components/Banner"
 *
 * <Banner.Info>
 *  <Banner.Content>
 *    Your banner text
 *  </Banner.Content>
 *  <Banner.CTA to={"/"}>
 *    Your CTA text
 *  </Banner.CTA>
 * <Banner.Info>
 */

const StyledButton = styled(Button)`
  min-width: fit-content;
  text-decoration: none;
  align-self: center;

  ${down(
    "tablet",
    css`
      align-self: flex-start;
    `
  )};
`;

const StyledAlert = styled(Alert)`
  flex-direction: row;
  max-width: 45rem;
  display: flex;
  gap: 1rem;

  &.narrow-banner {
    max-width: 32.5rem;
  }

  ${down(
    "tablet",
    css`
      flex-direction: column;
    `
  )};
`;

type BaseProps = React.PropsWithChildren<{
  name?: string;
  level: AlertType;
  className?: string;
}>;
type BannerProps = React.PropsWithChildren<{
  name?: string;
  className?: string;
}>;

// we do this to make the alert name available to the CTA button
const AlertContext = createContext<string | undefined>(undefined);

/**
 * An alert that magically pushes an analytics event when viewed
 * and also makes the its name available to child elements
 */
function BaseAlert({ name, level, children }: BaseProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Company Recruiter Viewed Alert",
      pathName: pathname,
      alertName: name,
    });
  }, [pathname, name]);

  return (
    <AlertContext.Provider value={name}>
      <StyledAlert level={level}>{children}</StyledAlert>
    </AlertContext.Provider>
  );
}

export function Warning({
  name,
  children,
  className,
}: BannerProps): React.ReactElement {
  return (
    <BaseAlert className={className} level={"warning"} name={name}>
      {children}
    </BaseAlert>
  );
}

export function Danger({
  name,
  children,
  className,
}: BannerProps): React.ReactElement {
  return (
    <BaseAlert className={className} level={"danger"} name={name}>
      {children}
    </BaseAlert>
  );
}

export function Info({
  children,
  name,
  className,
}: BannerProps): React.ReactElement {
  return (
    <BaseAlert className={className} level={"information"} name={name}>
      {children}
    </BaseAlert>
  );
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export function CTA(
  props: React.PropsWithChildren<{ to: To }>
): React.ReactElement {
  const name = useContext(AlertContext);
  return (
    <StyledButton
      as={Link}
      to={props.to}
      level="secondary"
      data-analytics-id={name}
    >
      {props.children}
    </StyledButton>
  );
}
