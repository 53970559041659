import { Navigate } from "react-router-dom";

import { useJobAuthorizations } from "@hire/providers/authorization";
import { JobFeature } from "@hire/schema";
import { Loading } from "@otta/shared-components";

const LINKS = [
  { path: "analytics", feature: JobFeature.Analytics },
  { path: "pipeline", feature: JobFeature.Pipeline },
  { path: "inbox", feature: JobFeature.Inbox },
  { path: "sourcing", feature: JobFeature.Sourcing },
  { path: "edit", feature: JobFeature.Edit },
  { path: "broadcast", feature: JobFeature.Broadcast },
];

const FEATURES = LINKS.map(l => l.feature);

export function JobIndex() {
  const { features, loading } = useJobAuthorizations(FEATURES);

  const firstLink = LINKS.find(l => {
    const f = features.get(l.feature);
    return f?.granted || f?.message;
  });

  if (loading) {
    return <Loading />;
  }

  if (firstLink) {
    return <Navigate to={firstLink.path} replace />;
  }

  return <Navigate to=".." replace />;
}
