import { MutationUpdaterFn, useMutation, useQuery } from "@apollo/client";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useCallback } from "react";
import { toast } from "react-toastify";

import { SourcingPageHeader } from "./components/SourcingPageHeader";
import { SourcingPage } from "./enums";
import { PaginatedCandidatesList } from "./components/CandidatesList/PaginatedCandidatesList";
import {
  CandidatesContainer,
  PageContainer,
  SpinnerWrapper,
} from "./components/shared";

import { handleMutationError } from "@hire/errors";
import {
  CandidateShortlistDocument,
  CreateCandidateReactionDocument,
} from "@hire/schema";
import { Spinner } from "@otta/shared-components";

const removeCandidateFromCache =
  (candidateId: string, variables: { jobId: string }): MutationUpdaterFn =>
  cache => {
    cache.updateQuery(
      {
        query: CandidateShortlistDocument,
        variables,
      },
      data => {
        if (!data?.candidateShortlist) {
          return null;
        }

        return {
          candidateShortlist: data.candidateShortlist.filter(
            ({ externalId: cacheId }) => cacheId !== candidateId
          ),
        };
      }
    );
  };

export function ShortlistedCandidates(): React.ReactElement {
  const { jobId } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useQuery(CandidateShortlistDocument, {
    variables: {
      jobId: jobId as string,
    },
    fetchPolicy: "network-only",
  });

  const [createReaction] = useMutation(CreateCandidateReactionDocument);

  const removeCandidate = useCallback(
    (candidateId: string) =>
      createReaction({
        variables: {
          candidateId,
          jobId: jobId as string,
          direction: false,
        },
        update: removeCandidateFromCache(candidateId, {
          jobId: jobId as string,
        }),
        onError: handleMutationError,
        onCompleted: () => {
          toast.success("Candidate removed from shortlist");
        },
      }),
    [createReaction, jobId]
  );

  const messageCandidate = useCallback(
    (candidateId: string) => {
      navigate({
        pathname: `${candidateId}/message`,
        search: location.search,
      });
    },
    [navigate]
  );

  const handleAfterMessage = useCallback(() => {
    navigate({
      pathname: ".",
      search: location.search,
    });
  }, [navigate]);

  const candidates = data?.candidateShortlist ?? [];

  return (
    <PageContainer>
      <Outlet context={{ onAfterMessage: handleAfterMessage }} />
      <CandidatesContainer>
        <SourcingPageHeader
          currentPage={SourcingPage.Shortlist}
          numCandidates={candidates.length}
          loading={loading}
        />
        {loading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <PaginatedCandidatesList
            candidates={candidates}
            type={SourcingPage.Shortlist}
            messageCandidate={messageCandidate}
            removeCandidate={removeCandidate}
          />
        )}
      </CandidatesContainer>
    </PageContainer>
  );
}
