import { TemplateContainer } from "./shared-styled-components";

import { palette } from "@otta/design-tokens";
import { Spacing, Button, Text } from "@otta/design";
import { ButtonWrapper } from "@hire/components/buttons/ButtonWrapper";

export const DeleteCard = ({
  cardHeading,
  onCancel: handleCancel,
  onDelete: handleDelete,
}: {
  cardHeading: string;
  onCancel: () => void;
  onDelete: () => void;
}) => {
  return (
    <TemplateContainer
      color={palette.grayscale.shade200}
      data-testid="delete-template-card"
    >
      <Spacing>
        <Text bold as="h3">
          {cardHeading}
        </Text>
        <div style={{ textAlign: "center" }}>
          <Spacing>
            <Text bold>Delete this template?</Text>
            <Text>It can&apos;t be recovered if you delete it.</Text>
            <ButtonWrapper align="center">
              <Button type="button" level="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="button" level="destructive" onClick={handleDelete}>
                Delete template
              </Button>
            </ButtonWrapper>
          </Spacing>
        </div>
      </Spacing>
    </TemplateContainer>
  );
};
