import { format } from "date-fns";
import { NumericFormat } from "react-number-format";

import { FundingRound } from "../../types";

import { Text, Spacing } from "@otta/design";
import { RectangleTag } from "@hire/components/tags/RectangleTag";

function getFundingDate(month: string, year: string) {
  return format(new Date(parseInt(year), parseInt(month) - 1), "MMMM yyyy");
}

export const FundingRoundPreview = (props: FundingRound) => {
  const { month, year, funding, round } = props;
  return (
    <Spacing size={-4}>
      <div>
        <Text bold>{getFundingDate(month, year)}</Text>
      </div>
      <Text
        as={NumericFormat}
        value={funding.amount}
        prefix={`${funding.currency} `}
        decimalScale={0}
        allowNegative={false}
        thousandSeparator
        displayType="text"
      />
      {round ? <RectangleTag content={round} /> : undefined}{" "}
    </Spacing>
  );
};
