import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";

import { ErrorText, Textarea as OttaTextarea } from "@otta/design";

type Props = React.ComponentProps<typeof OttaTextarea> & TextareaAutosizeProps;

export function Textarea(props: Props): React.ReactElement {
  const { id, name, error } = props;

  return (
    <>
      <OttaTextarea
        as={TextareaAutosize}
        {...props}
        id={id ?? name}
        style={{ resize: "vertical" }}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
}
