export function updateArrayWithNewItem<T>(
  existingArray: T[],
  newItem: T,
  updatedIndex?: number
) {
  if (updatedIndex !== undefined) {
    const newList = [...existingArray];
    newList.splice(updatedIndex, 1, newItem);

    return newList;
  }

  return [...existingArray, newItem];
}
