import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "@xstyled/styled-components";

import { FieldContainer } from "./FieldContainer";
import { ErrorText } from "./ErrorText";
import { Input } from "./Input";
import { CurrencySelection, MoneyInput, Wrapper } from "./style";
import { Label } from "./Label";

import { pxToRem } from "@otta/design-tokens";

const MoneyInputField = styled(Input)`
  text-align: center;
  padding-left: md;
  border-radius: 0 ${pxToRem(5)} ${pxToRem(5)} 0;
  border-left: 1px solid transparent;
  background-color: transparent;
`;

export interface IMoneyFieldProps<V> {
  value: { amount: number | null; currency: V };
  name: string;
  options: { value: V; label: string }[];
  onChange?: (money: { amount: number | null; currency: V }) => void;
  onBlur?: () => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  error?: string;
}

/**
 * This component is used for currency inputs in our Applications. An optional label can be added to this input by providing a `label` prop.
 *
 * ```ts
 *
 * import { MoneyField } from '@otta/design'
 *
 * ```
 */

export function MoneyField<V extends string>({
  value,
  name,
  options,
  placeholder,
  onChange,
  onBlur,
  label,
  disabled,
  error,
}: IMoneyFieldProps<V>): React.ReactElement {
  const [expand, setExpand] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setExpand(false);
    }
  };

  useEffect(() => {
    if (expand) {
      document.addEventListener("click", handleClickOutside, true);

      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }

    return undefined;
  }, [expand]);

  const selectedCurrency = useMemo(
    () => options.find(option => option.value === value.currency),
    [options, value.currency]
  );

  const handleValueChange = useCallback(
    (amount: number | null) => {
      onChange?.({ ...value, amount });
    },
    [onChange, value]
  );

  const handleCurrencyChange = useCallback(
    (currency: V) => {
      onChange?.({ ...value, currency });
      onBlur?.();
    },
    [onBlur, onChange, value]
  );

  return (
    <FieldContainer>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Wrapper ref={ref}>
        <CurrencySelection
          currency={value.currency}
          onClick={handleCurrencyChange}
          options={options.map(option => option.value)}
          expand={expand}
          setExpand={setExpand}
          disabled={disabled}
        />
        <MoneyInput
          value={value.amount}
          prefix={selectedCurrency?.label}
          customInput={MoneyInputField}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={handleValueChange}
          disabled={disabled}
        />
      </Wrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </FieldContainer>
  );
}
