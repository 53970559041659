import { useMemo } from "react";
import { GroupBase, Props } from "react-select";

import { QuizFieldValuesQuery } from "@hire/schema";
import { SelectField } from "@otta/design";

function useOptions(
  values: QuizFieldValuesQuery["jobFunctions"]
): GroupBase<{ label: string; value: string }>[] {
  return useMemo(
    () =>
      (values ?? []).map(({ value, subFunctions }) => ({
        label: value,
        options: subFunctions.map(({ id, value }) => ({
          value: id,
          label: value,
        })),
      })),
    [values]
  );
}

type FieldProps = Omit<
  Props<{ value: string; label: string }, false>,
  "options" | "value"
> & {
  options: QuizFieldValuesQuery["jobFunctions"];
  value?: string;
};

export function FunctionField({ options, value, ...props }: FieldProps) {
  const opts = useOptions(options);

  const val = useMemo(
    () => opts.flatMap(v => v.options).find(o => value === o.value),
    [opts, value]
  );

  return (
    <SelectField
      options={useOptions(options)}
      value={val}
      {...props}
    ></SelectField>
  );
}
