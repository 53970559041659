import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import styled from "@xstyled/styled-components";
import { TextareaAutosizeProps } from "react-textarea-autosize";
import { useCallback, useMemo } from "react";

import { palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Button, Spacing } from "@otta/design";
import { DeleteButton } from "@hire/components/buttons/DeleteButton";
import { Textarea } from "@hire/components/input/Textarea";
import { FieldErrors } from "@hire/components/form/FieldErrors";
import { splitBullets } from "@otta/shared-components";

const BulletContainer = styled.div`
  margin-bottom: lg;
`;

const MetaWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 1rem;
`;

const BulletRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 10%;
  justify-items: stretch;
  gap: 10;
  align-items: center;
`;

const MoreButton = styled(Button)`
  padding: 0.5rem 1rem 0.5rem 1rem;
  max-width: fit-content;
  display: flex;
  gap: 0.5rem;
`;

const ButtonIcon = styled(Icon)`
  margin-bottom: 0.1rem;
`;

const FlexColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export interface JobFormBullet {
  value?: string;
  order: number;
  desirable?: boolean;
}

interface BulletsProps {
  values: JobFormBullet[];
  fieldProps?: TextareaAutosizeProps[];
  onChange: (bullets: JobFormBullet[]) => void;
  onBlur?: () => void;
  fieldName: string;
  placeholders: string[];
  nounSingular?: string;
  errors?: string | (string | undefined)[];
  isDesirable?: boolean;
}

export function JobFormBullets({
  values,
  onChange,
  onBlur,
  fieldName,
  placeholders,
  nounSingular = "item",
  errors,
  fieldProps,
  isDesirable,
}: BulletsProps) {
  const handleChange = (index: number, bullet: JobFormBullet) => {
    const newValue = [...values];
    newValue[index] = bullet;
    onChange(newValue);
  };

  const toRender = useMemo(() => {
    if (values.length === 0) {
      return isDesirable !== undefined
        ? [{ desirable: isDesirable, value: undefined, order: 0 }]
        : [{ value: undefined, order: 0 }];
    } else {
      return values;
    }
  }, [values, isDesirable]);

  const handleDelete = (index: number) => {
    const newValue = [...values];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  const handleAdd = useCallback(() => {
    const newValue = {
      value: undefined,
      order: toRender.length,
      ...(isDesirable !== undefined ? { desirable: !!isDesirable } : {}),
    };

    onChange([...toRender, newValue]);
  }, [toRender, isDesirable, onChange]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newValue = [...values];
    const [reorderedItem] = newValue.splice(result.source.index, 1);
    newValue.splice(result.destination.index, 0, reorderedItem);
    const updatedOrderedValues = newValue.map((value, index) => ({
      ...value,
      order: index,
    }));
    onChange(updatedOrderedValues);
  };

  return (
    <Spacing size={-3}>
      <FlexColWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {toRender.map((v, index) => (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={toRender.length < 2}
                  >
                    {provided => (
                      <BulletContainer>
                        <BulletRow
                          key={index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Icon icon="grip-lines" size={1} />
                          <Textarea
                            key={index}
                            name={`${fieldName}-${index}`}
                            value={v.value}
                            data-testid={fieldName}
                            placeholder={
                              placeholders.length > 0
                                ? placeholders[index % placeholders.length]
                                : ""
                            }
                            onBlur={() => (onBlur ? onBlur() : undefined)}
                            onChange={e =>
                              handleChange(index, {
                                ...v,
                                value: e.target.value,
                              })
                            }
                            onPaste={e => {
                              if (!v.value) {
                                e.preventDefault();

                                const pastedData =
                                  e.clipboardData.getData("text");

                                const items = splitBullets(pastedData).map(
                                  bulletText => ({
                                    value: bulletText,
                                    desirable: v.desirable,
                                  })
                                );

                                const newBullets = [
                                  ...toRender.slice(0, index),
                                  ...items,
                                  ...toRender.slice(index + 1),
                                ].map((bullet, i) => ({
                                  ...bullet,
                                  order: i,
                                }));

                                onChange(newBullets);
                              }
                            }}
                            style={{ alignSelf: "center" }}
                            {...(fieldProps?.[index] ?? {})}
                          />
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {!!index && (
                              <DeleteButton
                                onClick={() => handleDelete(index)}
                                color={palette.brand.red}
                                hoverColor={palette.extended.red.shade800}
                                label={`Delete ${nounSingular}`}
                              />
                            )}
                          </div>
                        </BulletRow>
                        <FieldErrors
                          errors={
                            errors && Array.isArray(errors)
                              ? errors[index]
                              : undefined
                          }
                        />
                      </BulletContainer>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <MetaWrapper>
          {errors && typeof errors === "string" && (
            <FieldErrors errors={errors} />
          )}
          <MoreButton
            type="button"
            level="secondary"
            size="small"
            onClick={() => {
              handleAdd();
            }}
          >
            <ButtonIcon icon="plus" />
            Add {nounSingular}
          </MoreButton>
        </MetaWrapper>
      </FlexColWrapper>
    </Spacing>
  );
}
