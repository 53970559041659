import { PropsWithChildren, useEffect, useState } from "react";
import styled, { css, up } from "@xstyled/styled-components";
import { useNavigate } from "react-router-dom";

import { pushAnalyticsEvent } from "@otta/analytics";
import { Modal } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";

const Container = styled.div`
  padding: xl;

  ${up(
    "tablet",
    css`
      width: ${pxToRem(640)};
    `
  )}
`;

export const JobSlotsModal = ({
  name,
  children,
  redirectOnClose,
}: {
  name: string;
  redirectOnClose: string;
} & PropsWithChildren) => {
  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Company Recruiter Viewed Modal",
      modalName: name,
    });
  }, [name]);

  const [open, setIsOpen] = useState(true);
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onOpenChange={open => {
        if (!open) {
          navigate(redirectOnClose);
          setIsOpen(open);
        }
      }}
      style={{ minWidth: "fit-content" }}
      dismissable={true}
    >
      <Container data-testid={name}>{children}</Container>
    </Modal>
  );
};
