import { SectionProps } from "../../common/types";

import { Text } from "@otta/design";

function formatOfficeDays(min?: number, max?: number): string | undefined {
  const formattedDayString = min === 1 ? "day" : "days";
  if (max) {
    if (min === max) {
      return `${min} ${formattedDayString}`;
    }
    if (min && min < max) {
      return `${min}-${max} days`;
    }
  }
  return `${min}+ ${formattedDayString}`;
}

export function OfficeLocation({
  fields: {
    officeLocation,
    requiredOfficeDaysRange: { minDaysInOffice, maxDaysInOffice },
  },
}: SectionProps): React.ReactElement | null {
  const dayText = formatOfficeDays(minDaysInOffice, maxDaysInOffice);

  if (dayText && officeLocation && minDaysInOffice && minDaysInOffice > 0) {
    return (
      <Text>
        {dayText} ({officeLocation})
      </Text>
    );
  } else if (officeLocation) {
    return <Text>{officeLocation}</Text>;
  } else {
    return null;
  }
}
