import { Text, Modal } from "@otta/design";

interface FormModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  heading: string;
  children: React.ReactElement;
}

export const BaseModal = ({
  open,
  heading,
  children,
  onOpenChange,
}: FormModalProps) => {
  return (
    <Modal
      open={open}
      headerContent={
        <Text bold as="h2" size={1}>
          {heading}
        </Text>
      }
      dismissable={false}
      onOpenChange={onOpenChange}
    >
      {children}
    </Modal>
  );
};
