import { ReactNode, useCallback, useRef, useEffect, useMemo } from "react";
import { FlagProvider, UnleashClient } from "@unleash/proxy-client-react";
import { useQuery } from "@apollo/client";

import {
  useDebuggerPortal,
  useDebugger,
  type ExperimentDefinition,
} from "../debugger";

import { ExperimentContext } from "./context";
import { graphql } from "./gql";
import { type CurrentUserExperimentsQuery, RoleType } from "./gql/graphql";

import { pushExperimentToAmplitude } from "@otta/analytics";

interface ExperimentProviderProps {
  client: UnleashClient;
  children: ReactNode;
}

const determineRole = (me?: CurrentUserExperimentsQuery["me"]) => {
  if (!me) {
    return null;
  }

  switch (me.__typename) {
    case "CurrentAdmin":
      return RoleType.Admin;
    case "CurrentCandidate":
      return RoleType.Candidate;
    case "CurrentCompanyRecruiter":
      return RoleType.CompanyRecruiter;
    case "CurrentInternalOperator":
      return RoleType.InternalOperator;
    case "CurrentExternalResearcher":
      return RoleType.ExternalResearcher;
    default:
      return null;
  }
};

const CurrentUserDocument = graphql(`
  query CurrentUserExperiments {
    me {
      id
      ... on CurrentAdmin {
        currentCompany {
          id
          scraperUrl
        }
      }
      ... on CurrentCompanyRecruiter {
        currentCompany {
          id
          scraperUrl
        }
      }
    }
  }
`);

const AllFlagsDocument = graphql(`
  query AllFlags {
    featureFlags {
      name
      variants
    }
  }
`);

export function ExperimentProvider({
  client,
  children,
}: ExperimentProviderProps): React.ReactElement {
  const { data, loading } = useQuery(CurrentUserDocument);

  const { data: flagData } = useQuery(AllFlagsDocument, {
    skip: data?.me?.__typename !== "CurrentAdmin",
  });

  const definitions = useMemo(
    () =>
      flagData?.featureFlags.reduce<ExperimentDefinition>((acc, flag) => {
        const defn = flag.variants.length
          ? ["control", ...flag.variants]
          : ["control", "variant"];

        acc[flag.name] = defn.map(variant => ({ variant }));

        return acc;
      }, {}) ?? {},
    [flagData?.featureFlags]
  );

  useEffect(() => {
    if (loading) {
      return;
    }
    const user = data?.me;

    if (user?.id) {
      client.setContextField("userId", user.id);
    }

    const role = determineRole(user);

    if (role) {
      client.setContextField("role", role);
    }

    if (
      (user?.__typename === "CurrentCompanyRecruiter" ||
        user?.__typename === "CurrentAdmin") &&
      user.currentCompany
    ) {
      client.setContextField("companyId", user.currentCompany.id);
      client.setContextField(
        "postsDirectlyToOtta",
        `${!user.currentCompany.scraperUrl}`
      );
    }

    client.start();
  }, [data?.me, loading, client]);

  const portal = useDebuggerPortal();

  const [overrides, onVariantSeen, el] = useDebugger(definitions, portal);

  const seenExperiments = useRef<Record<string, string>>({});

  const variantViewed = useCallback(
    (name: string, variant: string) => {
      if (seenExperiments.current[name] !== variant) {
        seenExperiments.current[name] = variant;
        pushExperimentToAmplitude(name, variant);
      }

      return onVariantSeen(name, variant);
    },
    [onVariantSeen]
  );

  return (
    <>
      <FlagProvider unleashClient={client} startClient={false}>
        <ExperimentContext.Provider value={{ variantViewed, overrides }}>
          {children}
        </ExperimentContext.Provider>
      </FlagProvider>
      {el}
    </>
  );
}

interface MockedExperimentProviderProps {
  mocks: Record<string, string>;
  onSeen?: (name: string, value: string) => void;
  children: React.ReactNode;
}

export function MockedExperimentProvider({
  mocks,
  onSeen,
  children,
}: MockedExperimentProviderProps): React.ReactElement {
  const variantViewed = useCallback(
    (name: string, value: string) =>
      function () {
        if (onSeen) {
          onSeen(name, value);
        }

        return () => {
          return;
        };
      },
    [onSeen]
  );

  return (
    <ExperimentContext.Provider value={{ variantViewed, overrides: mocks }}>
      {children}
    </ExperimentContext.Provider>
  );
}
