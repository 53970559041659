import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookie } from "react-use";

import { PageLoadingSpinner } from "@hire/components/PageLoadingSpinner";

export function Logout(): React.ReactElement {
  const [csrf] = useCookie(import.meta.env.VITE_CSRF_COOKIE);
  const client = useApolloClient();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${import.meta.env.VITE_API_HOST}/auth/logout`, {
      method: "POST",
      credentials: "include",
      headers: { ...(csrf ? { "X-CSRF-Token": csrf } : {}) },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Something went wrong");
        }

        return client.clearStore();
      })
      .then(() => navigate("/", { replace: true }))
      .catch(error => {
        Sentry.captureException(error);

        navigate(-1);
      });
  }, [client, navigate, csrf]);

  return <PageLoadingSpinner />;
}
