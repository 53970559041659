import { Button, Middle, Spacing, Text } from "@otta/design";
import { Link } from "@hire/components/links/Link";
import { useWelcomeToTheJungle } from "@hire/hooks/useWelcomeToTheJungle";

export function NotFound(): React.ReactElement {
  const companyName = useWelcomeToTheJungle();

  return (
    <Middle>
      <Spacing size={4}>
        <Spacing size={2}>
          <Text as="h5" size={1} bold align="left">
            We don&apos;t have an ATS linked to your account right now
          </Text>
          <Text align="left">
            If you use an Applicant Tracking System (ATS), we may be able to add
            your roles automatically.
          </Text>
          <Text align="left">
            Integrating your ATS with {companyName} gives you clearer applicant
            attribution and reduces friction so that more matched candidates
            apply.
          </Text>
          <Text align="left">
            Complete this form to let us know if you use an ATS.
          </Text>
          <Text align="left">
            <Button
              style={{ textDecoration: "none" }}
              level="primary"
              as={Link}
              to="https://form.typeform.com/to/Lxg4Qa0T"
            >
              Complete form
            </Button>
          </Text>
        </Spacing>
      </Spacing>
    </Middle>
  );
}
