import styled, { css, up } from "@xstyled/styled-components";

import { palette, modularScale } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Heading } from "@otta/design";

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  background-color: ${palette.brand.yellow};
  padding: 0 sm ${modularScale(-14)} xs;
  border-radius: ${modularScale()};
  flex-shrink: 0;
  overflow: hidden;

  ${up(
    "tablet",
    css`
      padding: 0 md ${modularScale(-14)} ${modularScale(-11)};
    `
  )}
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3;

  ${up(
    "tablet",
    css`
      width: ${modularScale(3)};
    `
  )};

  svg {
    width: ${modularScale(-1)};
    color: ${({ color }) => color};
    margin-right: xs;

    ${up(
      "tablet",
      css`
        width: ${modularScale(1)};
        margin-right: 0;
      `
    )};
  }
`;

const StyledHeading = styled(Heading)`
  font-size: ${modularScale(-3)};
  ${up(
    "tablet",
    css`
      font-size: ${modularScale(-2)};
    `
  )};
`;

export function OttaCertifiedBadge({
  content,
}: {
  content: string;
}): React.ReactElement {
  return (
    <Container data-testid="otta-certified-badge">
      <IconWrapper>
        <Icon icon="certified" size={1} />
      </IconWrapper>
      <StyledHeading bold>{content}</StyledHeading>
    </Container>
  );
}
