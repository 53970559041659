import styled from "@xstyled/styled-components";

import { ApolloIAPProvider } from "@otta/shared-components";
import { palette } from "@otta/design-tokens";
import { Button, Text } from "@otta/design";

const Banner = styled.div`
  background: ${palette.brand.yellow};
  padding: lg;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface IAPRefresherProps {
  children: React.ReactNode;
}

export function IdentityProxy({
  children,
}: IAPRefresherProps): React.ReactElement {
  if (import.meta.env.MODE === "staging") {
    return (
      <ApolloIAPProvider uri={`${import.meta.env.VITE_API_HOST}/graphql`}>
        {renderProps => (
          <>
            {!renderProps.authenticated && (
              <Banner>
                <Text bold size={1}>
                  You&apos;re disconnected from the API!
                </Text>
                <Button
                  size="small"
                  level="secondary"
                  onClick={renderProps.onReauthenticate}
                >
                  Reauthenticate
                </Button>
              </Banner>
            )}
            {children}
          </>
        )}
      </ApolloIAPProvider>
    );
  }

  return <>{children}</>;
}
