import { useLocation, useOutlet, useSearchParams } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";
import { useQuery } from "@apollo/client";
import { useCallback, useEffect } from "react";

import { InboxSideBar } from "./InboxSidebar";
import { ConversationsList } from "./ConversationsList";
import { InboxProvider } from "./Context";

import { palette } from "@otta/design-tokens";
import { AllConversationsDocument } from "@hire/schema";
import { useTabVisible } from "@hire/util/hooks/useTabVisible";
import { hireAppUser } from "@hire/util/user";
import { useRequiredParams } from "@hire/util/routing";
import { Loading } from "@otta/shared-components";

const PageContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: ${palette.brand.white};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "top"
    "content"
    "message-input";

  ${up(
    "tablet",
    css`
      grid-template-columns: 350px 1fr;
      grid-template-areas:
        "left top"
        "left content"
        "left message-input";
    `
  )}
`;

const POLL_INTERVAL = 15000;

export function Inbox() {
  const outlet = useOutlet();

  const { jobId } = useRequiredParams(["jobId"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const showArchived = searchParams.get("archived") === "true";
  const showFavourites = searchParams.get("favourite") === "true";
  const showApplications = searchParams.get("applied") === "true";
  const showSourced = searchParams.get("sourced") === "true";
  const filterSender = searchParams.get("sender") === "me";
  const filterAwaitingResponse = searchParams.get("unreplied") === "true";

  const { data, loading, fetchMore, startPolling, stopPolling, refetch } =
    useQuery(AllConversationsDocument, {
      fetchPolicy: "network-only",
      pollInterval: POLL_INTERVAL,
      variables: {
        jobId: jobId as string,
        sourced: showSourced,
        applied: showApplications,
        favourites: showFavourites,
        onlyArchivedMessages: !!showArchived,
        allMessages: !filterSender,
        onlyAwaitingResponse: filterAwaitingResponse,
        offset: 0,
        limit: 15,
      },
    });

  const visible = useTabVisible();

  useEffect(() => {
    if (visible) {
      startPolling(POLL_INTERVAL);
    } else {
      stopPolling();
    }
  }, [visible, startPolling, stopPolling]);

  useEffect(() => {
    refetch();
  }, [location.pathname, refetch]);

  const user = hireAppUser(data?.me);

  const removeFilters = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  const fetchMoreConversations = useCallback(async () => {
    await fetchMore({
      variables: {
        offset: user?.companyConversations.length ?? 0,
        limit: 15,
      },
    });
  }, [fetchMore, user?.companyConversations.length]);

  return (
    <PageContainer>
      <InboxSideBar
        open={!outlet}
        showSourced={showSourced}
        filterAwaitingResponse={filterAwaitingResponse}
        filterSender={filterSender}
        showApplications={showApplications}
        showArchived={showArchived}
      >
        {loading ? (
          <Loading />
        ) : (
          <ConversationsList
            conversations={user?.companyConversations ?? []}
            company={user?.currentCompany ?? null}
            fetchMore={fetchMoreConversations}
            removeFilters={removeFilters}
          />
        )}
      </InboxSideBar>

      <InboxProvider refetch={refetch}>{outlet}</InboxProvider>
    </PageContainer>
  );
}
