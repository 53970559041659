import { JobWorkflowStatus } from "@hire/schema";

/**
 * For useJobList - fetch both paused + non paused jobs
 * or only paused ones, if you're on Starter
 */
export enum JobListMode {
  ONLY_PAUSED_JOBS = "only_paused",
  ALL_JOBS = "all",
}

/**
 * Simplified job workflow statuses used as filters on the dashboard
 * Unpublished is only for paused jobs, as they're not exactly published
 */
export enum JobListStatus {
  PUBLISHED = "published",
  UNPUBLISHED = "unpublished",
  ARCHIVED = "archived",
  REVIEW = "review",
  DRAFT = "draft",
}

/**
 * Live isn't really the right word here, but like should we show
 * you unpublished or published jobs for the given mode
 */
export function liveStatus(mode: JobListMode): JobListStatus {
  return mode === JobListMode.ONLY_PAUSED_JOBS
    ? JobListStatus.UNPUBLISHED
    : JobListStatus.PUBLISHED;
}

export function fromQuery(
  str: string | null,
  mode: JobListMode
): JobListStatus {
  if (str && Object.values(JobListStatus).includes(str as JobListStatus)) {
    return str as JobListStatus;
  } else if (mode === JobListMode.ONLY_PAUSED_JOBS) {
    return JobListStatus.UNPUBLISHED;
  } else {
    return JobListStatus.PUBLISHED;
  }
}

export function toWorkflowStatuses(jls: JobListStatus): JobWorkflowStatus[] {
  switch (jls) {
    case JobListStatus.PUBLISHED:
      return [JobWorkflowStatus.Completed, JobWorkflowStatus.Changed];
    case JobListStatus.UNPUBLISHED:
      return [JobWorkflowStatus.Completed, JobWorkflowStatus.Changed];
    case JobListStatus.REVIEW:
      return [JobWorkflowStatus.QaInternal, JobWorkflowStatus.QaExternal];
    case JobListStatus.DRAFT:
      return [JobWorkflowStatus.RecruiterDraft];
    case JobListStatus.ARCHIVED:
      return [JobWorkflowStatus.Archived];
  }
}
