import { Outlet } from "react-router-dom";

import { Tabs } from "./Tabs";

import { Text, Spacing } from "@otta/design";

export const Templates = () => {
  return (
    <Spacing size={2}>
      <Text size={2} bold role="heading">
        Message templates
      </Text>
      <Tabs />
      <Outlet />
    </Spacing>
  );
};
