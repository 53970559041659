import { useQuery } from "@apollo/client";
import styled from "@xstyled/styled-components";
import { useEffect } from "react";

import { OldQuestionResponses, QuestionResponse } from "./OldQuestionResponses";
import { NewQuestionResponses, answerValue } from "./NewQuestionResponses";

import { JobApplicationQuestionAnswersDocument } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { Middle, Card } from "@otta/design";

const StyledCard = styled(Card)`
  background-color: ${palette.grayscale.shade50};
`;

interface QuestionResponsesProps {
  questionResponses: QuestionResponse[];
  jobApplicationId: string;
}

export const QuestionResponses = ({
  questionResponses,
  jobApplicationId,
}: QuestionResponsesProps): React.ReactElement | null => {
  const { data, refetch: refetchJobAppQuestions } = useQuery(
    JobApplicationQuestionAnswersDocument,
    {
      variables: { id: jobApplicationId },
    }
  );

  useEffect(() => {
    refetchJobAppQuestions();
  }, [jobApplicationId, refetchJobAppQuestions]);

  const newSections = data?.jobApplication?.atsQuestions.sections;
  const newQuestions = newSections?.flatMap(s => s.questions.map(q => q));
  const hasNewAnswers = newQuestions?.some(
    q => q.__typename !== "AtsInformation" && answerValue(q) !== null
  );

  const hasOldQuestionResponses = !!questionResponses?.length;

  if (!hasOldQuestionResponses && !hasNewAnswers) {
    return null;
  }

  return (
    <Middle maxWidth={690} data-testid="question-responses-card-new">
      <StyledCard>
        {questionResponses && hasOldQuestionResponses ? (
          <OldQuestionResponses questionResponses={questionResponses} />
        ) : data && hasNewAnswers ? (
          <NewQuestionResponses jobApplication={data.jobApplication} />
        ) : null}
      </StyledCard>
    </Middle>
  );
};
