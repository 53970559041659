import { useState } from "react";
import styled from "@xstyled/styled-components";

import { NonNullDisableableStage } from "../../../types";
import { TruncatedStageName } from "../TruncatedStageName";

import { EditMode } from "./EditMode";

import { StageType } from "@hire/schema";
import { Text } from "@otta/design";

const TitleWrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

const Count = styled(Text)`
  margin-left: md;
  margin-right: md;
`;

interface StageNameProps {
  jobExternalId: string;
  jobInternalId: string;
  stage: NonNullDisableableStage;
}

export const StageName = ({
  jobExternalId,
  jobInternalId,
  stage,
}: StageNameProps): React.ReactElement => {
  const [inEditMode, setInEditMode] = useState(false);

  if (inEditMode) {
    return (
      <EditMode
        stage={stage}
        jobExternalId={jobExternalId}
        jobInternalId={jobInternalId}
        onFinished={() => setInEditMode(false)}
      />
    );
  }

  return (
    <TitleWrapper>
      <TruncatedStageName
        stageName={stage.name}
        onClick={
          stage.stageType === StageType.Custom
            ? () => setInEditMode(true)
            : undefined
        }
      />
      <Count>{stage.candidatePipelineItems?.length ?? 0}</Count>
    </TitleWrapper>
  );
};
