export function alphabeticalSortByKey<K extends string>(key: K) {
  return (a: { [key in K]: string }, b: { [key in K]: string }): number =>
    a[key].localeCompare(b[key]);
}

/**
 * Return a copy of `unordered` with the keys in alphabetical order.
 */
export function alphabeticallySortKeys<T>(
  unordered: Record<string, T>
): Record<string, T> {
  return Object.keys(unordered)
    .sort()
    .reduce((acc, key) => {
      acc[key] = unordered[key];
      return acc;
    }, {} as Record<string, T>);
}
