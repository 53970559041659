import { useQuery } from "@apollo/client";
import { captureException } from "@sentry/browser";
import { createContext, useCallback, useContext, useMemo, useRef } from "react";
import * as Yup from "yup";

import { CurrentUserDocument } from "@hire/schema";
import { hireAppUser } from "@hire/util/user";

interface MessageStorageContext {
  getStoredMessage: (jobId: string, candidateId: string) => string | null;
  setStoredMessage: (jobId: string, candidateId: string, value: string) => void;
  clearStoredMessage: (jobId: string, candidateId: string) => void;
}

const Context = createContext<MessageStorageContext>({
  getStoredMessage: noop => noop,
  setStoredMessage: noop => noop,
  clearStoredMessage: noop => noop,
});

function mapValues<T extends { [key: string]: any }, F>(
  obj: T,
  fn: (val: T[keyof T]) => F
) {
  return Object.keys(obj).reduce<Record<string, F>>((acc, key) => {
    acc[key] = fn(obj[key]);
    return acc;
  }, {});
}

const schema = Yup.lazy(o =>
  Yup.object(
    mapValues(o, () =>
      Yup.object({
        content: Yup.string().required(),
        expiresAt: Yup.number().required(),
      })
    )
  )
);

type Repo = Yup.InferType<typeof schema>;

export function MessageStorage({ children }: { children: React.ReactNode }) {
  const { data } = useQuery(CurrentUserDocument);

  const userId = hireAppUser(data?.me)?.externalId;

  const fromStorage = useMemo<Repo>(() => {
    const value = localStorage.getItem("repo:messages");

    if (!value) {
      return {};
    }

    try {
      return schema.validateSync(JSON.parse(value));
    } catch (e) {
      captureException(e);
      return {};
    }
  }, []);

  const repo = useRef(fromStorage);

  const generateKey = useCallback(
    (jobId: string, candidateId: string) =>
      userId ? `${userId}:${jobId}:${candidateId}` : null,
    [userId]
  );

  const timer = useRef<number>();

  const writeRepo = useCallback(() => {
    window.clearTimeout(timer.current);

    timer.current = window.setTimeout(() => {
      const now = Date.now();

      const filtered = Object.entries(repo.current).reduce<Repo>(
        (acc, [key, value]) => {
          if (value.expiresAt > now) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      try {
        const toWrite = schema.validateSync(filtered);

        localStorage.setItem("repo:messages", JSON.stringify(toWrite));
      } catch (e) {
        captureException(e);
      }
    }, 500);
  }, []);

  const getStoredMessage = useCallback(
    (jobId: string, candidateId: string) => {
      const key = generateKey(jobId, candidateId);

      if (!key) {
        return null;
      }

      const value = repo.current?.[key];

      if (value && value.expiresAt > Date.now()) {
        return value.content;
      }

      return null;
    },
    [generateKey]
  );

  const setStoredMessage = useCallback(
    (jobId: string, candidateId: string, value: string) => {
      const key = generateKey(jobId, candidateId);
      if (key) {
        repo.current[key] = {
          content: value,
          expiresAt: Date.now() + 1000 * 60 * 60 * 24,
        };

        writeRepo();
      }
    },
    [generateKey, writeRepo]
  );

  const clearStoredMessage = useCallback(
    (jobId: string, candidateId: string) => {
      const key = generateKey(jobId, candidateId);

      if (key) {
        delete repo.current[key];
        writeRepo();
      }
    },
    [generateKey, writeRepo]
  );

  return (
    <Context.Provider
      value={{ getStoredMessage, setStoredMessage, clearStoredMessage }}
    >
      {children}
    </Context.Provider>
  );
}

export function useMessageStorage() {
  return useContext(Context);
}
