import styled, { css, up } from "@xstyled/styled-components";

import { StageOption } from "../../types";

import { BulkStageSelectInput } from "./BulkStageSelectInput";
import { PipelineButtons } from "./PipelineButtons";

const Container = styled.div`
  display: flex;

  ${up(
    "desktop",
    css`
      max-width: 75vw;
    `
  )}
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: lg;

  ${up(
    "desktop",
    css`
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      min-width: 100%;
    `
  )}
`;

export const PipelineHeader = ({
  externalJobId,
  showMoveDropdown,
  bulkStageOptions,
  onBulkMoveItems,
  selectedBulkStageOption,
}: {
  externalJobId: string | undefined;
  showMoveDropdown: boolean;
  bulkStageOptions: StageOption[];
  selectedBulkStageOption: StageOption;
  onBulkMoveItems: (selectedOption: StageOption | null) => void;
}): React.ReactElement => {
  return (
    <Container>
      <FlexWrapper>
        {externalJobId && <PipelineButtons externalJobId={externalJobId} />}
        {showMoveDropdown && (
          <BulkStageSelectInput
            bulkStageOptions={bulkStageOptions}
            onBulkMoveItems={onBulkMoveItems}
            selectedBulkStageOption={selectedBulkStageOption}
          />
        )}
      </FlexWrapper>
    </Container>
  );
};
