import * as React from "react";
import type { SVGProps } from "react";
const SvgHouse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M21.705 11.075a.85.85 0 0 1 .097 1.189.825.825 0 0 1-1.173.098l-.851-.731v6.556C19.778 19.741 18.535 21 17 21H7c-1.534 0-2.778-1.259-2.778-2.813v-6.556l-.85.731a.826.826 0 0 1-1.174-.098.85.85 0 0 1 .096-1.189L11.462 3.2a.823.823 0 0 1 1.076 0l9.167 7.875ZM7 19.313h1.667v-5.344c0-.777.621-1.406 1.389-1.406h3.889c.767 0 1.388.629 1.388 1.406v5.344H17c.615 0 1.111-.503 1.111-1.125V10.2l-6.11-5.25-6.112 5.25v7.988c0 .622.496 1.125 1.111 1.125Zm3.333 0h3.334V14.25h-3.334v5.063Z"
    />
  </svg>
);
export default SvgHouse;
