import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@xstyled/styled-components";

import { useCurrentStage } from "../hooks/useCurrentStage";
import { useJobInternalId } from "../hooks/useJobInternalId";
import { usePipelineItem } from "../hooks/usePipelineItem";
import { useStages } from "../hooks/useStages";

import { DetailsForm } from "./DetailsForm";
import { EmailForm } from "./EmailForm";
import { transformOut } from "./transformers";

import { handleMutationError } from "@hire/errors";
import {
  CreateImportedCandidateDocument,
  CurrentUserDocument,
  FindImportedCandidateDocument,
  ImportedCandidateInput,
  UpdateImportedCandidateDocument,
} from "@hire/schema";
import { Loading } from "@otta/shared-components";
import { modularScale } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Text, VerticalSpacing } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useRequiredParams } from "@hire/util/routing";
import { hireAppUser } from "@hire/util/user";

const CloseButton = styled.button`
  display: flex;
  background: none;
  border: none;
  padding: 0;
  font-size: ${modularScale()};
  cursor: pointer;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddNewCandidate = (): React.ReactElement => {
  const navigate = useNavigate();
  const { jobId: jobExternalId } = useRequiredParams(["jobId"]);
  const { data: currentUserData } = useQuery(CurrentUserDocument);
  const me = hireAppUser(currentUserData?.me);

  const { stages, loading: stagesLoading } = useStages(jobExternalId);

  const [emailAddress, setEmailAddress] = useState<string | null>(null);

  const [findEmailAddress, { data, loading: findEmailLoading }] = useLazyQuery(
    FindImportedCandidateDocument,
    {
      fetchPolicy: "network-only",
    }
  );

  const { loading: jobIdLoading, jobInternalId } =
    useJobInternalId(jobExternalId);

  const { pipelineItem } = usePipelineItem({
    jobId: jobExternalId,
    candidateId: data?.findImportedCandidate?.externalId ?? "",
  });

  const { loading: currentStageLoading, currentStage } = useCurrentStage(
    jobExternalId,
    pipelineItem?.id ?? ""
  );

  const [addCandidate] = useMutation(CreateImportedCandidateDocument, {
    onError: handleMutationError,
  });

  const [updateCandidate] = useMutation(UpdateImportedCandidateDocument, {
    onError: handleMutationError,
  });

  const handleCheckEmail = ({ emailAddress }: { emailAddress: string }) => {
    findEmailAddress({ variables: { emailAddress: emailAddress } });
    setEmailAddress(emailAddress);
  };

  const handleCreateOrUpdateCandidate = (input: ImportedCandidateInput) => {
    if (!data?.findImportedCandidate) {
      addCandidate({
        variables: { input, workExperienceLimit: 1 },
      });
    } else {
      updateCandidate({
        variables: { input, workExperienceLimit: 1 },
      });
    }

    pushAnalyticsEvent({
      eventName: "Company Recruiter completed add candidate experience",
      companyId: me?.currentCompany?.externalId,
      jobId: jobExternalId,
      type: !data?.findImportedCandidate ? "Creating" : "Updating",
    });

    navigate("..");
  };

  const loading =
    findEmailLoading || stagesLoading || jobIdLoading || currentStageLoading;

  return (
    <VerticalSpacing size={3}>
      <HeadingContainer data-testid="create-candidate-drawer">
        <Text size={3} bold>
          Add a candidate
        </Text>

        <CloseButton
          data-testid="close-button"
          onClick={() => {
            navigate("..");
          }}
        >
          <Icon icon="close" />
        </CloseButton>
      </HeadingContainer>

      {loading ? (
        <div>
          <Loading />
        </div>
      ) : !emailAddress ? (
        <EmailForm onSubmit={handleCheckEmail} />
      ) : (
        <DetailsForm
          emailAddress={emailAddress}
          onSubmit={input =>
            handleCreateOrUpdateCandidate(
              transformOut(input, emailAddress, jobInternalId)
            )
          }
          importedCandidate={data?.findImportedCandidate ?? null}
          stages={stages}
          currentStage={currentStage}
        />
      )}
    </VerticalSpacing>
  );
};
