import styled from "@xstyled/styled-components";

import { palette, pxToRem, borderRadius } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Card as DefaultCard, Text, Spacing } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { ConditionalLink } from "@hire/components/links/ConditionalLink";
import { Warning } from "@hire/components/icons/checklist/Warning";
import { Checked } from "@hire/components/icons/checklist/Checked";
import { Unchecked } from "@hire/components/icons/checklist/Unchecked";

const Card = styled(DefaultCard)`
  margin: 0 auto;
  width: 100%;
  padding: 0;
`;

const OttaCertifiedWrapper = styled.div`
  margin-top: -7;
  left: ${pxToRem(6)};
  top: 0;
  position: absolute;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: lg;
  border-radius: ${pxToRem(borderRadius)} ${pxToRem(borderRadius)} 0 0;
  background-color: ${palette.brand.yellow};
  overflow: hidden;
  padding-left: 60;
`;

const IconWrapper = styled.div`
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
  align-items: center;
  margin-right: md;
`;

const InfoIconWrapper = styled.div`
  height: ${pxToRem(16)};
  width: ${pxToRem(16)};
  cursor: pointer;
`;

const Content = styled.div`
  padding: lg;
`;

const ListItem = styled.div<{ clickable?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: ${pxToRem(8)};
  background-color: ${palette.grayscale.shade50};
  padding: lg sm;
  border-bottom: ${pxToRem(4)} solid transparent;

  ${({ clickable }) =>
    clickable &&
    `
      &:hover {
        background-color: ${palette.beige.shade100};
      }`};
`;

interface ChecklistProps {
  title: string;
  dataTestId?: string;
  items: {
    title: string;
    checked: boolean;
    warning?: boolean;
    path?: string;
    testId: string;
    dataAnalyticsId: string;
  }[];
  showOttaCertifiedModal: () => void;
}

export function Checklist({
  title,
  dataTestId,
  items,
  showOttaCertifiedModal,
}: ChecklistProps): React.ReactElement {
  return (
    <Card data-testid={dataTestId}>
      <Header>
        <OttaCertifiedWrapper>
          <Icon icon="certified" data-testid="otta-certified-icon" size={7} />
        </OttaCertifiedWrapper>
        <Text bold>{title}</Text>
        <InfoIconWrapper
          onClick={() => {
            pushAnalyticsEvent({
              eventName: "Company Recruiter Clicked Info Icon Within OC Module",
            });
            showOttaCertifiedModal();
          }}
        >
          <Icon icon="circle-info" data-testid="info-icon" size={2} />
        </InfoIconWrapper>
      </Header>
      <Content>
        <Spacing size={-2}>
          {items.map(item => (
            <ConditionalLink
              key={item.title}
              link={item.path as string}
              condition={!!item.path && !item.checked}
            >
              <ListItem
                key={item.title}
                data-testid={item.testId}
                clickable={!!item.path && !item.checked}
              >
                <IconWrapper>
                  {item.warning ? (
                    <Warning data-testid="warning" />
                  ) : item.checked ? (
                    <Checked data-testid="checked" />
                  ) : (
                    <Unchecked data-testid="unchecked" />
                  )}
                </IconWrapper>
                <Text>{item.title}</Text>
              </ListItem>
            </ConditionalLink>
          ))}
        </Spacing>
      </Content>
    </Card>
  );
}
