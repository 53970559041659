import { Divider } from "../../common/Divider";
import { IconList, IconListItem } from "../../common/IconList";
import { Subheading } from "../../common/Subheading";
import { SectionProps } from "../../common/types";

import { Link } from "@hire/components/links/Link";
import { Spacing } from "@otta/design";

export function CompanyArticles({
  company: { articles },
}: SectionProps): React.ReactElement | null {
  return articles.length > 0 ? (
    <Spacing size={0}>
      <Divider />
      <Subheading>Articles</Subheading>
      <IconList>
        {articles.map((article, index) => (
          <IconListItem icon="article">
            <Link
              key={index}
              style={{ fontWeight: "normal" }}
              to={article.url}
              newTab
            >
              {article.title}
            </Link>
          </IconListItem>
        ))}
      </IconList>
    </Spacing>
  ) : null;
}
