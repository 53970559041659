import styled from "@xstyled/styled-components";

import { QuizHeadingWithSteps } from "./QuizHeadingWithSteps";
import { ResponsiveButtonContainer } from "./components/ResponsiveButtonContainer";

import { Button, Spacing } from "@otta/design";
import { modularScale, pxToRem } from "@otta/design-tokens";
import { Info } from "@hire/components/Banner";

const StyledList = styled.ul`
  padding-left: ${modularScale(1.5)};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: lg;
  max-width: ${pxToRem(444)};
`;

const SimpleButton = styled(Button)`
  width: ${pxToRem(200)};
`;

export function YesNoComponent({
  title,
  infoContent,
  bullets,
  onYesClick,
  onNoClick,
  step,
}: {
  title: string;
  infoContent?: React.ReactElement;
  bullets?: string[];
  onYesClick: () => void;
  onNoClick: () => void;
  step: number;
}) {
  return (
    <Spacing size={2}>
      <QuizHeadingWithSteps step={step}>{title}</QuizHeadingWithSteps>
      {infoContent && <Info>{infoContent}</Info>}
      {bullets && (
        <StyledList>
          <Spacing size={-2}>
            {bullets.map((bullet, index) => (
              <li key={index}>{bullet}</li>
            ))}
          </Spacing>
        </StyledList>
      )}
      <ResponsiveButtonContainer>
        <ButtonContainer>
          <SimpleButton
            data-analytics-id="quiz-yes"
            data-testid="quiz-yes-button"
            onClick={onYesClick}
            level="secondary"
          >
            Yes
          </SimpleButton>
          <SimpleButton
            data-analytics-id="quiz-no"
            data-testid="quiz-no-button"
            onClick={onNoClick}
            level="secondary"
          >
            No
          </SimpleButton>
        </ButtonContainer>
      </ResponsiveButtonContainer>
    </Spacing>
  );
}
