import * as Switch from "@radix-ui/react-switch";
import styled, { css } from "@xstyled/styled-components";

import { Text } from "./Typography";

import { palette, pxToRem } from "@otta/design-tokens";

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled(Text)<{ disabled?: boolean }>`
  margin-right: lg;
  font-weight: bold;
  color: ${({ disabled }) =>
    disabled ? palette.grayscale.shade600 : palette.brand.black};
`;

const Track = styled(Switch.Root)<{
  checked: boolean;
  $smallToggle?: boolean;
  disabled?: boolean;
}>`
  all: unset;
  width: ${({ $smallToggle }) => ($smallToggle ? pxToRem(28) : pxToRem(40))};
  height: ${({ $smallToggle }) => ($smallToggle ? pxToRem(16) : pxToRem(20))};
  background-color: ${({ disabled, checked }) =>
    disabled
      ? checked
        ? palette.extended.green.shade100
        : palette.grayscale.shade200
      : checked
      ? palette.extended.green.shade500
      : palette.grayscale.shade400};

  border-radius: ${({ $smallToggle }) =>
    $smallToggle ? pxToRem(16) : pxToRem(50)};
  padding: ${({ $smallToggle }) => ($smallToggle ? pxToRem(2) : pxToRem(4))};
  padding-left: ${({ $smallToggle }) => $smallToggle && pxToRem(2)};
  position: relative;
  webkittaphighlightcolor: rgba(0, 0, 0, 0);
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const Handle = styled(Switch.Thumb)<{
  checked: boolean;
  $smallToggle?: boolean;
  translateby: number;
  disabled?: boolean;
}>`
  display: block;
  width: ${({ $smallToggle }) => ($smallToggle ? pxToRem(16) : pxToRem(20))};
  height: ${({ $smallToggle }) => ($smallToggle ? pxToRem(16) : pxToRem(20))};
  background-color: ${({ disabled }) =>
    disabled ? palette.grayscale.shade50 : palette.brand.white};
  border-radius: ${pxToRem(40)};

  transition: default;
  transition-property: transform;

  will-change: transform;
  ${({ checked, translateby }) =>
    checked &&
    css`
      transform: translateX(${pxToRem(translateby)});
    `}
`;

export interface IToggleProps {
  onChange: (value: boolean) => void;
  label?: string;
  checked: boolean;
  smallToggle?: boolean;
  disabled?: boolean;
}

/**
 * Toggles can be used to switch a variable between an 'on' and an 'off' state. They can be either be the default size, or small.
 *
 * You can also add an optional label, using the `label` prop.
 *
 * ```tsx
 *
 * import { Toggle } from '@otta/design'
 *
 * ```
 *
 *
 */

export const Toggle = ({
  onChange: handleChange,
  label,
  checked,
  smallToggle,
  disabled,
  ...props
}: IToggleProps) => {
  return (
    <ToggleWrapper {...props}>
      {label && <Label disabled={disabled}>{label}</Label>}
      <Track
        checked={checked}
        onCheckedChange={(checked: boolean) => handleChange(checked)}
        $smallToggle={smallToggle}
        disabled={disabled}
      >
        <Handle
          checked={checked}
          $smallToggle={smallToggle}
          translateby={smallToggle ? 12 : 20}
          disabled={disabled}
        />
      </Track>
    </ToggleWrapper>
  );
};
