import styled from "@xstyled/styled-components";

import { pxToRem } from "@otta/design-tokens";
import { VerticalSpacing, Text, Card as DefaultCard } from "@otta/design";
import { ResponsiveCenteredContainer } from "@hire/components/containers/ResponsiveCenteredContainer";

const Container = styled(ResponsiveCenteredContainer)`
  max-width: ${pxToRem(1200)};
  text-align: left;
`;

const Card = styled(DefaultCard)`
  position: relative;
  padding: 0;
  overflow: hidden;
  padding: lg;
`;

export const JobBroadcastAlreadySent = () => {
  return (
    <Container>
      <Card>
        <VerticalSpacing size={1}>
          <Text size={1} bold>
            Someone has broadcast for this job recently
          </Text>
          <Text>
            Sorry, someone at your company has already broadcast for this job
            while you were drafting your message, and no candidates have saved
            it since then.
          </Text>
          <Text>
            You can send another broadcast when new candidates save the job.
          </Text>
        </VerticalSpacing>
      </Card>
    </Container>
  );
};
