import { SectionProps } from "../../common/types";

import { List, Spacing, Text } from "@otta/design";

export function JobRequirements({
  fields: { requirements, desirableRequirements },
}: SectionProps): React.ReactElement {
  return requirements.some(v => !!v.value) ? (
    <Spacing size={0}>
      {requirements.length > 0 && (
        <List type="bullet">
          {requirements.map(({ value }, i) => (
            <li key={i}>{value}</li>
          ))}
        </List>
      )}
      {desirableRequirements.some(v => !!v.value) && (
        <Spacing size={0}>
          <Text size={0} bold as={"h3"}>
            Desirable
          </Text>
          <List type="bullet">
            {desirableRequirements.map(({ value }, i) => (
              <li key={i}>{value}</li>
            ))}
          </List>
        </Spacing>
      )}
    </Spacing>
  ) : (
    <List type="bullet">
      <li key={0}>[List of requirements]</li>
    </List>
  );
}
