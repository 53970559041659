import { useSearchParams } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";

import { AppliedSourcedDate } from "./AppliedSourceDate";
import { TimeInStage } from "./TimeInStage";

import { CompanyConversationQuery } from "@hire/schema";
import { TabLink } from "@hire/components/links";
import { pxToRem } from "@otta/design-tokens";

const NavContainer = styled.div<{ justifyFlexEnd: boolean }>`
  display: flex;
  justify-content: ${({ justifyFlexEnd }) =>
    justifyFlexEnd ? "end" : "space-between"};
  flex-direction: column-reverse;
  margin: 0;

  div:nth-child(2) {
    margin-bottom: lg;
  }

  ${up(
    "tablet",
    css`
      flex-direction: row;
      margin: sm 0;
      align-items: center;

      div:nth-child(2) {
        margin-bottom: 0;
      }
    `
  )};
`;

const LinksContainer = styled.div`
  display: flex;
  width: ${pxToRem(230)};
  gap: 40;
`;

const StageInfoContainer = styled.div`
  display: flex;
`;

export const TabRow = ({
  pipelineItem,
  stageName,
  appliedAt,
  sourcedAt,
  conversation,
  showConversationTab,
}: {
  pipelineItem: NonNullable<CompanyConversationQuery["getJobPipelineItem"]>;
  stageName: string;
  appliedAt?: string;
  sourcedAt?: string;
  conversation: boolean;
  showConversationTab: boolean;
}): React.ReactElement => {
  const [searchParams] = useSearchParams();

  return (
    <NavContainer justifyFlexEnd={!!pipelineItem.importedCandidate}>
      {pipelineItem.candidate && (
        <LinksContainer>
          <TabLink
            to={{ pathname: "profile", search: searchParams.toString() }}
            data-testid="profile-tab"
          >
            Profile
          </TabLink>
          {showConversationTab && conversation && (
            <TabLink
              to={{
                pathname: "conversation",
                search: searchParams.toString(),
              }}
              data-testid="conversation-tab"
            >
              Conversation
            </TabLink>
          )}
          <TabLink
            to={{ pathname: "notes", search: searchParams.toString() }}
            data-testid="notes-tab"
          >
            Notes
          </TabLink>
        </LinksContainer>
      )}
      <StageInfoContainer>
        <AppliedSourcedDate
          pipelineItem={pipelineItem}
          appliedAt={appliedAt}
          sourcedAt={sourcedAt}
        />
        <TimeInStage
          stageName={stageName}
          movedToStageAt={pipelineItem.movedToStageAt}
        />
      </StageInfoContainer>
    </NavContainer>
  );
};
