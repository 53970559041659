import { useJobEditField } from "../../useHandlers";

import { Textarea } from "@hire/components/input/Textarea";
import { FieldWrapper } from "@otta/design";

export function RemoteInfo() {
  const [formik, meta] = useJobEditField("locationInformation");

  return (
    <FieldWrapper
      fieldError={meta.touched ? meta.error : undefined}
      label="Other hybrid or remote working information"
    >
      {({ field }) => <Textarea minRows={3} {...formik} {...field} />}
    </FieldWrapper>
  );
}
