import { CandidateEducationExperience } from "./CandidateEducationExperience";

import { CandidateCardFragment } from "@hire/schema";
import { Text } from "@otta/design";
import { Separator } from "@hire/components/Separator";

interface CandidateEducationExperiencesProps {
  candidate: CandidateCardFragment;
}

export function CandidateEducationExperiences({
  candidate,
}: CandidateEducationExperiencesProps): React.ReactElement | null {
  if (candidate.educationExperiences.length === 0) {
    return null;
  }

  return (
    <>
      <Separator />
      <Text size={1}>Education</Text>
      {candidate.educationExperiences.map(eduExp => (
        <CandidateEducationExperience
          key={eduExp.id}
          educationExperience={eduExp}
        />
      ))}
    </>
  );
}
