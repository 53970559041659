export function Kanban(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg {...props} viewBox="0 0 14 15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.12003 8.45978C3.72103 8.45978 3.40003 8.13878 3.40003 7.73979V4.37979C3.40003 3.98079 3.72103 3.65979 4.12003 3.65979C4.51903 3.65979 4.84003 3.98079 4.84003 4.37979V7.73979C4.84003 8.13878 4.51903 8.45978 4.12003 8.45978ZM9.16003 4.37979C9.16003 3.98079 9.48103 3.65979 9.88003 3.65979C10.279 3.65979 10.6 3.98079 10.6 4.37979V9.65979C10.6 10.0588 10.279 10.3798 9.88003 10.3798C9.48103 10.3798 9.16003 10.0588 9.16003 9.65979V4.37979ZM7.00003 7.49978C6.60103 7.49978 6.28003 7.17878 6.28003 6.77979V4.37979C6.28003 3.98079 6.60103 3.65979 7.00003 3.65979C7.39903 3.65979 7.72003 3.98079 7.72003 4.37979V6.77979C7.72003 7.17878 7.39903 7.49978 7.00003 7.49978ZM0.280029 2.69979C0.280029 1.63929 1.13953 0.779785 2.20003 0.779785H11.8C12.859 0.779785 13.72 1.63929 13.72 2.69979V12.2998C13.72 13.3588 12.859 14.2198 11.8 14.2198H2.20003C1.13953 14.2198 0.280029 13.3588 0.280029 12.2998V2.69979ZM2.20003 12.7798H11.8C12.064 12.7798 12.28 12.5638 12.28 12.2998V2.69979C12.28 2.43459 12.064 2.21979 11.8 2.21979H2.20003C1.93483 2.21979 1.72003 2.43459 1.72003 2.69979V12.2998C1.72003 12.5638 1.93483 12.7798 2.20003 12.7798Z"
        fill="currentColor"
      />
    </svg>
  );
}
