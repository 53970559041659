import { SectionProps } from "../../common/types";

import { Year } from "@hire/components/sliders/ExperienceRangeSlider";
import { Text } from "@otta/design";

const levels: Record<Year, string> = {
  0: "Entry",
  1: "Junior",
  2: "Junior",
  3: "Mid",
  4: "Mid",
  5: "Senior",
  6: "Senior",
  7: "Senior",
  8: "Senior",
  9: "Expert",
  10: "Expert",
};

const getAllLevels = (lower: Year, upper: Year) => {
  const allLevels = [];

  for (let i = lower; i <= upper; i++) {
    allLevels.push(levels[i]);
  }

  return [...new Set(allLevels)];
};

export function JobLevel({
  fields: { internship, experienceRange },
}: SectionProps): React.ReactElement | null {
  const [lower, upper] = experienceRange;
  const allLevels = getAllLevels(lower, upper);

  return internship ? null : (
    <Text>
      {allLevels.map((level, index) => {
        return allLevels.length === 1
          ? `${level} level`
          : allLevels.length === index + 1
          ? `${level} levels`
          : allLevels.length === 2
          ? `${level} and `
          : `${level}, `;
      })}
    </Text>
  );
}
