import { SourcingPreferencesFieldProps } from "./interfaces";

import { VisaRegion } from "@hire/schema";
import { Icon } from "@otta/icons";
import { VerticalSpacing, Label, SelectField, Text } from "@otta/design";
import { TooltipWithIcon } from "@hire/components/TooltipWithIcon";

const VISA_LOCATION_OPTIONS = [
  { label: "UK", value: VisaRegion.Uk },
  { label: "US", value: VisaRegion.Us },
  { label: "EU", value: VisaRegion.Eu },
];

export function VisaSponsorshipRegionsField({
  onChange,
  preferences,
}: SourcingPreferencesFieldProps): React.ReactElement {
  return (
    <VerticalSpacing size={-4}>
      <TooltipWithIcon
        content="Use this to include candidates who require a visa to work in the selected region/regions."
        icon={<Icon icon="circle-info" size={1} />}
      >
        <Label htmlFor="visaSponsorshipRegions">
          <Text bold>Visa sponsorship</Text>
        </Label>
      </TooltipWithIcon>

      <SelectField
        isMulti
        inputId="visaSponsorshipRegions"
        placeholder="Select locations"
        options={VISA_LOCATION_OPTIONS}
        value={VISA_LOCATION_OPTIONS.filter(({ value }) =>
          (preferences.visaSponsorshipRegions || []).find(
            (l: string) => l === value
          )
        )}
        onChange={vals => {
          onChange({ visaSponsorshipRegions: vals.map(({ value }) => value) });
        }}
      />
    </VerticalSpacing>
  );
}
