import { JobFormBullet, JobFormBullets } from "../JobFormBullets";
import { IOption } from "../types";
import { useJobEditFormikContext } from "../useHandlers";

import { LanguageRequirements } from "./LanguageRequirements";

import { Fieldset, FieldWrapper } from "@otta/design";

export function WhoYouAre({
  languageRequirementOptions,
}: {
  languageRequirementOptions: IOption[];
}) {
  const form = useJobEditFormikContext();

  const updateValue = (fieldName: string, values: JobFormBullet[]) => {
    const updatedOrderedValues = values.map((value, index) => ({
      ...value,
      order: index,
    }));

    form.setFieldValue(fieldName, updatedOrderedValues);
  };

  return (
    <Fieldset
      legend="Who you are"
      advice={
        <>
          Tell candidates the must-have and desirable job requirements that
          would make them a good fit for this job. For example, 'You have 1-3
          years of experience programming in a production environment' or
          'You've led design teams'.
        </>
      }
    >
      <FieldWrapper
        label="Must-have requirements"
        fieldError={
          form.touched.requirements
            ? (form.errors.requirements as string)
            : undefined
        }
        required
      >
        {({ field }) => (
          <JobFormBullets
            fieldName="must-have-requirements"
            nounSingular="a must-have requirement"
            values={form.values.requirements}
            placeholders={[
              "You have 1-3 years of experience programming in a production environment",
              "You're a strong communicator capable of justifying decisions, proposing new ideas and challenging the status quo",
              "You appreciate good design (UI/UX)",
            ]}
            onChange={v => updateValue("requirements", v)}
            onBlur={() =>
              form.setFieldTouched(
                "requirements[0]",
                true,
                form.validateOnBlur ?? false
              )
            }
            isDesirable={false}
            fieldProps={[field]}
          />
        )}
      </FieldWrapper>
      <FieldWrapper
        label="Desirable requirements"
        fieldError={
          form.touched.desirableRequirements
            ? (form.errors.desirableRequirements as string)
            : undefined
        }
        required={false}
      >
        {({ field }) => (
          <JobFormBullets
            fieldName="desirable-requirements"
            nounSingular="a desirable requirement"
            values={form.values.desirableRequirements}
            placeholders={[
              "You have 1-3 years of experience programming in a production environment",
              "You're a strong communicator capable of justifying decisions, proposing new ideas and challenging the status quo",
              "You appreciate good design (UI/UX)",
            ]}
            onChange={v => updateValue("desirableRequirements", v)}
            onBlur={() =>
              form.setFieldTouched(
                "desirableRequirements[0]",
                true,
                form.validateOnBlur ?? false
              )
            }
            isDesirable={true}
            fieldProps={[field]}
          />
        )}
      </FieldWrapper>
      <LanguageRequirements
        languageRequirementOptions={languageRequirementOptions}
      />
    </Fieldset>
  );
}
