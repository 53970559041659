import { CandidateCardFragment, CandidateCardJobQuery } from "@hire/schema";
import { palette } from "@otta/design-tokens";
import { RoundedTag } from "@hire/components/tags/RoundedTag";

interface CandidateSavedThisJobOrCompanyBadgeProps {
  candidate: CandidateCardFragment;
  job: NonNullable<CandidateCardJobQuery["companyJob"]>;
}

export function CandidateSavedThisJobOrCompanyBadge({
  candidate,
  job,
}: CandidateSavedThisJobOrCompanyBadgeProps): React.ReactElement | null {
  const candidateSavedThisJob = candidate.savedJobsAtCompany.some(
    ({ externalId }) => externalId === job.externalId
  );

  const candidateSavedThisCompany = candidate.savedJobsAtCompany.length > 0;

  return candidateSavedThisJob ? (
    <RoundedTag
      icon="star"
      content="Saved this job"
      color={palette.brand.yellow}
      hoverText="The candidate saved this job when they were searching"
    />
  ) : candidateSavedThisCompany ? (
    <RoundedTag
      icon="star"
      content="Saved this company"
      color={palette.brand.yellow}
      hoverText="The candidate saved a job at your company"
    />
  ) : null;
}
