import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { CircularImg } from "@hire/pages/CompanySettings/Team/CircularImg";

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: sm;
`;

const RecruiterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RecruiterDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

interface RecruiterInfoProps {
  firstName: string;
  lastName: string;
  email: string;
  profileImagePath: string | null;
  isCurrentUser: boolean;
}

export function RecruiterInfo({
  firstName,
  lastName,
  email,
  profileImagePath,
  isCurrentUser,
}: RecruiterInfoProps): React.ReactElement {
  return (
    <RecruiterWrapper>
      {profileImagePath && (
        <Centered>
          <CircularImg src={profileImagePath} width="41px" alt="Profile" />
        </Centered>
      )}
      <RecruiterDetails>
        <Text data-cs-mask bold>
          {firstName} {lastName}{" "}
        </Text>
        <Text data-cs-mask size={-1}>
          {email} {isCurrentUser && " (You)"}
        </Text>
      </RecruiterDetails>
    </RecruiterWrapper>
  );
}
