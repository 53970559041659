import styled from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";
import { Icon, OttaIconsId } from "@otta/icons";
import { Text } from "@otta/design";

const Tag = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  padding: xs sm;
  background-color: ${({ color }) => color ?? palette.extended.blue.shade200};
  border-radius: ${pxToRem(8)};
  width: fit-content;

  i {
    margin-right: xs;
  }
`;

export const RectangleTag = ({
  color,
  content,
  textColor,
  fontSize = -1,
  iconSize = -1,
  icon,
  iconColor,
  bold = true,
}: {
  content: React.ReactNode;
  color?: string;
  textColor?: string;
  icon?: OttaIconsId;
  iconSize?: number;
  iconColor?: string;
  fontSize?: number;
  bold?: boolean;
}) => {
  return (
    <Tag color={color}>
      {icon && (
        <Icon icon={icon} size={iconSize} style={{ color: iconColor }} />
      )}
      <Text size={fontSize} color={textColor} bold={bold}>
        {content}
      </Text>
    </Tag>
  );
};
