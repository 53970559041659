import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import {
  AuthorizeAtsIntegrationDocument,
  CompanyAts,
  CurrentUserDocument,
} from "@hire/schema";
import { PageLoadingSpinner } from "@hire/components/PageLoadingSpinner";
import { Middle, Spacing, Text } from "@otta/design";
import { hireAppUser } from "@hire/util/user";

function CodeExchange({ onComplete }: { onComplete(): void }) {
  const params = useParams<"client">();
  const [searchParams] = useSearchParams();
  const client = params.client?.toUpperCase() as CompanyAts;

  const code = searchParams.get("code");

  const errorCode = searchParams.get("error");

  const oauthError =
    code === "access_denied" || errorCode === "access_denied"
      ? "Your ATS was unable to authorize this account, check to see you have used the correct account details"
      : searchParams.get("error_description");

  const [mutation, { error }] = useMutation(AuthorizeAtsIntegrationDocument, {
    errorPolicy: "all",
    onCompleted(data) {
      if (data.authorizeAtsIntegration) {
        onComplete();
      }
    },
  });

  const graphqlError = error?.graphQLErrors?.[0]?.message;

  useEffect(() => {
    if (code) {
      mutation({
        variables: {
          code,
          ats: client,
        },
      });
    }
  }, [code, mutation, client]);

  if (oauthError || graphqlError) {
    return (
      <Middle maxWidth={580}>
        <Spacing>
          <Text as="h2" size={4}>
            Something went wrong
          </Text>
          <Text as="h3" bold size={2}>
            Error: {oauthError || graphqlError}
          </Text>
        </Spacing>
      </Middle>
    );
  }

  if (!code) {
    return (
      <Middle maxWidth={580}>
        <Text as="h2" size={4} data-testid="no-code">
          No authorization code provided.
        </Text>
      </Middle>
    );
  }

  return <PageLoadingSpinner />;
}

export function ATSOAuthCallback() {
  const navigate = useNavigate();
  const { data, loading } = useQuery(CurrentUserDocument);

  const user = hireAppUser(data?.me);

  if (loading) {
    return <PageLoadingSpinner />;
  }

  const company = user?.currentCompany;

  if (!company) {
    return <Navigate to="/" />;
  }

  return (
    <CodeExchange
      onComplete={() =>
        navigate(`/${company.urlSafeName}/settings/ats`, { replace: true })
      }
    />
  );
}
